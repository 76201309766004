import { LinkOutlined } from "@mui/icons-material";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from "@mui/material";
import { useState } from "react";
import { toast } from "react-toastify";

import "survey-core/defaultV2.min.css";
import { API } from "../../../common/api-client/or-api/api";
import { ShareSubmissionRequest } from "../../../common/api-client/or-api/models/ShareSubmissionRequest";
import { validateEmail } from "../../../common/utils";

interface ShareReportDialogProps {
  isShareDialogOpen: boolean;
  setIsShareDialogOpen: (value: boolean) => void;
  appName: string;
  orgId: string;
  id: string;
}

const ShareReportDialog = ({
  isShareDialogOpen,
  setIsShareDialogOpen,
  appName,
  orgId,
  id,
}: ShareReportDialogProps) => {
  const [shareEmail, setShareEmail] = useState("");

  const shareReport = async (toEmail: string) => {
    const request: ShareSubmissionRequest = {
      share_email: toEmail,
    };
    try {
      await API.submission.shareSubmission(id ?? "", request);
    } catch (error) {
      console.error("Error while sharing report", error);
    }
  };

  const shareReportAction = async (email: string) => {
    if (validateEmail(email)) {
      await shareReport(email).then(() => {
        toast.success("Report shared successfully");
        setShareEmail("");
        setIsShareDialogOpen(false);
      });
    } else {
      toast.error("Invalid email address");
    }
  };

  return (
    <Dialog
      open={isShareDialogOpen}
      onClose={() => {
        setIsShareDialogOpen(false);
      }}
    >
      <DialogTitle>Share Report</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Please copy the link below and share it with the relevant parties. Or
          enter their email addresses below to send them a direct link.
        </DialogContentText>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: "8px",
            paddingTop: "8px",
          }}
        >
          <TextField
            fullWidth
            variant="outlined"
            label="Email Address"
            onKeyDown={(event: React.KeyboardEvent<HTMLInputElement>) => {
              if (event.key === "Enter") {
                shareReportAction(shareEmail);
              }
            }}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              setShareEmail(event.target.value.trim())
            }
          />
        </div>
      </DialogContent>
      <DialogActions
        sx={{
          display: "flex",
          justifyContent: "space-between",
          padding: "16px",
        }}
      >
        <div>
          <Button
            onClick={() => {
              navigator.clipboard
                .writeText(
                  `${window.location.origin}/${appName}/${orgId}/report/${id}/condensed`,
                )
                .then(() => {
                  toast.success("Link copied to clipboard");
                });
            }}
            color="secondary"
            startIcon={<LinkOutlined />}
            variant="outlined"
          >
            Copy Link
          </Button>
        </div>
        <div>
          <Button onClick={() => setIsShareDialogOpen(false)} color="secondary">
            Close
          </Button>
          <Button
            onClick={async () => {
              shareReportAction(shareEmail);
            }}
            color="primary"
            variant="contained"
          >
            Share
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  );
};

export default ShareReportDialog;
