/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { DeleteOrgTemplatesRequest } from '../models/DeleteOrgTemplatesRequest';
import type { DeleteOrgTemplatesResponse } from '../models/DeleteOrgTemplatesResponse';
import type { GetOrgConfigResponse } from '../models/GetOrgConfigResponse';
import type { GetOrgTemplatesResponse } from '../models/GetOrgTemplatesResponse';
import type { OrgConfigModel } from '../models/OrgConfigModel';
import type { PatchOrgConfigRequest } from '../models/PatchOrgConfigRequest';
import type { PatchOrgTemplatesRequest } from '../models/PatchOrgTemplatesRequest';
import type { PatchOrgTemplatesResponse } from '../models/PatchOrgTemplatesResponse';
import type { PutOrgTemplatesRequest } from '../models/PutOrgTemplatesRequest';
import type { PutOrgTemplatesResponse } from '../models/PutOrgTemplatesResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class OrgConfigService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * @param orgId 
     * @returns GetOrgConfigResponse 
     * @throws ApiError
     */
    public get(
orgId: string,
): CancelablePromise<GetOrgConfigResponse> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/report/org/{org_id}/config',
            path: {
                'org_id': orgId,
            },
        });
    }

    /**
     * @param orgId 
     * @returns GetOrgTemplatesResponse 
     * @throws ApiError
     */
    public getTemplate(
orgId: string,
): CancelablePromise<GetOrgTemplatesResponse> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/report/org/{org_id}/template',
            path: {
                'org_id': orgId,
            },
        });
    }

    /**
     * @param orgId 
     * @param requestBody 
     * @returns PutOrgTemplatesResponse 
     * @throws ApiError
     */
    public putTemplate(
orgId: string,
requestBody: PutOrgTemplatesRequest,
): CancelablePromise<PutOrgTemplatesResponse> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/report/org/{org_id}/template',
            path: {
                'org_id': orgId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param orgId 
     * @param requestBody 
     * @returns DeleteOrgTemplatesResponse 
     * @throws ApiError
     */
    public deleteTemplate(
orgId: string,
requestBody: DeleteOrgTemplatesRequest,
): CancelablePromise<DeleteOrgTemplatesResponse> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/report/org/{org_id}/template',
            path: {
                'org_id': orgId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param orgId 
     * @param requestBody 
     * @returns PatchOrgTemplatesResponse 
     * @throws ApiError
     */
    public patchTemplate(
orgId: string,
requestBody: PatchOrgTemplatesRequest,
): CancelablePromise<PatchOrgTemplatesResponse> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/report/org/{org_id}/template',
            path: {
                'org_id': orgId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param orgId 
     * @returns OrgConfigModel 
     * @throws ApiError
     */
    public get1(
orgId: string,
): CancelablePromise<OrgConfigModel> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/report/portal/{org_id}/org_config',
            path: {
                'org_id': orgId,
            },
        });
    }

    /**
     * @param orgId 
     * @param requestBody 
     * @returns OrgConfigModel 
     * @throws ApiError
     */
    public patch(
orgId: string,
requestBody: PatchOrgConfigRequest,
): CancelablePromise<OrgConfigModel> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/report/portal/{org_id}/org_config',
            path: {
                'org_id': orgId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}
