import React, { useState } from "react";

import { ReportField } from "./ReportField";
import { ShowHideButton } from "../fields/UI/ShowHideButton";
import styles from "../report.module.css";

export const InvolvedPersonsField = (props) => {
  const { data } = props;
  const [show, setShow] = useState(false);
  return (
    <>
      {data &&
        data.length > 0 &&
        data.map((row, index) => (
          <div key={index}>
            <div className={styles.subHeading}>
              Person {index + 1}
              <ShowHideButton
                onClick={() => {
                  setShow(!show);
                }}
              >
                {" "}
                {show ? "Hide " : "Show "} empty fields{" "}
              </ShowHideButton>
            </div>
            {(row && row.firstName) || show ? (
              <ReportField
                label="First Name"
                value={row.firstName}
                source="row.firstName"
              />
            ) : null}
            {(row && row.lastName) || show ? (
              <ReportField
                label="Last Name"
                value={row.lastName}
                source="row.lastName"
              />
            ) : null}
            {(row && row.sSN) || show ? (
              <ReportField label="SSN" value={row.sSN} source="row.sSN" />
            ) : null}
            {(row && row.email) || show ? (
              <ReportField
                value={row.email}
                source="row.email"
                label="Email Address"
              />
            ) : null}
            {(row && row.contactPhoneNumber) || show ? (
              <ReportField
                value={row.contactPhoneNumber}
                source="row.contactPhoneNumber"
                label="Contact Phone Number"
              />
            ) : null}
            {(row && row.workPhone) || show ? (
              <ReportField
                value={row.workPhone}
                source="row.workPhone"
                label="Work Phone Number"
              />
            ) : null}
            {(row && row.involvementType) || show ? (
              <ReportField
                className="report-field-row"
                value={row.involvementType}
                source="row.involvementType"
                label="Involvement Type"
              />
            ) : null}
            {(row && row.gender) || show ? (
              <ReportField
                value={row.gender}
                source="row.gender"
                label="Gender"
              />
            ) : null}
            {(row && row.age) || show ? (
              <ReportField value={row.age} source="row.age" label="Age" />
            ) : null}
          </div>
        ))}
    </>
  );
};
