import { Share } from "@mui/icons-material";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import { forwardRef, useContext, useMemo, useState } from "react";

//Internal Imports
import styles from "./AlertCard.module.scss";
import AlertCardMedia from "./ImageGallery";
import { serviceConfigurations } from "../../../common/serviceConfigurations";
import { formatDateShort, formatWeekdayShort } from "../../../common/utils";
import { AuthStateContext } from "../../../context";
import { BulletinResourceResponse, IUserPermission } from "../../../types";
import { parseUserPermissions } from "../../UserManagement/utils";
import ShareDialog from "../ShareDialog";
import { shortenedResolutionReasons } from "../constants";

const priorityColorMapping = {
  //Low Priority - To Identify - pastel blue
  "Suspect(s) to Identify": "#AEC6CF",
  "Vehicle(s) to Identify": "#AEC6CF",
  "Suspect(s) & Vehicle(s) to Identify": "#AEC6CF",

  // FYI - pastel green
  "Police Information": "#77DD77",
  Intelligence: "#77DD77",
  Other: "#77DD77",

  //Medium Priority - pastel yellow
  "Missing Person": "#FFFACD",
  "Arrests Made": "#FFFACD",
  "Arrest Made": "#FFFACD",
  "Auto Theft": "#FFFACD",

  //High Priority - pastel red
  "Officer Safety": "#FF6961",
  Wanted: "#FF6961",
  Warrant: "#FF6961",
};

const AlertCard = forwardRef<HTMLDivElement, any>(
  (
    {
      index,
      alertItem,
      alertClickHandler,
      className,
    }: {
      index: number;
      alertItem: BulletinResourceResponse;
      alertClickHandler: (
        alertItem: BulletinResourceResponse,
        index: number,
      ) => void;
      className?: string;
    },
    ref,
  ) => {
    const {
      resolutions,
      evidence_files,
      issuing_police_service,
      occurrence_number,
      type,
      description,
      incident_time,
      associated_crime,
      creator_user_id,
    } = alertItem;
    const { currentUser } = useContext(AuthStateContext);

    const [alertPermissions] = useState(
      currentUser &&
        parseUserPermissions(currentUser?.permissions as IUserPermission[]),
    );
    const readOnly = alertPermissions
      ? !alertPermissions.canCreateBulletin
      : false;

    const policeServiceName = issuing_police_service?.name;
    const getPoliceServiceName = useMemo(() => {
      const config = serviceConfigurations[policeServiceName];
      return config?.region || config?.shortName || policeServiceName;
    }, [policeServiceName]);

    const occurenceNumber = useMemo(() => {
      if (occurrence_number?.length > 0) {
        return occurrence_number.join(", ");
      }
      return "";
    }, [occurrence_number]);

    const alertCreatorId = alertItem?.creator_user_id;
    const isCreator =
      currentUser?.id?.toString() === alertCreatorId?.toString();

    const latestPublishedResolution = useMemo(() => {
      return resolutions
        .filter((resolution) => resolution.published)
        .sort((a, b) => {
          return (
            new Date(b.time_updated).getTime() -
            new Date(a.time_updated).getTime()
          );
        })[0];
    }, [resolutions]);

    const parseMediaDetails = () => {
      const mediaDetails: {
        media_type: "video" | "image";
        download_url: string;
      }[] = [];
      const evidenceFiles = Object.values(evidence_files);
      if (evidenceFiles.length > 0) {
        evidenceFiles.forEach((file) => {
          if (file.content_type.includes("video")) {
            mediaDetails.push({
              media_type: "video",
              download_url: file.download_url,
            });
          } else {
            mediaDetails.push({
              media_type: "image",
              download_url: file.thumbnail_url,
            });
          }
        });
      }
      mediaDetails.sort((a, b) => (a.media_type === "video" ? -1 : 1));
      return mediaDetails.slice(0, 4);
    };
    const mediaDetails = parseMediaDetails();

    const [isShareModalOpen, setIsShareModalOpen] = useState(false);

    const titleColor =
      priorityColorMapping[type] ?? (priorityColorMapping[type] || "");

    const getResolutionReason = () => {
      if (
        shortenedResolutionReasons[latestPublishedResolution.reason] !== "Other"
      ) {
        return shortenedResolutionReasons[latestPublishedResolution.reason];
      } else if (latestPublishedResolution?.reason === "Other") {
        return latestPublishedResolution?.other_reason;
      }
    };
    return (
      <div
        ref={ref}
        key={index}
        className={`${styles.alert_item} ${className}`}
      >
        {isShareModalOpen && (
          <ShareDialog
            isShareModalOpenArg={isShareModalOpen}
            setIsShareModalOpenArg={setIsShareModalOpen}
            bulletinID={alertItem?.id}
          />
        )}
        {(readOnly || (!readOnly && isCreator)) && (
          <div
            className={styles.share_icon}
            onClick={() => {
              setIsShareModalOpen(true);
            }}
          >
            <Share />
          </div>
        )}
        <div
          className={styles.content}
          onClick={() => {
            //Differentiate between Text Select and OnClick event
            if (window.getSelection()?.toString().length === 0) {
              alertClickHandler(alertItem, index);
            }
          }}
        >
          {type && (
            <div
              className={styles.alert_title}
              style={{ background: titleColor }}
            >
              {type}
            </div>
          )}

          <div className={styles.alert_image_parent}>
            {latestPublishedResolution?.reason && (
              <div
                className={
                  latestPublishedResolution?.resolved
                    ? styles.resolved_box_resolved
                    : styles.resolved_box_active
                }
              >
                <Typography variant="caption">
                  {getResolutionReason()}
                </Typography>
              </div>
            )}
            {mediaDetails.length > 0 ? (
              <AlertCardMedia
                mediaDetails={mediaDetails}
                resolved={latestPublishedResolution?.resolved}
              />
            ) : (
              //TODO: use thumbnail_url instead
              <div className={styles.alert_description}>{description}</div>
            )}
          </div>

          <div className={styles.alert_action}>
            <Divider />
            <div className={styles.alert_info}>
              {issuing_police_service && (
                <div className={styles.truncate}>
                  <span className={styles.alert_title}>
                    {getPoliceServiceName}
                  </span>{" "}
                  {occurenceNumber !== "" && (
                    <span className={styles.alert_subtitle}>
                      - {occurenceNumber}
                    </span>
                  )}
                </div>
              )}
              <div className={styles.alert_date_parent}>
                <Typography variant="caption">
                  {formatDateShort(incident_time)}
                </Typography>
                <Typography variant="caption">
                  &nbsp;<i>({formatWeekdayShort(incident_time)})</i>
                </Typography>
              </div>

              <div className={styles.truncate}>
                {associated_crime.join(", ")}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  },
);

AlertCard.displayName = "AlertCard";

export default AlertCard;
