import React, { createContext, useContext, useState } from "react";
import { useSidebarState } from "react-admin";

const DrawerContext = createContext<{
  isOpen: boolean;
  openDrawer: () => void;
  closeDrawer: () => void;
}>({} as { isOpen: boolean; openDrawer: () => void; closeDrawer: () => void });

interface DrawerProviderProps {
  children: React.ReactNode;
}

export const DrawerProvider = ({ children }: DrawerProviderProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const [open, setOpen] = useSidebarState();

  const openDrawer = () => {
    setOpen(false);
    setIsOpen(true);
  };

  const closeDrawer = () => setIsOpen(false);

  return (
    <DrawerContext.Provider value={{ isOpen, openDrawer, closeDrawer }}>
      {children}
    </DrawerContext.Provider>
  );
};

export const useDrawer = () => useContext(DrawerContext);
