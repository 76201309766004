import { ArrowBack, PersonAdd, Save } from "@mui/icons-material";
import { Button, CircularProgress, Grid } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";

import {
  DeactivateUserButton,
  emptyUser,
  fieldValidationRules,
  generateInformationGrid,
  initValidationGrid,
} from "./utils";
import { apiAlertClient, apiReportClient } from "../../../../common/api-client";
import { PutUserRequest } from "../../../../common/api-client/or-api";
import { API } from "../../../../common/api-client/or-api/api";
import { AuthStateContext } from "../../../../context";
import { IOrgUser } from "../../../../types";
import { Permission } from "../../types";
import { userPermissionChecks } from "../../utils";

// const giveAlertAccess = async (orgUserId) => {
//   try {
//     await apiAlertClient.putUser(orgUserId);
//   } catch (error) {
//     console.log(
//       `User ${orgUserId} is already in the alert user table or another error occurred:`,
//       error
//     );
//   }
//   try {
//     await apiReportClient.updateAppAccess(orgUserId, app_name, app_access);
// };

// const giveReportAccess = async (orgUserId) => {
//   try {
//     await apiReportClient.putReportUser(orgUserId);
//   } catch (error) {
//     console.log(
//       `User ${orgUserId} is already in the report user table or another error occurred:`,
//       error
//     );
//   }
// };

// const giveRegistryAccess = async (orgUserId) => {
//   // TODO: Implement this function
// };

// const giveAdminAccess = (orgUserId) => {
//   apiReportClient.promoteToAdmin(orgUserId);
// };

const UpdateUserPage: React.FC = () => {
  const navigate = useNavigate();
  const [orgUser, setOrgUser] = useState<IOrgUser>(emptyUser);
  const [initOrgUser, setInitOrgUser] = useState<IOrgUser>(emptyUser);
  const [initOrgUserPermissions, setInitOrgUserPermissions] = useState(
    [] as Permission[],
  );
  const [orgUserPermissions, setOrgUserPermissions] = useState(
    [] as Permission[],
  );
  const [orgId, setOrgId] = useState<string>("");
  const [orgUserId, setOrgUserId] = useState<string>("");

  const url = window.location.href;
  const parts = url.split("/").filter((part) => part.length > 0);

  const checkAddOrEdit = () => {
    if (parts.includes("new")) {
      return "add";
    }
    if (parts.includes("edit")) {
      return "edit";
    }
    return "view";
  };

  const mode = checkAddOrEdit();

  const { currentUser, setCurrentUser } = useContext(AuthStateContext);
  const currentUserPermissions =
    currentUser?.permissions?.map((permission) => permission.title) || [];

  const [fieldValidationGrid, setFieldValidationGrid] = useState<{
    [key in keyof Omit<IOrgUser, "id">]: boolean;
  }>(initValidationGrid);

  const adminPermissionsChecks = userPermissionChecks(currentUserPermissions);
  const orgUserPermissionsChecks = userPermissionChecks(orgUserPermissions);
  const [modifyingOwnProfile, setModifyingOwnProfile] = useState(false);
  const fieldValidationRulesArg = fieldValidationRules(
    orgUser,
    mode,
    modifyingOwnProfile,
  );

  const { RUBI_ORG_OWN_ADD_USER, ALERT, REPORT, REPORT_V2, CAMERA_REGISTRY } =
    Permission;

  useEffect(() => {
    if (mode === "add") {
      const orgIdUrl = parts[parts.length - 3];
      if (orgIdUrl.length > 0) {
        console.info("Setting org id: ", orgIdUrl);
        setOrgId(orgIdUrl);
      } else {
        console.error("No org id found in url");
      }
    }
    if (mode === "edit") {
      const orgIdUrl = parts[parts.length - 4];
      const orgUserIdUrl = parts[parts.length - 2];
      if (orgIdUrl.length > 0) {
        console.info("Setting org id: ", orgIdUrl);
        setOrgId(orgIdUrl);
      }
      if (orgUserIdUrl.length > 0) {
        if (orgUserIdUrl === "profile") {
          console.info("Setting user id: ", currentUser?.id);
          setOrgUserId(currentUser?.id ?? "");
          setModifyingOwnProfile(true);
        } else {
          console.info("Setting user id: ", orgUserIdUrl);
          setOrgUserId(orgUserIdUrl);
        }
      }
    }
  }, []);

  useEffect(() => {
    if (orgUserId) {
      console.info("Getting user details for user id: ", orgUserId);
      apiReportClient.getUser(orgUserId).then((response) => {
        setOrgUser(response);
        setInitOrgUser(response);
      });
    }
  }, [orgUserId]);

  const handleUserAction = async (action: "add" | "update") => {
    const newFieldValidationGrid = { ...fieldValidationGrid };

    Object.keys(fieldValidationRulesArg).forEach((key) => {
      newFieldValidationGrid[key] = fieldValidationRulesArg[key];
    });

    setFieldValidationGrid(newFieldValidationGrid);

    const allFieldsValid = Object.values(newFieldValidationGrid).every(
      (value) => value,
    );

    if (allFieldsValid) {
      // we need to add a 'delete org user' function

      const updateState = initOrgUser === emptyUser ? "add" : "update";
      const apiAction =
        updateState === "add"
          ? apiReportClient.putUser
          : apiReportClient.updateOrgUser;

      const response = await apiAction(
        updateState === "add" ? orgId : orgUserId,
        orgUser,
      );

      if (action === "add") {
        setOrgUserId(response.id);
        setOrgUser(response);
        setInitOrgUser(response);
      }

      if (modifyingOwnProfile) {
        setCurrentUser({
          ...currentUser,
          ...response,
          profile_update_required: false,
        });
        toast.info(`Profile updated successfully`);
        navigate("/");
        return;
      }

      const userIdentifier = action === "add" ? response.id : orgUserId;

      // Queue access functions to be executed after org user ID is set
      const accessFunctionsQueue: (() => Promise<void>)[] = [];
      const revokeFunctionsQueue: (() => Promise<void>)[] = [];

      console.log(
        "🚀 ~ file: UpdateUserPage.tsx:164 ~ orgUserPermissions.forEach ~ orgUserPermissions:",
        orgUserPermissions,
      );
      orgUserPermissions.forEach((permission) => {
        if (!initOrgUserPermissions.includes(permission)) {
          accessFunctionsQueue.push(async () => {
            switch (permission) {
              case ALERT:
                await apiAlertClient
                  .putUser(userIdentifier)
                  .then(() => {
                    console.log("User added to alert user table");
                  })
                  .catch((error) => {
                    console.error(error);
                  })
                  .finally(() => {
                    apiReportClient
                      .updateAppAccess(userIdentifier, "alert", true)
                      .catch((error) => {
                        console.error(error);
                      })
                      .then(() => {
                        console.log("Updated alert access for user", {
                          userIdentifier,
                        });
                      })
                      .catch((error) => {
                        console.error(error);
                      });
                    // apiAlertClient
                    //   .giveAlertAccess(userIdentifier, true)
                    //   .then(() => {
                    //     console.log("Updated alert access for user", {
                    //       userIdentifier,
                    //     });
                    //   })
                    //   .catch((error) => {
                    //     console.error(error);
                    //   });
                  });
                break;
              case REPORT:
                console.log("give report access");
                apiReportClient
                  .putReportUser(userIdentifier)
                  .then(() => {
                    console.log("Updated report access for user", {
                      userIdentifier,
                    });
                  })
                  .catch((error) => {
                    console.error(error);
                  })
                  .finally(() => {
                    apiReportClient
                      .updateAppAccess(userIdentifier, "report", true)
                      .then(() => {
                        console.log("User added to report user table");
                      })
                      .catch((error) => {
                        console.error(error);
                      });
                  });
                break;
              case REPORT_V2:
                await API.portal
                  .putRv2User({
                    id: userIdentifier,
                  })
                  .then(() => {
                    console.log("User added to report user table");
                  })
                  .catch((error) => {
                    console.error(error);
                  })
                  .finally(() => {
                    apiReportClient
                      .updateAppAccess(userIdentifier, "report_v2", true)
                      .then(() => {
                        console.log("Updated report v2 access for user", {
                          userIdentifier,
                        });
                      })
                      .catch((error) => {
                        console.error(error);
                      });
                  });
                break;
              case CAMERA_REGISTRY:
                console.log("give camera registry access");
                break;
              case RUBI_ORG_OWN_ADD_USER:
                console.log("give admin access");
                apiReportClient
                  .promoteToAdmin(userIdentifier)
                  .then(() => {
                    console.log("User promoted to admin");
                  })
                  .catch((error) => {
                    console.error(error);
                  });
                break;
              default:
                break;
            }
          });
        }
      });

      initOrgUserPermissions.forEach((permission) => {
        if (!orgUserPermissions.includes(permission)) {
          revokeFunctionsQueue.push(async () => {
            switch (permission) {
              case ALERT:
                apiReportClient.updateAppAccess(userIdentifier, "alert", false);
                break;
              case REPORT:
                apiReportClient.updateAppAccess(
                  userIdentifier,
                  "report",
                  false,
                );
                break;
              case REPORT_V2:
                apiReportClient.updateAppAccess(
                  userIdentifier,
                  "report_v2",
                  false,
                );
                break;
              case CAMERA_REGISTRY:
                apiReportClient.updateAppAccess(
                  userIdentifier,
                  "camera_registry",
                  false,
                );
                break;
              case RUBI_ORG_OWN_ADD_USER:
                // TODO: Implement this function
                break;
              default:
                break;
            }
          });
        }
      });

      // Execute access functions
      await Promise.all(accessFunctionsQueue.map((fn) => fn()))
        .then(() => {
          toast.info(`Org user ${action === "add" ? "added" : "updated"}`);
          navigate(`/organizations/${orgId}/users/`);
        })
        .catch((error) => {
          console.error(error);
        });

      // Execute revoke functions
      await Promise.all(revokeFunctionsQueue.map((fn) => fn())).then(() => {
        toast.info(`Org user ${action === "add" ? "added" : "updated"}`);
        navigate(`/organizations/${orgId}/users/`);
      });
    }
  };

  const AddUserButton = ({ disabled = false }) => {
    return (
      <Button
        variant="contained"
        onClick={() => {
          handleUserAction("add");
        }}
        startIcon={<PersonAdd />}
        disabled={disabled}
      >
        Add User
      </Button>
    );
  };

  const SaveButton = ({ disabled = false }) => {
    return (
      <Button
        variant="contained"
        color="primary"
        startIcon={<Save />}
        onClick={() => {
          handleUserAction("update");
        }}
        disabled={disabled}
      >
        Save
      </Button>
    );
  };

  const BackToUsersListButton = (orgIdArg: string) => {
    return (
      <Button
        variant="contained"
        onClick={() => {
          navigate(`/organizations/${orgIdArg}/users`);
        }}
        startIcon={<ArrowBack />}
      >
        Back to Users List
      </Button>
    );
  };

  const DeactivateUserBtn = () => {
    if (orgUser.id === currentUser?.id) {
      return null;
    }
    return (
      <DeactivateUserButton
        userData={orgUser}
        adminHasEditPermission={adminPermissionsChecks.canCreateUsers}
        userHasLoginPermission={orgUserPermissionsChecks.canLogin}
      />
    );
  };

  const EditModeButtons = () => {
    return (
      <Grid container justifyContent="space-between" alignItems="center">
        <Grid item xs={12} sm={6}>
          {/* {BackToUsersListButton(orgId)} */}
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          style={{
            textAlign: "right",
            display: "flex",
            justifyContent: "flex-end",
            gap: "8px",
          }}
        >
          <DeactivateUserBtn />
          <SaveButton
            disabled={
              !adminPermissionsChecks.canModifyUsers &&
              orgUser.id !== currentUser?.id
            }
          />
        </Grid>
      </Grid>
    );
  };

  const AddModeButtons = () => {
    return (
      <>
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item xs={12} sm={6}>
            {BackToUsersListButton(orgId)}
          </Grid>
          <Grid item xs={12} sm={6} style={{ textAlign: "right" }}>
            <AddUserButton disabled={!adminPermissionsChecks.canCreateUsers} />
          </Grid>
        </Grid>
      </>
    );
  };

  const informationGrid = generateInformationGrid(
    orgUser,
    setOrgUser,
    mode,
    fieldValidationGrid,
    setOrgUserPermissions,
    setInitOrgUserPermissions,
    orgUserPermissions,
    currentUserPermissions,
    orgId,
    modifyingOwnProfile,
  );

  if (mode === "edit" && orgUser.id === "") {
    return <CircularProgress />;
  }

  return (
    <>
      {informationGrid}
      {mode === "add" && <AddModeButtons />}
      {mode === "edit" && <EditModeButtons />}
    </>
  );
};

export default UpdateUserPage;
