import Button from "@mui/material/Button";
import React from "react";

export const ShowHideButton = ({ onClick, children }) => {
  return (
    <Button
      variant="outlined"
      style={{ maxHeight: "25px", margin: "8px" }}
      color="primary"
      size="small"
      onClick={onClick}
    >
      {children}
    </Button>
  );
};
