/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { GetSubmissionAttachmentDescriptionResponse } from '../models/GetSubmissionAttachmentDescriptionResponse';
import type { GetSubmissionResponsePortal } from '../models/GetSubmissionResponsePortal';
import type { GetSubmissionResponsePublic } from '../models/GetSubmissionResponsePublic';
import type { PatchSubmissionRequestPortal } from '../models/PatchSubmissionRequestPortal';
import type { PatchSubmissionRequestPublic } from '../models/PatchSubmissionRequestPublic';
import type { PatchSubmissionResponse } from '../models/PatchSubmissionResponse';
import type { PostSubmissionAttachmentRequest } from '../models/PostSubmissionAttachmentRequest';
import type { PostSubmissionAttachmentResponse } from '../models/PostSubmissionAttachmentResponse';
import type { PostSubmissionIssueIncidentResponsePortal } from '../models/PostSubmissionIssueIncidentResponsePortal';
import type { PostSubmissionStatusRequest } from '../models/PostSubmissionStatusRequest';
import type { PostSubmissionSupplementalStatusRequest } from '../models/PostSubmissionSupplementalStatusRequest';
import type { PutSubmissionRequestPortal } from '../models/PutSubmissionRequestPortal';
import type { PutSubmissionRequestPublic } from '../models/PutSubmissionRequestPublic';
import type { PutSubmissionResponsePortal } from '../models/PutSubmissionResponsePortal';
import type { PutSubmissionResponsePublic } from '../models/PutSubmissionResponsePublic';
import type { ShareSubmissionRequest } from '../models/ShareSubmissionRequest';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class SubmissionService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * @param requestBody 
     * @returns PutSubmissionResponsePortal 
     * @throws ApiError
     */
    public putPortal(
requestBody: PutSubmissionRequestPortal,
): CancelablePromise<PutSubmissionResponsePortal> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/report/portal/submission',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param submissionId 
     * @param requestBody 
     * @returns PatchSubmissionResponse 
     * @throws ApiError
     */
    public patchPortal(
submissionId: string,
requestBody: PatchSubmissionRequestPortal,
): CancelablePromise<PatchSubmissionResponse> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/report/portal/submission/{submission_id}',
            path: {
                'submission_id': submissionId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param submissionId 
     * @param requestBody 
     * @returns PostSubmissionAttachmentResponse 
     * @throws ApiError
     */
    public postSubmissionAttachmentPortal(
submissionId: string,
requestBody: PostSubmissionAttachmentRequest,
): CancelablePromise<PostSubmissionAttachmentResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/report/portal/submission/{submission_id}/attachment',
            path: {
                'submission_id': submissionId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param submissionId 
     * @param attachmentId 
     * @returns GetSubmissionAttachmentDescriptionResponse 
     * @throws ApiError
     */
    public getSubmissionAttachmentDescriptionRequestPortal(
submissionId: string,
attachmentId: string,
): CancelablePromise<GetSubmissionAttachmentDescriptionResponse> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/report/portal/submission/{submission_id}/describe_attachment/{attachment_id}',
            path: {
                'submission_id': submissionId,
                'attachment_id': attachmentId,
            },
        });
    }

    /**
     * @param submissionId 
     * @returns PostSubmissionIssueIncidentResponsePortal 
     * @throws ApiError
     */
    public generateIncident(
submissionId: string,
): CancelablePromise<PostSubmissionIssueIncidentResponsePortal> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/report/portal/submission/{submission_id}/generate_incident',
            path: {
                'submission_id': submissionId,
            },
        });
    }

    /**
     * @param submissionId 
     * @param requestBody 
     * @returns any 
     * @throws ApiError
     */
    public shareSubmission(
submissionId: string,
requestBody: ShareSubmissionRequest,
): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/report/portal/submission/{submission_id}/share',
            path: {
                'submission_id': submissionId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param submissionId 
     * @param requestBody 
     * @returns any 
     * @throws ApiError
     */
    public updateStatusPortal(
submissionId: string,
requestBody: PostSubmissionStatusRequest,
): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/report/portal/submission/{submission_id}/status',
            path: {
                'submission_id': submissionId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param submissionId 
     * @param requestBody 
     * @returns any 
     * @throws ApiError
     */
    public updateStatusPortal1(
submissionId: string,
requestBody: PostSubmissionSupplementalStatusRequest,
): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/report/portal/submission/{submission_id}/supplemental_status',
            path: {
                'submission_id': submissionId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param submissionId 
     * @param version 
     * @returns GetSubmissionResponsePortal 
     * @throws ApiError
     */
    public getPortal(
submissionId: string,
version: string,
): CancelablePromise<GetSubmissionResponsePortal> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/report/portal/submission/{submission_id}/{version}',
            path: {
                'submission_id': submissionId,
                'version': version,
            },
        });
    }

    /**
     * @param requestBody 
     * @returns PutSubmissionResponsePublic 
     * @throws ApiError
     */
    public putPublic(
requestBody: PutSubmissionRequestPublic,
): CancelablePromise<PutSubmissionResponsePublic> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/report/public/submission',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param submissionId 
     * @param requestBody 
     * @returns PatchSubmissionResponse 
     * @throws ApiError
     */
    public patchPublic(
submissionId: string,
requestBody: PatchSubmissionRequestPublic,
): CancelablePromise<PatchSubmissionResponse> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/report/public/submission/{submission_id}',
            path: {
                'submission_id': submissionId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param submissionId 
     * @param requestBody 
     * @returns PostSubmissionAttachmentResponse 
     * @throws ApiError
     */
    public postSubmissionAttachmentPublic(
submissionId: string,
requestBody: PostSubmissionAttachmentRequest,
): CancelablePromise<PostSubmissionAttachmentResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/report/public/submission/{submission_id}/attachment',
            path: {
                'submission_id': submissionId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param submissionId 
     * @param attachmentId 
     * @returns GetSubmissionAttachmentDescriptionResponse 
     * @throws ApiError
     */
    public getSubmissionAttachmentDescriptionRequestPublic(
submissionId: string,
attachmentId: string,
): CancelablePromise<GetSubmissionAttachmentDescriptionResponse> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/report/public/submission/{submission_id}/describe_attachment/{attachment_id}',
            path: {
                'submission_id': submissionId,
                'attachment_id': attachmentId,
            },
        });
    }

    /**
     * @param submissionId 
     * @param requestBody 
     * @returns any 
     * @throws ApiError
     */
    public updateStatusPublic(
submissionId: string,
requestBody: PostSubmissionStatusRequest,
): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/report/public/submission/{submission_id}/status',
            path: {
                'submission_id': submissionId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param submissionId 
     * @param version 
     * @returns GetSubmissionResponsePublic 
     * @throws ApiError
     */
    public getPublic(
submissionId: string,
version: string,
): CancelablePromise<GetSubmissionResponsePublic> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/report/public/submission/{submission_id}/{version}',
            path: {
                'submission_id': submissionId,
                'version': version,
            },
        });
    }

}
