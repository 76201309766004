import { LockOutlined, Visibility, VisibilityOff } from "@mui/icons-material";
import {
  Avatar,
  Button,
  CircularProgress,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import { useContext, useState } from "react";
import { useSearchParams } from "react-router-dom";

import { checkPasswords } from "./ChangePassword";
import { AuthStateContext } from "../../context";
import styles from "../../layout/Home.module.css";
import LoginWrapper from "../../layout/LoginWrapper";

const SetPassword = () => {
  const [error, setError] = useState("");
  const [newPassword, setNewPassword] = useState<string>("");
  const [confirmPassword, setConfirmPassword] = useState<string>("");
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const { setPassword, verifyPasswordStrength } = useContext(AuthStateContext);

  const [searchParams] = useSearchParams();
  const token = searchParams.get("token");

  const handleSubmit = async () => {
    setLoading(true);

    setError("");

    // if (newPassword !== confirmPassword) {
    //   setError("Passwords do not match.");
    //   setLoading(false);
    //   return;
    // }

    checkPasswords({
      oldPasswordRequired: false,
      formdata: { newpassword: newPassword, confirmpassword: confirmPassword },
      verifyPasswordStrength,
    }).then((passed) => {
      setLoading(true);
      if (!passed) {
        setLoading(false);
        return;
      }

      setPassword(token ?? "", newPassword).catch((er) => {
        setLoading(false);
      });
    });
  };

  const title = "Set your password";
  const description = (
    <>Please choose and confirm a password for your account.</>
  );

  function enterPressed(event: React.KeyboardEvent<HTMLInputElement>) {
    if (event.key === "Enter" && !loading) {
      handleSubmit();
    }
  }

  const endButton = {
    endAdornment: (
      <InputAdornment position="end">
        <IconButton
          aria-label="toggle password visibility"
          onClick={() => setShowPassword(!showPassword)}
        >
          {showPassword ? <Visibility /> : <VisibilityOff />}
        </IconButton>
      </InputAdornment>
    ),
  };

  return (
    <LoginWrapper>
      <div className={styles.paper}>
        <Avatar className={styles.avatar}>
          <LockOutlined />
        </Avatar>
        <Typography component="h1" variant="h5">
          {title}
        </Typography>
        <Typography component="p">{description}</Typography>
        <div className={styles.form}>
          <TextField
            required
            value={newPassword}
            autoFocus
            type={showPassword ? "text" : "password"}
            variant="outlined"
            id="newPassword"
            label="New Password"
            fullWidth
            disabled={loading}
            onChange={(event) => {
              if (event.target.value !== null)
                setNewPassword(event.target.value);
            }}
            onKeyDown={enterPressed}
            InputProps={endButton}
          />
          <TextField
            required
            value={confirmPassword}
            autoFocus
            type={showPassword ? "text" : "password"}
            variant="outlined"
            id="confirmPassword"
            label="Confirm Password"
            fullWidth
            disabled={loading}
            onChange={(event) => {
              if (event.target.value !== null)
                setConfirmPassword(event.target.value);
            }}
            onKeyDown={enterPressed}
            InputProps={endButton}
          />
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Button
                name="Submit"
                variant="contained"
                type="submit"
                color="primary"
                size="large"
                disabled={loading}
                fullWidth
                onClick={() => {
                  handleSubmit();
                }}
              >
                {loading && <CircularProgress size={25} thickness={2} />}
                {"Submit"}
              </Button>
            </Grid>
          </Grid>

          {error && <h3 className={styles.errorMessage}>{error}</h3>}
        </div>
      </div>
    </LoginWrapper>
  );
};

export default SetPassword;
