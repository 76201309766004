import { useState } from "react";
import { useNavigate } from "react-router";

import styles from "./AddRegistryPage.module.css";
import AddAddressStep from "./RegistrationPage/AddAddressStep";
import AddCamerasStep from "./RegistrationPage/AddCamerasStep";
import AddContactsStep from "./RegistrationPage/AddContactsStep";
import ReviewStep from "./RegistrationPage/ReviewStep";
import CameraRegistryProvider from "./context";

const AddRegistryPage = () => {
  const [step, setStep] = useState(0);
  const navigate = useNavigate();

  const nextStep = () => {
    if (step === 3) navigate("/rubicanvas/map");
    setStep(step + 1);
  };

  const prevStep = () => {
    setStep(step - 1);
  };

  const currentStep = () => {
    switch (step) {
      case 0:
        return <AddAddressStep nextStep={nextStep} />;
      case 1:
        return <AddCamerasStep nextStep={nextStep} prevStep={prevStep} />;
      case 2:
        return <AddContactsStep nextStep={nextStep} prevStep={prevStep} />;
      case 3:
        return <ReviewStep nextStep={nextStep} prevStep={prevStep} />;
      default:
        return <AddAddressStep />;
    }
  };

  return (
    <CameraRegistryProvider>
      <div id="addCameraFlow">
        <div className={styles.stepContainer}>{currentStep()}</div>
      </div>
    </CameraRegistryProvider>
  );
};

export default AddRegistryPage;
