import {
  DndContext,
  PointerSensor,
  TouchSensor,
  closestCenter,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  SortableContext,
  rectSortingStrategy,
  useSortable,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { OpenWith } from "@mui/icons-material";
import { IconButton, Tooltip } from "@mui/material";
import { useContext, useEffect, useState } from "react";

// Internal Imports
import MediaComponent from "./MediaComponent";
import styles from "./SortableAttachments.module.css";
import { apiAlertClient } from "../../../../common/api-client";
import { IEvidenceFile } from "../../../../types";
import { SingleAlertContext } from "../../context";

interface SortableItemProps {
  handleFileDelete: (filename: string, bulletinID: number) => void;
  bulletinID: number;
  attachment: IEvidenceFile;
  setEditMediaObject: any;
}
const SortableItem = ({
  handleFileDelete,
  bulletinID,
  attachment,
  setEditMediaObject,
}: SortableItemProps) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({ id: attachment.filename });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    zIndex: isDragging ? 2 : 1, // elevate the item being dragged
    cursor: isDragging ? "grabbing" : undefined,
    touchAction: "none",
  };

  return (
    <div ref={setNodeRef} style={style} {...attributes}>
      <Tooltip title="Drag to Reorder" placement="right">
        <IconButton
          {...listeners}
          sx={{
            backgroundColor: "rgba(255,255,255,0.5)",
            cursor: "grab",
            position: "absolute",
            zIndex: 2,
            margin: "4px",
          }}
          disableRipple
          size="small"
        >
          <OpenWith color="secondary" />
        </IconButton>
      </Tooltip>
      <MediaComponent
        handleFileDelete={() =>
          handleFileDelete(attachment.filename, bulletinID)
        }
        bulletinID={bulletinID}
        evidence={attachment}
        setEditMediaObject={setEditMediaObject}
      />
    </div>
  );
};

interface SortableAttachmentsProps {
  handleFileDelete: any;
  bulletinID: number;
  attachments: { [key: string]: IEvidenceFile };
  setAttachments: any;
  setEditMediaObject: any;
}

export const SortableAttachments = ({
  handleFileDelete,
  bulletinID,
  // attachments,
  // setAttachments,
  setEditMediaObject,
}: SortableAttachmentsProps) => {
  const {
    alertProperties: { evidence_files: attachments },
    setAlertProperty,
  } = useContext(SingleAlertContext);

  const setAttachments = (attachments) => {
    setAlertProperty("evidence_files", attachments);
  };
  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 8,
      },
    }),
    useSensor(TouchSensor),
  );

  const [items, setItems] = useState<any[]>([]);

  useEffect(() => {
    const sortedAttachments = Object.values(attachments)
      .sort((a: any, b: any) => a?.sort_ordinal - b?.sort_ordinal)
      .map((attachment: any) => attachment.filename);

    setItems(sortedAttachments);
  }, [attachments]);

  const handleDragEnd = (event) => {
    const { active, over } = event;

    if (over?.id && active.id !== over.id) {
      const sourceIndex = items.indexOf(active.id);
      const destinationIndex = items.indexOf(over.id);
      let newSortOrdinal = items.length;

      //If moved to the right
      if (destinationIndex > sourceIndex) {
        // Check if this is the end
        if (destinationIndex === items.length - 1)
          newSortOrdinal = attachments[over.id].sort_ordinal + 1;
        // Insert to the right of the destination index
        else {
          newSortOrdinal =
            (attachments[over.id].sort_ordinal +
              attachments[items[destinationIndex + 1]].sort_ordinal) /
            2;
        }
      }
      // It was moved to the left
      else {
        // Check if this is the start
        if (destinationIndex === 0)
          newSortOrdinal = attachments[over.id].sort_ordinal - 1;
        // Insert to the left of the destination index
        else {
          newSortOrdinal =
            (attachments[over.id].sort_ordinal +
              attachments[items[destinationIndex - 1]].sort_ordinal) /
            2;
        }
      }
      const updatedAttachments = { ...attachments };
      updatedAttachments[active.id].sort_ordinal = newSortOrdinal;
      setAttachments(updatedAttachments);

      apiAlertClient
        .patchAttachmentSort(
          bulletinID,
          attachments[active.id].filename,
          newSortOrdinal,
        )
        .then((res) => {
          setAttachments({ ...res.evidence_files });
        });
    }
  };

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCenter}
      onDragEnd={handleDragEnd}
    >
      <SortableContext items={items} strategy={rectSortingStrategy}>
        <div className={styles.imagesContainer}>
          {items.map(
            (filename) =>
              filename in attachments && (
                <SortableItem
                  key={filename}
                  handleFileDelete={handleFileDelete}
                  bulletinID={bulletinID}
                  attachment={attachments[filename]}
                  setEditMediaObject={setEditMediaObject}
                />
              ),
          )}
        </div>
      </SortableContext>
    </DndContext>
  );
};
