import React from "react";
import { useSidebarState } from "react-admin";

import rubicon from "../../images/rubicon.png";

export const Copyright = (props: any) => {
  const [open] = useSidebarState();
  const currentYear = new Date().getFullYear();
  return (
    <div className="footer-copyright">
      <a href="https://tryrubicon.com">
        <img src={rubicon} className="rubicon-icon" alt="" />
      </a>
      {open && (
        <div className="copyright-text">
          &copy; {currentYear} Made Proudly in North America by Rubicon Inc.
        </div>
      )}
    </div>
  );
};
