import { useState } from "react";

import { HomeForgotPassword } from "./HomeForgotPassword";
import { HomeLogin } from "./HomeLogin";
import { HomeSignUp } from "./HomeSignup";
import LoginWrapper from "./LoginWrapper";

const Home = (props) => {
  const [isSignin, setIsSignin] = useState(true);
  const [isSignup, setIsSignup] = useState(false);
  const [isForgotPassword, setIsForgotPassword] = useState(false);

  const DisplaySignUp = () => {
    setIsSignin(false);
    setIsSignup(true);
    setIsForgotPassword(false);
  };
  const DisplaySignIn = () => {
    setIsSignin(true);
    setIsSignup(false);
    setIsForgotPassword(false);
  };
  const DisplayForgotPassword = () => {
    setIsSignin(false);
    setIsSignup(false);
    setIsForgotPassword(true);
  };

  return (
    <LoginWrapper>
      <>
        {isSignin && (
          <HomeLogin
            onForgotPassword={() => {
              DisplayForgotPassword();
            }}
            onSignup={() => {
              DisplaySignUp();
            }}
          />
        )}
        {isSignup && (
          <HomeSignUp
            onSignin={() => {
              DisplaySignIn();
            }}
          />
        )}
        {isForgotPassword && (
          <HomeForgotPassword
            onSignup={() => {
              DisplaySignUp();
            }}
            onSignin={() => {
              DisplaySignIn();
            }}
          />
        )}
      </>
    </LoginWrapper>
  );
};

export default Home;
