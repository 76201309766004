import React from "react";
import { TextInput, SimpleForm, required } from "react-admin";
import "../../../common/common.css";
import { toast } from "react-toastify";

import { FormToolbar } from "../../../common/FormToolbar";
import { apiReportClient } from "../../../common/api-client";
import styles from "../report.module.css";

const ApproveWithNote = (props) => {
  const handleSubmit = async (formdata) => {
    apiReportClient
      .approveReportWithNote(props.id, formdata.approvalNote)
      .then(() => {
        toast.success("Report is approved.");
        props.setStatus("APPROVED");
        props.onClose();
      })
      .catch(() => {
        //Error proccessed in http.js
      });
  };

  return (
    <React.Fragment>
      <h3 className={styles.formHeader}>Approve with Note</h3>
      <div className={styles.formNote}>Please enter the note for approval.</div>
      <SimpleForm
        toolbar={
          <FormToolbar
            {...props}
            showDelete={false}
            showCancel={true}
            saveLabel="Approve Report"
            onCancel={props.onClose}
            onSave={handleSubmit}
          />
        }
        onSubmit={handleSubmit}
      >
        <TextInput
          validate={[required()]}
          variant="outlined"
          multiline
          source="approvalNote"
          label="Approval Note (max. 2000 characters)"
          fullWidth
          formClassName="richtexteditor_input"
          minRows={5}
          inputProps={{ maxLength: 2000 }}
        />

        {/* <RichTextInput source="approvalNote" label="Approval Note" formClassName={classes.richtexteditor_input} /> */}
      </SimpleForm>
    </React.Fragment>
  );
};
export default ApproveWithNote;
