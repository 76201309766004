import { createContext, useState } from "react";
import { Model } from "survey-core";

import {
  GetSubmissionResponsePortal,
  SubmissionAttachment,
  SubmissionPortal,
  SubmissionVersionTime,
} from "../../../common/api-client/or-api";

export const CondensedReportContext = createContext<{
  report: { [key: string]: any };
  attachments: SubmissionAttachment[];
  reportMetadata: GetSubmissionResponsePortal;
  baseSurveyStructure: { [key: string]: any };
  basePDFStructure: { [key: string]: any };
  survey: Model;
  surveyOneVersion: string;
  childrenSupplementaryReportSurveyJS: any[];
  childrenSupplementaryReportsMetadata: SubmissionPortal[];
  reportVersionHistory: SubmissionVersionTime[];
  setReport: (report: any) => void;
  setAttachments: (attachments: any[]) => void;
  setReportMetadata: any;
  setBaseSurveyStructure: (baseSurveyStructure: any) => void;
  setBasePDFStructure: (basePDFStructure: any) => void;
  setSurvey: (survey: Model) => void;
  setSurveyOneVersion: (surveyOneVersion: string) => void;
  setChildrenSupplementaryReportSurveyJS: (surveyJS: any[]) => void;
  setChildrenSupplementaryReportsMetadata: (
    metadata: SubmissionPortal[],
  ) => void;
  setReportVersionHistory: (versionHistory: SubmissionVersionTime[]) => void;
}>({
  report: {},
  attachments: [],
  reportMetadata: {} as GetSubmissionResponsePortal,
  baseSurveyStructure: {},
  basePDFStructure: {},
  survey: new Model(),
  surveyOneVersion: "",
  childrenSupplementaryReportSurveyJS: [],
  childrenSupplementaryReportsMetadata: [],
  reportVersionHistory: [],
  setReport: () => {},
  setAttachments: () => {},
  setReportMetadata: () => {},
  setBaseSurveyStructure: () => {},
  setBasePDFStructure: () => {},
  setSurvey: () => {},
  setSurveyOneVersion: () => {},
  setChildrenSupplementaryReportSurveyJS: () => {},
  setChildrenSupplementaryReportsMetadata: () => {},
  setReportVersionHistory: () => {},
});

interface CondensedReportProviderProps {
  children: React.ReactNode;
}

export const CondensedReportProvider: React.FC<
  CondensedReportProviderProps
> = ({ children }) => {
  const [report, setReport] = useState<{ [key: string]: any }>({});
  const [attachments, setAttachments] = useState<Array<SubmissionAttachment>>(
    [],
  );
  const [reportMetadata, setReportMetadata] = useState(
    {} as GetSubmissionResponsePortal,
  );
  const [survey, setSurvey] = useState<any>(new Model());
  const [surveyOneVersion, setSurveyOneVersion] = useState("");
  const [
    childrenSupplementaryReportSurveyJS,
    setChildrenSupplementaryReportSurveyJS,
  ] = useState<any[]>([]);

  const [
    childrenSupplementaryReportsMetadata,
    setChildrenSupplementaryReportsMetadata,
  ] = useState<SubmissionPortal[]>([]);

  const [reportVersionHistory, setReportVersionHistory] = useState<
    SubmissionVersionTime[]
  >([]);
  const [baseSurveyStructure, setBaseSurveyStructure] = useState<{
    [key: string]: any;
  }>({});

  const [basePDFStructure, setBasePDFStructure] = useState<{
    [key: string]: any;
  }>({});

  return (
    <CondensedReportContext.Provider
      value={{
        report,
        setReport,
        attachments,
        setAttachments,
        reportMetadata,
        setReportMetadata,
        baseSurveyStructure,
        setBaseSurveyStructure,
        basePDFStructure,
        setBasePDFStructure,
        survey,
        setSurvey,
        surveyOneVersion,
        setSurveyOneVersion,
        childrenSupplementaryReportSurveyJS,
        setChildrenSupplementaryReportSurveyJS,
        childrenSupplementaryReportsMetadata,
        setChildrenSupplementaryReportsMetadata,
        reportVersionHistory,
        setReportVersionHistory,
      }}
    >
      {children}
    </CondensedReportContext.Provider>
  );
};

// Context for individual Supplementary Report

export const SupplementaryReportContext = createContext<{
  report: { [key: string]: any };
  attachments: SubmissionAttachment[];
  reportMetadata: GetSubmissionResponsePortal;
  surveyStructure: any;
  pdfStructure: any;
  survey: Model;
  surveyOneVersion: string;
  reportVersionHistory: SubmissionVersionTime[];
  baseSurveyStructure: { [key: string]: any };
  basePDFStructure: { [key: string]: any };
  setReport: (report: any) => void;
  setAttachments: (attachments: any[]) => void;
  setReportMetadata: (reportMetadata: GetSubmissionResponsePortal) => void;
  setSurveyStructure: (surveyStructure: any) => void;
  setPdfStructure: (pdfStructure: any) => void;
  setSurvey: (survey: Model) => void;
  setSurveyOneVersion: (surveyOneVersion: string) => void;
  setReportVersionHistory: (versionHistory: SubmissionVersionTime[]) => void;
  setBaseSurveyStructure: (baseSurveyStructure: any) => void;
  setBasePDFStructure: (basePDFStructure: any) => void;
}>({
  report: {},
  attachments: [],
  reportMetadata: {} as GetSubmissionResponsePortal,
  surveyStructure: {},
  pdfStructure: {},
  survey: new Model(),
  surveyOneVersion: "",
  reportVersionHistory: [],
  baseSurveyStructure: {},
  basePDFStructure: {},
  setReport: () => {},
  setAttachments: () => {},
  setReportMetadata: () => {},
  setSurveyStructure: () => {},
  setPdfStructure: () => {},
  setSurvey: () => {},
  setSurveyOneVersion: () => {},
  setReportVersionHistory: () => {},
  setBaseSurveyStructure: () => {},
  setBasePDFStructure: () => {},
});

interface SupplementaryReportProviderProps {
  children: React.ReactNode;
}

export const SupplementaryReportProvider: React.FC<
  SupplementaryReportProviderProps
> = ({ children }) => {
  const [report, setReport] = useState<{ [key: string]: any }>({});
  const [attachments, setAttachments] = useState<Array<SubmissionAttachment>>(
    [],
  );
  const [reportMetadata, setReportMetadata] = useState(
    {} as GetSubmissionResponsePortal,
  );
  const [surveyStructure, setSurveyStructure] = useState({});
  const [pdfStructure, setPdfStructure] = useState({});
  const [survey, setSurvey] = useState<any>(new Model());
  const [surveyOneVersion, setSurveyOneVersion] = useState("");

  const [reportVersionHistory, setReportVersionHistory] = useState<
    SubmissionVersionTime[]
  >([]);

  const [baseSurveyStructure, setBaseSurveyStructure] = useState<{
    [key: string]: any;
  }>({});

  const [basePDFStructure, setBasePDFStructure] = useState<{
    [key: string]: any;
  }>({});

  return (
    <SupplementaryReportContext.Provider
      value={{
        report,
        attachments,
        reportMetadata,
        surveyStructure,
        pdfStructure,
        survey,
        surveyOneVersion,
        reportVersionHistory,
        baseSurveyStructure,
        basePDFStructure,
        setReport,
        setAttachments,
        setReportMetadata,
        setSurveyStructure,
        setPdfStructure,
        setSurvey,
        setSurveyOneVersion,
        setReportVersionHistory,
        setBaseSurveyStructure,
        setBasePDFStructure,
      }}
    >
      {children}
    </SupplementaryReportContext.Provider>
  );
};
