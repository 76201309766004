//External Imports
import {
  Add,
  ArrowBack,
  ArrowForward,
  Delete,
  ExpandMore,
} from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import { Formik, FormikHelpers } from "formik";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import * as Yup from "yup";

//Internal Imports

import styles from "./AddContactsStep.module.css";
import { API } from "../../../../common/api-client/camera-api/api";
import { IContacts } from "../../../../types";
import { CameraRegistryContext } from "../context";

const formatPhoneNumber = (inputValue: string) => {
  const match = inputValue.match(
    /(\d{0,3})(-{0,1})(\d{0,3})(-{0,1})(\d{0,4})(\s?)(ext\.|ext|ex|e)?(\s?)(\d{0,6})/,
  );
  let formattedNumber = "";
  console.log("match", match);
  if (!match) return "";

  //   if (match[1]) formattedNumber += match[1];
  //   else if (match[2]) formattedNumber += "+";
  //   else return "";

  //   //Country Code
  //   if (match[2]) formattedNumber += match[2];
  //   else return formattedNumber;

  //   //Space
  //   if (match[3]) formattedNumber += match[3];
  //   else if (match[4]) formattedNumber += " "; //Case if user types a number
  //   else return formattedNumber;

  //Area code
  if (match[1]) formattedNumber += match[1];
  else return formattedNumber;

  //Hyphen
  if (match[2]) formattedNumber += match[2];
  else if (match[3])
    formattedNumber += "-"; //Case if user types a number
  else return formattedNumber;

  //3 digits
  if (match[3]) formattedNumber += match[3];
  else return formattedNumber;

  //Hyphen
  if (match[4]) formattedNumber += match[4];
  else if (match[5])
    formattedNumber += "-"; //Case if user types a number
  else return formattedNumber;

  //3 digits
  if (match[5]) formattedNumber += match[5];
  else return formattedNumber;

  //Extension 1st space
  if (match[6]) formattedNumber += match[6];
  else if (match[7])
    formattedNumber += " "; //Case if user types letter
  else return formattedNumber;

  //Extension ext
  if (match[7] && (match[8] || match[9]))
    formattedNumber += "ext."; //Case if user types letter
  else if (match[7]) formattedNumber += match[7];
  else if (match[8] || match[9]) formattedNumber += "ext.";
  else return formattedNumber;

  //Extension 2st space
  if (match[8]) formattedNumber += match[8];
  else if (match[9])
    formattedNumber += " "; //Case if user types a number
  else return formattedNumber;

  //Extension Number
  if (match[9]) formattedNumber += match[9];
  else return formattedNumber;
  return formattedNumber;
};

const phoneRegExp = /^\d{3}-\d{3}-\d{4}\s?(ext\.?\s?\d{1,6})?$/;
// Define the validation schema
const ContactsSchema = Yup.object().shape({
  contacts: Yup.array()
    .of(
      Yup.object().shape({
        firstName: Yup.string(),
        lastName: Yup.string(),
        email: Yup.string().email("Invalid email format (e.g. name@gmail.com)"),
        phoneNumber: Yup.string().matches(
          phoneRegExp,
          "Invalid phone number (e.g. 647-333-4444 ext. 1234)",
        ),
        secondaryPhoneNumber: Yup.string()
          .matches(
            phoneRegExp,
            "Invalid phone number(e.g. 647-333-4444 ext. 1234)",
          )
          .nullable()
          .test(
            "is-not-same-as-phone",
            "Secondary Phone # cannot be the same as Primary Phone #",
            function (value) {
              return !value || value !== this.parent.phoneNumber;
            },
          ),
        notes: Yup.string(), // Optional, no specific validation required
      }),
    )
    .required("Contacts are required"),
});

const AddContactsStep = ({
  nextStep,
  prevStep,
}: {
  nextStep?: () => void;
  prevStep?: () => void;
}) => {
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const [contactIndexToDelete, setContactIndexToDelete] = useState<
    number | null
  >(null);
  //   const [isSubmitionCompleted, setSubmitionCompleted] = useState(false);
  const [expandedAccordion, setExpandedAccordion] = useState<boolean>(true);
  const navigate = useNavigate();
  const {
    property,
    cameras,
    contacts,
    setContacts,
    setContactField,
    updateContacts,
    deleteContact,
  } = useContext(CameraRegistryContext);
  console.log("Initial contacts: ", JSON.stringify(contacts));
  //   console.log("Initial cameras: ", cameras);

  useEffect(() => {
    console.log("In UseEffect Add Contacts");
    if (
      !property ||
      !property?.googleMapsPlace?.geometry ||
      !cameras ||
      !contacts ||
      !setContacts
    )
      navigate("../add-address");
  }, []);

  if (
    !property ||
    !property?.googleMapsPlace?.geometry ||
    !cameras ||
    !contacts ||
    !setContacts
  ) {
    console.log("didn't pass");
    return <>Error: Please notify support@tryrubicon.com</>;
  }

  //   const onSubmit={(values, { setSubmitting }) => {
  //     setSubmitting(true);
  //    axios.post(contactFormEndpoint,
  //      values,
  //      {
  //        headers: {
  //          'Access-Control-Allow-Origin': '*',
  //          'Content-Type': 'application/json',
  //        }
  //      },
  //    ).then((resp) => {
  //     //  setSubmitionCompleted(true);
  //    }
  //    );
  //  }}

  useEffect(() => {
    async function getID() {
      const newContact = await API.contact.putPortal(property?.id ?? "", {});
      setContactField && setContactField("id", newContact.id, 0);
    }
    if (!contacts.contacts[0].id) {
      getID();
    }
  }, []);

  const patchContacts = async (values: IContacts) => {
    let contactsToUse = contacts;

    const changed = (oldContact: any, newContact: any) => {
      return (
        oldContact.firstName !== newContact.firstName ||
        oldContact.lastName !== newContact.lastName ||
        oldContact.email !== newContact.email ||
        oldContact.phoneNumber !== newContact.phoneNumber ||
        oldContact.secondaryPhoneNumber !== newContact.secondaryPhoneNumber ||
        oldContact.notes !== newContact.notes
      );
    };

    const changedIDs = contacts.contacts
      .filter(
        (contact, index) =>
          contact.id && changed(contact, values.contacts[index]),
      )
      .map((contact) => contact.id);

    if (updateContacts) {
      contactsToUse = updateContacts(values);
    }

    try {
      const updateContact = async (contact: any) => {
        await API.contact.patchPortal(property.id ?? "", contact.id ?? "", {
          first_name: contact.firstName,
          last_name: contact.lastName,
          email: contact.email,
          phone_number: contact.phoneNumber,
          secondary_phone_number: contact.secondaryPhoneNumber,
          notes: contact.notes,
        });
      };

      const oldContacts = contactsToUse.contacts.filter(
        (contact) => contact.id && changedIDs.includes(contact.id),
      );

      for (const contact of oldContacts) {
        updateContact(contact);
        await new Promise((r) => setTimeout(r, 200));
      }
      return true;
    } catch (error) {
      console.log("ERROR: ", error);
      toast.error(`An error occurred. Please check the form and try again.`);
      return false;
    }
  };

  const handleOnSubmit = async (
    values: IContacts,
    helpers: FormikHelpers<IContacts>,
  ) => {
    console.log("SUBMITTING ", values);
    helpers.setSubmitting(true);
    const success = await patchContacts(values);

    if (success) {
      nextStep ? nextStep() : navigate("../review");
    }
  };

  const openDeleteDialog = (index: number) => {
    setContactIndexToDelete(index);
    setDialogOpen(true);
  };

  const handleContactDelete = async (
    setFieldValue: (
      field: string,
      value: any,
      shouldValidate?: boolean,
    ) => void,
    values: IContacts,
    index?: number,
  ) => {
    console.log("Trying to delete contact");
    let indexToUse = index ?? 0;
    if (contactIndexToDelete !== null) {
      indexToUse = contactIndexToDelete;
    }

    const contactToDelete = contacts.contacts[indexToUse];
    setDialogOpen(false);
    setContactIndexToDelete(null);

    if (contactToDelete.id) {
      setFieldValue(
        "contacts",
        values.contacts.filter(
          (_, contactIndex) => contactIndex !== indexToUse,
        ),
      );
      deleteContact && deleteContact(indexToUse);
      API.contact.deletePortal(property.id ?? "", contactToDelete.id ?? "");
    }
  };
  return (
    <Formik
      initialValues={contacts}
      validationSchema={ContactsSchema}
      onSubmit={handleOnSubmit}
      enableReinitialize
    >
      {(props) => {
        const {
          values,
          touched,
          errors,
          isSubmitting,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
        } = props;

        return (
          <form className={styles.formContainer} onSubmit={handleSubmit}>
            <Accordion
              disableGutters
              elevation={3}
              expanded={expandedAccordion}
              onChange={() => setExpandedAccordion((prevValue) => !prevValue)}
            >
              <AccordionSummary
                expandIcon={<ExpandMore />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <div className={styles.accordionTitleContainer}>
                  <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                    Contact Information
                  </Typography>
                  <Typography sx={{ color: "text.secondary" }}>
                    Fields marked with * are required.
                  </Typography>
                </div>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  This information allows members to get in touch with the
                  camera operator if there's an incident in the area. This is an
                  optional step but recommended if the information is available.
                </Typography>
              </AccordionDetails>
            </Accordion>

            {values.contacts.map((contact, index) => {
              return (
                <Paper
                  elevation={3}
                  key={index}
                  className={styles.contactContainer}
                >
                  <div className={styles.contactHeaderContainer}>
                    <Typography sx={{ fontWeight: "bold" }} variant="h6">
                      {index === 0
                        ? "Primary Contact"
                        : `Contact #${index + 1}`}
                    </Typography>{" "}
                    {(index !== 0 || values.contacts.length > 1) && (
                      <IconButton
                        onClick={() => {
                          if (
                            contact.firstName ||
                            contact.lastName ||
                            contact.email ||
                            (contact.phoneNumber !== "+1" &&
                              contact.phoneNumber !== "") ||
                            contact.secondaryPhoneNumber ||
                            contact.notes
                          )
                            openDeleteDialog(index);
                          else
                            handleContactDelete(setFieldValue, values, index);
                        }}
                        color="primary"
                      >
                        <Delete />
                      </IconButton>
                    )}
                  </div>
                  <TextField
                    className={styles.fieldContainer}
                    name={`contacts[${index}].firstName`}
                    label="First Name"
                    size="small"
                    value={contact.firstName}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    onKeyDown={(e) => {
                      e.key === "Enter" && e.preventDefault();
                    }}
                    helperText={
                      errors.contacts &&
                      touched.contacts &&
                      touched.contacts[index]?.firstName &&
                      (errors.contacts[index] as any)?.firstName
                    }
                    error={
                      !!(
                        errors.contacts &&
                        touched.contacts &&
                        (errors.contacts[index] as any)?.firstName &&
                        touched.contacts[index]?.firstName
                      )
                    }
                  />
                  <TextField
                    className={styles.fieldContainer}
                    name={`contacts[${index}].lastName`}
                    label="Last Name"
                    size="small"
                    value={contact.lastName}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    onKeyDown={(e) => {
                      e.key === "Enter" && e.preventDefault();
                    }}
                    helperText={
                      errors.contacts &&
                      touched.contacts &&
                      touched.contacts[index]?.lastName &&
                      (errors.contacts[index] as any)?.lastName
                    }
                    error={
                      !!(
                        errors.contacts &&
                        touched.contacts &&
                        (errors.contacts[index] as any)?.lastName &&
                        touched.contacts[index]?.lastName
                      )
                    }
                  />
                  <TextField
                    className={styles.fieldContainer}
                    name={`contacts[${index}].email`}
                    label="Email"
                    size="small"
                    value={contact.email}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    onKeyDown={(e) => {
                      e.key === "Enter" && e.preventDefault();
                    }}
                    helperText={
                      errors.contacts &&
                      touched.contacts &&
                      touched.contacts[index]?.email &&
                      (errors.contacts[index] as any)?.email
                    }
                    error={
                      !!(
                        errors.contacts &&
                        touched.contacts &&
                        (errors.contacts[index] as any)?.email &&
                        touched.contacts[index]?.email
                      )
                    }
                  />

                  <TextField
                    className={styles.fieldContainer}
                    name={`contacts[${index}].phoneNumber`}
                    label="Primary Phone #"
                    size="small"
                    value={contact.phoneNumber}
                    onChange={(e) => {
                      setFieldValue(
                        `contacts[${index}].phoneNumber`,
                        formatPhoneNumber(e.target.value),
                      );
                    }}
                    onBlur={handleBlur}
                    onKeyDown={(e) => {
                      e.key === "Enter" && e.preventDefault();
                    }}
                    helperText={
                      errors.contacts &&
                      touched.contacts &&
                      touched.contacts[index]?.phoneNumber &&
                      (errors.contacts[index] as any)?.phoneNumber
                    }
                    error={
                      !!(
                        errors.contacts &&
                        touched.contacts &&
                        (errors.contacts[index] as any)?.phoneNumber &&
                        touched.contacts[index]?.phoneNumber
                      )
                    }
                  />

                  <TextField
                    className={styles.fieldContainer}
                    name={`contacts[${index}].secondaryPhoneNumber`}
                    label="Secondary Phone #"
                    size="small"
                    value={contact.secondaryPhoneNumber}
                    onChange={(e) => {
                      setFieldValue(
                        `contacts[${index}].secondaryPhoneNumber`,
                        formatPhoneNumber(e.target.value),
                      );
                    }}
                    onBlur={handleBlur}
                    onKeyDown={(e) => {
                      e.key === "Enter" && e.preventDefault();
                    }}
                    helperText={
                      errors.contacts &&
                      touched.contacts &&
                      touched.contacts[index]?.secondaryPhoneNumber &&
                      (errors.contacts[index] as any)?.secondaryPhoneNumber
                    }
                    error={
                      !!(
                        errors.contacts &&
                        touched.contacts &&
                        (errors.contacts[index] as any)?.secondaryPhoneNumber &&
                        touched.contacts[index]?.secondaryPhoneNumber
                      )
                    }
                  />

                  <div className={styles.fieldContainer}></div>

                  <TextField
                    //   className={styles.fieldContainer}
                    name={`contacts[${index}].notes`}
                    label="Contact Notes"
                    size="small"
                    value={contact.notes}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    minRows={2}
                    multiline
                    fullWidth
                    onKeyDown={(e) => {
                      e.key === "Enter" && e.preventDefault();
                    }}
                  />
                </Paper>
              );
            })}

            <Button
              variant="contained"
              color="secondary"
              fullWidth
              endIcon={<Add />}
              disabled={isSubmitting}
              onClick={async () => {
                setFieldValue("contacts", [
                  ...values.contacts,
                  {
                    firstName: "",
                    lastName: "",
                    email: "",
                    phoneNumber: "",
                    secondaryPhoneNumber: "",
                    notes: "",
                  },
                ]);
                const newContact = await API.contact.putPortal(
                  property.id ?? "",
                  {},
                );
                setContactField &&
                  setContactField("id", newContact.id, values.contacts.length);
              }}
            >
              ADD ANOTHER CONTACT PERSON (Optional)
            </Button>

            <div className={styles.buttonContainer}>
              <Button
                variant="outlined"
                color="secondary"
                startIcon={<ArrowBack />}
                disabled={isSubmitting}
                onClick={() => {
                  patchContacts(values);
                  prevStep ? prevStep() : navigate("../add-cameras");
                }}
              >
                Back
              </Button>
              <Button
                type="submit"
                variant="contained"
                size="large"
                endIcon={
                  isSubmitting ? (
                    <CircularProgress size={20} />
                  ) : (
                    <ArrowForward />
                  )
                }
                disabled={isSubmitting}
              >
                Next
              </Button>

              <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)}>
                <DialogTitle>Confirm Deletion</DialogTitle>
                <DialogContent>
                  <DialogContentText>
                    Are you sure you want to delete this contact? You will lose
                    all information that you’ve entered for this contact.
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button
                    onClick={() => setDialogOpen(false)}
                    color="secondary"
                  >
                    Cancel
                  </Button>
                  <Button
                    onClick={() => handleContactDelete(setFieldValue, values)}
                    color="secondary"
                  >
                    Delete Contact
                  </Button>
                </DialogActions>
              </Dialog>
            </div>
          </form>
        );
      }}
    </Formik>
  );
};

export default AddContactsStep;
