import { Box } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import { useContext, useState } from "react";
import {
  Create,
  SaveButton,
  SimpleForm,
  Toolbar,
  required,
  useLogout,
} from "react-admin";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import PasswordField from "../../common/PasswordField";
import { apiUserClient } from "../../common/api-client";
import { BackButton } from "../../common/atomic-UI-components/BackButton";
import { AuthStateContext } from "../../context";

export const ChangePassword = ({ oldPasswordRequired = true }) => {
  //TODO clear form on submit!
  // const form = useForm();
  const navigate = useNavigate();
  const logout = useLogout();
  const [loading, setLoading] = useState(false);
  const { authSteps, verifyPasswordStrength } = useContext(AuthStateContext);

  const saveData = async (formdata) => {
    checkPasswords({
      oldPasswordRequired,
      formdata,
      verifyPasswordStrength,
    }).then((passed) => {
      setLoading(true);
      if (!passed) {
        setLoading(false);
        return;
      }

      if (oldPasswordRequired) {
        const data = {
          old: formdata.oldpassword,
          new: formdata.newpassword,
        };

        apiUserClient
          .ChangePassword(data)
          .then(() => {
            // form.change("oldpassword", "");
            // form.change("newpassword", "");
            // form.change("confirmpassword", "");
            toast.success(
              "Great! Your password has been updated successfully. " +
                "Please log in using your new password.",
              { autoClose: 10000 },
            );
            setLoading(false);
            logout();
          })
          .catch((er) => {
            // toast.error(
            //   "Something went wrong. Try again or contact support@tryrubicon.com " +
            //     er
            // );
            setLoading(false);
          });
      } else {
        let token = new URL(document.URL).searchParams.get("token");
        token = token ? token : "";
        const data = {
          new: formdata.newpassword,
          token: token,
        };

        apiUserClient
          .ResetPassword(data)
          .then(() => {
            // form.change("newpassword", "");
            // form.change("confirmpassword", "");
            toast.dismiss(); //TODO didn't test wheather it acutally clears
            toast.success(
              "Great! Your password has been updated successfully. " +
                "Please log in using your new password :)",
              { autoClose: 10000 },
            );
            setLoading(false);
            navigate("/login");
          })
          .catch((er) => {
            setLoading(false);
          });
      }
    });
  };

  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const hasTempPassword =
    authSteps.filter((step) => step.includes("changepassword")).length > 0;
  return (
    <div className="change-password-block">
      <Create
        title="Change Password"
        resource={oldPasswordRequired ? "changepassword" : "resetpassword"}
      >
        <SimpleForm
          toolbar={<ChangePasswordCreateToolbar loading={loading} />}
          onSubmit={saveData}
        >
          <Typography
            gutterBottom
            align="center"
            display="inline"
            component="h1"
            variant="h5"
          >
            Set a new password
          </Typography>
          {oldPasswordRequired && (
            <PasswordField
              source="oldpassword"
              label={
                hasTempPassword ? "Temporary Password" : "Existing Password"
              }
              fullWidth
              validate={[required()]}
              showPassword={showOldPassword}
              setShowPassword={setShowOldPassword}
            />
          )}
          <PasswordField
            source="newpassword"
            label="New Password"
            fullWidth
            validate={[required()]}
            showPassword={showNewPassword}
            setShowPassword={setShowNewPassword}
          />
          <PasswordField
            source="confirmpassword"
            label="Confirm Password"
            fullWidth
            validate={[required()]}
            showPassword={showConfirmPassword}
            setShowPassword={setShowConfirmPassword}
          />
        </SimpleForm>
      </Create>
    </div>
  );
};

const ChangePasswordCreateToolbar = ({ loading }) => {
  return (
    <Toolbar>
      <Box
        sx={{ display: "flex", justifyContent: "space-between", width: "100%" }}
      >
        {!loading && (
          <>
            <SaveButton label="Change password" />
            <BackButton />
          </>
        )}
        {loading && (
          <Box
            sx={{ display: "flex", justifyContent: "center", width: "100%" }}
          >
            <CircularProgress size={25} thickness={2} />
          </Box>
        )}
      </Box>
    </Toolbar>
  );
};

export const checkPasswords = async ({
  oldPasswordRequired,
  formdata,
  verifyPasswordStrength,
}) => {
  if (
    oldPasswordRequired &&
    formdata.oldpassword === formdata.confirmpassword
  ) {
    toast.error(
      "We're sorry, but your new password cannot be the same as your old password. " +
        "Please choose a new password that is different from your previous one. " +
        "Thank you!",
    );
    return false;
  }

  if (formdata.newpassword === "") {
    toast.error("Please enter a new password.");
    return false;
  }

  const strength = await verifyPasswordStrength(formdata.newpassword);

  if (!strength.valid_password) {
    toast.error(
      `The new password must be Stronger. 
              ${strength.warning ? `${strength.warning}` : ""}
              ${
                strength.suggestions.length > 0
                  ? `Suggestions: \n ${strength.suggestions.join("\n")}`
                  : ""
              }
              `,
      { autoClose: 10000 },
    );
    return false;
  }

  if (formdata.newpassword !== formdata.confirmpassword) {
    toast.error("Confirm password must be same as new password");
    return false;
  }

  return true;
};
