export enum AlertPermission {
  CREATE_BULLETIN = "alert.create_bulletin.",
  DASHBOARD = "alert.dashboard.",
  VIEW_BULLETIN = "alert.view_bulletin.",
  SHARE_BULLETIN = "alert.share_bulletin.",
}

export enum Permission {
  LOGIN = "login.",
  ALERT = "app.alert.",
  REPORT = "app.report.",
  REPORT_V2 = "app.report_v2.",
  CAMERA_REGISTRY = "app.camera_registry.",
  INTEL = "app.intel.",
  RUBI_ORG_CREATE = "rubi_org.create.",
  RUBI_ORG_ANY_LIST_USERS = "rubi_org.any.list_users.",
  RUBI_ORG_OWN_LIST_USERS = "rubi_org.own.list_users.",
  RUBI_ORG_ANY_ADD_USER = "rubi_org.any.add_user.",
  RUBI_ORG_OWN_ADD_USER = "rubi_org.own.add_user.",
  RUBI_ORG_ANY_MODIFY_USER_APPS = "rubi_org.any.modify_user_apps.",
  RUBI_ORG_OWN_MODIFY_USER_APPS = "rubi_org.own.modify_user_apps.",
  RUBI_ORG_OWN_MANAGE_ADMINS = "rubi_org.own.manage_admins.",
  RUBI_ORG_ANY_MANAGE_ADMINS = "rubi_org.any.manage_admins.",
  RUBI_USER_ANY_EDIT_FIELDS = "rubi_user.any.edit_fields.",
  RUBI_USER_ORG_EDIT_FIELDS = "rubi_user.org.edit_fields.",
  RUBI_USER_ANY_READ_PERMISSIONS = "rubi_user.any.read_permissions.",
  RUBI_USER_ORG_READ_PERMISSIONS = "rubi_user.org.read_permissions.",
  RUBI_USER_ANY_WRITE_PERMISSIONS = "rubi_user.any.write_permissions.",

  ALERT_CREATE_BULLETIN = AlertPermission.CREATE_BULLETIN,
  ALERT_DASHBOARD = AlertPermission.DASHBOARD,
}

export interface CheckedUserPermissions {
  canManageApps: boolean;
  canManageAdmins: boolean;
  canModifyUsers: boolean;
  canCreateUsers: boolean;
  canAccessAlertsApp: boolean;
  canAccessReportsApp: boolean;
  canAccessReportsV2App: boolean;
  canAccessCameraRegistryApp: boolean;
  canLogin: boolean;
  canCreateBulletin: boolean;
  canAccessDashboard: boolean;
}
