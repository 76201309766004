import { AddressField } from "../fields/AddressField";
import { InvolvedPersonField } from "../fields/InvolvedPersonField";
import { ReportDateField } from "../fields/ReportDateField";
import { ReportField } from "../fields/ReportField";
import styles from "../report.module.css";

export const Bullying = (props) => {
  const { data } = props;
  return (
    <>
      {data.info && data.info.bullying && (
        <div className="theftfromVehicle">
          <ReportDateField
            showtime={true}
            className="report-field-row"
            value={data.info.bullying.startTime}
            label="Start Date/Time of Incident"
          />
          <ReportDateField
            showtime={true}
            className="report-field-row"
            value={data.info.bullying.endTime}
            label="End Date/Time of Incident"
          />
          <AddressField
            label="Incident Location"
            address={data.info.bullying.streetAddress}
            onEditAddress={() => {
              props.onEditAddress(
                data.info.bullying.streetAddress,
                "info.bullying.streetAddress",
              );
            }}
          />
          {data.info.bullying.narrative && (
            <div className={styles.subHeading}>Narrative</div>
          )}
          {data.info.bullying.narrative || props.clickState ? (
            <ReportField
              className={styles.narrativeField + " report-field-row"}
              value={data.info.bullying.narrative}
              label="Incident Description"
            />
          ) : null}

          {data.info &&
            data.info.bullying &&
            data.info.bullying.involvedPersons && (
              <div className={styles.subHeading}>
                Involved Person Information
              </div>
            )}
          {data.info &&
            data.info.bullying &&
            data.info.bullying.involvedPersons && (
              <InvolvedPersonField
                data={data.info.bullying.involvedPersons}
                onEditAddress={props.onEditAddress}
              />
            )}
        </div>
      )}
    </>
  );
};
