import { Email } from "@mui/icons-material";
import { IconButton, Typography } from "@mui/material";
import React from "react";

interface EmailButtonProps {
  email: string;
  subject?: string;
  body?: string;
}

const EmailButton: React.FC<EmailButtonProps> = ({
  email,
  subject = "",
  body = "",
}) => {
  const hrefMailTo = `mailto:${email}?subject=${encodeURIComponent(
    subject,
  )}&body=${encodeURIComponent(body)}`;

  const handleClick = () => {
    window.location.href = hrefMailTo;
  };

  return (
    <div style={{ display: "inline-block" }}>
      <IconButton onClick={handleClick} aria-label="send email" size="small">
        <Email color="secondary" />
      </IconButton>
      <Typography component="span">
        <a href={hrefMailTo} style={{ textDecoration: "none" }}>
          {email}
        </a>
      </Typography>
    </div>
  );
};

export default EmailButton;
