import { Button, CircularProgress } from "@mui/material";
import {
  AdvancedMarker,
  APIProvider,
  Map,
  Pin,
} from "@vis.gl/react-google-maps";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import { SubmissionSummary } from "../../common/api-client/or-api";
import { APP_GOOGLE_PLACES_KEY } from "../../environment";

const containerStyle = {
  width: "calc(100% - 32px)",
  height: "90%",
  marginLeft: "8px",
  marginRight: "8px",
};

interface ReportsMapProps {
  reports: SubmissionSummary[];
  reportClickHandler: (report: SubmissionSummary, index: number) => void;
  loadMore: () => void;
  isFetchingNextPage: boolean;
  hasNextPage: boolean;
  totalReports: number;
  selectedReport: SubmissionSummary;
  setSelectedReport: (report: SubmissionSummary) => void;
}

const ReportsMap: React.FC<ReportsMapProps> = ({
  reports,
  reportClickHandler,
  loadMore,
  isFetchingNextPage,
  hasNextPage,
  totalReports,
  selectedReport,
  setSelectedReport,
}) => {
  const { state } = useLocation();
  const googleMapId = "4380cd85df35e961";
  const [defaultCenter, setDefaultCenter] = useState<google.maps.LatLngLiteral>(
    {
      lat: 0,
      lng: 0,
    },
  );
  const [defaultZoom, setDefaultZoom] = useState(6);
  const [isMapLoaded, setIsMapLoaded] = useState(false);

  useEffect(() => {
    const validCoordinates = reports
      .map(
        (report) =>
          report.latest_data?.incident_location_address?.googleMapsPlace
            ?.geometry,
      )
      .filter(
        (geometry) =>
          geometry &&
          typeof geometry.lat === "number" &&
          typeof geometry.lng === "number",
      );

    if (validCoordinates.length > 0) {
      const latSum = validCoordinates.reduce(
        (sum, coord) => sum + coord.lat,
        0,
      );
      const lngSum = validCoordinates.reduce(
        (sum, coord) => sum + coord.lng,
        0,
      );
      const centerLat = latSum / validCoordinates.length;
      const centerLng = lngSum / validCoordinates.length;

      setDefaultCenter({
        lat: centerLat,
        lng: centerLng,
      } as google.maps.LatLngLiteral);
    }
  }, [reports]);

  const handleMarkerClick = (report: SubmissionSummary, index: number) => {
    setSelectedReport(report);
    reportClickHandler(report, index);
  };

  return (
    <>
      <APIProvider apiKey={APP_GOOGLE_PLACES_KEY}>
        <Map
          defaultCenter={
            selectedReport
              ? selectedReport.latest_data?.incident_location_address
                  ?.googleMapsPlace.geometry
              : defaultCenter
          }
          defaultZoom={defaultZoom}
          onTilesLoaded={() => setIsMapLoaded(true)}
          mapId={googleMapId}
        >
          {isMapLoaded &&
            reports.map((report, index) => {
              const geometry =
                report.latest_data?.incident_location_address?.googleMapsPlace
                  .geometry;
              const { lat, lng } = geometry || {};

              if (typeof lat === "number" && typeof lng === "number") {
                return (
                  <AdvancedMarker
                    key={index}
                    position={{ lat, lng }}
                    onClick={() => handleMarkerClick(report, index)}
                    clickable
                    zIndex={selectedReport?.id === report?.id ? 1000 : 0}
                  >
                    <Pin
                      scale={selectedReport?.id === report?.id ? 1.5 : 1}
                      glyphColor={
                        selectedReport?.id === report?.id ? "#ffffff" : ""
                      }
                    />
                  </AdvancedMarker>
                );
              }

              return null;
            })}
        </Map>
      </APIProvider>

      <div className="loadMoreContainer">
        <div className="report-count">
          Loaded {reports.length} of {totalReports}
        </div>
        <Button
          variant="contained"
          color="primary"
          disabled={isFetchingNextPage || !hasNextPage}
          onClick={loadMore}
          endIcon={isFetchingNextPage ? <CircularProgress size={20} /> : null}
        >
          {isFetchingNextPage ? "Loading..." : "Load More Reports"}
        </Button>
      </div>
    </>
  );
};

export default React.memo(ReportsMap);
