import { Info } from "@mui/icons-material";
import {
  Autocomplete,
  Button,
  Chip,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormControlLabel,
  IconButton,
  Link,
  Paper,
  Switch,
  TextField,
  TextareaAutosize,
  Theme,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useContext, useEffect, useRef, useState } from "react";
import { useTheme } from "react-admin";
import { toast } from "react-toastify";

import styles from "./ResolveBulletin.module.scss";
import MediaDisplay from "../../../../common/MediaDisplay";
import { CustomSlider, MediaSlide } from "../../../../common/Slider";
import TriUploadFile from "../../../../common/TriUploadFile";
import { apiAlertClient } from "../../../../common/api-client";
import { colors } from "../../../../common/colors";
import { isSmallScreen, mailTo } from "../../../../common/utils";
import { AuthStateContext } from "../../../../context";
import {
  IBulletinResolution,
  IUserPermission,
} from "../../../../types/typesAlerts";
import { DisabledComponentBanner } from "../../../DisabledComponent";
import { parseUserPermissions } from "../../../UserManagement/utils";
import { BULLETIN_TYPE, bulletinResolutions } from "../../constants";
import { SingleAlertContext } from "../../context";
import MediaComponent from "../UploadStep/MediaComponent";

interface ResolveBulletinItemProps {
  resolution: IBulletinResolution;
  reasons: string[];
  setIsResolveOpen: (value: boolean) => void;
  readOnly?: boolean;
}

interface handleConfirmProps {
  bulletinId: number;
  resolution: IBulletinResolution;
  resolutionId: number;
}

export const PublishedResolution = ({
  resolution,
}: {
  resolution: IBulletinResolution;
}) => {
  const attachments = Object.values(resolution.attachments);
  const getThumbnailUrl = (attachment) => {
    const url = new URL((attachment as any)?.download_url ?? "");
    return url.toString();
  };

  const [theme] = useTheme();
  const isSmall = useMediaQuery((theme as Theme).breakpoints.down("sm"));
  const isMedium = useMediaQuery((theme as Theme).breakpoints.down("md"));

  const mediaItems =
    attachments.length > 0
      ? attachments.map((attachment) => (
          <>
            <MediaSlide
              key={getThumbnailUrl(attachment)}
              mediaSrc={getThumbnailUrl(attachment)}
              mediaType={
                attachment?.content_type.includes("video") ? "video" : "image"
              }
              // customHeight={400}
            />
          </>
        ))
      : [];

  return (
    <Paper
      style={{
        padding: "16px",
        marginBottom: "16px",
        alignItems: "center",
        gap: "16px",
      }}
      elevation={4}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography
          variant="h5"
          style={{
            marginBottom: "16px",
            fontWeight: "bold",
          }}
        >
          {resolution.reason !== "Other"
            ? resolution.reason
            : resolution.other_reason}
          <Chip
            label={resolution.resolved ? "Resolved" : "Active"}
            color={resolution.resolved ? "primary" : "secondary"}
            size="small"
            style={{
              marginLeft: "8px",
              textTransform: "uppercase",
              fontSize: "0.8rem",
            }}
          />
        </Typography>
        <Typography
          variant="body1"
          style={{
            marginBottom: "16px",
            fontSize: "0.9rem",
            color: "GrayText",
          }}
        >
          Update Time: {new Date(resolution.time_updated).toLocaleString()}
        </Typography>
      </div>

      <Typography
        variant="body1"
        style={{
          marginBottom: "16px",
          whiteSpace: "pre-wrap",
        }}
        paragraph
      >
        {resolution.details}
      </Typography>
      {/* <div
        style={{
          display: "flex",
          justifyContent: "center",
          gap: "16px",
        }}
      >
        {attachments.map((attachment, index) => {
          return (
            <MediaDisplay
              key={getThumbnailUrl(attachment)}
              mediaType={
                attachment?.content_type.includes("video") ? "video" : "image"
              }
              src={getThumbnailUrl(attachment)}
            />
          );
        })}
      </div> */}
      {attachments.length > 0 && (
        <div
          style={{
            width: "95%",
            margin: "auto",
          }}
        >
          <CustomSlider items={mediaItems} maxItemsPerRow={1} />
        </div>
      )}
    </Paper>
  );
};

const ResolveBulletinItem = ({
  resolution,
  reasons,
  setIsResolveOpen,
  readOnly = false,
}: ResolveBulletinItemProps) => {
  const [updatedResolution, setResolution] = useState(resolution);
  const [initialResolution, setInitialResolution] = useState(resolution);
  const [isMediaUploading, setIsMediaUploading] = useState(false);
  const [attachments, setAttachments] = useState(resolution.attachments);
  const [isInfoIconOpen, setIsInfoIconOpen] = useState(false);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [isCancelDialogOpen, setIsCancelDialogOpen] = useState(false);
  const [isRevertDialogOpen, setIsRevertDialogOpen] = useState(false);
  const [shouldCancel, setShouldCancel] = useState(false);
  const shouldCancelRef = useRef(shouldCancel);

  const wasPreviouslyStarted =
    !resolution.published &&
    (resolution.reason !== "" ||
      resolution.details !== "" ||
      Object.keys(resolution.attachments)?.length > 0);

  const {
    alertProperties: { bulletinId, resolutions },
    setAlertProperty,
  } = useContext(SingleAlertContext);

  const fileInput = useRef(null);

  const [fieldErrors, setFieldErrors] = useState({});

  const hasChanges = () => {
    return (
      updatedResolution.reason !== initialResolution.reason ||
      updatedResolution.other_reason !== initialResolution.other_reason ||
      updatedResolution.details !== initialResolution.details ||
      updatedResolution.resolved !== initialResolution.resolved
    );
  };

  useEffect(() => {
    shouldCancelRef.current = shouldCancel;
  });

  const handleConfirm = ({
    bulletinId,
    resolution,
    resolutionId,
  }: handleConfirmProps) => {
    if (readOnly) return;
    const errors = {};

    if (!resolution.reason) errors["reason"] = "Reason is required";
    if (!resolution.details) errors["details"] = "Details are required";
    if (resolution.reason === "Other" && !resolution.other_reason)
      errors["other_reason"] = "Other reason is required";

    setFieldErrors(errors);

    if (Object.keys(errors)?.length > 0) return;
    resolution.details = resolution.details.trim();

    apiAlertClient
      .patchBulletinResolution(bulletinId, resolutionId, {
        ...resolution,
        published: true,
      })
      .then((response) => {
        const updatedResolutions = resolutions.map((res) =>
          res.id === resolutionId ? response : res,
        );
        setAlertProperty("resolutions", updatedResolutions);
        setInitialResolution(response);
        setIsResolveOpen(false);
      });
  };

  const handleSaveDraft = (
    bulletinId: number,
    resolution: IBulletinResolution,
    resolutionId: number,
  ) => {
    apiAlertClient
      .patchBulletinResolution(bulletinId, resolutionId, resolution)
      .then((response) => {
        const updatedResolutions = resolutions.map((res) =>
          res.id === resolutionId ? response : res,
        );
        setAlertProperty("resolutions", updatedResolutions);
        setInitialResolution(response);
        toast.success("Resolution saved as draft successfully");
      });
  };

  const handleMediaUpload = async (event, files) => {
    setIsMediaUploading(true);

    for (let index = 0; index < files.length; index++) {
      if (bulletinId) {
        try {
          const res = await apiAlertClient.uploadResolutionAttachment(
            bulletinId,
            updatedResolution.id,
            files[index],
          );

          if (shouldCancelRef.current) {
            // Cancelled uploading, break the loop
            handleAttachDelete(
              files[index].name,
              bulletinId,
              updatedResolution.id,
            );
            break;
          }

          if (res) {
            const updatedResolutions = resolutions.map((res) =>
              res.id === updatedResolution.id ? res : res,
            );
            setAlertProperty("resolutions", updatedResolutions);
            setAttachments(res.attachments);
          } else {
            toast.error("Error uploading the Image.");
          }
        } catch (err) {
          console.error(err);
        }
      }
    }
    setIsMediaUploading(false);

    if (event.target) {
      event.target.value = null;
    }
  };

  const handleAttachDelete = (
    fileName: string,
    bulletinID: number,
    resolutionID: number,
  ) => {
    apiAlertClient
      .deleteResolutionAttachment(bulletinID, resolutionID, fileName)
      .then((newResolution) => {
        const updatedResolutions = resolutions.map((res) =>
          res.id === resolutionID ? newResolution : res,
        );
        setAlertProperty("resolutions", updatedResolutions);
        setAttachments(newResolution.attachments);
      });
  };

  const handleReset = (bulletinId, resolutionId) => {
    apiAlertClient
      .deleteBulletinResolution(bulletinId, resolutionId)
      .then(() => {
        const updatedResolutions = resolutions.filter(
          (res) => res.id !== resolutionId,
        );
        setAlertProperty("resolutions", updatedResolutions);
        setInitialResolution(initialResolution);
        setResolution(initialResolution);
        setIsResolveOpen(false);
      });
  };

  const dialogs = {
    delete: {
      title: "Delete Resolution",
      content: "Are you sure you want to delete this resolution?",
      actions: [
        {
          label: "Cancel",
          action: () => setIsDeleteDialogOpen(false),
        },
        {
          label: "Delete",
          action: () => handleReset(bulletinId as number, updatedResolution.id),
        },
      ],
      open: isDeleteDialogOpen,
      onClose: () => setIsDeleteDialogOpen(false),
    },
    discardChanges: {
      title: "Revert Changes",
      content:
        "Are you sure you want to revert to the previously saved resolution?",
      actions: [
        {
          label: "Cancel",
          action: () => {
            setIsRevertDialogOpen(false);
          },
        },
        {
          label: "Revert",
          action: () => {
            setResolution(initialResolution);
            setIsRevertDialogOpen(false);
          },
        },
      ],
      open: isRevertDialogOpen,
      onClose: () => setIsRevertDialogOpen(false),
    },
    cancel: {
      title: "Cancel",
      content: "Are you sure you want to cancel? All changes will be lost.",
      actions: [
        {
          label: "Cancel",
          action: () => setIsDeleteDialogOpen(false),
        },
        {
          label: "Confirm",
          action: () => setIsResolveOpen(false),
        },
      ],
      open: isCancelDialogOpen,
      onClose: () => setIsCancelDialogOpen(false),
    },
  };

  const renderDialog = (dialog) => {
    return (
      <Dialog open={dialog.open} onClose={dialog.onClose}>
        <DialogTitle>{dialog.title}</DialogTitle>
        <DialogContent>
          <DialogContentText>{dialog.content}</DialogContentText>
        </DialogContent>
        <DialogActions>
          {dialog.actions.map((action) => (
            <Button onClick={action.action} color="primary" key={action.label}>
              {action.label}
            </Button>
          ))}
        </DialogActions>
      </Dialog>
    );
  };

  return (
    <Paper
      style={{
        padding: "16px",
        marginBottom: "16px",
        display: "flex",
        flexDirection: "column",
      }}
      elevation={4}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography
          variant="h5"
          style={{
            fontWeight: "bold",
          }}
        >
          Resolve Bulletin
        </Typography>
        <IconButton
          onClick={() => setIsInfoIconOpen(!isInfoIconOpen)}
          style={{ alignSelf: "flex-end" }}
        >
          <Info />
        </IconButton>
      </div>
      {wasPreviouslyStarted && (
        <Typography
          variant="body1"
          style={{
            marginBottom: "8px",
            color: "GrayText",
            fontSize: "0.8rem",
          }}
        >
          This resolution was previously started. You can continue editing or
          discard changes.
        </Typography>
      )}
      {isInfoIconOpen && (
        <Typography
          variant="body1"
          style={{
            marginBottom: "16px",
            color: colors.MUI_TEXT_SECONDARY,
            fontSize: "0.9rem",
          }}
        >
          This information will be visible to users with access to this
          bulletin.
        </Typography>
      )}
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: isSmallScreen() ? "16px" : "0",
          gap: isSmallScreen() ? "0" : "16px",
          flexDirection: isSmallScreen() ? "column" : "row",
        }}
      >
        <Autocomplete
          options={reasons}
          value={updatedResolution.reason}
          onChange={(event, value) =>
            setResolution({ ...updatedResolution, reason: value || "" })
          }
          renderInput={(params) => (
            <TextField
              {...params}
              label="Reason"
              variant="outlined"
              required
              error={fieldErrors["reason"]}
              helperText={fieldErrors["reason"]}
            />
          )}
          style={{
            width: isSmallScreen() ? "100%" : "50%",
          }}
          disabled={readOnly}
        />
        {updatedResolution.reason === "Other" && (
          <TextField
            value={updatedResolution?.other_reason}
            label="Other Reason"
            variant="outlined"
            style={{
              width: isSmallScreen() ? "100%" : "50%",
            }}
            onChange={(event) =>
              setResolution({
                ...updatedResolution,
                other_reason: event.target.value.slice(0, 30),
              })
            }
            required
            error={fieldErrors["other_reason"]}
            helperText={
              fieldErrors["other_reason"] ||
              updatedResolution.other_reason.length + "/30"
            }
            disabled={readOnly}
          />
        )}
      </div>
      <TextField
        label="Resolution Details"
        variant="outlined"
        placeholder="Provide resolution details (e.g., name of suspect identified, etc.)"
        required
        value={updatedResolution.details}
        error={!!fieldErrors["details"]}
        helperText={fieldErrors["details"]}
        onChange={(event) =>
          setResolution({ ...updatedResolution, details: event.target.value })
        }
        InputProps={{
          inputComponent: TextareaAutosize,
          inputProps: {
            minRows: 4,
            maxRows: 100,
            style: { resize: "none" },
          },
        }}
        disabled={readOnly}
      />
      <FormControl component="fieldset" disabled={readOnly}>
        <FormControlLabel
          control={
            <Switch
              checked={updatedResolution.resolved}
              onChange={(event, value) =>
                setResolution({ ...updatedResolution, resolved: value })
              }
              color="primary"
              size="small"
              style={{
                marginRight: "8px",
              }}
            />
          }
          label={
            updatedResolution.resolved ? (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  gap: "8px",
                  alignItems: "center",
                }}
              >
                <Typography variant="body1">Resolved</Typography>
                <Typography
                  variant="body1"
                  style={{
                    color: "GrayText",
                    fontSize: "0.8rem",
                    fontWeight: "normal",
                  }}
                >
                  Close the bulletin, and disable new resolutions.
                </Typography>
              </div>
            ) : (
              <Typography variant="body1">Keep Active</Typography>
            )
          }
          style={{
            color: updatedResolution.resolved
              ? colors.MUI_TEXT_PRIMARY
              : colors.MUI_TEXT_SECONDARY,
            textTransform: "uppercase",
            marginLeft: "2px",
            fontSize: "0.9rem",
            fontWeight: "bold",
          }}
        />
      </FormControl>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          paddingTop: "16px",
        }}
      >
        {!isMediaUploading && (
          <TriUploadFile
            uploadMessage="Upload any relevant images or videos"
            fileInput={fileInput}
            handleFileUpload={handleMediaUpload}
            accept="image/*,video/*"
            multiple
            disabled={readOnly}
          />
        )}
        {isMediaUploading && (
          <>
            <Button
              variant="outlined"
              onClick={() => {
                setShouldCancel(true);
                setIsMediaUploading(false);
              }}
            >
              Cancel
            </Button>
          </>
        )}
      </div>
      {isMediaUploading && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            paddingTop: "16px",
          }}
        >
          <CircularProgress />
        </div>
      )}
      {attachments && bulletinId && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            paddingTop: "16px",
            gap: "16px",
          }}
        >
          {Object.values(attachments).map((attachment) => (
            <MediaComponent
              key={attachment.download_url}
              handleFileDelete={() =>
                handleAttachDelete(
                  attachment.filename,
                  bulletinId,
                  updatedResolution.id,
                )
              }
              bulletinID={bulletinId}
              evidence={attachment}
              setEditMediaObject={() => {}}
              disableEdit={true}
              disableTags={true}
              disableOpenAiDescription={false}
            />
          ))}
        </div>
      )}
      {wasPreviouslyStarted && (
        <Typography
          variant="body1"
          style={{
            color: "GrayText",
            fontSize: "0.8rem",
            alignSelf: "flex-end",
          }}
        >
          {resolution.time_updated &&
            `Last Updated: ${new Date(
              resolution.time_updated,
            ).toLocaleString()}`}
        </Typography>
      )}
      <div
        className="resolve-actions"
        style={{
          marginTop: "16px",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <div
          style={{
            display: "flex",
            gap: "16px",
          }}
        >
          <Button
            variant="outlined"
            color="secondary"
            onClick={
              hasChanges() && wasPreviouslyStarted
                ? () => setIsRevertDialogOpen(true)
                : wasPreviouslyStarted
                  ? () => setIsDeleteDialogOpen(true)
                  : () => {
                      if (hasChanges()) {
                        setIsCancelDialogOpen(true);
                      } else {
                        setIsResolveOpen(false);
                      }
                    }
            }
          >
            {hasChanges() && wasPreviouslyStarted
              ? "Revert Changes"
              : wasPreviouslyStarted
                ? "Delete"
                : "Cancel"}
          </Button>
        </div>

        <div
          style={{
            display: "flex",
            gap: "16px",
          }}
        >
          <Button
            variant="contained"
            color="secondary"
            onClick={() => {
              if (hasChanges()) {
                handleSaveDraft(
                  bulletinId as number,
                  updatedResolution,
                  updatedResolution.id,
                );
                setIsResolveOpen(false);
              } else {
                setIsResolveOpen(false);
              }
            }}
          >
            {hasChanges() ? "Save as Draft" : "Close"}
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              handleConfirm({
                bulletinId: bulletinId as number,
                resolution: updatedResolution,
                resolutionId: updatedResolution.id,
              });
            }}
            disabled={readOnly}
          >
            Confirm
          </Button>
        </div>
      </div>
      {Object.values(dialogs).map((dialog) => renderDialog(dialog))}
    </Paper>
  );
};

interface ResolveBulletinProps {
  bulletinType: BULLETIN_TYPE;
  bulletinId: number;
  setIsResolveOpen: (value: boolean) => void;
}

const ResolveBulletin = ({
  bulletinType,
  bulletinId,
  setIsResolveOpen,
}: ResolveBulletinProps) => {
  const {
    alertProperties: { resolutions },
    setAlertProperty,
  } = useContext(SingleAlertContext);
  const { currentUser } = useContext(AuthStateContext);

  const [isCreatingResolution, setIsCreatingResolution] = useState(false);

  const reasons = bulletinResolutions[bulletinType];

  const [unpublishedResolutions, setUnpublishedResolutions] = useState(
    resolutions?.filter((resolution) => !resolution.published),
  );

  const [alertPermissions] = useState(
    parseUserPermissions(currentUser?.permissions as IUserPermission[]),
  );
  const readOnly = !alertPermissions.canCreateBulletin;

  useEffect(() => {
    if (readOnly) {
      //mock empty resolution for read-only users
      setUnpublishedResolutions([
        {
          id: -1,
          reason: "",
          other_reason: "",
          details: "",
          resolved: false,
          published: false,
          time_updated: new Date().toISOString(),
          attachments: {},
          bulletin_id: bulletinId,
          resolution_author_id: currentUser?.id ?? "",
          time_created: new Date().toISOString(),
        },
      ]);
      return;
    }

    setUnpublishedResolutions(resolutions?.filter((res) => !res.published));
    if (
      unpublishedResolutions?.length === 0 ||
      (resolutions === undefined && !isCreatingResolution)
    ) {
      setIsCreatingResolution(true);
      apiAlertClient
        .putBulletinResolution(bulletinId, {
          reason: "",
          other_reason: "",
          details: "",
          resolved: true,
          published: false,
        })
        .then((response: IBulletinResolution) => {
          setAlertProperty("resolutions", [...resolutions, response]);
          setUnpublishedResolutions([...unpublishedResolutions, response]);
        })
        .finally(() => {
          setIsCreatingResolution(false);
        });
    }
  }, [resolutions, readOnly]);

  return (
    <div className={styles.resolveContainer}>
      {readOnly && (
        <DisabledComponentBanner
          handleClose={() => setIsResolveOpen(false)}
          title="You do not have permission to resolve this bulletin."
          message={
            <Typography>
              Please contact{" "}
              <Link
                rel="noopener noreferrer"
                target="_blank"
                href={mailTo({
                  email: "support@tryrubicon.com",
                  subject: "Requesting Access to Resolve Bulletins",
                  body: `Hello Rubicon Support Team, 

                  I would like to request access to resolve bulletins. I am trying to resolve a bulletin with id ${bulletinId}.

                  Thank you. 

                  Sincerely,

                  ${currentUser?.first_name} ${currentUser?.last_name} 
                  ${currentUser?.email}`,
                })}
                color="secondary"
              >
                support@tryrubicon.com
              </Link>{" "}
              to request access.
            </Typography>
          }
        />
      )}
      {unpublishedResolutions?.map((resolution) => (
        <ResolveBulletinItem
          key={resolution.id}
          resolution={resolution}
          reasons={reasons}
          setIsResolveOpen={setIsResolveOpen}
          readOnly={readOnly}
        />
      ))}
    </div>
  );
};

export default ResolveBulletin;
