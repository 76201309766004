import { ArrowBack } from "@mui/icons-material";
import Button from "@mui/material/Button";
import { Toolbar, SaveButton, DeleteButton } from "react-admin";
import { useNavigate } from "react-router-dom";
import "./common.css";
import { toast } from "react-toastify";

export const FormToolbar = (props) => {
  const navigate = useNavigate();
  const cancelForm = () => {
    navigate(-1);
    toast.clearWaitingQueue();
  };
  let showSave = true;
  let showCancel = false;

  let showDelete = false;
  if (props.hideSave) {
    showSave = false;
  }
  if (props.showCancel) {
    showCancel = true;
  }
  if (props.hasDelete) {
    showDelete = props.hasDelete;
  }
  return (
    <Toolbar>
      <div className="toolBarMain">
        {showSave && (
          <SaveButton
            disabled={showSave ? false : true}
            label={props?.saveLabel ? props.saveLabel : "SAVE"}
          />
        )}
        {!showCancel && (
          <Button
            startIcon={<ArrowBack />}
            className="cancel_button"
            variant="contained"
            color="primary"
            size="medium"
            onClick={cancelForm}
          >
            Back
          </Button>
        )}
        {showCancel && (
          <Button
            className="cancel_button"
            variant="contained"
            color="primary"
            size="medium"
            onClick={props.onCancel}
          >
            Cancel
          </Button>
        )}
        {showDelete ? <DeleteButton {...props} /> : null}
      </div>
    </Toolbar>
  );
};
