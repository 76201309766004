import { Edit } from "@mui/icons-material";

import styles from "../report.module.css";

export const AddressField = (props) => {
  const { address, label } = props;

  const addressLineOneText = [
    address.streetNumber,
    address.streetName,
    address.streetType,
    address.postDirection,
  ]
    .filter(Boolean)
    .join(" ");
  const addressLineTwoText = [
    address.apartment,
    address.city,
    address.province,
    address.country,
    address.zipCode,
  ]
    .filter(Boolean)
    .join(", ");

  return (
    <div className="ra-field report-field-row">
      <div className="MuiFormControl-root MuiFormControl-marginDense">
        <label
          className="MuiFormLabel-root MuiInputLabel-root RaLabeled-label MuiInputLabel-formControl MuiInputLabel-animated MuiInputLabel-shrink MuiInputLabel-marginDense"
          data-shrink="true"
        >
          <span>{label}</span>
        </label>
        <div className={styles.fullAddress + " RaLabeled-value"}>
          <span className="address-value MuiTypography-root report-field-row MuiTypography-body2">
            {[addressLineOneText, addressLineTwoText]
              .filter(Boolean)
              .join(", ")}
          </span>
          <span
            className={styles.editIcon}
            onClick={() => {
              props.onEditAddress();
            }}
          >
            <Edit />
          </span>
        </div>
      </div>
    </div>
  );
};
