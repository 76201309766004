//External Imports
import {
  ArrowBack,
  ArrowForward,
  Delete,
  ExpandMore,
} from "@mui/icons-material";
import {
  TextField,
  Button,
  Paper,
  IconButton,
  Autocomplete,
  createFilterOptions,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  CircularProgress,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from "@mui/material";
import { Formik, FormikHelpers } from "formik";
import { debounce } from "lodash";
import { useContext, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import * as Yup from "yup";

//Internal Imports
import styles from "./AddCamerasStep.module.css";
import PropertyCamerasMap from "./PropertyCamerasMap";
import { API } from "../../../../common/api-client/camera-api/api";
import { Camera, ICameras } from "../../../../types";
import { CameraRegistryContext } from "../context";

const filter = createFilterOptions<InstallerOption>();

const installersList: InstallerOption[] = [
  { value: "DIY", label: "DIY (Self-install)" },
  { value: "Bell", label: "Bell" },
  { value: "K9 Alarm", label: "K9 Alarm" },
  { value: "Alliance", label: "Alliance" },
  { value: "Telus/ADT", label: "Telus/ADT" },
];

type InstallerOption = { value: string; label: string };

// Define the validation schema
const CameraValidationSchema = Yup.object().shape({
  locationDescription: Yup.string().required(
    "Describe where it is located on the property",
  ),
  FOVDescription: Yup.string().required(
    "Describe what is captured by the camera",
  ),
  Make: Yup.string(),
  Model: Yup.string(),
});

const CamerasValidationSchema = Yup.object().shape({
  cameras: Yup.array()
    .of(CameraValidationSchema)
    .min(1, "Add at least one camera to the map")
    .required("Add at least one camera to the map"),
  installedBy: Yup.string().required(
    "Add information on who installed the cameras",
  ),
  additionalCamerasDescription: Yup.string(),
});

const AddCamerasStep = ({
  nextStep,
  prevStep,
}: {
  nextStep?: () => void;
  prevStep?: () => void;
}) => {
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const [cameraIndexToDelete, setCameraIndexToDelete] = useState<number | null>(
    null,
  );
  //   const [isSubmitionCompleted, setSubmitionCompleted] = useState(false);
  const [expandedAccordion, setExpandedAccordion] = useState<boolean>(true);
  const navigate = useNavigate();
  const {
    property,
    setProperty,
    cameras,
    police,
    setCameraField,
    updateCameras,
    deleteCamera,
  } = useContext(CameraRegistryContext);
  //   console.log("Initial property: ", property);
  // console.log("Initial cameras: ", cameras);

  useEffect(() => {
    console.log("In UseEffect Add Cameras");
    if (
      !property ||
      !setProperty ||
      !property?.googleMapsPlace?.geometry ||
      !cameras
    )
      navigate("add-address");
  }, []);

  if (
    !property ||
    !setProperty ||
    !property?.googleMapsPlace?.geometry ||
    !cameras
  ) {
    console.log("didn't pass");
    return <>Error: Please notify support@tryrubicon.com</>;
  }

  //   const onSubmit={(values, { setSubmitting }) => {
  //     setSubmitting(true);
  //    axios.post(contactFormEndpoint,
  //      values,
  //      {
  //        headers: {
  //          'Access-Control-Allow-Origin': '*',
  //          'Content-Type': 'application/json',
  //        }
  //      },
  //    ).then((resp) => {
  //     //  setSubmitionCompleted(true);
  //    }
  //    );
  //  }}

  const patchCameras = async (values: ICameras) => {
    let camerasToUse = cameras;

    const changed = (oldCamera: Camera, newCamera: Camera) => {
      return (
        oldCamera.FOVDescription !== newCamera.FOVDescription ||
        oldCamera.locationDescription !== newCamera.locationDescription ||
        oldCamera.Make !== newCamera.Make ||
        oldCamera.Model !== newCamera.Model
      );
    };
    const changedIDs = cameras.cameras
      .filter(
        (camera, index) => camera.id && changed(camera, values.cameras[index]),
      )
      .map((camera) => camera.id);

    if (updateCameras) {
      camerasToUse = updateCameras(values);
    }

    try {
      const updateCamera = async (camera: Camera) => {
        await API.camera.patchPortal(property.id ?? "", camera.id!, {
          field_of_view_description: camera.FOVDescription,
          location_description: camera.locationDescription,
          make: camera.Make,
          model: camera.Model,
        });
      };

      const oldCameras = camerasToUse.cameras.filter(
        (camera) => camera.id && changedIDs.includes(camera.id),
      );

      for (const camera of oldCameras) {
        updateCamera(camera);
        await new Promise((r) => setTimeout(r, 200));
      }
      return true;
    } catch (error) {
      console.log("ERROR: ", error);
      toast.error(`An error occurred. Please check the form and try again.`);
      return false;
    }
  };

  const handleOnSubmit = async (
    values: ICameras,
    helpers: FormikHelpers<ICameras>,
  ) => {
    console.log("SUBMITTING ", values);
    helpers.setSubmitting(true);
    const success = await patchCameras(values);
    if (success) {
      nextStep ? nextStep() : navigate("../add-contacts");
    }
  };

  const openDeleteDialog = (index: number) => {
    setCameraIndexToDelete(index);
    setDialogOpen(true);
  };

  const handleCameraDelete = async (
    setFieldValue: (
      field: string,
      value: any,
      shouldValidate?: boolean,
    ) => void,
    values: ICameras,
    index?: number,
  ) => {
    console.log("Trying to delete camera");
    let indexToUse = index ?? 0;
    if (cameraIndexToDelete !== null) {
      indexToUse = cameraIndexToDelete;
    }
    const cameraToDelete = cameras.cameras[indexToUse];

    setDialogOpen(false);
    if (cameraToDelete.id) {
      setFieldValue(
        "cameras",
        values.cameras.filter((_, cameraIndex) => cameraIndex !== indexToUse),
      );
      deleteCamera && deleteCamera(indexToUse);
      console.log("cameraToDelete", cameraToDelete);

      API.camera.deletePortal(property.id ?? "", cameraToDelete.id ?? "");
    }
    setCameraIndexToDelete(null);
  };

  return (
    <Formik
      initialValues={cameras}
      validationSchema={CamerasValidationSchema}
      onSubmit={handleOnSubmit}
    >
      {(props) => {
        const {
          values,
          touched,
          errors,
          isSubmitting,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
        } = props;

        return (
          <form className={styles.formContainer} onSubmit={handleSubmit}>
            <Accordion
              disableGutters
              elevation={3}
              expanded={expandedAccordion}
              onChange={() => setExpandedAccordion((prevValue) => !prevValue)}
            >
              <AccordionSummary
                expandIcon={<ExpandMore />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <div className={styles.accordionTitleContainer}>
                  <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                    Cameras Information
                  </Typography>
                  <Typography sx={{ color: "text.secondary" }}>
                    Fields marked with * are required.
                  </Typography>
                </div>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  Any video capturing device should be added. Any camera
                  footage, from professional security cameras to smart
                  doorbells, can assist in investigations.
                </Typography>
              </AccordionDetails>
            </Accordion>

            <Paper elevation={3} className={styles.camerasContainer}>
              <Typography variant="h6">
                {property.googleMapsPlace?.formatted_address
                  ? property.googleMapsPlace.formatted_address?.includes(
                      property.googleMapsPlace.name
                        ? property.googleMapsPlace.name
                        : "",
                    )
                    ? property.googleMapsPlace.formatted_address
                    : property.googleMapsPlace.name +
                      " - " +
                      property.googleMapsPlace.formatted_address
                  : ""}
              </Typography>

              <Typography>Tap on the map to add a new camera</Typography>

              <PropertyCamerasMap
                location={
                  property.googleMapsPlace?.geometry
                    ? property.googleMapsPlace.geometry
                    : (police?.mapCenter ?? { lat: 43.6532, lng: -79.3832 })
                }
                values={values}
                setFieldValue={async (field: string, value: Camera[]) => {
                  setFieldValue(field, value);
                  const index = value.length - 1;
                  const camera = value[index];
                  const newCamera = await API.camera.putPortal(
                    property.id ?? "",
                    {
                      latitude: camera.geometry.lat,
                      longitude: camera.geometry.lng,
                    },
                  );
                  setCameraField && setCameraField("id", newCamera.id, index);
                }}
                errors={errors}
              />

              {values.cameras.map((camera, index) => {
                return (
                  <div key={index}>
                    <div className={styles.cameraHeaderContainer}>
                      <Typography sx={{ fontWeight: "bold" }}>
                        Camera #{index + 1}
                      </Typography>{" "}
                      <IconButton
                        onClick={() => {
                          const camera = { ...values.cameras[index] };
                          if (
                            camera.locationDescription ||
                            camera.FOVDescription ||
                            camera.Make ||
                            camera.Model
                          )
                            openDeleteDialog(index);
                          else handleCameraDelete(setFieldValue, values, index);
                        }}
                        color="primary"
                      >
                        <Delete />
                      </IconButton>
                    </div>
                    <div className={styles.cameraFieldsContainer}>
                      <TextField
                        className={styles.fieldContainer}
                        name={`cameras[${index}].locationDescription`}
                        label="Location on Property"
                        placeholder="E.g. front-door, North-West corner, back-entrance"
                        size="small"
                        value={camera.locationDescription}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        required
                        onKeyDown={(e) => {
                          e.key === "Enter" && e.preventDefault();
                        }}
                        helperText={
                          errors.cameras &&
                          touched.cameras &&
                          touched.cameras[index]?.locationDescription &&
                          (errors.cameras[index] as any)?.locationDescription
                        }
                        error={
                          !!(
                            errors.cameras &&
                            touched.cameras &&
                            (errors.cameras[index] as any)
                              ?.locationDescription &&
                            touched.cameras[index]?.locationDescription
                          )
                        }
                      />
                      <TextField
                        className={styles.fieldContainer}
                        name={`cameras[${index}].FOVDescription`}
                        label="Field of View"
                        placeholder="E.g. Divison st., West side parking lot, or Intersection"
                        size="small"
                        value={camera.FOVDescription}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        required
                        onKeyDown={(e) => {
                          e.key === "Enter" && e.preventDefault();
                        }}
                        helperText={
                          errors.cameras &&
                          touched.cameras &&
                          touched.cameras[index]?.FOVDescription &&
                          (errors.cameras[index] as any)?.FOVDescription
                        }
                        error={
                          !!(
                            errors.cameras &&
                            touched.cameras &&
                            (errors.cameras[index] as any)?.FOVDescription &&
                            touched.cameras[index]?.FOVDescription
                          )
                        }
                      />
                      <TextField
                        className={styles.fieldContainer}
                        name={`cameras[${index}].Make`}
                        label="Make"
                        size="small"
                        value={camera.Make}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        onKeyDown={(e) => {
                          e.key === "Enter" && e.preventDefault();
                        }}
                        helperText={
                          errors.cameras &&
                          touched.cameras &&
                          touched.cameras[index]?.Make &&
                          (errors.cameras[index] as any)?.Make
                        }
                        error={
                          !!(
                            errors.cameras &&
                            touched.cameras &&
                            (errors.cameras[index] as any)?.Make &&
                            touched.cameras[index]?.Make
                          )
                        }
                      />
                      <TextField
                        className={styles.fieldContainer}
                        name={`cameras[${index}].Model`}
                        label="Model"
                        size="small"
                        value={camera.Model}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        onKeyDown={(e) => {
                          e.key === "Enter" && e.preventDefault();
                        }}
                        helperText={
                          errors.cameras &&
                          touched.cameras &&
                          touched.cameras[index]?.Model &&
                          (errors.cameras[index] as any)?.Model
                        }
                        error={
                          !!(
                            errors.cameras &&
                            touched.cameras &&
                            (errors.cameras[index] as any)?.Model &&
                            touched.cameras[index]?.Model
                          )
                        }
                      />
                    </div>
                  </div>
                );
              })}
              <div />
              <Autocomplete
                value={
                  installersList.find(
                    (installer) => installer.value === values.installedBy,
                  ) ||
                  (values.installedBy
                    ? { label: values.installedBy, value: values.installedBy }
                    : null)
                }
                onChange={(_event, newValue) => {
                  if (newValue === null) {
                    setFieldValue("installedBy", "");
                  } else if (typeof newValue === "string") {
                    setFieldValue("installedBy", newValue);
                  } else if (newValue?.value) {
                    setFieldValue("installedBy", newValue.value);
                  }
                }}
                freeSolo
                fullWidth
                size="small"
                selectOnFocus
                clearOnBlur
                handleHomeEndKeys
                options={installersList}
                onKeyDown={(e) => {
                  e.key === "Enter" && e.preventDefault();
                }}
                filterOptions={(options, params) => {
                  const filtered: InstallerOption[] = filter(options, params);

                  const { inputValue } = params;
                  // Suggest the creation of a new value
                  const isExisting = options.some(
                    (option) => inputValue === option.label,
                  );

                  if (inputValue !== "" && !isExisting) {
                    filtered.push({
                      value: inputValue,
                      label: `Add "${inputValue}"`,
                    });
                  }

                  return filtered;
                }}
                getOptionLabel={(option) => {
                  // Value selected with enter, right from the input
                  if (typeof option === "string") {
                    return option;
                  }
                  // Add "xxx" option created dynamically
                  if (option.value) {
                    return option.value;
                  }
                  // Regular option
                  return option.label;
                }}
                renderOption={(props, option) => (
                  <li {...props}>{option.label}</li>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Installer"
                    placeholder="Type a different installer"
                    className={styles.fieldContainer}
                    onBlur={handleBlur}
                    required
                    error={!!(errors.installedBy && touched.installedBy)}
                    helperText={touched.installedBy && errors.installedBy}
                  />
                )}
              />
              <TextField
                name="additionalCamerasDescription"
                label="Additional details (e.g., quality, types of camera, night vision ability, etc.)"
                multiline
                fullWidth
                value={values.additionalCamerasDescription}
                onChange={handleChange}
                onBlur={handleBlur}
                minRows={3}
                onKeyDown={(e) => {
                  e.key === "Enter" && e.preventDefault();
                }}
              />
            </Paper>

            <div className={styles.buttonContainer}>
              <Button
                variant="outlined"
                color="secondary"
                startIcon={<ArrowBack />}
                disabled={isSubmitting}
                onClick={() => {
                  patchCameras(values);
                  prevStep ? prevStep() : navigate("../add-address");
                }}
              >
                Back
              </Button>
              <Button
                type="submit"
                variant="contained"
                size="large"
                endIcon={
                  isSubmitting ? (
                    <CircularProgress size={20} />
                  ) : (
                    <ArrowForward />
                  )
                }
                disabled={isSubmitting}
              >
                Next
              </Button>

              <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)}>
                <DialogTitle>Confirm Deletion</DialogTitle>
                <DialogContent>
                  <DialogContentText>
                    Are you sure you want to delete this camera? You will lose
                    all information that you’ve entered for this camera.
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button
                    onClick={() => setDialogOpen(false)}
                    color="secondary"
                  >
                    Cancel
                  </Button>
                  <Button
                    onClick={() => handleCameraDelete(setFieldValue, values)}
                    color="secondary"
                  >
                    Delete Camera
                  </Button>
                </DialogActions>
              </Dialog>
            </div>
          </form>
        );
      }}
    </Formik>
  );
};

export default AddCamerasStep;
