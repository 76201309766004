/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { GetPropertyCamerasCheckResponse } from '../models/GetPropertyCamerasCheckResponse';
import type { GetPropertyContactsCheckResponse } from '../models/GetPropertyContactsCheckResponse';
import type { GetPropertyLocationCheckResponse } from '../models/GetPropertyLocationCheckResponse';
import type { GetPropertyResponse } from '../models/GetPropertyResponse';
import type { PatchPropertyRequest } from '../models/PatchPropertyRequest';
import type { PostPropertySubmitRequest } from '../models/PostPropertySubmitRequest';
import type { PropertyModel } from '../models/PropertyModel';
import type { PutPropertyRequestPortal } from '../models/PutPropertyRequestPortal';
import type { PutPropertyRequestPublic } from '../models/PutPropertyRequestPublic';
import type { PutPropertyResponsePortal } from '../models/PutPropertyResponsePortal';
import type { PutPropertyResponsePublic } from '../models/PutPropertyResponsePublic';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class PropertyService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * @param requestBody 
     * @returns PutPropertyResponsePortal 
     * @throws ApiError
     */
    public putPortal(
requestBody: PutPropertyRequestPortal,
): CancelablePromise<PutPropertyResponsePortal> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/camera_registry/portal/property',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param propertyId 
     * @returns GetPropertyResponse 
     * @throws ApiError
     */
    public getPortal(
propertyId: string,
): CancelablePromise<GetPropertyResponse> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/camera_registry/portal/property/{property_id}',
            path: {
                'property_id': propertyId,
            },
        });
    }

    /**
     * @param propertyId property_id
     * @returns any 
     * @throws ApiError
     */
    public deletePortal(
propertyId: string,
): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/camera_registry/portal/property/{property_id}',
            path: {
                'property_id': propertyId,
            },
        });
    }

    /**
     * @param propertyId property_id
     * @param requestBody 
     * @returns PropertyModel 
     * @throws ApiError
     */
    public patchPortal(
propertyId: string,
requestBody: PatchPropertyRequest,
): CancelablePromise<PropertyModel> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/camera_registry/portal/property/{property_id}',
            path: {
                'property_id': propertyId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param propertyId property_id
     * @param requestBody 
     * @returns PropertyModel 
     * @throws ApiError
     */
    public submitPortal(
propertyId: string,
requestBody: PostPropertySubmitRequest,
): CancelablePromise<PropertyModel> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/camera_registry/portal/property/{property_id}/submit',
            path: {
                'property_id': propertyId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param requestBody 
     * @returns PutPropertyResponsePublic 
     * @throws ApiError
     */
    public putPublic(
requestBody: PutPropertyRequestPublic,
): CancelablePromise<PutPropertyResponsePublic> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/camera_registry/public/property',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param propertyId 
     * @returns GetPropertyResponse 
     * @throws ApiError
     */
    public getPublic(
propertyId: string,
): CancelablePromise<GetPropertyResponse> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/camera_registry/public/property/{property_id}',
            path: {
                'property_id': propertyId,
            },
        });
    }

    /**
     * @param propertyId property_id
     * @returns any 
     * @throws ApiError
     */
    public deletePublic(
propertyId: string,
): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/camera_registry/public/property/{property_id}',
            path: {
                'property_id': propertyId,
            },
        });
    }

    /**
     * @param propertyId property_id
     * @param requestBody 
     * @returns PropertyModel 
     * @throws ApiError
     */
    public patchPublic(
propertyId: string,
requestBody: PatchPropertyRequest,
): CancelablePromise<PropertyModel> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/camera_registry/public/property/{property_id}',
            path: {
                'property_id': propertyId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param propertyId property_id
     * @returns GetPropertyCamerasCheckResponse 
     * @throws ApiError
     */
    public camerasCheck(
propertyId: string,
): CancelablePromise<GetPropertyCamerasCheckResponse> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/camera_registry/public/property/{property_id}/cameras_check',
            path: {
                'property_id': propertyId,
            },
        });
    }

    /**
     * @param propertyId property_id
     * @returns GetPropertyContactsCheckResponse 
     * @throws ApiError
     */
    public contactsCheck(
propertyId: string,
): CancelablePromise<GetPropertyContactsCheckResponse> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/camera_registry/public/property/{property_id}/contacts_check',
            path: {
                'property_id': propertyId,
            },
        });
    }

    /**
     * @param propertyId property_id
     * @returns GetPropertyLocationCheckResponse 
     * @throws ApiError
     */
    public locationCheck(
propertyId: string,
): CancelablePromise<GetPropertyLocationCheckResponse> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/camera_registry/public/property/{property_id}/location_check',
            path: {
                'property_id': propertyId,
            },
        });
    }

    /**
     * @param propertyId property_id
     * @param requestBody 
     * @returns PropertyModel 
     * @throws ApiError
     */
    public submitPublic(
propertyId: string,
requestBody: PostPropertySubmitRequest,
): CancelablePromise<PropertyModel> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/camera_registry/public/property/{property_id}/submit',
            path: {
                'property_id': propertyId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}
