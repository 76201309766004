import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from "@mui/material";
import React from "react";

interface CancelEditDialogProps {
  title: string;
  text: string;
  open: boolean;
  onClose: (confirmed: boolean) => void;
}

const CancelEditDialog: React.FC<CancelEditDialogProps> = ({
  title,
  text,
  open,
  onClose,
}) => {
  return (
    <Dialog open={open} onClose={() => onClose(false)}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>{text}</DialogContent>
      <DialogActions>
        <Button onClick={() => onClose(false)}>Cancel</Button>
        <Button onClick={() => onClose(true)}>OK</Button>
      </DialogActions>
    </Dialog>
  );
};

export default CancelEditDialog;
