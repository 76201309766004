import { AssessmentOutlined, Assignment } from "@mui/icons-material";
import { IconButton, Theme, Tooltip, useMediaQuery } from "@mui/material";
import { FC } from "react";
import { Menu, useSidebarState, useTheme } from "react-admin";

interface Props {
  dense: boolean;
  logout: () => void;
  onMenuClick: () => void;
}

const RubiReportMenu: FC<Props> = ({ onMenuClick, dense, logout }) => {
  const [theme] = useTheme();
  const isSmall = useMediaQuery((theme as Theme).breakpoints.down("sm"));
  const [open, setOpen] = useSidebarState();

  const hideSideBar = () => {
    setOpen(false);
  };

  return (
    <div className="menu">
      {open ? (
        <div className="sidebar-top">
          <div className="app-name">
            Rubi<span>REPORT</span>
          </div>
        </div>
      ) : (
        <div className="application-item">
          <Tooltip title="Online Reporting System" placement="right" arrow>
            <IconButton
              color="secondary"
              aria-label="RubiREPORT App"
              size="large"
              disableRipple
              sx={{ backgroundColor: "lightgrey" }}
            >
              <Assignment />
            </IconButton>
          </Tooltip>
        </div>
      )}
      <div className="left-menu">
        <Menu.Item
          to={`/rubireport`}
          primaryText="Reports"
          leftIcon={<AssessmentOutlined />}
          sidebarIsOpen={open}
          dense={dense}
          onClick={isSmall ? hideSideBar : () => {}}
        />
        {/* //TODO Enable once we have a dashboards */}
        {/* <DashboardMenuItem sidebarIsOpen={open} /> */}
      </div>
    </div>
  );
};

export default RubiReportMenu;
