import { BicycleInformationField } from "../fields/BicycleInformationField";
import styles from "../report.module.css";

export const BicycleRegistration = (props) => {
  const { data } = props;

  return (
    <>
      {data.info &&
        data.info.bicycleRegistration &&
        data.info.bicycleRegistration.bicycles && (
          <div className={styles.subHeading}>Bicycle Information</div>
        )}

      {data.info &&
        data.info.bicycleRegistration &&
        data.info.bicycleRegistration.bicycles && (
          <BicycleInformationField
            data={data.info.bicycleRegistration.bicycles}
          />
        )}
    </>
  );
};
