import Rubicon_Big_Logo from "../../../../images/Rubicon_Big_Logo.png";
import DRDC_Logo from "../../../../images/drdc logo2.png";
import { ICreator } from "../../../../types";

export const getCreatorName = (creator: ICreator) => {
  const firstName = creator?.first_name;
  const lastName = creator?.last_name;
  if (firstName && lastName) {
    return `${firstName} ${lastName}`;
  } else if (firstName) {
    return firstName;
  } else if (lastName) {
    return lastName;
  } else {
    return "";
  }
};

export const creatorText = (creator: ICreator) => {
  const creatorName = getCreatorName(creator);
  if (creatorName !== "" && creator?.badge !== undefined) {
    return `${creatorName} - (${creator?.badge})`;
  } else if (creatorName) {
    return creatorName;
  } else if (creator?.badge) {
    return creator?.badge;
  } else {
    return "";
  }
};

export const approvedByText = (approvedBy, approvedByBadge) => {
  if (
    approvedBy !== "" &&
    approvedBy !== null &&
    approvedByBadge !== "" &&
    approvedByBadge !== null
  ) {
    return `${approvedBy} - (${approvedByBadge})`;
  } else if (approvedBy) {
    return approvedBy;
  } else if (approvedByBadge) {
    return approvedByBadge;
  } else {
    return "";
  }
};

export const policeServiceNameImage = {
  "Peel Regional Police": "/service-logos/Peel_Regional_Police_Logo.png",
  "Cobourg Police Service": "/service-logos/Cobourg_Police_Service_Logo.png",
  "Brantford Police Service": "/service-logos/brantford_logo_no_background.png",
  Rubicon: Rubicon_Big_Logo,
  DRDC: DRDC_Logo,
  "OPP - Ontario Provincial Police": "/service-logos/OPP.png",
  "Aylmer Police Service": "/service-logos/aylmer_police_service_logo.png",
  "Barrie Police Service": "/service-logos/barrie_police_service_logo.png",
  "Belleville Police Service":
    "/service-logos/belleville_police_service_logo.png",
  "Brockville Police Service":
    "/service-logos/brockville_police_service_logo.png",
  "Chatham-Kent Police Service":
    "/service-logos/chatham-kent_police_service_logo.png",
  "Cornwall Community Police Service":
    "/service-logos/cornwall_police_service_logo.png",
  "Cornwall Police Service": "/service-logos/cornwall_police_service_logo.png",
  "Deep River Police Service":
    "/service-logos/deep_river_police_service_logo.png",
  "Durham Regional Police Service":
    "/service-logos/durham_regional_police_service_logo.png",
  "Gananoque Police Service":
    "/service-logos/gananoque_police_service_logo.png",
  "Greater Sudbury Police Service":
    "/service-logos/greater_sudbury_police_service_logo.png",
  "Guelph Police Service": "/service-logos/guelph_police_service_logo.png",
  "Halton Regional Police Service":
    "/service-logos/halton_regional_police_service_logo.png",
  "Hamilton Police Service": "/service-logos/hamilton_police_service_logo.png",
  "Hanover Police Service": "/service-logos/hanover_police_service_logo.png",
  "Kawartha Lakes Police Service":
    "/service-logos/kawartha_lakes_police_service_logo.png",
  "Kingston Police": "/service-logos/kingston_police_logo.png",
  "LaSalle Police Service": "/service-logos/lasalle_police_service_logo.png",
  "Laval Police": "/service-logos/laval_police_logo.png",
  "London Police Service": "/service-logos/london_police_service_logo.png",
  "Niagara Regional Police Service":
    "/service-logos/niagara_regional_police_service_logo.png",
  "North Bay Police Service":
    "/service-logos/north_bay_police_service_logo.png",
  "Ottawa Police Service": "/service-logos/ottawa_police_service_logo.png",
  "Owen Sound Police Service":
    "/service-logos/owen_sound_police_service_logo.png",
  "Peterborough Police Service":
    "/service-logos/peterborough_police_service_logo.png",
  "Port Hope Police Service":
    "/service-logos/port_hope_police_service_logo.png",
  "Repentigny Police Service":
    "/service-logos/repentigny_police_service_logo.png",
  "RCMP - Royal Canadian Mounted Police":
    "/service-logos/royal_canadian_mounted_police_logo.png",
  "Sarnia Police Service": "/service-logos/sarnia_police_service_logo.png",
  "Saugeen Shores Police Service":
    "/service-logos/saugeen_shores_police_service_logo.png",
  "Sault Ste. Marie Police Service":
    "/service-logos/sault_ste_marie_police_service_logo.png",
  "Smiths Falls Police Service":
    "/service-logos/smiths_falls_police_service_logo.png",
  "South Simcoe Police Service":
    "/service-logos/south_simcoe_police_service_logo.png",
  "St. Thomas Police Service":
    "/service-logos/st_thomas_police_service_logo.png",
  "Stratford Police Service":
    "/service-logos/stratford_police_service_logo.png",
  "Strathroy-Caradoc Police Service":
    "/service-logos/strathroy-caradoc_police_service_logo.png",
  "Sûreté du Québec": "/service-logos/surete_du_quebec_logo.png",
  "Thunder Bay Police Service":
    "/service-logos/thunder_bay_police_service_logo.png",
  "Timmins Police Service": "/service-logos/timmins_police_service_logo.png",
  "Toronto Police Service": "/service-logos/toronto_police_service_logo.png",
  "Service de Police de la Ville de Gatineau":
    "/service-logos/ville_de_gatineau_police_service_logo.png",
  "Service de Police de la Ville de Montréal":
    "/service-logos/ville_de_montreal_police_service_logo.png",
  "Service de Police de la Ville de Québec":
    "/service-logos/ville_de_quebec_police_service_logo.png",
  "Waterloo Regional Police Service":
    "/service-logos/waterloo_regional_police_service_logo.png",
  "West Grey Police Service":
    "/service-logos/west_grey_police_service_logo.png",
  "Windsor Police Service": "/service-logos/windsor_police_service_logo.png",
  "Woodstock Police Service":
    "/service-logos/woodstock_police_service_logo.png",
  "York Regional Police": "/service-logos/york_regional_police_logo.png",
};

export const fetchBlobAndCreateURL = async (url: string) => {
  try {
    const response = await fetch(url, {
      credentials: "include",
      cache: "no-cache",
    });

    if (!response.ok) {
      throw new Error("Failed to fetch the blob");
    }
    const blob = await response.blob();
    return { blob, objectURL: URL.createObjectURL(blob) };
  } catch (error) {
    console.error("Error fetching the blob:", error);
    throw error;
  }
};

export const getMediaDimensions = (
  mediaType: "video" | "image",
  objectURL: string,
) => {
  return new Promise<{ width: number; height: number }>((resolve) => {
    if (mediaType === "video") {
      const video = document.createElement("video");
      video.src = objectURL;
      video.onloadedmetadata = () => {
        resolve({ width: video.videoWidth, height: video.videoHeight });
      };
      video.onerror = () => {
        resolve({ width: 0, height: 0 });
      };
    } else {
      const img = new Image();
      img.src = objectURL;
      img.onload = () => {
        resolve({ width: img.width, height: img.height });
      };
    }
  });
};

export function captureMiddleFrameFromBlob(videoBlob, callback) {
  const blobURL = URL.createObjectURL(videoBlob);
  const video = document.createElement("video");
  video.src = blobURL;

  video.addEventListener("error", function () {
    console.error("Video failed to load");
    if (callback) callback(null);
    URL.revokeObjectURL(blobURL);
  });

  video.addEventListener("loadedmetadata", function () {
    video.currentTime = video.duration / 2;
  });

  video.addEventListener("seeked", function () {
    const canvas = document.createElement("canvas");
    canvas.width = video.videoWidth;
    canvas.height = video.videoHeight;
    const ctx = canvas.getContext("2d");
    ctx?.drawImage(video, 0, 0, canvas.width, canvas.height);

    canvas.toBlob((blob) => {
      if (callback) callback(blob);

      URL.revokeObjectURL(blobURL);
    }, "image/jpeg");
  });

  video.load();
}
