import { LockOutlined } from "@mui/icons-material";
import {
  Avatar,
  Button,
  CircularProgress,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";

import verifyStyles from "./VerifyPhone.module.css";
import { AuthStateContext } from "../../context";
import styles from "../../layout/Home.module.css";
import LoginWrapper from "../../layout/LoginWrapper";

const VerifyPhone = ({
  verifyType,
  previouslyConfirmed,
}: {
  verifyType: "phone" | "email";
  previouslyConfirmed?: boolean;
}) => {
  const [error, setError] = useState("");
  const [mfaCode, setMFACode] = useState<string>("");
  const [loading, setLoading] = useState(false);

  const [otpTimer, setOtpTimer] = useState(60);

  const {
    verifyOTP,
    goBack,
    resendOTP,
    setMFAMethodID,
    setMFAUserID,
    redactedNumber,
  } = useContext(AuthStateContext);

  const [searchParams] = useSearchParams();
  const mfaMethodID = searchParams.get("mfaMethodID");
  const mfaUserID = searchParams.get("mfaUserID");

  useEffect(() => {
    if (mfaMethodID && mfaUserID) {
      setMFAMethodID(mfaMethodID);
      setMFAUserID(mfaUserID);
    }
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      if (otpTimer > 0) {
        setOtpTimer((previous) => previous - 1);
      }
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    setMFACode("");
    setError("");
    setLoading(false);
  }, [verifyType]);

  const handleSubmit = () => {
    setLoading(true);

    setError("");

    if (!mfaCode || mfaCode.length !== 6) {
      setError("Please enter a valid 6-digit code");
      setLoading(false);
      return;
    }

    verifyOTP(mfaCode).catch(() => {
      toast.error(
        "The code you entered is incorrect or has expired. Please try again or press Resend Code to get a new one-time passcode.",
      );
      setLoading(false);
    });
  };

  let title = "Verify Phone Number";
  let description = (
    <>
      Please enter the 6-digit code sent to <b>{redactedNumber}</b>.
    </>
  );

  if (verifyType === "email") {
    title = "Verify your Email";
    description = (
      <>
        To complete the multi-factor authentication setup:
        <ol>
          <li>
            <b>Check Your Inbox:</b> Enter the 6-digit code sent to your email.
          </li>
          <li>
            <b>Can&apos;t Find the Email?</b> Look in your spam folder.
          </li>
        </ol>
      </>
    );
  } else if (previouslyConfirmed) {
    title = "Confirm One-Time Passcode";
    description = (
      <>
        Please enter the 6-digit code sent to <b>{redactedNumber}</b>.
      </>
    );
  }

  function enterPressed(event: React.KeyboardEvent<HTMLInputElement>) {
    if (event.key === "Enter" && !loading) {
      handleSubmit();
    }
  }

  return (
    <LoginWrapper>
      <div className={styles.paper}>
        <Avatar className={styles.avatar}>
          <LockOutlined />
        </Avatar>
        <Typography component="h1" variant="h5">
          {title}
        </Typography>
        <Typography component="p">{description}</Typography>
        <div className={styles.form}>
          <TextField
            required
            value={mfaCode}
            autoFocus
            type="text"
            variant="outlined"
            id="mfaCode"
            label="6 Digit Code"
            fullWidth
            disabled={loading}
            onChange={(event) => {
              if (event.target.value !== null)
                setMFACode(event.target.value.replace(/\D/g, ""));
            }}
            onKeyDown={enterPressed}
          />
          <Button
            name="Resend Code"
            variant="outlined"
            type="submit"
            color="primary"
            size="large"
            disabled={otpTimer > 0 || loading}
            fullWidth
            onClick={async () => {
              setLoading(true);
              await resendOTP();
              toast.success("A new code has been sent.");
              setOtpTimer(60);
              setLoading(false);
            }}
          >
            {`Resend Code ${otpTimer > 0 ? ` - Available in ${otpTimer}` : ""}`}
          </Button>
          <Typography component="p" className={verifyStyles.codeTimer}>
            *** Your passcode is only valid for 5 minutes ***
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Button
                name="Back"
                variant="outlined"
                type="submit"
                color="primary"
                size="large"
                disabled={loading}
                fullWidth
                onClick={() => {
                  goBack();
                }}
              >
                {"Back"}
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button
                name="Submit"
                variant="contained"
                type="submit"
                color="primary"
                size="large"
                disabled={loading}
                fullWidth
                onClick={() => {
                  handleSubmit();
                }}
              >
                {loading && <CircularProgress size={25} thickness={2} />}
                {"Submit"}
              </Button>
            </Grid>
          </Grid>

          {error && <h3 className={styles.errorMessage}>{error}</h3>}
        </div>
      </div>
    </LoginWrapper>
  );
};

export default VerifyPhone;
