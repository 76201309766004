import { Button, Grid, TextField } from "@mui/material";
import { Box } from "@mui/system";
import { useState } from "react";

import { SurveyConfiguration } from "../../../common/api-client/or-api";
const ReportFilterButtons = ({
  reports,
  selectedReport,
  onReportSelect,
}: {
  reports: SurveyConfiguration[];
  selectedReport: SurveyConfiguration | null;
  onReportSelect: (report: SurveyConfiguration) => void;
}) => {
  const [filterText, setFilterText] = useState("");

  const handleFilterChange = (event) => {
    setFilterText(event.target.value.toLowerCase());
  };

  const filteredReports = reports.filter((report) =>
    report.name.toLowerCase().includes(filterText),
  );

  return (
    <Box>
      <TextField
        variant="outlined"
        label="Filter Reports"
        fullWidth
        margin="dense"
        value={filterText}
        onChange={handleFilterChange}
        style={{ marginBottom: "8px" }}
      />
      <Grid container spacing={1}>
        {filteredReports.map((report) => (
          <Grid item xs={12} sm={6} md={4} key={report.name}>
            <Button
              variant={
                selectedReport?.name === report.name ? "contained" : "outlined"
              }
              color="primary"
              fullWidth
              onClick={() => onReportSelect(report)}
              startIcon={
                <img
                  src={report.description.image_url}
                  alt={report.name}
                  style={{ width: 24, height: 24 }}
                />
              }
              sx={{
                justifyContent: "flex-start",
                height: "100%",
                padding: 1,
                border: selectedReport?.name === report.name ? 2 : 1,
                borderColor:
                  selectedReport?.name === report.name
                    ? "primary.main"
                    : "divider",
                backgroundColor:
                  selectedReport?.name === report.name
                    ? "primary.light"
                    : "background.paper",
                color:
                  selectedReport?.name === report.name
                    ? "primary.contrastText"
                    : "text.primary",
                "&:hover": {
                  backgroundColor:
                    selectedReport?.name === report.name
                      ? "primary.main"
                      : "action.hover",
                },
              }}
            >
              {report.name}
            </Button>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default ReportFilterButtons;
