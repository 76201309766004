import ReactPlayer from "react-player";

import { APP_SERVER_URL } from "../../../environment";
import styles from "../report.module.css";

export const AttachmentPopup = (props) => {
  const { attachments } = props;
  return (
    <div className={styles.attachmentContainer}>
      <div className={styles.attachmentTitle}>Attachments</div>
      {attachments &&
        attachments.map((item, index) => {
          const url = new URL(`${APP_SERVER_URL}${item.download_url}`);
          const mime = item.mime;
          if (mime.split("/")[0] === "image") {
            return (
              <div key={index}>
                <img
                  src={url.toString()}
                  className={styles.attachmentImage}
                  alt="Report Attachment"
                />
                <div className={styles.attachmentName}>{item.filename}</div>
                <a
                  target="_blank"
                  download
                  type="application/octet-stream"
                  rel="noopener noreferrer"
                  href={url.toString()}
                >
                  Download
                </a>
              </div>
            );
          } else if (mime.split("/")[0] === "video") {
            return (
              <div>
                <ReactPlayer
                  url={url.toString()}
                  controls={true}
                  light={false}
                />
                <div className={styles.attachmentName}>{item.filename}</div>
                <a
                  target="_blank"
                  download
                  type="application/octet-stream"
                  rel="noopener noreferrer"
                  href={url.toString()}
                >
                  Download
                </a>
              </div>
            );
          } else {
            return <div></div>;
          }
        })}
    </div>
  );
};
