import {
  GetSubmissionResponsePortal,
  SubmissionPortal,
} from "../../../common/api-client/or-api";
import { formatDateShort } from "../../../common/utils";

export const isReportClosed = (
  reportMetadata: GetSubmissionResponsePortal | SubmissionPortal,
) => {
  return (
    reportMetadata.status === "approved" ||
    reportMetadata.status === "rejected" ||
    reportMetadata.status === "escalated"
  );
};

export const getReportTitle = (
  isSupplementaryReport: boolean,
  title: string,
): string => {
  if (isSupplementaryReport) {
    return "Supplementary Report";
  } else {
    if (title === "Supplementary Report") {
      return "General Report (Submitted as Supplementary)";
    }
  }
  return title;
};

export const getReportTimeDisplay = (
  timeCreated: string,
  timeUpdated?: string,
) => {
  if (!timeUpdated) {
    return `Time Created: ${formatDateShort(timeCreated)}`;
  } else if (timeCreated === timeUpdated) {
    return `Time Created: ${formatDateShort(timeCreated)}`;
  } else {
    const diffInMinutes =
      Math.abs(
        new Date(timeCreated).getTime() - new Date(timeUpdated).getTime(),
      ) /
      (1000 * 60);
    if (diffInMinutes < 5) {
      return `Time Created: ${formatDateShort(timeCreated)}`;
    } else {
      return `Time Updated: ${formatDateShort(timeUpdated)}`;
    }
  }
};
