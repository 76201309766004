import styles from "../report.module.css";

export const ReportField = (props) => {
  const { value, label, source, onChange, className } = props;

  return (
    <div className={`ra-field report-field-row ${className}`}>
      <div className="MuiFormControl-root MuiFormControl-marginDense">
        <label
          className="MuiFormLabel-root MuiInputLabel-root RaLabeled-label MuiInputLabel-formControl MuiInputLabel-animated MuiInputLabel-shrink MuiInputLabel-marginDense"
          data-shrink="true"
        >
          <span>{label}</span>
        </label>
        <div className={styles.fieldValue + " RaLabeled-value"}>
          {onChange && (
            <input
              style={{ width: "320px" }}
              type="text"
              value={value}
              onChange={(e) => {
                onChange(e.target.value, source);
              }}
            />
          )}
          {!onChange && (
            <span className="MuiTypography-root report-field-row MuiTypography-body2">
              {value}
            </span>
          )}
        </div>
      </div>
    </div>
  );
};
