import {
  CheckCircle,
  ContentCopy,
  Delete,
  Edit,
  Error,
  LocalOffer,
} from "@mui/icons-material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  Autocomplete,
  Box,
  IconButton,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import match from "autosuggest-highlight/match";
import parse from "autosuggest-highlight/parse";
import { useContext, useEffect, useRef, useState } from "react";
//Internal Imports
import { toast } from "react-toastify";

import styles from "./MediaComponent.module.css";
import MediaDisplay from "../../../../common/MediaDisplay";
import { apiAlertClient } from "../../../../common/api-client";
import { ITag } from "../../../../types";
import { SingleAlertContext } from "../../context";

interface MediaComponentProps {
  handleFileDelete: any;
  bulletinID: number;
  evidence: any;
  setEditMediaObject: (any) => void;
  disableEdit?: boolean;
  disableTags?: boolean;
  disableOpenAiDescription?: boolean;
}

const MediaComponent = ({
  handleFileDelete,
  bulletinID,
  evidence,
  setEditMediaObject,
  disableEdit = false,
  disableTags = false,
  disableOpenAiDescription = false,
}: MediaComponentProps) => {
  const {
    openai_description,
    filename,
    content_type,
    download_url,
    thumbnail_url,
    ...rest
  } = evidence;
  const { alertProperties, setAlertProperty } = useContext(SingleAlertContext);
  const { tagDict } = alertProperties;

  const updateTagDictInContext = (tagDict: any) => {
    setAlertProperty("tagDict", tagDict);
  };

  const [addTags, setAddTags] = useState(
    Object.keys(tagDict).length ? true : false,
  );
  const [maxWidth, setMaxWidth] = useState<number | null>(null);
  const divRef = useRef<HTMLElement>();

  const url = content_type.includes("video")
    ? new URL(download_url)
    : new URL(thumbnail_url);

  const mediaType = content_type.includes("video") ? "video" : "image";

  const mediaUrl = url.toString();

  const handleEditClick = () => setEditMediaObject(evidence); // Check this

  const handleAddTagClick = () => setAddTags((prevValue) => !prevValue);

  const handleMaxWidth = () => {
    if (divRef?.current?.offsetWidth) {
      setMaxWidth(divRef?.current?.offsetWidth);
    }
  };

  useEffect(() => {
    handleMaxWidth();
  }, []);

  const handleBulletinTags = (tags: ITag[]) => {
    //TODO this all is very inneficient, better way is to also trach a mapping
    //between attachments and corresponding tag ids

    const updatedTagDict = { ...tagDict };

    // Check if new tag was added
    for (const imageTag of tags) {
      const hasAttachment = updatedTagDict[imageTag.id].attachments.findIndex(
        (_fileName) => _fileName === filename,
      );
      if (hasAttachment === -1) {
        const updatedTag = { ...tagDict[imageTag.id] };
        updatedTag.attachments.push(filename);

        apiAlertClient
          .patchTag(bulletinID, imageTag.id, updatedTag)
          .then((res) => {
            updatedTagDict[imageTag.id] = res;
            updateTagDictInContext(updatedTagDict);
          });
        return;
      }
    }

    //Check if a tag was deleted
    for (const bulletinTag of Object.values(tagDict)) {
      //Find all tags that have this image name in attachements
      const hasAttachment = bulletinTag.attachments.findIndex(
        (_fileName) => _fileName === filename,
      );
      if (hasAttachment > -1) {
        // Check wheather that tag is still in the image tags
        const hasTag = tags.findIndex(
          (imageTag) => imageTag.id === bulletinTag.id,
        );
        if (hasTag === -1) {
          // If tag is not found in image tags, remove it from tag's attachment
          const updatedTag = { ...tagDict[bulletinTag.id] };
          updatedTag.attachments = updatedTag.attachments.filter(
            (_fileName) => _fileName !== filename,
          );

          apiAlertClient
            .patchTag(bulletinID, bulletinTag.id, updatedTag)
            .then((res) => {
              updatedTagDict[bulletinTag.id] = res;
              updateTagDictInContext(updatedTagDict);
            });
          return;
        }
      }
    }
  };

  const OPEN_AI_IMAGE_MESSAGE_SUCCESS =
    "These tags are generated based on the image to assist with search. These are hidden to the end user.";
  const OPEN_AI_IMAGE_MESSAGE_WARNING =
    "Tags were not generated for this image. Please add image tag descriptions manually.\nReasons for this could be: Image quality, Image content, or Image size, etc.";

  const hasOpenaiDescription = openai_description && openai_description["data"];

  const copyContents = (openai_description: any) => {
    let content = openai_description["data"];
    if (openai_description["type"] === "json") {
      content = Object.keys(openai_description["data"])
        .map(
          (tag) =>
            `${tag.toUpperCase()}: ${openai_description["data"]![tag].join(", ")}`,
        )
        .join(" -- ");
    }
    navigator.clipboard.writeText(JSON.stringify(content));
    toast.success("Copied to clipboard");
  };

  return (
    <div>
      <Box ref={divRef} style={{ height: "300px", position: "relative" }}>
        <MediaDisplay
          mediaType={mediaType}
          src={mediaUrl}
          onLoad={() => handleMaxWidth()}
          style={{
            marginLeft: "auto",
            marginRight: "auto",
            display: "block",
            paddingBottom: mediaType === "video" ? "0px" : "16px",
            maxWidth: "100%",
            maxHeight: "100%",
            objectFit: "contain",
            cursor: "pointer",
          }}
        />

        <Box
          sx={{
            position: "absolute",
            top: 0,
            right: 0,
            // backgroundColor: "rgba(100,0,0,0.5)",
            // color: "rgba(0,0,0,0.9)",
            display: "flex",
            flexDirection: "column",
            gap: "4px",
            padding: "4px",
          }}
        >
          <Tooltip title="Delete" placement="left">
            <IconButton
              onClick={handleFileDelete}
              sx={{ backgroundColor: "rgba(255,255,255,0.5)" }}
              disableRipple
              size="small"
            >
              <Delete color="secondary" />
            </IconButton>
          </Tooltip>
          {mediaType === "video" ? null : disableEdit ? null : (
            <Tooltip title="Edit" placement="left">
              <IconButton
                onClick={handleEditClick}
                sx={{ backgroundColor: "rgba(255,255,255,0.5)" }}
                disableRipple
                size="small"
              >
                <Edit color="secondary" />
              </IconButton>
            </Tooltip>
          )}
          {disableTags ? null : (
            <Tooltip title="Tag" placement="left">
              <IconButton
                onClick={handleAddTagClick}
                sx={{ backgroundColor: "rgba(255,255,255,0.5)" }}
                disableRipple
                size="small"
              >
                <LocalOffer color="secondary" />
              </IconButton>
            </Tooltip>
          )}
        </Box>
      </Box>
      {maxWidth && !disableTags && (
        <Box>
          <Autocomplete
            id="select-bulltin-type"
            options={Object.values(tagDict)}
            getOptionLabel={(tag: ITag) => tag.title}
            value={Object.values(tagDict).filter((tag) =>
              tag.attachments.find((attachment) => attachment === filename),
            )}
            autoComplete
            autoHighlight
            filterSelectedOptions
            clearOnBlur
            fullWidth
            openOnFocus
            multiple
            isOptionEqualToValue={(option, value) => option.id === value.id}
            noOptionsText="Add a Tag below first"
            style={{
              marginTop: mediaType === "video" ? "0px" : "-16px",
            }}
            size="small"
            onChange={(_, newValue) => {
              handleBulletinTags(newValue);
            }}
            renderInput={(params) => (
              <TextField {...params} label="Tags" variant="outlined" />
            )}
            renderOption={(props, option, { inputValue }) => {
              const matches = match(option.title, inputValue, {
                insideWords: true,
              });
              const parts = parse(option.title, matches);
              return (
                <li {...props}>
                  <div>
                    {parts.map((part, index) => (
                      <span
                        key={index}
                        style={{
                          fontWeight: part.highlight ? 700 : 400,
                        }}
                      >
                        {part.text}
                      </span>
                    ))}
                  </div>
                </li>
              );
            }}
          />
        </Box>
      )}
      {!disableOpenAiDescription &&
        maxWidth &&
        openai_description &&
        mediaType === "image" && (
          <Accordion sx={{ maxWidth, minWidth: "300px" }}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
            >
              <Typography>Generated Image Description </Typography>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginLeft: "auto",
                  transform: "scale(0.8)",
                }}
              >
                {hasOpenaiDescription ? (
                  <>
                    <IconButton
                      onClick={(e) => {
                        copyContents(openai_description);
                        e.stopPropagation();
                      }}
                      size="small"
                    >
                      <ContentCopy />
                    </IconButton>
                    <CheckCircle color="success" />
                  </>
                ) : (
                  <Error color="error" />
                )}
              </div>
            </AccordionSummary>
            <AccordionDetails>
              {hasOpenaiDescription ? (
                <Alert severity="info">{OPEN_AI_IMAGE_MESSAGE_SUCCESS}</Alert>
              ) : (
                <Alert severity="warning">
                  {OPEN_AI_IMAGE_MESSAGE_WARNING}
                </Alert>
              )}
              {openai_description["type"] === "json" ? (
                Object.keys(openai_description["data"]).map((tag) => (
                  <div className={styles.generatedImageTag} key={tag}>
                    <Typography>
                      <b>{tag.toUpperCase()}</b>
                    </Typography>
                    <Typography>
                      {openai_description["data"]![tag].join(", ")}
                    </Typography>
                  </div>
                ))
              ) : (
                <Typography>{openai_description["data"]}</Typography>
              )}
            </AccordionDetails>
          </Accordion>
        )}
    </div>
  );
};

export default MediaComponent;
