interface ILinkCellProps {
  value: string;
  onClick: () => void;
}
export const LinkCell = ({ value, onClick }: ILinkCellProps) => {
  return (
    <div style={{ cursor: "pointer", color: "#1976d2" }} onClick={onClick}>
      {value}
    </div>
  );
};
