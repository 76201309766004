import { useState } from "react";

import { AddressField } from "../fields/AddressField";
import { ReportDateField } from "../fields/ReportDateField";
import { ReportField } from "../fields/ReportField";
import { ShowHideButton } from "../fields/UI/ShowHideButton";
import styles from "../report.module.css";

export const DamageToVehicle = (props) => {
  const { data } = props;
  const [show, setShow] = useState(false);

  return (
    <>
      {data.info && data.info.damageToVehicle && (
        <div className="theftfromVehicle">
          <ReportDateField
            showtime={true}
            className="report-field-row"
            value={data.info.damageToVehicle.startTime}
            label="Start Date/Time of Incident"
          />
          <ReportDateField
            showtime={true}
            className="report-field-row"
            value={data.info.damageToVehicle.endTime}
            label="End Date/Time of Incident"
          />
          <ReportField
            className="report-field-row"
            value={data.info.damageToVehicle.locationType}
            label="Location Type"
          />
          <ReportField
            className="report-field-row"
            value={data.info.damageToVehicle.graffitiInvolved}
            label="Graffiti Involved"
          />
          <AddressField
            label="Incident Location"
            address={data.info.damageToVehicle.streetAddress}
            onEditAddress={() => {
              props.onEditAddress(
                data.info.damageToVehicle.streetAddress,
                "info.damageToVehicle.streetAddress",
              );
            }}
          />
          {data.info.damageToVehicle.narrative && (
            <div className={styles.subHeading}>Narrative</div>
          )}
          {data.info.damageToVehicle.narrative || props.clickState ? (
            <ReportField
              className={styles.narrativeField + " report-field-row"}
              value={data.info.damageToVehicle.narrative}
              label="Incident Description"
            />
          ) : null}

          {data.info.damageToVehicle.vehicle && (
            <div className={styles.subHeading}>
              Vehicle Information
              <ShowHideButton
                onClick={() => {
                  setShow(!show);
                }}
              >
                {" "}
                {show ? "Hide " : "Show "} empty fields{" "}
              </ShowHideButton>
            </div>
          )}
          {data.info.damageToVehicle && data.info.damageToVehicle.vehicle && (
            <>
              <ReportField
                className="report-field-row"
                value={data.info.damageToVehicle.vehicle.involvementType}
                label="Type"
              />
              <ReportField
                className="report-field-row"
                value={data.info.damageToVehicle.vehicle.maker}
                label="Make"
              />
              <ReportField
                className="report-field-row"
                value={data.info.damageToVehicle.vehicle.model}
                label="Model"
              />
              {data.info.damageToVehicle.vehicle.vIN || show ? (
                <ReportField
                  className="report-field-row"
                  value={data.info.damageToVehicle.vehicle.vIN}
                  label="VIN"
                />
              ) : null}
              <ReportField
                className="report-field-row"
                value={data.info.damageToVehicle.vehicle.style}
                label="Style"
              />
              <ReportField
                className="report-field-row"
                value={data.info.damageToVehicle.vehicle.year}
                label="Year"
              />
              <ReportField
                className="report-field-row"
                value={data.info.damageToVehicle.vehicle.colours}
                label="Colour"
              />
              {data.info.damageToVehicle.vehicle.marketValue || show ? (
                <ReportField
                  className="report-field-row"
                  value={data.info.damageToVehicle.vehicle.marketValue}
                  label="Market Value"
                />
              ) : null}
              {data.info.damageToVehicle.vehicle.damagedValue || show ? (
                <ReportField
                  className="report-field-row"
                  value={data.info.damageToVehicle.vehicle.damagedValue}
                  label="Damaged Value"
                />
              ) : null}
              {data.info.damageToVehicle.vehicle.licenseType || show ? (
                <ReportField
                  className="report-field-row"
                  value={data.info.damageToVehicle.vehicle.licenseType}
                  label="License Type"
                />
              ) : null}
              {data.info.damageToVehicle.vehicle.licensePlateNo || show ? (
                <ReportField
                  className="report-field-row"
                  value={data.info.damageToVehicle.vehicle.licensePlateNo}
                  label="License Plate No"
                />
              ) : null}
              {data.info.damageToVehicle.vehicle.licenseLocation || show ? (
                <ReportField
                  className="report-field-row"
                  value={data.info.damageToVehicle.vehicle.licenseLocation}
                  label="License Province"
                />
              ) : null}
            </>
          )}
        </div>
      )}
    </>
  );
};
