import {
  ArrowBack,
  PersonAdd,
  PersonOff,
  PersonRemove,
} from "@mui/icons-material";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Link,
  ListItemText,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import {
  memo,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { useNavigate, useParams } from "react-router";
import { toast } from "react-toastify";

import { RubiModal } from "../../../common/RubiModal";
import { apiAlertClient } from "../../../common/api-client";
import { mailTo } from "../../../common/utils";
import { AuthStateContext } from "../../../context";
import { IOrgUser, IOrgUserAlertUserState } from "../../../types";

import "./GroupManagement.scss";

const useDynamicColumnWidths = (topPageRef) => {
  const calculateColumnWidths = useCallback((parentWidth) => {
    const baseWidth = parentWidth ?? window.innerWidth * 0.85;
    const ratios = {
      first_name: 2,
      last_name: 2,
      email: 3,
      badge_number: 1,
      rank_short: 1,
      actions: 1,
    };
    const totalRatios = Object.values(ratios).reduce(
      (acc, val) => acc + val,
      0,
    );

    return Object.fromEntries(
      Object.entries(ratios).map(([key, value]) => [
        key,
        (baseWidth / totalRatios) * value,
      ]),
    );
  }, []);

  const [columnWidths, setColumnWidths] = useState(() =>
    calculateColumnWidths(topPageRef.current?.parentElement?.offsetWidth),
  );

  useEffect(() => {
    const handleResize = () => {
      const parentWidth = topPageRef.current?.parentElement?.offsetWidth;
      setColumnWidths(calculateColumnWidths(parentWidth));
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [topPageRef]);

  return columnWidths;
};

const AlertGroupUsersList = () => {
  const navigate = useNavigate();
  const { groupId } = useParams();
  const { currentUserGroups, currentUser } = useContext(AuthStateContext);

  const [users, setUsers] = useState<IOrgUser[]>([]);
  const [nonMembers, setNonMembers] = useState<IOrgUserAlertUserState[]>([]);
  const [loading, setLoading] = useState(true);
  const [selectedUser, setSelectedUser] =
    useState<IOrgUserAlertUserState | null>(null);
  const [isAddUserDialogOpen, setIsAddUserDialogOpen] = useState(false);
  const [isConfirmAddUserDialogOpen, setIsConfirmAddUserDialogOpen] =
    useState(false);
  const [isConfirmRemoveUserDialogOpen, setIsConfirmRemoveUserDialogOpen] =
    useState(false);
  const [addLoading, setAddLoading] = useState(false);

  const topPageRef = useRef<HTMLInputElement | null>(null);
  const columnWidths = useDynamicColumnWidths(topPageRef);

  // Fetch group members and non-members
  useEffect(() => {
    const fetchMembers = async () => {
      try {
        const [members, nonMembersResponse] = await Promise.all([
          apiAlertClient.getGroupMembers(groupId),
          currentUserGroups
            .find((group) => group.id === Number(groupId))
            ?.role?.includes("admin" || "owner")
            ? apiAlertClient.getNonMembers(groupId)
            : [],
        ]);
        setUsers(members);
        setNonMembers(nonMembersResponse);
      } catch (error) {
        console.error("Error fetching users", error);
        toast.error("Error fetching user data");
      } finally {
        setLoading(false);
      }
    };

    fetchMembers();
  }, [groupId]);

  const userClickHandler = (user) => {
    navigate(`/organizations/${groupId}/users/${user.id}`, {
      replace: true,
      state: { orgId: groupId },
    });
  };

  const handleUserSelect = (event) => {
    const userId = event.target.value;
    const user = nonMembers.find((nonMember) => nonMember.id === userId);
    setSelectedUser(user || null);
  };

  const handleAddUserToGroup = async () => {
    if (!selectedUser) return;
    setAddLoading(true);

    try {
      await apiAlertClient.addUserToGroup(groupId, selectedUser.id);
      toast.success("User added to group");
      const updatedMembers = await apiAlertClient.getGroupMembers(groupId);
      setUsers(updatedMembers);
      setIsAddUserDialogOpen(false);
      setSelectedUser(null);
    } catch (error) {
      console.error("Error adding user", error);
      toast.error("Error adding user to group");
    } finally {
      setAddLoading(false);
    }
  };

  const handleRemoveUserFromGroup = async (userId) => {
    try {
      await apiAlertClient.removeUserFromGroup(groupId, userId);
      toast.success("User removed from group");
      const updatedMembers = await apiAlertClient.getGroupMembers(groupId);
      setUsers(updatedMembers);
    } catch (error) {
      console.error("Error removing user", error);
      toast.error("Error removing user from group");
    }
  };

  const columns: {
    field: string;
    headerName: string;
    width: number;
    renderCell?: (params: any) => JSX.Element;
  }[] = [
    {
      field: "first_name",
      headerName: "First Name",
      width: columnWidths.first_name,
    },
    {
      field: "last_name",
      headerName: "Last Name",
      width: columnWidths.last_name,
    },
    { field: "email", headerName: "Email", width: columnWidths.email },
    {
      field: "badge",
      headerName: "Badge",
      width: columnWidths.badge_number,
    },
    { field: "rank", headerName: "Rank", width: columnWidths.rank_short },
  ];

  if (
    currentUserGroups
      .find((group) => group.id === Number(groupId))
      ?.role?.includes("admin" || "owner")
  ) {
    columns.push({
      field: "actions",
      headerName: "Actions",
      width: columnWidths.actions,
      renderCell: (params) => (
        <Button
          color="primary"
          startIcon={<PersonRemove />}
          onClick={() => {
            setSelectedUser(params.row);
            setIsConfirmRemoveUserDialogOpen(true);
          }}
        />
      ),
    });
  }

  const requestAccessEmail = () => {
    const selectedUserName =
      `${selectedUser?.first_name || ""} ${selectedUser?.last_name || ""}`.trim();
    const groupName =
      currentUserGroups.find((group) => group.id === Number(groupId))?.name ||
      "the specified group";
    const currentUserName =
      `${currentUser?.first_name || ""} ${currentUser?.last_name || ""}`.trim();

    const body = `
      Hello Rubicon Support Team,
  
      I would like to request access to RubiALERT for ${selectedUserName}.
  
      Here are some details about the user:
      Name: ${selectedUserName}
      Email: ${selectedUser?.email || "Not provided"}
  
      I would like to add them to the group: ${groupName}.
  
      Please let me know if you need any additional information.
  
      Thank you,
      ${currentUserName}
    `.trim();

    return mailTo({
      email: "support@tryrubicon.com",
      subject: `RubiALERT access request for ${selectedUserName}`,
      body: body,
    });
  };

  return (
    <div className="users_list_container">
      <div id="top-page-div" ref={topPageRef}></div>

      <Grid container alignItems="center" justifyContent="center">
        <Grid item xs={4}>
          <Button
            variant="contained"
            color="primary"
            startIcon={<ArrowBack />}
            onClick={() =>
              navigate("/rubialert/manage-groups", { replace: true })
            }
          >
            Back
          </Button>
        </Grid>

        <Grid item xs={4} style={{ textAlign: "center" }}>
          <Typography variant="h4" gutterBottom>
            Members of{" "}
            {
              currentUserGroups.find((group) => group.id === Number(groupId))
                ?.name
            }
          </Typography>
        </Grid>

        <Grid
          item
          xs={4}
          style={{
            textAlign: "right",
          }}
        >
          {currentUserGroups
            .find((group) => group.id === Number(groupId))
            ?.role?.includes("admin" || "owner") && (
            <Button
              variant="contained"
              color="secondary"
              startIcon={<PersonAdd />}
              onClick={() => setIsAddUserDialogOpen(true)}
            >
              Add User
            </Button>
          )}
        </Grid>
      </Grid>

      <DataGrid
        rows={users.filter((user) => user.id !== currentUser?.id)}
        columns={columns}
        rowSelection={false}
        sx={{
          "& .MuiDataGrid-row:not(.MuiDataGrid-row--dynamicHeight)>.MuiDataGrid-cell":
            {
              whiteSpace: "normal",
              wordWrap: "break-word",
            },
        }}
      />

      <Dialog
        open={isAddUserDialogOpen}
        onClose={() => {
          setIsAddUserDialogOpen(false);
          setSelectedUser(null);
        }}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>Add User to Group</DialogTitle>
        <DialogContent>
          {loading ? (
            <CircularProgress />
          ) : (
            <Box mt={2}>
              <Select
                value={selectedUser?.id || ""}
                onChange={handleUserSelect}
                fullWidth
                variant="outlined"
                disabled={addLoading}
                displayEmpty
                sx={{
                  borderRadius: 2,
                  borderColor: "grey.300",
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderWidth: 2,
                  },
                  "&:hover .MuiOutlinedInput-notchedOutline": {
                    borderColor: "primary.main",
                  },
                }}
                renderValue={(selected) => {
                  if (!selected) return <em>Select a user to add</em>;
                  const selectedUserObj = nonMembers.find(
                    (user) => user.id === selected,
                  );
                  return selectedUserObj ? (
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                    >
                      <ListItemText
                        primary={`${selectedUserObj.first_name} ${selectedUserObj.last_name}`}
                        secondary={
                          <Typography variant="body2" color="textSecondary">
                            {selectedUserObj.email}
                          </Typography>
                        }
                        sx={{
                          "& .MuiListItemText-primary": {
                            fontWeight: 500,
                            color: "text.primary",
                          },
                        }}
                      />
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        {selectedUserObj.is_alert_user === false ? (
                          <>
                            <PersonOff sx={{ color: "orange", mr: 1 }} />
                            <Typography variant="body2" color="textSecondary">
                              Not an Alert User
                            </Typography>
                          </>
                        ) : (
                          <></>
                        )}
                      </Box>
                    </Box>
                  ) : (
                    <em>Select a user to add</em>
                  );
                }}
              >
                {nonMembers.map((user) => (
                  <MenuItem
                    key={user.id}
                    value={user.id}
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      borderBottom: "1px solid",
                      borderColor: "grey.200",
                      py: 1.5,
                      "&:hover": {
                        backgroundColor: "grey.100",
                      },
                    }}
                  >
                    <ListItemText
                      primary={`${user.first_name} ${user.last_name}`}
                      secondary={
                        <Typography variant="body2" color="textSecondary">
                          {user.email}
                        </Typography>
                      }
                      sx={{
                        "& .MuiListItemText-primary": {
                          fontWeight: 500,
                          color: "text.primary",
                        },
                      }}
                    />
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      {user.is_alert_user === false ? (
                        <>
                          <PersonOff sx={{ color: "orange", mr: 1 }} />
                          <Typography variant="body2" color="textSecondary">
                            Not an Alert User
                          </Typography>
                        </>
                      ) : (
                        <></>
                      )}
                    </Box>
                  </MenuItem>
                ))}
              </Select>

              {selectedUser?.is_alert_user === false && (
                <Grid container mt={2}>
                  <Typography color="" variant="body2">
                    User does not have access to RubiALERT. Please contact admin
                    to grant them access:{" "}
                    <Link href={requestAccessEmail()}>
                      support@tryrubicon.com
                    </Link>
                  </Typography>
                </Grid>
              )}
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setIsAddUserDialogOpen(false)}
            color="secondary"
            disabled={addLoading}
          >
            Cancel
          </Button>
          <Button
            onClick={() => setIsConfirmAddUserDialogOpen(true)}
            color="primary"
            variant="contained"
            disabled={
              !selectedUser ||
              addLoading ||
              selectedUser?.is_alert_user === false
            }
          >
            {addLoading ? <CircularProgress size={24} /> : "Add"}
          </Button>
        </DialogActions>
      </Dialog>

      <RubiModal
        isModalOpenArg={isConfirmAddUserDialogOpen}
        setIsModalOpenArg={setIsConfirmAddUserDialogOpen}
      >
        <DialogTitle>Add User to Group</DialogTitle>
        <DialogContent>
          <Typography>
            Are you sure you want to add {selectedUser?.first_name}{" "}
            {selectedUser?.last_name} to the group?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setIsConfirmAddUserDialogOpen(false)}
            color="secondary"
          >
            Cancel
          </Button>
          <Button
            onClick={() => {
              handleAddUserToGroup();
              setIsConfirmAddUserDialogOpen(false);
            }}
            color="primary"
            variant="contained"
          >
            Add
          </Button>
        </DialogActions>
      </RubiModal>

      <RubiModal
        isModalOpenArg={isConfirmRemoveUserDialogOpen}
        setIsModalOpenArg={setIsConfirmRemoveUserDialogOpen}
      >
        <DialogTitle>Remove User from Group</DialogTitle>
        <DialogContent>
          <Typography>
            Are you sure you want to remove {selectedUser?.first_name}{" "}
            {selectedUser?.last_name} from the group?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setIsConfirmRemoveUserDialogOpen(false)}
            color="secondary"
          >
            Cancel
          </Button>
          <Button
            onClick={() => {
              handleRemoveUserFromGroup(selectedUser ? selectedUser.id : "");
              setIsConfirmRemoveUserDialogOpen(false);
            }}
            color="primary"
            variant="contained"
          >
            Remove
          </Button>
        </DialogActions>
      </RubiModal>
    </div>
  );
};

export default memo(AlertGroupUsersList);
