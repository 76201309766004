import { toast } from "react-toastify";

type Options = {
  [key: string]: any;
  method?: string;
};

const extractStatusBody = (response) =>
  response.json().then((rJson) => ({
    status: response.status,
    body: rJson,
  }));

const statusTriaging = ({ status, body }) => {
  if (status >= 400 && status <= 599) {
    if (status === 401) {
      console.log("In STATUS TRIAGING 401");
      if (body?.detail?.msg) {
        toast.error(JSON.stringify(body.detail.msg));
      }
      // if (!window.location.pathname.includes("/login")) {
      //   toast.error("Your session has expired. \n\nRedirecting to login...");
      //   setTimeout(() => {
      //     window.location.href = "/login";
      //   }, 3000);
      // }
      return Promise.reject("Unauthorized");
    }
    if (body?.detail?.msg) {
      toast.error(JSON.stringify(body.detail.msg));
    } else if (body?.detail) {
      toast.error(JSON.stringify(body.detail));
    } else {
      toast.error(
        "Error " +
          status +
          ". Please try again. If you need support contact support@tryrubicon.com.",
      );
    }
    console.log("In STATUS TRIAGING 400-599");
    return Promise.reject(body);
    // throw Error(body);
  }

  return body;
};

// Set header, conver body to string before fetching
const setOptions = (options: Options) => {
  if (!options.headers) {
    options.headers = new Headers();
  }

  if (
    typeof options?.body != "string" &&
    !(options?.body instanceof FormData)
  ) {
    options.body = JSON.stringify(options.body);
    options.headers.append("Content-Type", "application/json");
  }

  return options;
};

export const post = (url, options: Options = {}) => {
  options.method = "POST";
  options = setOptions(options);

  return fetch(url, options).then(extractStatusBody).then(statusTriaging);
};

export const patch = (url, options: Options = {}) => {
  options.method = "PATCH";
  options = setOptions(options);
  return fetch(url, options).then(extractStatusBody).then(statusTriaging);
};

export const get = (url, options: Options = {}) => {
  options.method = "GET";
  return fetch(url, options).then(extractStatusBody).then(statusTriaging);
};

export const put = (url, options: Options = {}) => {
  options.method = "PUT";
  options = setOptions(options);
  return fetch(url, options).then(extractStatusBody).then(statusTriaging);
};

export const del = (url, options: Options = {}) => {
  options.method = "DELETE";
  options = setOptions(options);
  return fetch(url, options).then(extractStatusBody).then(statusTriaging);
};
