/* eslint-disable react/display-name */
import Divider from "@mui/material/Divider";
import React, { FC, useContext, useEffect, useState } from "react";
import { Layout, Sidebar, useGetIdentity, useTheme } from "react-admin";
import { useLocation } from "react-router-dom";

import AppBar from "./AppBar";
import AppsSidebar from "./menu/AppsSideBar";
import RubiBoloMenu from "./menu/RubiBoloMenu";
import RubiCanvasMenu from "./menu/RubiCanvasMenu";
import RubiIntelMenu from "./menu/RubiIntelMenu";
import RubiNoMenu from "./menu/RubiNoMenu";
import RubiParadeMenu from "./menu/RubiParadeMenu";
import RubiReportMenu from "./menu/RubiReportMenu";
import RubiReportV2Menu from "./menu/RubiReportV2Menu";
import UsersAdminMenu from "./menu/UsersAdminMenu";
import { Copyright } from "./menu/copyright";
import {
  APP_REPORT,
  APP_REPORT_V2,
  APP_BOLO_ALERT,
  APP_USERS_ADMIN,
  APP_EPARADE,
  APP_CANVAS,
  NO_APP,
  APP_INTEL,
} from "../common/constants";
import { AuthStateContext } from "../context";

export default (props: any) => {
  const [theme] = useTheme();
  const location = useLocation();
  const alertPathNames: string[] = ["rubialert"];
  const usersAdminPathNames: string[] = ["organizations"];
  const paradePathNames: string[] = ["rubiparade"];
  const reportPathNames: string[] = ["rubireport"];
  const reportV2PathNames: string[] = ["rubireportv2"];
  const intelPathNames: string[] = ["rubiintel"];
  const canvasPathNames: string[] = ["rubicanvas"];

  const [Menu, setMenu] = useState<FC<any>>(() => RubiReportMenu);
  const [selectedApp, setSelectedApp] = useState<string>(APP_REPORT);
  const { refetch } = useGetIdentity();

  const { currentUser } = useContext(AuthStateContext);

  useEffect(() => {
    refetch && refetch();
  }, [currentUser]);

  //Match URL Pathname to pick the right app to display
  useEffect(() => {
    const topLevelPath = location.pathname.split("/")[1];

    if (alertPathNames.includes(topLevelPath)) {
      setSelectedApp(APP_BOLO_ALERT);
    } else if (usersAdminPathNames.includes(topLevelPath)) {
      setSelectedApp(APP_USERS_ADMIN);
    } else if (paradePathNames.includes(topLevelPath)) {
      setSelectedApp(APP_EPARADE);
    } else if (reportPathNames.includes(topLevelPath)) {
      setSelectedApp(APP_REPORT);
    } else if (reportV2PathNames.includes(topLevelPath)) {
      setSelectedApp(APP_REPORT_V2);
    } else if (intelPathNames.includes(topLevelPath)) {
      setSelectedApp(APP_INTEL);
    } else if (canvasPathNames.includes(topLevelPath)) {
      setSelectedApp(APP_CANVAS);
    } else setSelectedApp(NO_APP);
  }, [location]);

  //If Different App is picked, change menu
  useEffect(() => {
    if (selectedApp === APP_BOLO_ALERT) {
      setMenu(() => RubiBoloMenu);
    } else if (selectedApp === APP_REPORT) {
      setMenu(() => RubiReportMenu);
    } else if (selectedApp === APP_REPORT_V2) {
      setMenu(() => RubiReportV2Menu);
    } else if (selectedApp === APP_INTEL) {
      setMenu(() => RubiIntelMenu);
    } else if (selectedApp === APP_USERS_ADMIN) {
      setMenu(() => UsersAdminMenu);
    } else if (selectedApp === APP_EPARADE) {
      setMenu(() => RubiParadeMenu);
    } else if (selectedApp === APP_CANVAS) {
      setMenu(() => RubiCanvasMenu);
    } else setMenu(() => RubiNoMenu);
  }, [selectedApp]);

  const CustomSideMenu = (props: any) => {
    return (
      <>
        <div className="app-and-menu">
          <AppsSidebar
            selectedApp={selectedApp}
            setSelectedApp={setSelectedApp}
          />
          <Menu />
        </div>
        <Divider />
        <Copyright />
      </>
    );
  };

  const CustomSidebar = (props: any) => {
    return (
      <>
        {/* BLACK MAGIC - Need the div bellow so that it doesn't scroll */}
        <div></div>
        <Sidebar {...props} />
      </>
    );
  };

  return (
    <Layout
      className="mylayout"
      {...props}
      sidebar={CustomSidebar}
      appBar={AppBar}
      menu={CustomSideMenu}
      theme={theme}
    />
  );
};
