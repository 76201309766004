import {
  ArrowBack,
  LibraryAddCheckRounded,
  Assignment,
  Collections,
} from "@mui/icons-material";
import { Drawer, Paper } from "@mui/material";
import { format } from "date-fns";
import React, { useState, useEffect, useRef, useMemo } from "react";
import { confirm } from "react-confirm-box";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";

import ApproveWithNote from "./actions/ApproveWithNote";
import EditAddress from "./actions/EditAddress";
import RejectReport from "./actions/RejectReport";
import { AddressField } from "./fields/AddressField";
import { DisplayActions } from "./fields/DisplayActions";
import { DisplayAttachment } from "./fields/DisplayAttachment";
import { ReportActions } from "./fields/ReportActions";
import { ReportDateField } from "./fields/ReportDateField";
import { ReportField } from "./fields/ReportField";
import { ShowHideButton } from "./fields/UI/ShowHideButton";
import { BicycleRegistration } from "./incidents/BicycleRegistration";
import { Bullying } from "./incidents/Bullying";
import { DamageToProperty } from "./incidents/DamageToProperty";
import { DamageToVehicle } from "./incidents/DamageToVehicle";
import { LostProperty } from "./incidents/LostProperty";
import { TheftFromVehicle } from "./incidents/TheftFromVehicle";
import { TheftOfGas } from "./incidents/TheftOfGas";
import { TheftUnder5k } from "./incidents/TheftUnder5k";
import { TrafficComplaint } from "./incidents/TrafficComplaint";
import styles from "./report.module.css";
import { apiReportClient } from "../../common/api-client";
import { transformString } from "../../dataProvider/VSDataProvider";

function useIsInViewport(ref) {
  const [isIntersecting, setIsIntersecting] = useState(false);

  const observer = useMemo(
    () =>
      new IntersectionObserver(([entry]) =>
        setIsIntersecting(entry.isIntersecting),
      ),
    [],
  );

  useEffect(() => {
    if (!ref.current) return;
    observer.observe(ref.current);

    return () => {
      observer.disconnect();
    };
  }, [ref, observer]);

  return isIntersecting;
}

const setPath = (object, path, value) =>
  path
    .split(".")
    .reduce(
      (o, p, i) => (o[p] = path.split(".").length === ++i ? value : o[p] || {}),
      object,
    );

export const ReportShow = (props) => {
  const { id } = useParams();
  const scrollToActions = useRef<HTMLDivElement>(null);
  const scrollToInfo = useRef<HTMLDivElement>(null);
  const scrollToAttachment = useRef<HTMLDivElement>(null);

  const scrollToActionsBlock = useRef<HTMLDivElement>(null);
  const scrollToInfoBlock = useRef<HTMLDivElement>(null);
  const scrollToAttachmentBlock = useRef<HTMLDivElement>(null);

  const isInViewportActions = useIsInViewport(scrollToActionsBlock);
  const isInViewportInfo = useIsInViewport(scrollToInfoBlock);
  const isInViewportAttachment = useIsInViewport(scrollToAttachmentBlock);

  const navigate = useNavigate();
  const [isReject, setIsReject] = useState(false);
  const [data, setData] = useState<any>(null);
  const [occurrenceNumberPresent, setOccurrenceNumberPresent] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [isApproveWithNote, setIsApproveWithNote] = useState(false);
  const [isEditAddress, setIsEditAddress] = useState(false);
  const [address, setAddress] = useState(null);
  const [addressSource, setAddressSource] = useState("");
  const [isEdited, setIsEdited] = useState(false);
  const [show, setShow] = useState(false);
  const [status, setStatus] = useState<string>("");
  const [attachments, setAttachments] = useState<any>(null);
  const [actions, setActions] = useState<any>(null);

  const showClick = () => {
    setShow(!show);
  };

  const onApprove = () => {
    apiReportClient
      .approveReport(id)
      .then(() => {
        toast.success("Report is approved");
        setStatus("APPROVED");
      })
      .catch(() => {
        //Error proccessed in http.js
      });
  };

  const onApproveWithNote = () => {
    setIsApproveWithNote(true);
  };

  const CheckOccurrenceBeforeApprove = (approve) => {
    if (!occurrenceNumberPresent) {
      occuranceNumberPrompt(approve);
    } else {
      approve();
    }
  };

  const occuranceNumberPrompt = async (approve) => {
    const optionsWithLabelChange = {
      closeOnOverlayClick: false,
      labels: {
        confirmable: "Add",
        cancellable: "No",
      },
    };
    const resultOccurrenceNumber = await confirm(
      "You left Occurrence Number empty. Would you like to add one?",
      optionsWithLabelChange,
    );

    if (!resultOccurrenceNumber) {
      setOccurrenceNumberPresent(true);
      approve();
    }
  };

  const onReject = () => {
    setIsReject(true);
  };

  const onCancel = async () => {
    if (!isEdited) {
      navigate(-1);
      return;
    }
    const optionsWithLabelChange = {
      closeOnOverlayClick: false,
      labels: {
        cancellable: "No",
        confirmable: "Yes",
      },
    };

    const result = await confirm(
      "Are you sure? All unsaved changes will be lost.",
      optionsWithLabelChange,
    );
    if (result) {
      navigate(-1);
    }
  };
  const onClose = () => {
    setIsReject(false);
    setIsApproveWithNote(false);
    setIsEditAddress(false);
  };

  const updateField = (value, field) => {
    const dataVal = JSON.parse(JSON.stringify(data));
    setPath(dataVal, field, value);
    setData(dataVal);
    setIsEdited(true);
  };
  const updateOccurrenceNumber = (value) => {
    setData({ ...data, meta: { ...data.meta, occurrenceNumber: value } });
    setOccurrenceNumberPresent(true);
    setIsEdited(true);
  };

  const updateAddress = (value, field) => {
    const dataVal = JSON.parse(JSON.stringify(data));
    setPath(dataVal, field, value);
    setData(dataVal);
    setIsEdited(true);
  };

  const onUpdateAddress = (data, field) => {
    setAddressSource(field);
    setAddress(data);
    setIsEditAddress(true);
    setIsEdited(true);
  };

  const saveReport = () => {
    if (id) {
      apiReportClient
        .saveReport(id, data)
        .then((res) => {
          toast.success("Report updated successfully.");
          setIsEdited(false);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  useEffect(() => {
    const getReport = () => {
      if (id) {
        setIsLoading(true);
        apiReportClient
          .getReport(id)
          .then((res) => {
            if (!res.report.meta.occurrenceNumber) {
              setData({
                ...res.report,
                // trackingNumber: transformString(res.trackingNumber, "NorthBay"),
                meta: {
                  ...res.report.meta,
                  occurrenceNumber: "",
                  // trackingNumber: transformString(
                  //   res.report.meta.trackingNumber,
                  //   "NorthBay"
                  // ),
                },
              });
              setOccurrenceNumberPresent(false);
            } else {
              setData(res.report);
            }
            setStatus(res.status);
            setAttachments(res.attachments);
            setActions(res.actions.reverse());
            setIsLoading(false);
            setIsEdited(false);
          })
          .catch((err) => {
            console.log(err);
          });
      }
    };
    getReport();
  }, [id]);

  let reportDate = "";
  if (data && data.meta && data.meta.reportTime) {
    const d = Date.parse(data.meta.reportTime);
    reportDate = format(d, "MMMM dd, p");
  }
  return (
    <React.Fragment>
      {isLoading && <div className="loader">Loading...</div>}
      {data && (
        <div className={styles.reportContainer}>
          <div className={styles.reportInner}>
            <div className={styles.reportViewHeading}>
              <div
                className="back-button"
                onClick={() => {
                  onCancel();
                }}
              >
                <ArrowBack className="back-icon" /> BACK
              </div>
              <div className="report-status">{status}</div>
              <div className={styles.reportDate}>{reportDate}</div>
              <div className="incident-type">{data.meta.incidentType}</div>
              <div className={styles.reporterName}>
                {data.reporter.firstName} {data.reporter.lastName}
              </div>
              <div className={styles.trackingNumber}>
                {data.meta.trackingNumber}
              </div>
            </div>

            {/* Action Cards */}
            <Paper elevation={3} className={styles.paperContainer}>
              <div className={styles.actionHeader} ref={scrollToActions}></div>
              <div className={styles.subHeading}>
                <LibraryAddCheckRounded
                  fontSize="large"
                  color="disabled"
                  className={styles.actionsIcon}
                />
                Action History
              </div>
              {actions && (
                <DisplayActions
                  scrollToActionsBlock={scrollToActionsBlock}
                  actions={actions}
                  id={id}
                  label="Actions"
                />
              )}
            </Paper>

            <Paper
              elevation={3}
              className={styles.paperContainer}
              ref={scrollToInfoBlock}
            >
              <div className="info-header" ref={scrollToInfo}></div>
              <div className={styles.subHeading}>
                <Assignment
                  fontSize="large"
                  color="disabled"
                  className={styles.actionsIcon}
                />
                General Information
                <ShowHideButton onClick={showClick}>
                  {" "}
                  {show ? "Hide " : "Show "} empty fields{" "}
                </ShowHideButton>
              </div>

              {data.meta.trackingNumber || show ? (
                <ReportField
                  value={data.meta.trackingNumber}
                  label="Tracking Number"
                />
              ) : null}
              {data.meta.reportType === "Supplemental" && (
                <ReportField
                  value={data.meta.originalReportNumber}
                  label="Original Tracking Number"
                />
              )}
              <ReportField
                className="report-field-row"
                value={data.meta.occurrenceNumber}
                source="meta.occurrenceNumber"
                label="Occurrence Number"
                onChange={updateOccurrenceNumber}
              />
              {data.meta.reportType || show ? (
                <ReportField value={data.meta.reportType} label="Report Type" />
              ) : null}
              {data.meta.reportTime || show ? (
                <ReportDateField
                  showtime={true}
                  value={data.meta.reportTime}
                  label="Report Date"
                />
              ) : null}
              {data.meta.reportLanguage || show ? (
                <ReportField
                  value={data.meta.reportLanguage}
                  label="Report Filled Language"
                />
              ) : null}
              {data.meta.reportIpAddress || show ? (
                <ReportField
                  value={data.meta.reportIpAddress}
                  label="Report Filled from IP Address"
                />
              ) : null}
              {data.meta.reporterType || show ? (
                <ReportField
                  value={data.meta.reporterType}
                  label="Person Type"
                />
              ) : null}

              <div className={styles.subHeading}>
                Reporting Person Information
                <ShowHideButton onClick={showClick}>
                  {" "}
                  {show ? "Hide " : "Show "} empty fields{" "}
                </ShowHideButton>
              </div>
              {data.info.bullying && data.info.bullying.anonymous && (
                <ReportField
                  className="report-field-row"
                  value={data.info.bullying.anonymous}
                  label="Wishes to remain anonymous"
                />
              )}
              {data.info.bullying && data.info.bullying.willReportToSchool && (
                <ReportField
                  className="report-field-row"
                  value={data.info.bullying.willReportToSchool}
                  label="Wishes to report to school"
                />
              )}
              {data.reporter.involvement && (
                <ReportField
                  value={data.reporter.involvement}
                  label="Involvement"
                />
              )}
              <ReportField
                label="First Name"
                value={data.reporter.firstName}
                source="reporter.firstName"
                onChange={updateField}
              />
              <ReportField
                label="Last Name"
                value={data.reporter.lastName}
                source="reporter.lastName"
                onChange={updateField}
              />
              <AddressField
                label="Home Address"
                address={data.reporter.homeAddress}
                onEditAddress={() => {
                  setAddressSource("reporter.homeAddress");
                  setAddress(data.reporter.homeAddress);
                  setIsEditAddress(true);
                }}
              />
              <ReportField
                value={data.reporter.contactPhoneNumber}
                source="reporter.contactPhoneNumber"
                label="Contact Phone Number"
                onChange={updateField}
              />
              <ReportField
                value={data.reporter.email}
                source="reporter.email"
                label="Email"
                onChange={updateField}
              />
              {data.reporter.employerName || show ? (
                <ReportField
                  value={data.reporter.employerName}
                  source="reporter.employerName"
                  label="Employer Name"
                  onChange={updateField}
                />
              ) : null}
              <AddressField
                label="Work Address"
                address={data.reporter.workAddress}
                onEditAddress={() => {
                  setAddressSource("reporter.workAddress");
                  setAddress(data.reporter.workAddress);
                  setIsEditAddress(true);
                }}
              />
              <ReportField
                className="report-field-row"
                value={data.reporter.workPhone}
                source="reporter.workPhone"
                label="Work Phone Number"
                onChange={updateField}
              />
              <ReportField
                className="report-field-row"
                value={data.reporter.gender}
                source="reporter.gender"
                label="Gender"
                onChange={updateField}
              />
              <ReportDateField
                showtime={false}
                className="report-field-row"
                value={data.reporter.dateOfBirth}
                source="reporter.dateOfBirth"
                label="Date of birth"
              />
              {data.info.bullying && data.info.bullying.school && (
                <ReportField
                  className="report-field-row"
                  value={data.info.bullying.school}
                  label="What school?"
                />
              )}
              {data.info.bullying && data.info.bullying.grade && (
                <ReportField
                  className="report-field-row"
                  value={data.info.bullying.grade}
                  label="What grade?"
                />
              )}
              {data.reporter.driversLicense &&
                data.reporter.driversLicense.number && (
                  <ReportField
                    className="report-field-row"
                    value={data.reporter.driversLicense.number}
                    source="reporter.driversLicense.number"
                    label="Driving License Number"
                    onChange={updateField}
                  />
                )}
              {data.reporter.driversLicense &&
                data.reporter.driversLicense.provider && (
                  <ReportField
                    className="report-field-row"
                    value={data.reporter.driversLicense.provider}
                    source="reporter.driversLicense.provider"
                    label="Licensing Pre/State"
                    onChange={updateField}
                  />
                )}

              <div className={styles.subHeading}>
                Incident Information
                <ShowHideButton onClick={showClick}>
                  {" "}
                  {show ? "Hide " : "Show "} empty fields{" "}
                </ShowHideButton>
              </div>
              <ReportField
                className="report-field-row"
                value={data.meta.incidentType}
                label="Incident Type"
              />
              <BicycleRegistration
                data={data}
                onEditAddress={onUpdateAddress}
              />
              <TheftFromVehicle data={data} onEditAddress={onUpdateAddress} />
              <TrafficComplaint data={data} onEditAddress={onUpdateAddress} />
              <TheftUnder5k
                clickState={show}
                data={data}
                onEditAddress={onUpdateAddress}
              />
              <TheftOfGas
                clickState={show}
                data={data}
                onEditAddress={onUpdateAddress}
              />
              <DamageToProperty
                clickState={show}
                data={data}
                onEditAddress={onUpdateAddress}
              />
              <DamageToVehicle
                clickState={show}
                data={data}
                onEditAddress={onUpdateAddress}
              />
              <LostProperty
                clickState={show}
                data={data}
                onEditAddress={onUpdateAddress}
              />
              <Bullying
                clickState={show}
                data={data}
                onEditAddress={onUpdateAddress}
              />
            </Paper>

            <Paper
              elevation={3}
              className={styles.paperContainer}
              ref={scrollToAttachmentBlock}
            >
              <div
                className={styles.attachmentHeader}
                ref={scrollToAttachment}
              ></div>
              <div className={styles.subHeading}>
                <Collections
                  fontSize="large"
                  color="disabled"
                  className={styles.actionsIcon}
                />
                Attachments
              </div>
              {attachments && (
                <DisplayAttachment
                  attachments={attachments}
                  id={id}
                  label="Attachments"
                />
              )}
            </Paper>

            {/* Below component stands for chat with Officer */}
            {/* < ChatWithOfficer/> */}

            <ReportActions
              status={status}
              onApprove={CheckOccurrenceBeforeApprove.bind(null, onApprove)}
              onApproveWithNote={CheckOccurrenceBeforeApprove.bind(
                null,
                onApproveWithNote,
              )}
              onReject={onReject}
              onCancel={onCancel}
              onSave={saveReport}
            />
          </div>
          {/* <div className={styles.rightNavigationContainer}>
            <div
              className={
                styles.messageIconParent +
                (isInViewportActions && !isInViewportActions ? "active" : "")
              }
              onClick={() =>
                scrollToActions.current &&
                scrollToActions.current.scrollIntoView({ behavior: "smooth" })
              }
            >
              <LibraryAddCheckOutlinedIcon
                style={{ color: "lightgrey" }}
                className="info-icon"
              />
            </div>
            <div className={styles.iconDivider}>
              <div></div>
            </div>
            <div
              className={
                styles.infoIconParent +
                (isInViewportInfo && !isInViewportInfo ? "active" : "")
              }
              onClick={() =>
                scrollToInfo.current &&
                scrollToInfo.current.scrollIntoView({ behavior: "smooth" })
              }
            >
              <img
                src={assignment_w}
                className="info-icon"
                alt="Information Icon"
              />
            </div>
            <div className={styles.iconDivider}>
              <div></div>
            </div>
            <div
              className={
                styles.attachmentIconParent +
                (isInViewportAttachment && !isInViewportAttachment
                  ? "active"
                  : "")
              }
              onClick={() =>
                scrollToAttachment.current &&
                scrollToAttachment.current.scrollIntoView({
                  behavior: "smooth",
                })
              }
            >
              <img
                src={attachment_w}
                className="info-icon"
                alt="Information Icon"
              />
            </div>
          </div> */}
        </div>
      )}
      <Drawer
        anchor="right"
        onClose={onClose}
        PaperProps={{
          sx: { maxWidth: "750px", zIndex: 100, width: "100%" },
        }}
        open={isReject || isApproveWithNote || isEditAddress}
      >
        {isApproveWithNote && (
          <ApproveWithNote id={id} onClose={onClose} setStatus={setStatus} />
        )}
        {isReject && (
          <RejectReport id={id} onClose={onClose} setStatus={setStatus} />
        )}
        {isEditAddress && (
          <EditAddress
            address={address}
            onAddressUpdate={(updatedAddres) => {
              updateAddress(updatedAddres, addressSource);
            }}
            onClose={() => {
              setIsEditAddress(false);
            }}
          />
        )}
      </Drawer>
    </React.Fragment>
  );
};
