import { Refresh } from "@mui/icons-material";
import { Autocomplete, Button, IconButton, TextField } from "@mui/material";

import { statusTypes } from "../../../types/typesReportV2";

export interface IReportListFilterOptions {
  creationStartDate: string | null;
  creationEndDate: string | null;
  updateStartDate: string | null;
  updateEndDate: string | null;
  reportStatus: string | null;
  trackingNumber?: string | null;
  reportType?: string | null;
}

const ReportV2Filters = ({
  filterStatus,
  setFilterStatus,
  reportTypeNames,
}: {
  filterStatus: IReportListFilterOptions;
  setFilterStatus: (IReportListFilterOptions) => void;
  reportTypeNames: string[];
}) => {
  const {
    creationStartDate,
    creationEndDate,
    updateStartDate,
    updateEndDate,
    reportStatus,
    trackingNumber,
    reportType,
  } = filterStatus;
  function clearFilters() {
    setFilterStatus({
      creationStartDate: null,
      creationEndDate: null,
      updateStartDate: null,
      updateEndDate: null,
      reportStatus: null,
      trackingNumber: null,
      reportType: null,
    });
  }

  function refreshFilters() {
    setFilterStatus({
      creationStartDate,
      creationEndDate,
      updateStartDate,
      updateEndDate,
      reportStatus,
      trackingNumber,
      reportType,
    });
  }

  function updateFilters(key: string, value: string | null) {
    setFilterStatus({
      ...filterStatus,
      [key]: value,
    });
  }

  return (
    <div className="alert-filters">
      <TextField
        label="Tracking Number"
        type="text"
        size="small"
        variant="outlined"
        style={{ minWidth: "140px", maxWidth: "240px", flexGrow: 1 }}
        value={trackingNumber ?? ""}
        onChange={(ev) => {
          updateFilters(
            "trackingNumber",
            ev.target.value ? ev.target.value.toUpperCase() : null,
          );
        }}
        InputLabelProps={{
          shrink: true,
        }}
      />
      <div className="date-range">
        <TextField
          label="Creation Date From"
          type="date"
          size="small"
          variant="outlined"
          style={{ minWidth: "140px", maxWidth: "240px", flexGrow: 1 }}
          value={
            creationStartDate
              ? new Date(creationStartDate).toISOString().split("T")[0]
              : ""
          }
          onChange={(ev) => {
            updateFilters(
              "creationStartDate",
              new Date(ev.target.value).toISOString(),
            );
          }}
          InputLabelProps={{
            shrink: true,
          }}
        />
        <TextField
          label="Creation Date To"
          type="date"
          size="small"
          variant="outlined"
          style={{ minWidth: "140px", maxWidth: "240px", flexGrow: 1 }}
          value={
            creationEndDate
              ? new Date(creationEndDate).toISOString().split("T")[0]
              : ""
          }
          onChange={(ev) => {
            updateFilters(
              "creationEndDate",
              new Date(ev.target.value).toISOString(),
            );
          }}
          InputLabelProps={{
            shrink: true,
          }}
        />
      </div>
      <div className="date-range">
        <TextField
          label="Update Date From"
          type="date"
          size="small"
          variant="outlined"
          style={{ minWidth: "140px", maxWidth: "240px", flexGrow: 1 }}
          value={updateStartDate || ""}
          onChange={(ev) => {
            updateFilters("updateStartDate", ev.target.value);
          }}
          InputLabelProps={{
            shrink: true,
          }}
        />
        <TextField
          label="Update Date To"
          type="date"
          size="small"
          variant="outlined"
          style={{ minWidth: "140px", maxWidth: "240px", flexGrow: 1 }}
          value={updateEndDate || ""}
          onChange={(ev) => {
            updateFilters("updateEndDate", ev.target.value);
          }}
          InputLabelProps={{
            shrink: true,
          }}
        />
      </div>
      <Autocomplete
        id="select-bulltin-type"
        options={Object.keys(statusTypes)}
        value={reportStatus}
        getOptionLabel={(option) => statusTypes[option]}
        autoComplete
        autoHighlight
        clearOnBlur
        openOnFocus
        style={{
          minWidth: "180px",
          maxWidth: "280px",
          flexGrow: 1,
        }}
        size="small"
        onChange={(event, newValue) => {
          if (newValue) updateFilters("reportStatus", newValue);
          else updateFilters("reportStatus", null);
        }}
        renderInput={(params) => (
          <TextField {...params} variant="outlined" label="Report Status" />
        )}
      />
      <Autocomplete
        id="select-report-type"
        options={reportTypeNames}
        value={reportType}
        getOptionLabel={(option) => option}
        autoComplete
        autoHighlight
        clearOnBlur
        openOnFocus
        style={{
          minWidth: "180px",
          maxWidth: "280px",
          flexGrow: 1,
        }}
        size="small"
        onChange={(event, newValue) => {
          if (newValue) updateFilters("reportType", newValue);
          else updateFilters("reportType", "");
        }}
        renderInput={(params) => (
          <TextField {...params} variant="outlined" label="Report Type" />
        )}
      />
      <IconButton color="primary" onClick={refreshFilters} size="large">
        <Refresh />
      </IconButton>
      <Button
        variant="outlined"
        color="primary"
        onClick={clearFilters}
        style={{ height: "40px", marginTop: "4px" }}
      >
        Clear Filters
      </Button>
    </div>
  );
};

export default ReportV2Filters;
