import React from "react";

import completed from "../../../images/completed.png";

interface StepNavigationProps {
  index: number;
  activeStep: number;
  steps: string[];
  label: string;
}
const StepNavigation = ({
  index,
  activeStep,
  steps,
  label,
}: StepNavigationProps): JSX.Element => {
  let classes = "navigation-item step";
  if (index <= activeStep) {
    classes = "navigation-item step active";
  }
  return (
    <React.Fragment key={index}>
      <div className={classes}>
        {index < activeStep && (
          <div className="step-number">
            <img src={completed} className="step-completed" alt="" />
          </div>
        )}
        {index >= activeStep && <div className="step-number">{index + 1}</div>}
        <div className="step-caption">{label}</div>
      </div>
      {index !== steps.length - 1 && (
        <div className="navigation-item divider" />
      )}
    </React.Fragment>
  );
};

export default StepNavigation;
