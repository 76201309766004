import { Document, Image, Link, Page, Text, View } from "@react-pdf/renderer";
import dayjs from "dayjs";

import {
  dlPDFDetailsStyles,
  dlPDFFooterStyles,
  dlPDFHeaderStyles,
  dlPDFImageStyles,
} from "./ReactPDFStyling";
import {
  formatPhoneNumber,
  stripPhoneNumberFormatting,
} from "../../../../../common/utils";
import {
  IAlertProperties,
  ICreator,
  ITag,
  MediaBlob,
} from "../../../../../types";
import {
  getAddressClassification,
  getAddressDescription,
} from "../../../utils";
import { approvedByText, creatorText } from "../utils";

interface generateAlertPDFProps {
  alertProperties: IAlertProperties;
  policeLogo: any;
  evidenceArray: MediaBlob[];
  issuedDate: string | undefined;
  currentUser: ICreator | null;
}
/**
 * Generates a PDF of the alert
 * @param alertProperties
 * @param policeLogo
 * @param evidenceArray
 * @param issuedTime
 * @param currentUser
 * @returns PDF of the alert
 */
export function generateAlertPDF({
  alertProperties,
  policeLogo,
  evidenceArray,
  issuedDate: issuedTime,
  currentUser,
}: generateAlertPDFProps) {
  const {
    policeService,
    type,
    associatedCrimes,
    occurrenceNumbers,
    incidentDate,
    description,
    operationalArea,
    approvedBy,
    approvedByBadge,
    classification,
    contacts,
    bulletinId: alertId,
    tagDict,
    creator,
  } = alertProperties;

  const formattedIncidentDate = dayjs(incidentDate).format(
    "dddd - MMMM DD, YYYY, hh:mm A",
  );

  const creatorToUse = creator ? creator : currentUser;

  /**
   * evidenceArrayChunks is an array of arrays of blobs that will be displayed on each page
   * The first page will have 3 images, the second page will have the rest of the images
   */
  const evidenceArrayChunks: MediaBlob[][] = [];

  let pageOneImages: MediaBlob[] = [];
  let pageTwoImages: MediaBlob[] = [];
  if (evidenceArray.length <= 3) {
    pageOneImages = evidenceArray;
  } else {
    pageOneImages = evidenceArray.slice(0, 3);
    pageTwoImages = evidenceArray.slice(3);
  }
  evidenceArrayChunks.push(pageOneImages);
  if (pageTwoImages.length > 0) {
    evidenceArrayChunks.push(pageTwoImages);
  }
  /**
   *
   * @param imageCount
   * @param image
   * @returns styling for the image based on the number of images on the page
   */
  const calculateImageDimensions = (imageCount: number, image) => {
    const result: {
      width: string;
      height: string;
    } = {} as any;
    const { width, height } = image.dimensions;
    const ratio = width / height;
    const orientation = () => {
      if (ratio > 1) {
        return "landscape";
      } else if (ratio < 1) {
        return "portrait";
      } else {
        return "square";
      }
    };
    const maxWidthPerImage = 95 / imageCount;

    result.width = `${maxWidthPerImage}%`;
    return result;
  };

  const currentDate = dayjs(new Date()).format("YYYY-MM-DD HH:mm");

  // Map of attachment to tags
  const tagDictValues = Object.values(tagDict);
  const tagDictWithDescription = tagDictValues.filter(
    (tag) => tag.description !== "",
  );
  const tagDictWithoutDescription = tagDictValues.filter(
    (tag) => tag.description === "",
  );
  const imagesToTags: { [key: string]: ITag[] } = {};
  for (const tag of tagDictValues) {
    for (const attachment of tag.attachments) {
      if (attachment in imagesToTags) {
        imagesToTags[attachment].push(tag);
      } else {
        imagesToTags[attachment] = [tag];
      }
    }
  }

  const alertUrl =
    window.location.origin + `/rubialert/alerts/${alertId}/single`;

  const renderAddresses = () => {
    return alertProperties.rawAddresses.map((address, index) => {
      const classification =
        getAddressClassification(address) || "Incident Address";
      const addressNumber =
        alertProperties.rawAddresses.filter(
          (addr) => getAddressClassification(addr) === classification,
        ).length > 1
          ? ` ${index + 1}`
          : "";
      const description = getAddressDescription(address);

      return (
        <Text style={dlPDFDetailsStyles.address} key={index.toString()}>
          {classification + addressNumber}: {description}
        </Text>
      );
    });
  };

  const MyDocument = () => (
    <Document>
      <Page size="A4" wrap>
        <View style={dlPDFHeaderStyles.header_container}>
          <View style={dlPDFHeaderStyles.logo_container}>
            <Image src={policeLogo} style={dlPDFHeaderStyles.police_logo} />
            {operationalArea && (
              <Text style={dlPDFHeaderStyles.operational_area}>
                {operationalArea}
              </Text>
            )}
          </View>
          <View style={dlPDFHeaderStyles.crime_types_container}>
            <Text style={dlPDFHeaderStyles.confidentiality_text}>
              <Link src="https://www.tbs-sct.canada.ca/pol/doc-eng.aspx?id=32614">
                *** {classification} ***
              </Link>
            </Text>
            <Text
              style={{
                ...dlPDFHeaderStyles.crime_type,
                fontSize: type && type.length > 30 ? 18 : 22,
              }}
            >
              {type}
            </Text>
            <View style={dlPDFHeaderStyles.associated_crimes_container}>
              {associatedCrimes.map((crime, index) => (
                <Text style={dlPDFHeaderStyles.associated_crimes} key={index}>
                  {crime.crime}
                </Text>
              ))}
            </View>
            <Text style={dlPDFHeaderStyles.incident_text}>
              Incident Time: {formattedIncidentDate}
            </Text>
          </View>
        </View>
        {evidenceArrayChunks[0].length > 0 && (
          <View
            style={{
              ...dlPDFImageStyles.page_one_image_container,
              marginBottom: evidenceArrayChunks.length > 1 ? 32 : 16,
            }}
          >
            {evidenceArrayChunks[0].map((image, index) => (
              <View
                style={{
                  ...dlPDFImageStyles.image_card,
                  ...calculateImageDimensions(evidenceArrayChunks[0].length, {
                    dimensions: image.dimensions,
                    filename: image.filename,
                  }),
                }}
                key={index.toString()}
              >
                <Image
                  src={URL.createObjectURL(image.blob)}
                  key={index}
                  style={{
                    height: "auto",
                    width: "100%",
                    objectFit: "contain",
                  }}
                />
                <View
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    gap: 4,
                    marginTop: 4,
                  }}
                >
                  {imagesToTags[image.filename] &&
                    imagesToTags[image.filename].map((tag, index) => (
                      <Text style={dlPDFImageStyles.tag} key={index.toString()}>
                        {tag.title}
                      </Text>
                    ))}
                  {image.media_type === "video_thumbnail" && (
                    <Link style={dlPDFImageStyles.video_link} src={alertUrl}>
                      View the video in RubiALERT
                    </Link>
                  )}
                </View>
              </View>
            ))}
            {evidenceArrayChunks.length > 1 && (
              <Text style={dlPDFImageStyles.more_images_text}>
                More images on next page
              </Text>
            )}
          </View>
        )}
        <View style={dlPDFDetailsStyles.details_container}>
          {tagDictValues.length > 0 && (
            <View
              style={{
                display: "flex",
                flexDirection: "column",
                fontSize: 10,
                alignContent: "center",
              }}
            >
              <View
                style={{
                  flexDirection: "row",
                  justifyContent: "space-between",
                  marginRight: 32,
                }}
              >
                {tagDictWithDescription.map((tag, index) => (
                  <View
                    style={{
                      flex: 1,
                      flexDirection: "column",
                      alignItems: "flex-start",
                      padding: 4,
                      borderLeft: index === 0 ? "" : "0.75px solid grey",
                      borderRight:
                        index === tagDictWithDescription.length - 1
                          ? ""
                          : "0.25px solid grey",
                      borderTop:
                        evidenceArrayChunks.length > 1
                          ? ""
                          : "0.75px solid grey",
                    }}
                    key={index.toString()}
                  >
                    <Text style={{ ...dlPDFImageStyles.tag }}>{tag.title}</Text>
                    <Text>{tag.description}</Text>
                  </View>
                ))}
              </View>
              <View
                style={{
                  flexDirection: "row",
                  alignItems: "center",
                  flexWrap: "wrap",
                  marginTop: 2,
                }}
              >
                {tagDictWithoutDescription.map((tag, index) => (
                  <Text
                    style={{ ...dlPDFImageStyles.tag, margin: 2 }}
                    key={index.toString()}
                  >
                    {tag.title}
                  </Text>
                ))}
              </View>
            </View>
          )}
          <View
            style={{
              marginRight: 32,
            }}
          >
            {renderAddresses()}
          </View>

          <Text style={dlPDFDetailsStyles.description}>{description}</Text>
          <Text style={dlPDFDetailsStyles.contact_label}>
            Anyone with information is asked to contact:
          </Text>
          {contacts.map((contact, index) => (
            <div
              style={dlPDFDetailsStyles.contact_details}
              key={index.toString()}
            >
              <Text>
                {contact.name}
                {contact?.badge_number && ` - ${contact.badge_number}`}
                {contact?.email && (
                  <>
                    {" "}
                    <Link
                      key={index}
                      style={dlPDFDetailsStyles.contact_text}
                      src={`mailto:${contact.email}`}
                    >
                      {contact.email}
                    </Link>
                  </>
                )}
                {contact?.phone_number && (
                  <>
                    {" "}
                    <Link
                      key={index}
                      style={dlPDFDetailsStyles.contact_text}
                      src={`tel:${stripPhoneNumberFormatting(
                        contact.phone_number,
                      )}`}
                    >
                      {formatPhoneNumber(contact.phone_number)}
                      {contact?.extension && ` ext. ${contact.extension}`}
                    </Link>
                  </>
                )}
              </Text>
            </div>
          ))}
          <Text style={dlPDFDetailsStyles.police_service_label}>
            {policeService?.name} - {occurrenceNumbers.join(", ")}
          </Text>

          <Text style={dlPDFDetailsStyles.approved_by_label}>
            {creatorToUse &&
              creatorText(creatorToUse) !== "" &&
              `Prepared by: ${creatorText(creatorToUse)}`}
            {approvedByText(approvedBy, approvedByBadge) !== "" &&
              ` Approved by: ${approvedByText(approvedBy, approvedByBadge)}`}
          </Text>
          <Text style={dlPDFDetailsStyles.issue_date}>
            Issued on: {issuedTime}
          </Text>
        </View>
        <View style={dlPDFFooterStyles.footer_container} fixed>
          <Text>
            Generated on: {currentDate} using{" "}
            <Link src="https://www.tryrubicon.com/">Rubicon</Link>{" "}
          </Text>
          <Text
            style={{
              position: "absolute",
              bottom: 10,
              left: 0,
              right: 0,
              textAlign: "center",
            }}
            render={({ pageNumber, totalPages }) =>
              `Page ${pageNumber} / ${totalPages}`
            }
          />
          {alertId !== 0 && (
            <Text
              style={{
                position: "absolute",
                bottom: 10,
                left: "75%",
                right: 0,
                textAlign: "right",
                justifyContent: "flex-end",
              }}
            >
              Alert ID: {alertId}
            </Text>
          )}
        </View>
        {/* </Page> */}

        {evidenceArrayChunks.length > 1 &&
          evidenceArrayChunks.slice(1).map((chunk, chunkIndex) => (
            <View
              style={dlPDFImageStyles.image_container_remaining}
              key={chunkIndex.toString()}
            >
              {chunk.map((image, index) => (
                <View
                  key={index.toString()}
                  style={{
                    ...dlPDFImageStyles.image_card_remaining,
                  }}
                >
                  <Image
                    src={URL.createObjectURL(image.blob)}
                    key={index}
                    style={{ objectFit: "contain", height: "200pt" }}
                  />
                  <View
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      gap: 4,
                      marginTop: 4,
                    }}
                  >
                    {imagesToTags[image.filename] &&
                      imagesToTags[image.filename].map((tag, index) => (
                        <Text
                          style={dlPDFImageStyles.tag}
                          key={index.toString()}
                        >
                          {tag.title}
                        </Text>
                      ))}
                  </View>
                </View>
              ))}
            </View>
          ))}
      </Page>
    </Document>
  );

  return <MyDocument />;
}
