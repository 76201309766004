import axios from "axios";

import { CustomSlider, MediaSlide } from "../../../common/Slider";
import { ReportAttachment } from "../utils";

async function downloadAsset(assetURL: string, fileName: string) {
  const aElement = document.createElement("a");
  const data = await axios.get(assetURL, {
    responseType: "blob",
    headers: {
      "Cache-Control": "no-cache",
      Pragma: "no-cache",
      Expires: "0",
    },
  });
  const blob = data.data;
  const url = window.URL.createObjectURL(blob);
  aElement.setAttribute("download", fileName);
  aElement.href = url;
  aElement.setAttribute("target", "_blank");
  aElement.click();
}

export const AttachmentSlider = ({
  reportAttachments,
  setSelectedIndex,
}: {
  reportAttachments: ReportAttachment[];
  setSelectedIndex: (index: number) => void;
}) => {
  const AttachmentItem = ({
    attachment,
    index,
  }: {
    attachment: any;
    index: number;
  }) => {
    const descriptionKey =
      Object.keys(attachment).find((key) => key.includes("_description")) || "";
    const description = attachment[descriptionKey];

    const fileKey =
      Object.keys(attachment).find((key) => key.includes("_upload")) || "";
    const files = attachment[fileKey];

    return (
      <MediaSlide
        downloadAsset={() => downloadAsset(files[0].content, files[0].name)}
        mediaSrc={files[0].content}
        mediaType={files[0].type.includes("image") ? "image" : "video"}
        description={description}
        descriptionStyle={{
          padding: "10px",
          whiteSpace: "pre-wrap",
        }}
        onClick={() => {
          setSelectedIndex(index);
        }}
        customVideoErrorMessage="The video can't be played in the browser because of the unsupported format. Please download it to play it on your device."
        handleVideoErrorClick={() => {
          downloadAsset(files[0].content, files[0].name);
        }}
        customHeight={600}
      />
    );
  };
  const allAttachments = reportAttachments.map((attachment, index) => (
    <AttachmentItem key={index} attachment={attachment} index={index} />
  ));
  return (
    <CustomSlider
      items={allAttachments}
      style={{
        width: "95%",
      }}
    />
  );
};
