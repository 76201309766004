import { ArrowBack, Block } from "@mui/icons-material";
import {
  Button,
  FormControlLabel,
  Grid,
  Switch,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";

import { useDevEnvironment } from "../../../../DevContext";
import ViewOrEditTextField from "../../../../common/MultiStateFields/ViewOrEditTextField";
import { RubiModal } from "../../../../common/RubiModal";
import { apiReportClient } from "../../../../common/api-client";
import { getServiceConfig } from "../../../../common/serviceConfigurations";
import { IOrgUser, IReturnedOrgUser } from "../../../../types";
import { Permission } from "../../types";
import { userPermissionChecks } from "../../utils";

export const emptyUser: IReturnedOrgUser = {
  badge_number: "",
  email: "",
  first_name: "",
  id: "",
  last_name: "",
  rank_short: "",
  rubi_org_id: "",
  stytch_user_id: "",
  has_temp_pw: true,
  contact_phone_number: "",
  contact_phone_extension: "",
};

export const initValidationGrid = {
  badge_number: true,
  email: true,
  first_name: true,
  last_name: true,
  rank_short: true,
  contact_phone_number: true,
  contact_phone_extension: true,
};

export const fieldValidationRules = (user, mode, updateOwnProfile) => {
  if (mode === "add" || (mode === "edit" && !updateOwnProfile)) {
    return {
      badge_number: true,
      email:
        typeof user?.email === "string" &&
        user.email.length > 0 &&
        user.email.includes("@"),
      first_name: true,
      last_name: true,
      rank_short: true,
      contact_phone_number: true,
      contact_phone_extension: true,
    };
  }
  return {
    badge_number:
      typeof user?.badge_number === "string" && user.badge_number.length > 0,
    email:
      typeof user?.email === "string" &&
      user.email.length > 0 &&
      user.email.includes("@"),
    first_name:
      typeof user?.first_name === "string" && user.first_name.length > 0,
    last_name: typeof user?.last_name === "string" && user.last_name.length > 0,
    rank_short:
      typeof user?.rank_short === "string" && user.rank_short.length > 0,
    contact_phone_number: user?.contact_phone_number
      ? typeof user?.contact_phone_number === "string" &&
        (user.contact_phone_number.length === 0 ||
          (user.contact_phone_number.length === 10 &&
            !isNaN(Number(user.contact_phone_number))))
      : false,
    contact_phone_extension: user?.contact_phone_extension
      ? typeof user?.contact_phone_extension === "string" &&
        (user.contact_phone_extension.length === 0 ||
          (user.contact_phone_extension.length > 0 &&
            !isNaN(Number(user.contact_phone_extension))))
      : true,
  };
};

export const fieldLabels: {
  [keyof in keyof Omit<IOrgUser, "id">]: string;
} = {
  first_name: "First Name",
  last_name: "Last Name",
  email: "Email",
  contact_phone_number: "Telephone",
  contact_phone_extension: "Ext",
  badge_number: "Badge Number",
  rank_short: "Rank",
};

const generateEmailBodyRequestAppTrial = ({ appName }: { appName: string }) => {
  const text = `Dear Rubicon Team, \n\nI'm interested in exploring Rubicon's other apps and request a trial for ${appName}.\n\nThank you, \n[Your Name]\n[Your Agency]\n[Your Contact Information]\n`;

  return encodeURIComponent(text);
};

const PermissionSwitchWithText = ({
  permissionState,
  permissionLabel,
  onClick,
  disabled,
  orgHasAppAccess,
}: {
  permissionState: boolean;
  permissionLabel: string;
  onClick: () => void;
  disabled?: boolean;
  orgHasAppAccess?: boolean;
}) => {
  const emailBody = generateEmailBodyRequestAppTrial({
    appName: permissionLabel,
  });
  const mailtoLink = `mailto:support@tryrubicon.com?subject=Request for Trial Subscription to ${permissionLabel}&body=${emailBody}`;

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        margin: "4px 0",
      }}
    >
      <FormControlLabel
        control={
          <Switch
            checked={permissionState}
            onChange={onClick}
            color="primary"
            disabled={
              disabled ||
              (orgHasAppAccess === false && !permissionState) ||
              (orgHasAppAccess === false && permissionState)
            }
          />
        }
        label={permissionLabel}
        labelPlacement="end"
        style={{
          marginRight: "auto",
        }}
      />
      {orgHasAppAccess === false && (
        <Typography
          variant="body2"
          style={{
            color: "gray",
            marginLeft: "auto",
            alignItems: "center",
            fontSize: "0.8rem",
          }}
        >
          Your service does not have an active subscription to this application.
          <br />
          Reach out to <a href={mailtoLink}>Rubicon</a> to request a trial{" "}
        </Typography>
      )}
    </div>
  );
};

// const ConfirmationModal = ({
//   isModalOpenArg,
//   setIsModalOpenArg,
//   updateAccess,
//   accessMessage,
// }: {
//   isModalOpenArg: boolean;
//   setIsModalOpenArg: (arg: boolean) => void;
//   updateAccess: () => void;
//   accessMessage: string;
// }) => {
//   return (
//     <RubiModal
//       isModalOpenArg={isModalOpenArg}
//       setIsModalOpenArg={setIsModalOpenArg}
//     >
//       <div
//         style={{
//           padding: "1rem",
//         }}
//       >
//         <Typography variant="h6">Confirmation</Typography>
//         <Typography variant="body1">
//           Are you sure you want to update access to this application? <br />
//           <br />
//           The user will be able to: <br />
//           <Typography
//             variant="body2"
//             style={{
//               fontWeight: "bold",
//             }}
//           >
//             {accessMessage}
//           </Typography>
//         </Typography>
//         <div
//           style={{
//             display: "flex",
//             marginTop: "1rem",
//             flexDirection: "row",
//             justifyContent: "flex-end",
//           }}
//         >
//           <Button
//             variant="contained"
//             color="primary"
//             onClick={() => {
//               setIsModalOpenArg(false);
//             }}
//             style={{ marginRight: "1rem" }}
//           >
//             Cancel
//           </Button>
//           <Button
//             variant="outlined"
//             color="primary"
//             onClick={() => {
//               updateAccess();
//               setIsModalOpenArg(false);
//             }}
//           >
//             Confirm
//           </Button>
//         </div>
//       </div>
//     </RubiModal>
//   );
// };

export const generateInformationGrid = (
  orgUser: IOrgUser,
  setOrgUser: (user: IOrgUser) => void,
  mode: "view" | "edit" | "add",
  fieldValidationGrid: {
    [key in keyof Omit<IOrgUser, "id">]: boolean;
  } = initValidationGrid,
  setOrgUserPermissions: (userPermissions: Permission[]) => void,
  setInitOrgUserPermissions: (userPermissions: Permission[]) => void,
  orgUserPermissions: Permission[],
  currentUserPermissions: Permission[],
  orgId: string,
  modifyingOwnProfile: boolean,
) => {
  const { isDevEnv } = useDevEnvironment();

  const permissionChecks = userPermissionChecks(currentUserPermissions);

  useEffect(() => {
    if (orgUser.id) {
      apiReportClient
        .getUserPermissions(orgUser.id)
        .then((res) => {
          const userPermissions = res.permissions.map(
            (permission) => permission.title,
          );
          setOrgUserPermissions(userPermissions);
          setInitOrgUserPermissions(userPermissions);
        })
        .catch(console.error);
    }
  }, [orgUser.id]);

  const renderField = (key, label, isSmallField, isOptional, isDisabled) => (
    <Grid item sm={isSmallField ? 3 : 6} key={key}>
      <ViewOrEditTextField
        label={label}
        value={orgUser[key] ?? ""}
        mode={mode}
        error={!fieldValidationGrid[key]}
        onChange={(e) => setOrgUser({ ...orgUser, [key]: e.target.value })}
        required={!isOptional}
        disabled={isDisabled}
      />
    </Grid>
  );

  const renderFields = () => (
    <Grid container spacing={2}>
      {Object.entries(fieldLabels).map(([key, label]) => {
        const isSmallField = [
          "contact_phone_number",
          "contact_phone_extension",
        ].includes(key);
        let isOptional = ["contact_phone_extension"].includes(key);
        const isDisabled = ["email"].includes(key) && mode === "edit";

        // Only email is mandatory for adding a new user or editing a user as an admin
        if (mode === "add" || (mode === "edit" && !modifyingOwnProfile)) {
          isOptional = !(key === "email");
        }
        return renderField(
          key,
          label,
          isSmallField,
          isOptional,
          isDisabled ||
            (mode === "edit" &&
              !(permissionChecks.canModifyUsers || modifyingOwnProfile)),
        );
      })}
    </Grid>
  );

  const { ALERT, REPORT, CAMERA_REGISTRY, RUBI_ORG_OWN_ADD_USER, REPORT_V2 } =
    Permission;
  const renderPermissionsSection = (sectionType: "app" | "admin") => {
    const permissionsConfig = {
      app: [
        {
          permission: ALERT,
          label: "RubiALERT - Bulletin Management System",
        },
        {
          permission: REPORT,
          label: "RubiREPORT - Online Reporting System",
        },
        {
          permission: REPORT_V2,
          label: "RubiREPORT V2 - Online Reporting System",
        },
        {
          permission: CAMERA_REGISTRY,
          label: "RubiREGISTRY - Camera Registry System",
        },
      ],
      admin: [
        {
          permission: RUBI_ORG_OWN_ADD_USER,
          label:
            "Administrator - Can add and remove users and configure their access",
        },
      ],
    };

    const organizationConfig = getServiceConfig(orgId ?? "NA", isDevEnv);

    const { apps: organizationApps } = organizationConfig;

    const permissionsToRender = permissionsConfig[sectionType];

    const userHasPersmissionToUpdatePermissions =
      sectionType === "app"
        ? permissionChecks.canManageApps
        : permissionChecks.canManageAdmins;

    return (
      <Grid>
        <Typography variant="h6">
          {sectionType === "app"
            ? "Application Access and Permissions"
            : "Admin Access and Permissions"}
        </Typography>
        {permissionsToRender.map(({ permission, label }) => (
          <PermissionSwitchWithText
            key={permission}
            permissionState={orgUserPermissions.includes(permission)}
            permissionLabel={label}
            onClick={() => {
              if (orgUserPermissions.includes(permission)) {
                setOrgUserPermissions(
                  orgUserPermissions.filter((p) => p !== permission),
                );
              } else {
                setOrgUserPermissions([...orgUserPermissions, permission]);
              }
            }}
            disabled={
              modifyingOwnProfile ||
              mode === "view" ||
              (!userHasPersmissionToUpdatePermissions &&
                !orgUserPermissions.includes(permission)) ||
              (sectionType === "admin" &&
                orgUserPermissions.includes(RUBI_ORG_OWN_ADD_USER))
            }
            orgHasAppAccess={
              sectionType === "app"
                ? organizationApps?.includes(permission)
                : true
            }
          />
        ))}
      </Grid>
    );
  };

  return (
    <div style={{ marginBottom: "1rem" }}>
      {renderFields()}
      {mode && renderPermissionsSection("app")}
      {mode && renderPermissionsSection("admin")}
    </div>
  );
};

export const BackToUsersListButton = (orgId: string) => {
  const navigate = useNavigate();
  return (
    <Button
      variant="contained"
      onClick={() => {
        navigate(`/organizations/${orgId}/users`);
      }}
      startIcon={<ArrowBack />}
    >
      Back to Users List
    </Button>
  );
};

export const DeactivateUserButton = ({
  userData,
  userHasLoginPermission,
  adminHasEditPermission,
}: {
  userData: IOrgUser;
  userHasLoginPermission: boolean;
  adminHasEditPermission: boolean;
}) => {
  const [deactivateUserDialogOpen, setDeactivateUserDialogOpen] =
    useState(false);

  const navigate = useNavigate();

  const deactivateUser = (userId) => {
    apiReportClient
      .deactivateOrgUser(userId)
      .then((response) => {
        toast.success("User deactivated successfully");
      })
      .catch((error) => {
        toast.error(`Error deactivating user: ${error}`);
      });
  };

  const DeactivateUserModal = () => {
    return (
      <RubiModal
        isModalOpenArg={deactivateUserDialogOpen}
        setIsModalOpenArg={setDeactivateUserDialogOpen}
      >
        <div
          style={{
            padding: "1rem",
          }}
        >
          <Typography variant="h6">Deactivate User</Typography>
          <Typography variant="body1">
            Are you sure you want to deactivate this user? They will no longer
            be able to log in to the system.
          </Typography>
          <div
            style={{
              display: "flex",
              marginTop: "1rem",
              flexDirection: "row",
              justifyContent: "flex-end",
            }}
          >
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                setDeactivateUserDialogOpen(false);
              }}
              style={{ marginRight: "1rem" }}
            >
              Cancel
            </Button>
            <Button
              variant="outlined"
              color="primary"
              onClick={() => {
                deactivateUser(userData?.id);
                setDeactivateUserDialogOpen(false);
                navigate(`/organizations/${userData.rubi_org_id}/users`);
              }}
            >
              Deactivate
            </Button>
          </div>
        </div>
      </RubiModal>
    );
  };

  if (!adminHasEditPermission) {
    return null;
  }

  return (
    <>
      <Button
        startIcon={<Block />}
        variant="outlined"
        color="primary"
        size="medium"
        onClick={() => setDeactivateUserDialogOpen(true)}
        disabled={!userHasLoginPermission}
      >
        Deactivate
      </Button>
      <DeactivateUserModal />
    </>
  );
};
