import React from "react";
import { TextInput, SimpleForm, required } from "react-admin";
import "../../../common/common.css";
import { toast } from "react-toastify";

import { FormToolbar } from "../../../common/FormToolbar";
import { apiReportClient } from "../../../common/api-client";
import styles from "../report.module.css";

const RejectReport = (props) => {
  const handleSubmit = async (formdata) => {
    apiReportClient
      .rejectReport(props.id, formdata.rejectNote)
      .then(() => {
        toast.success("Report is Rejected.");
        props.setStatus("REJECTED");
        props.onClose();
      })
      .catch(() => {
        //Error proccessed in http.js
      });
  };

  return (
    <React.Fragment>
      <h3 className={styles.formHeader}>Reject Report</h3>
      <div className={styles.formNote}>
        Please enter the reason for rejecting this report (e.g. more information
        is required). An email will be sent to the complainant to address the
        rejection reason.
      </div>
      <SimpleForm
        toolbar={
          <FormToolbar
            {...props}
            showDelete={false}
            showCancel={true}
            saveLabel="Reject Report"
            onCancel={props.onClose}
            onSave={handleSubmit}
          />
        }
        onSubmit={handleSubmit}
      >
        <TextInput
          validate={[required()]}
          multiline
          variant="outlined"
          source="rejectNote"
          label="Reject Note (max. 2000 characters)"
          fullWidth
          formClassName="richtexteditor_input"
          minRows={5}
          inputProps={{ maxLength: 2000 }}
        />
        {/* <RichTextInput source="rejectNote" label="Reject Note" formClassName={classes.richtexteditor_input} /> */}
      </SimpleForm>
    </React.Fragment>
  );
};
export default RejectReport;
