import { useState } from "react";

import { AddressField } from "../fields/AddressField";
import { ReportDateField } from "../fields/ReportDateField";
import { ReportField } from "../fields/ReportField";
import { ShowHideButton } from "../fields/UI/ShowHideButton";
import styles from "../report.module.css";

export const TrafficComplaint = (props) => {
  const { data } = props;
  const [show, setShow] = useState(false);
  return (
    <>
      {data.info && data.info.trafficComplaint && (
        <div className="trafficComplaint">
          <ReportDateField
            showtime={true}
            className="report-field-row"
            value={data.info.trafficComplaint.startTime}
            label="Start Date/Time of Incident"
          />
          <ReportDateField
            showtime={true}
            className="report-field-row"
            value={data.info.trafficComplaint.endTime}
            label="End Date/Time of Incident"
          />
          <ReportField
            className="report-field-row"
            value={data.info.trafficComplaint.locationType}
            label="Location Type"
          />
          <AddressField
            label="Incident Location"
            address={data.info.trafficComplaint.streetAddress}
            onEditAddress={() => {
              props.onEditAddress(
                data.info.trafficComplaint.streetAddress,
                "info.trafficComplaint.streetAddress",
              );
            }}
          />
          {data.info.trafficComplaint.narrative && (
            <div className={styles.subHeading}>Narrative</div>
          )}
          <ReportField
            className={styles.narrativeField + " report-field-row"}
            value={data.info.trafficComplaint.narrative}
            label="Incident Description"
          />

          {data.info.trafficComplaint && data.info.trafficComplaint.vehicle && (
            <div className={styles.subHeading}>
              Vehicle Information
              <ShowHideButton
                onClick={() => {
                  setShow(!show);
                }}
              >
                {" "}
                {show ? "Hide " : "Show "} empty fields{" "}
              </ShowHideButton>
            </div>
          )}

          {data.info.trafficComplaint && data.info.trafficComplaint.vehicle && (
            <>
              <ReportField
                className="report-field-row"
                value={data.info.trafficComplaint.vehicle.involvementType}
                label="Type"
              />
              {data.info.trafficComplaint.vehicle.maker || show ? (
                <ReportField
                  className="report-field-row"
                  value={data.info.trafficComplaint.vehicle.maker}
                  label="Make"
                />
              ) : null}
              {data.info.trafficComplaint.vehicle.model || show ? (
                <ReportField
                  className="report-field-row"
                  value={data.info.trafficComplaint.vehicle.model}
                  label="Model"
                />
              ) : null}
              {data.info.trafficComplaint.vehicle.style || show ? (
                <ReportField
                  className="report-field-row"
                  value={data.info.trafficComplaint.vehicle.style}
                  label="Style"
                />
              ) : null}
              {data.info.trafficComplaint.vehicle.year || show ? (
                <ReportField
                  className="report-field-row"
                  value={data.info.trafficComplaint.vehicle.year}
                  label="Year"
                />
              ) : null}
              {data.info.trafficComplaint.vehicle.colours || show ? (
                <ReportField
                  className="report-field-row"
                  value={data.info.trafficComplaint.vehicle.colours}
                  label="Colour"
                />
              ) : null}
              {data.info.trafficComplaint.vehicle.licenseType || show ? (
                <ReportField
                  className="report-field-row"
                  value={data.info.trafficComplaint.vehicle.licenseType}
                  label="License Type"
                />
              ) : null}
              {data.info.trafficComplaint.vehicle.licensePlateNo || show ? (
                <ReportField
                  className="report-field-row"
                  value={data.info.trafficComplaint.vehicle.licensePlateNo}
                  label="License Plate No"
                />
              ) : null}
              {data.info.trafficComplaint.vehicle.licenseLocation || show ? (
                <ReportField
                  className="report-field-row"
                  value={data.info.trafficComplaint.vehicle.licenseLocation}
                  label="License Province"
                />
              ) : null}
              <ReportField
                className="report-field-row"
                value={data.info.trafficComplaint.vehicle.vehicleDescription}
                label="Vehicle Description"
              />
            </>
          )}
        </div>
      )}
    </>
  );
};
