// External imports
import { SearchOutlined } from "@mui/icons-material";
import {
  Autocomplete,
  Box,
  Grid,
  InputBase,
  LinearProgress,
  Pagination,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import { alpha, styled } from "@mui/material/styles";
import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router";
import { toast } from "react-toastify";

import EmailTemplateConfig from "./EmailTemplateConfig";
import styles from "./ReportV2Settings.module.css";
import { OnlineReportingContext } from "./context";
import { OrgConfigModel } from "../../common/api-client/or-api";
import { API } from "../../common/api-client/or-api/api";
import { OrgConfigNotifyItem } from "../../common/api-client/or-api/models/OrgConfigNotifyItem";
import { validateEmail } from "../../common/utils";

interface IAddEmailOption {
  inputValue: string;
  label: string;
}

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.black, 0.05),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.black, 0.1),
  },
  marginRight: theme.spacing(2),
  marginBottom: theme.spacing(2),
  width: "100%",
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  width: "100%",
  padding: theme.spacing(1, 1, 1, 0),
  paddingLeft: `calc(1em + ${theme.spacing(4)})`,
}));

const ReportV2Settings = () => {
  const [serviceConfig, setServiceConfig] = useState<OrgConfigModel | null>(
    null,
  );
  const { reportConfigurations, fetchReportConfigurations } = useContext(
    OnlineReportingContext,
  );
  const { orgId } = useParams();
  const [searchTerm, setSearchTerm] = useState("");
  const [page, setPage] = useState(1);
  const itemsPerPage = 5;

  useEffect(() => {
    async function getConfig() {
      const response = await API.orgConfig.get1(orgId ?? "");
      setServiceConfig(response);
    }
    getConfig();
  }, [orgId]);

  useEffect(() => {
    fetchReportConfigurations(orgId ?? "");
  }, [orgId]);

  useEffect(() => {
    if (serviceConfig && reportConfigurations) {
      const existingKeys = serviceConfig.notify?.notify_items.map(
        (item) => item.key,
      );

      const newNotifyItems = reportConfigurations
        .filter(
          (config) =>
            !existingKeys?.includes(config.survey_class) && !config.hidden,
        )
        .map((config) => ({
          key: config.survey_class,
          emails: [],
        }));

      if (newNotifyItems.length > 0) {
        const updatedNotifyItems = [
          ...(serviceConfig.notify?.notify_items || []),
          ...newNotifyItems,
        ];

        setServiceConfig((prev) => ({
          ...prev!,
          notify: { notify_items: updatedNotifyItems },
        }));
      }
    }
  }, [serviceConfig, reportConfigurations]);

  const handleEmailChange = async (
    key: string,
    newValues: (string | IAddEmailOption)[],
  ) => {
    if (!serviceConfig) return;

    const updatedNotifyItems = serviceConfig.notify?.notify_items.map(
      (item) => {
        if (item.key === key) {
          const emails = newValues.map((v) =>
            typeof v === "string" ? v : v.inputValue,
          );

          if (!emails.every((email) => validateEmail(email))) {
            toast.error("Invalid email format!");
            return item;
          }

          return { ...item, emails };
        }
        return item;
      },
    );

    if (updatedNotifyItems) {
      const newConfig = await API.orgConfig.patch(orgId ?? "", {
        notify: { notify_items: updatedNotifyItems },
      });
      setServiceConfig(newConfig);
    }
  };

  const filteredNotifyItems = serviceConfig?.notify?.notify_items.filter(
    (item) =>
      item.key.toLowerCase().includes(searchTerm.toLowerCase()) ||
      reportConfigurations
        ?.find((config) => config.survey_class === item.key)
        ?.name.toLowerCase()
        .includes(searchTerm.toLowerCase()),
  );

  const paginatedItems = filteredNotifyItems?.slice(
    (page - 1) * itemsPerPage,
    page * itemsPerPage,
  );

  const renderNotifyItem = (item: OrgConfigNotifyItem) => {
    const config = reportConfigurations?.find(
      (config) => config.survey_class === item.key,
    );

    return (
      <Box
        key={item.key}
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "flex-start",
          width: "100%",
          padding: "4px",
        }}
      >
        <Grid
          item
          xs={3}
          sx={{
            display: "flex",
            alignItems: "center",
            width: "30%",
            marginTop: "8px",
            marginRight: "8px",
          }}
        >
          <Typography
            variant="body2"
            sx={{
              fontWeight: "bold",
              width: "100%",
              textOverflow: "ellipsis",
            }}
          >
            {config?.name || (item.key === "all" && "All Reports") || item.key}
          </Typography>
        </Grid>
        <Grid
          item
          xs={9}
          sx={{
            width: "100%",
          }}
        >
          <Autocomplete
            multiple
            freeSolo
            options={[]}
            id={`email-autocomplete-${item.key}`}
            value={item.emails}
            onChange={(event, newValues) =>
              handleEmailChange(item.key, newValues)
            }
            sx={{ width: "100%" }}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                size="small"
                label="Emails"
                placeholder="Add email..."
                sx={{ width: "100%" }}
              />
            )}
          />
        </Grid>
      </Box>
    );
  };

  return (
    <div className={styles.formContainer}>
      <Paper elevation={3} className={styles.sectionContainer}>
        <Typography variant="h6">
          <b>Notification Settings</b>
        </Typography>

        <Typography variant="body2" gutterBottom>
          Manage who should receive email notifications for different report
          submissions.
        </Typography>

        <Search>
          <SearchIconWrapper>
            <SearchOutlined />
          </SearchIconWrapper>
          <StyledInputBase
            placeholder="Filter by report name..."
            inputProps={{ "aria-label": "search" }}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </Search>

        {!serviceConfig ? (
          <LinearProgress sx={{ width: "100%" }} />
        ) : (
          <Box sx={{ width: "100%" }}>
            {paginatedItems?.map(renderNotifyItem)}
            <Pagination
              count={Math.ceil(
                (filteredNotifyItems?.length || 0) / itemsPerPage,
              )}
              page={page}
              onChange={(event, value) => setPage(value)}
              sx={{ marginTop: 2, display: "flex", justifyContent: "center" }}
            />
          </Box>
        )}
      </Paper>

      <Paper elevation={3} className={styles.sectionContainer}>
        <EmailTemplateConfig />
      </Paper>
    </div>
  );
};

export default ReportV2Settings;
