import { Visibility, VisibilityOff } from "@mui/icons-material";
import { IconButton, InputAdornment } from "@mui/material";
import { TextInput } from "react-admin";

interface PasswordFieldProps {
  showPassword: boolean;
  setShowPassword: (value: boolean) => void;
  source: string;
  label: string;
  fullWidth: boolean;
  validate: any;
  onChange?: (value: string) => void;
}
const PasswordField = ({ ...props }: PasswordFieldProps) => {
  return (
    <TextInput
      source={props.source}
      label={props.label}
      type={props.showPassword ? "text" : "password"}
      fullWidth={props.fullWidth}
      variant="outlined"
      validate={props.validate}
      onChange={(event) => {
        if (event.target.value !== null && props.onChange)
          props.onChange(event.target.value);
      }}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={() => props.setShowPassword(!props.showPassword)}
            >
              {props.showPassword ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
};

export default PasswordField;
