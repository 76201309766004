import { AddressField } from "../fields/AddressField";
import { ReportDateField } from "../fields/ReportDateField";
import { ReportField } from "../fields/ReportField";
import { TheftFromVehiclePropertyField } from "../fields/TheftFromVehiclePropertyField";
import styles from "../report.module.css";

export const DamageToProperty = (props) => {
  const { data } = props;

  return (
    <>
      {data.info && data.info.damageToProperty && (
        <div className="theftfromVehicle">
          <ReportDateField
            showtime={true}
            className="report-field-row"
            value={data.info.damageToProperty.startTime}
            label="Start Date/Time of Incident"
          />
          <ReportDateField
            showtime={true}
            className="report-field-row"
            value={data.info.damageToProperty.endTime}
            label="End Date/Time of Incident"
          />
          <ReportField
            className="report-field-row"
            value={data.info.damageToProperty.locationType}
            label="Location Type"
          />
          <ReportField
            className="report-field-row"
            value={data.info.damageToProperty.graffitiInvolved}
            label="Graffiti Involved"
          />
          <ReportField
            className="report-field-row"
            value={data.info.damageToProperty.havePropertyInfo}
            label="Have Property Info"
          />
          <AddressField
            label="Incident Location"
            address={data.info.damageToProperty.streetAddress}
            onEditAddress={() => {
              props.onEditAddress(
                data.info.damageToProperty.streetAddress,
                "info.damageToProperty.streetAddress",
              );
            }}
          />
          {data.info.damageToProperty.narrative && (
            <div className={styles.subHeading}>Narrative</div>
          )}
          {data.info.damageToProperty.narrative || props.clickState ? (
            <ReportField
              className={styles.narrativeField + " report-field-row"}
              value={data.info.damageToProperty.narrative}
              label="Incident Description"
            />
          ) : null}

          {data.info &&
            data.info.damageToProperty &&
            data.info.damageToProperty.property && (
              <div className={styles.subHeading}>Property Information</div>
            )}
          {data.info &&
            data.info.damageToProperty &&
            data.info.damageToProperty.property && (
              <TheftFromVehiclePropertyField
                data={data.info.damageToProperty.property}
              />
            )}
        </div>
      )}
    </>
  );
};
