import React, { createContext, useEffect, useState } from "react";
import { useLocation } from "react-router";
import { useSearchParams } from "react-router-dom";

import { API, updateToken } from "../../../../common/api-client/camera-api/api";
import {
  IPolice,
  IProperty,
  ICameras,
  IContacts,
  CameraRegistryState,
} from "../../../../types";

// Define the shape of your context state

const serverUrl = import.meta.env.VITE_CAMERA_SERVER_URL;

const policeVariablesMap: { [key: string]: IPolice } = {
  "cobourg-police-service": {
    fullName: "Cobourg Police Service",
    shortName: "CPS",
    id: serverUrl?.includes("test.cam")
      ? "58f803ee-3228-43ee-9814-74a3a108574e"
      : "01b95fa3-85ae-48df-b1fd-3175181ccef4",
    logoSrc: "/Cobourg-Police-Service-Logo.png",
    url: "https://cobourgpoliceservice.com",
    onlineReportingUrl: "https://cobourgpolice.report.tryrubicon.com/",
    mapCenter: { lat: 43.9593, lng: -78.1657 },
    email: "info@cobourgpolice.com",
  },
  Rubicon: {
    fullName: "Rubicon Public Safety",
    shortName: "Rubicon",
    id: "d2d3adab-3920-4ce7-bf94-26ee49d2a52a",
    logoSrc: "/Rubicon Logo.png",
    url: "https://tryrubicon.com",
    onlineReportingUrl: "https://www.tryrubicon.com/rubicon-rubireport",
    mapCenter: { lat: 43.6532, lng: -79.3832 },
    email: "support@tryrubicon.com",
  },
};

const initProperty: IProperty = {
  googleMapsPlace: null,
  type: "",
  businessName: "",
  additionalPropertyDescription: "",
};

const initCameras: ICameras = {
  cameras: [],
  installedBy: "",
  additionalCamerasDescription: "",
};

const initContact = {
  firstName: "",
  lastName: "",
  email: "",
  phoneNumber: "",
  secondaryPhoneNumber: "",
  notes: "",
};

const initContacts: IContacts = {
  contacts: [
    {
      ...initContact,
    },
  ],
};

// Create the context
export const CameraRegistryContext = createContext<
  Partial<CameraRegistryState>
>({});

const CameraRegistryProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  // Initialize your state variables with appropriate initial values
  const subdomain = window.location.hostname.split(".")[0];
  const policePath = window.location.pathname.split("/")[1];

  const [env, setEnv] = useState<"test" | "prod">(
    subdomain === "test" || subdomain === "localhost" ? "test" : "prod",
  );

  const [recaptchaToken, setRecaptchaToken] = useState<string | null>(null);
  const [modificationToken, setModificationToken] = useState<string | null>(
    null,
  );

  const [police, setPolice] = useState<IPolice | null>(() => {
    if (policeVariablesMap.hasOwnProperty(policePath)) {
      return policeVariablesMap[policePath];
    }
    return policeVariablesMap["cobourg-police-service"];
  });

  //TODO initialization would change if modification_token is present
  const [property, setProperty] = useState<IProperty>({ ...initProperty });
  const [cameras, setCameras] = useState<ICameras>({ ...initCameras });
  const [mediaAttachments, setMediaAttachments] = useState<{}[]>([]);
  const [contacts, setContacts] = useState<IContacts>({ ...initContacts });

  const location = useLocation();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    async function startProperty() {
      const newProperty = await API.property.putPortal({});
      setProperty({
        id: newProperty.property.id,
        googleMapsPlace: newProperty.property.place_details ?? null,
        type: newProperty.property.location_type ?? "",
        businessName: newProperty.property.installed_by ?? "",
        additionalPropertyDescription:
          newProperty.property.additional_description ?? "",
      });
    }
    if (
      property.id === undefined &&
      location.pathname.includes("add-camera") &&
      !location.search.includes("propertyID=")
    ) {
      startProperty();
    }
  }, [property.id]);

  useEffect(() => {
    if (searchParams.get("propertyID")) {
      const propertyID = searchParams.get("propertyID");
      handleModify(propertyID ?? "");
    }
  }, []);

  const handleModify = async (selectedPropertyID: string) => {
    const property = await API.property.getPortal(selectedPropertyID);
    console.log(property);

    if (setProperty && setCameras && setContacts && property) {
      clearForm && clearForm();

      setProperty({
        id: property.property.id,
        googleMapsPlace: property.property.place_details ?? null,
        type: property.property.location_type ?? "",
        businessName: property.property.business_name ?? "",
        additionalPropertyDescription:
          property.property.additional_description ?? "",
      });
      setCameras({
        cameras: property.cameras.map((camera) => ({
          locationDescription: camera.camera.location_description ?? "",
          FOVDescription: camera.camera.field_of_view_description ?? "",
          Make: camera.camera.make ?? "",
          Model: camera.camera.model ?? "",
          geometry: {
            lat: camera.camera.latitude,
            lng: camera.camera.longitude,
          },
          id: camera.camera.id,
        })),
        additionalCamerasDescription:
          property.property.additional_description_cameras ?? "",
        installedBy: property.property.installed_by ?? "",
      });
      setContacts({
        contacts: property.contacts.map((contact) => ({
          firstName: contact.first_name ?? "",
          lastName: contact.last_name ?? "",
          email: contact.email ?? "",
          phoneNumber: contact.phone_number ?? "",
          secondaryPhoneNumber: contact.secondary_phone_number ?? "",
          notes: contact.notes ?? "",
          id: contact.id,
        })),
      });
    }
  };

  const clearCameras = () => {
    setCameras({ ...initCameras, cameras: [] });
  };

  const clearForm = () => {
    console.log("CLEARING FORM");
    setContacts({ contacts: [{ ...initContact }] });
    setCameras({ ...initCameras, cameras: [] });
    setProperty({ ...initProperty });
  };

  const setCameraField = (field: string, value: any, index: number) => {
    console.log("setCameraField", field, value, index);
    const newCameras = { ...cameras };

    if (index >= newCameras.cameras.length) {
      newCameras.cameras.push({
        locationDescription: "",
        FOVDescription: "",
        Make: "",
        Model: "",
        geometry: { lat: 0, lng: 0 },
      });
    }
    const camera = { ...newCameras.cameras[index], [field]: value };
    newCameras.cameras[index] = camera;
    console.log("newCameras", newCameras);
    setCameras(newCameras);
  };

  const updateCameras = (data: ICameras) => {
    const newCameras = { ...cameras };
    newCameras.installedBy = data.installedBy;
    newCameras.additionalCamerasDescription = data.additionalCamerasDescription;

    const newCameraList = data.cameras.map((camera, index) => {
      return {
        locationDescription: camera.locationDescription,
        FOVDescription: camera.FOVDescription,
        Make: camera.Make,
        Model: camera.Model,
        geometry: camera.geometry,
        id: cameras.cameras[index]?.id,
      };
    });
    newCameras.cameras = newCameraList;
    setCameras(newCameras);
    return newCameras;
  };

  const deleteCamera = (index: number) => {
    const newCameras = { ...cameras };
    newCameras.cameras.splice(index, 1);
    setCameras(newCameras);
  };

  const setContactField = (field: string, value: any, index: number) => {
    const newContacts = { ...contacts };

    if (index >= newContacts.contacts.length) {
      newContacts.contacts.push({
        ...initContact,
      });
    }
    const contact = { ...newContacts.contacts[index], [field]: value };
    newContacts.contacts[index] = contact;
    console.log("newContacts", newContacts);
    setContacts(newContacts);
  };

  const updateContacts = (data: IContacts) => {
    const newContacts = { ...contacts };

    const newContactsList = data.contacts.map((contact, index) => {
      return {
        firstName: contact.firstName,
        lastName: contact.lastName,
        email: contact.email,
        phoneNumber: contact.phoneNumber,
        secondaryPhoneNumber: contact.secondaryPhoneNumber,
        notes: contact.notes,
        id: contacts.contacts[index]?.id,
      };
    });
    newContacts.contacts = newContactsList;
    setContacts(newContacts);
    return newContacts;
  };

  const deleteContact = (index: number) => {
    const newContacts = { ...contacts };
    newContacts.contacts.splice(index, 1);
    setContacts(newContacts);
  };

  // You can add effect hooks here to fetch data from an API if needed

  return (
    <CameraRegistryContext.Provider
      value={{
        env,
        recaptchaToken,
        modificationToken,
        police,
        property,
        cameras,
        mediaAttachments,
        contacts,
        // Include the state setters so they can be used to update state from within consuming components
        setEnv,
        setRecaptchaToken,
        setModificationToken,
        setPolice,
        setProperty,
        setCameras,
        setMediaAttachments,
        setContacts,
        // Clear Methods
        clearCameras,
        clearForm,
        setCameraField,
        updateCameras,
        deleteCamera,
        setContactField,
        updateContacts,
        deleteContact,
      }}
    >
      {children}
    </CameraRegistryContext.Provider>
  );
};

export default CameraRegistryProvider;
