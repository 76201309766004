import { Typography } from "@mui/material";
import {
  GoogleMap,
  LoadScriptProps,
  MarkerF,
  StandaloneSearchBox,
  useJsApiLoader,
} from "@react-google-maps/api";
import React, { useState, useRef, useContext, useEffect } from "react";

const GOOGLE_PLACES_KEY = import.meta.env.VITE_GOOGLE_PLACES_KEY;
const mapLibraries: LoadScriptProps["libraries"] = ["places"];
import { ICoordinates, IPlace } from "../../../../types";
import { CameraRegistryContext } from "../context";

const MapSearch: React.FC<any> = ({
  values,
  setFieldValue,
  errors,
  touched,
  handleBlur,
}) => {
  const { police, clearCameras } = useContext(CameraRegistryContext);
  if (!police || !clearCameras) {
    return <></>;
  }

  const defaultAddress = () => {
    return values.googleMapsPlace?.formatted_address
      ? values.googleMapsPlace.formatted_address?.includes(
          values.googleMapsPlace.name ? values.googleMapsPlace.name : "",
        )
        ? values.googleMapsPlace.formatted_address
        : values.googleMapsPlace.name +
          " - " +
          values.googleMapsPlace.formatted_address
      : "";
  };

  const [location, setLocation] = useState<ICoordinates>(
    values.googleMapsPlace?.geometry
      ? values.googleMapsPlace?.geometry
      : police.mapCenter,
  );
  const [zoom, setZoom] = useState(values.googleMapsPlace ? 16 : 12);
  // const [error, setError] = useState<string | null>(null);
  const searchBoxRef = useRef<google.maps.places.SearchBox | null>(null);
  const [addressText, setAddressText] = useState(defaultAddress());

  useEffect(() => {
    setLocation(
      values.googleMapsPlace?.geometry
        ? values.googleMapsPlace?.geometry
        : police.mapCenter,
    );
    setAddressText(defaultAddress());

    if (values.googleMapsPlace?.geometry) {
      setZoom(16);
    }
  }, [values.googleMapsPlace]);

  const mapOptions = {
    streetViewControl: false, // Disable Street View
    tilt: 0,
    // Other map options...
  };

  const searchBoxOptions = {
    south: police.mapCenter.lat - 0.05,
    west: police.mapCenter.lng - 0.05,
    north: police.mapCenter.lat + 0.05,
    east: police.mapCenter.lng + 0.05,
  };

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: GOOGLE_PLACES_KEY,
    libraries: mapLibraries,
  });

  const handlePlacesChanged = () => {
    const places = searchBoxRef.current?.getPlaces();
    console.log("places", places);
    if (places && places.length > 0) {
      const place = places[0];
      console.log("place", place);
      if (place.formatted_address?.includes(place.name ? place.name : ""))
        setAddressText(place.formatted_address);
      else setAddressText(place.name + " - " + place.formatted_address);

      let lat = 0;
      let lng = 0;
      if (place?.geometry?.location) {
        lat = place.geometry?.location.lat();
        lng = place.geometry?.location.lng();
      }

      if (lat && lng) {
        const selectedLocation = { lat, lng };

        const formattedPlace: IPlace = {
          address_components: place.address_components
            ? [...place.address_components]
            : [],
          formatted_address: place.formatted_address
            ? place.formatted_address
            : "",
          geometry: selectedLocation,
          name: place.name ? place.name : "",
          url: place.url ? place.url : "",
          types: place.types ? [...place.types] : [],
          place_id: place.place_id ? place.place_id : "",
        };
        setFieldValue("googleMapsPlace", formattedPlace);
        clearCameras();
        console.log("formattedPlace", formattedPlace);

        // Check if the selected location is within Cobourg city limits
        if (true) {
          console.log("selectedLocation", selectedLocation);
          setLocation(selectedLocation);
          setZoom(16);
          // setError(null);
        } else {
          // setError(
          //   "Address must be within the city limits of Cobourg, Ontario."
          // );
        }
      }
    }
  };

  const onLoadSearchBox = (ref: google.maps.places.SearchBox) => {
    searchBoxRef.current = ref;
  };

  return (
    <>
      {isLoaded ? (
        <div style={{ width: "100%" }}>
          {touched.googleMapsPlace && errors.googleMapsPlace && (
            <Typography sx={{ color: "red" }}>
              {errors.googleMapsPlace}
            </Typography>
          )}

          <StandaloneSearchBox
            onLoad={onLoadSearchBox}
            onPlacesChanged={handlePlacesChanged}
            bounds={searchBoxOptions}
          >
            <input
              value={addressText}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setAddressText(event.target.value);
              }}
              onKeyDown={(e) => {
                e.key === "Enter" && e.preventDefault();
              }}
              type="text"
              name="googleMapsPlace"
              placeholder="Search address *"
              onBlur={handleBlur}
              style={{
                boxSizing: `border-box`,
                border:
                  touched.googleMapsPlace && errors.googleMapsPlace
                    ? `1px solid red`
                    : `1px solid transparent`,
                width: "100%",
                height: `40px`,
                padding: `0 12px`,
                borderRadius: `3px`,
                boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
                fontSize: `14px`,
                marginBottom: "4px",
                outline: `none`,
                textOverflow: `ellipses`,
              }}
            />
          </StandaloneSearchBox>

          <GoogleMap
            center={location}
            zoom={zoom}
            mapContainerStyle={{ height: "412px", width: "100%" }}
            options={mapOptions}
          >
            <MarkerF position={location} />
          </GoogleMap>
          {errors.googleMapsPlace && (
            <div className="error">{errors.googleMapsPlace}</div>
          )}
        </div>
      ) : (
        <div>Map Loading ...</div>
      )}
    </>
  );
};

export default MapSearch;
