import MapIcon from "@mui/icons-material/Map";
import { createElement } from "react";
import ReactDOMServer from "react-dom/server";
import { ElementFactory, Question, Serializer, SvgRegistry } from "survey-core";
import { localization } from "survey-creator-core";
import { PropertyGridEditorCollection } from "survey-creator-react";
import { FlatQuestion, FlatRepository, PdfBrick } from "survey-pdf";
import {
  SurveyQuestionElementBase,
  ReactQuestionFactory,
} from "survey-react-ui";

import MapSearch from "./MapWithSearch";
import { IPlace } from "../types";
import { extractAddress } from "../utils";

const CUSTOM_TYPE = "address-autocomplete";

// A model for the new question type
export class QuestionAutocompleteModel extends Question {
  getType() {
    return CUSTOM_TYPE;
  }
  get autocompleteType() {
    return this.getPropertyValue("autocompleteType");
  }
  set autocompleteType(val) {
    this.setPropertyValue("autocompleteType", val);
  }
}

// Register `QuestionAutocompleteModel` as a model for the `color-picker` type
export function registerAutocomplete() {
  ElementFactory.Instance.registerElement(CUSTOM_TYPE, (name) => {
    return new QuestionAutocompleteModel(name);
  });
}

// Specify display names for the question type and its properties
const locale = localization.getLocale("");
locale.qt[CUSTOM_TYPE] = "Address Autocomplete";
locale.pe.colorPickerType = "Address autocomplete type";

// Register an SVG icon for the question type
const svg = ReactDOMServer.renderToString(<MapIcon />);
SvgRegistry.registerIconFromSvg(CUSTOM_TYPE, svg);

// Add question type metadata for further serialization into JSON
Serializer.addClass(
  CUSTOM_TYPE,
  [
    {
      name: "autocompleteType",
      default: "No Map",
      choices: ["With Map", "No Map"],
      category: "general",
      visibleIndex: 2, // After the Name and Title
    },
  ],
  function () {
    return new QuestionAutocompleteModel("");
  },
  "question",
);

// A class that renders questions of the new type in the UI
export class SurveyQuestionAddressAutocomplete extends SurveyQuestionElementBase {
  constructor(props: any) {
    super(props);
    if (!this.question.value) {
      this.question.value = { googleMapsPlace: {} };
    }
    this.state = { value: this.question.value };
    console.log(this.question.value);
  }
  get question() {
    return this.questionBase;
  }
  get value() {
    return this.question.value;
  }
  get type() {
    return this.question.autocompleteType;
  }

  setFieldValue = (field: string, data: IPlace) => {
    console.log(data);
    this.question.value = { [field]: data };
  };

  // Support the read-only and design modes
  get style() {
    return this.question.getPropertyValue("readOnly") ||
      this.question.isDesignMode
      ? { pointerEvents: "none" }
      : undefined;
  }

  // renderColorPicker(type) {
  //   switch (type) {
  //     case "Slider": {
  //       return (
  //         <SliderPicker color={this.value} onChange={this.handleColorChange} />
  //       );
  //     }
  //     case "Sketch": {
  //       return (
  //         <SketchPicker color={this.value} onChange={this.handleColorChange} disableAlpha={this.disableAlpha} />
  //       );
  //     }
  //     case "Compact": {
  //       return (
  //         <CompactPicker color={this.value} onChange={this.handleColorChange} />
  //       );
  //     }
  //     default:
  //       return <div>Unknown type</div>;
  //   }
  // }

  renderElement() {
    return (
      <div style={this.props.isDisplayMode ? { pointerEvents: "none" } : {}}>
        <MapSearch
          values={this.question.value}
          setFieldValue={this.setFieldValue}
          errors={{}}
          touched={false}
          handleBlur={() => {}}
          showMap={this.type === "With Map"}
          disabled={this.props.isDisplayMode}
        />
      </div>
    );
  }
}

// Register `SurveyQuestionAddressAutocomplete` as a class that renders `color-picker` questions
ReactQuestionFactory.Instance.registerQuestion(CUSTOM_TYPE, (props) => {
  return createElement(SurveyQuestionAddressAutocomplete, props);
});

// Register the `color-picker` as an editor for properties of the `color` type in the Survey Creator's Property Grid
PropertyGridEditorCollection.register({
  fit: function (prop) {
    return prop.type === "color";
  },
  getJSON: function () {
    return {
      type: CUSTOM_TYPE,
      autocompleteType: "No Map",
    };
  },
});

class CustomPdfBrick extends PdfBrick {
  async renderInteractive() {
    const doc = this.controller.doc;
    const value = extractAddress(this.question.value);
    const splitText = doc.splitTextToSize(value, 180);
    doc.text(this.xLeft, this.yTop, splitText);
  }
}

class FlatCustomColor extends FlatQuestion {
  async generateFlatsContent(point: any) {
    const rect = {
      xLeft: point.xLeft,
      yTop: point.yTop + 10,
      xRight: point.xLeft + 20,
      yBot: point.yTop + 20,
    };
    return [new CustomPdfBrick(this.question, this.controller, rect)];
  }
}

FlatRepository.getInstance().register(CUSTOM_TYPE, FlatCustomColor);
