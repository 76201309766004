import { Phone } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import React from "react";

import { formatPhoneNumber, stripPhoneNumberFormatting } from "./utils";

interface PhoneButtonProps {
  phoneNumber: string;
  extension?: string;
}

const PhoneButton: React.FC<PhoneButtonProps> = ({
  phoneNumber,
  extension,
}) => {
  const stripPhoneNumber = stripPhoneNumberFormatting(phoneNumber);
  const telLink =
    extension !== ""
      ? `tel:${stripPhoneNumber},${extension}`
      : `tel:${stripPhoneNumber}`;

  const handleClick = () => {
    window.location.href = telLink;
  };

  return (
    <div style={{ display: "inline-block" }}>
      <IconButton
        onClick={handleClick}
        aria-label="dial phone number"
        size="small"
      >
        <Phone color="secondary" />
      </IconButton>
      <a href={telLink}>
        {formatPhoneNumber(phoneNumber)}
        {extension !== "" && ` Ext. ${extension}`}
      </a>
    </div>
  );
};

export default PhoneButton;
