import { Info, Search } from "@mui/icons-material";
import LinkIcon from "@mui/icons-material/Link";
import {
  Button,
  Chip,
  LinearProgress,
  Link,
  Paper,
  Theme,
  Tooltip,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { Fragment, useContext, useEffect, useState } from "react";
import { useTheme } from "react-admin";
import { useInView } from "react-intersection-observer";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";

import styles from "./DisplayAlert.module.scss";
import DisplayAttachedPDF from "./DisplayAttachedPdf";
import DownloadReportPDF from "./DownloadAlertPDF/DownloadReportPDF";
import { PublishedResolution } from "./ResolveBulletin";
import {
  approvedByText,
  creatorText,
  fetchBlobAndCreateURL,
  getMediaDimensions,
  policeServiceNameImage,
} from "./utils";
import { EmailButton, PhoneButton } from "../../../../common";
import ExpandedMediaDisplay from "../../../../common/ExpandedMediaDisplay";
import LazyLoadVideo from "../../../../common/LazyLoadVideo";
import { CustomSlider, MediaSlide } from "../../../../common/Slider";
import { formatDateLong } from "../../../../common/utils";
import { AuthStateContext } from "../../../../context";
import {
  IBulletinResolution,
  IEvidenceFile,
  IPdfFile,
  ITag,
  MediaBlob,
  PlaceType,
} from "../../../../types/typesAlerts";
import {
  LOCATION_CLASSIFICATION,
  classificationDetails,
} from "../../constants";
import { AlertViewContext, SingleAlertContext } from "../../context";
import { getAddressClassification, getAddressDescription } from "../../utils";

interface DisplayAlertProps {
  mode: "add" | "view" | "parade";
  issuing_police_service: string;
  type: string | null;
  associatedCrimes: string[];
  occurrenceNumbers: string[];
  issuedTime: string | null;
  incidentTime: string;
  description: string | null;
  pdf: IPdfFile | {};
  evidences: { [key: string]: IEvidenceFile };
  view_token?: any; //TODO Create IView_token
}

export const DisplayAlert = ({
  mode,
  issuing_police_service,
  type,
  associatedCrimes = [],
  occurrenceNumbers = [],
  issuedTime,
  incidentTime,
  description,
  pdf,
  evidences = {},
  view_token,
}: DisplayAlertProps) => {
  const { alertProperties } = useContext(SingleAlertContext);
  const { currentUser, setCurrentUser } = useContext(AuthStateContext);
  const { setHighlightAlert } = useContext(AlertViewContext);
  const [searchParams, setSearchParams] = useSearchParams();
  const {
    approvedBy,
    approvedByBadge,
    operationalArea,
    contacts,
    bulletinId: alertId,
    tagDict,
    creator,
    resolutions,
    rawAddresses,
  } = alertProperties;

  const publishedResolutions = resolutions.filter(
    (resolution: IBulletinResolution) => resolution.published,
  );

  const creatorToUse = creator ? creator : currentUser;

  const [attachmentsArray, setAttachmentsArray] = useState(
    Object.values(evidences).sort(
      (a: any, b: any) => a?.sort_ordinal - b?.sort_ordinal,
    ),
  );
  const [attachmentBlobArray, setAttachmentBlobArray] = useState<MediaBlob[]>(
    [],
  );

  const [selectedIndex, setSelectedIndex] = useState(-1);
  const [isSlideShowActive, setIsSlideShowActive] = useState(false);

  const [theme] = useTheme();
  const isSmall = useMediaQuery((theme as Theme).breakpoints.down("sm"));
  const isMedium = useMediaQuery((theme as Theme).breakpoints.down("md"));
  const [mediaLoading, setMediaLoading] = useState(false);

  const { inView, ref } = useInView({
    triggerOnce: true, // This will make sure the observer is triggered once. After loading the DisplayAlert, it won't observe anymore.
    delay: 250,
  });
  const navigate = useNavigate();
  const location = useLocation();
  const isDraftsRoute = location.pathname.includes("/drafts");
  const isEditRoute = location.pathname.includes("/edit");

  useEffect(() => {
    if (selectedIndex === -1) {
      setIsSlideShowActive(false);
      return;
    }
    setIsSlideShowActive(true);
  }, [selectedIndex]);

  useEffect(() => {
    if (inView && Object.keys(evidences).length > 0) {
      const updatedAttachmentsArray = Object.values(evidences).sort(
        (a: any, b: any) => a?.sort_ordinal - b?.sort_ordinal,
      );
      setAttachmentsArray(updatedAttachmentsArray);
      setMediaLoading(updatedAttachmentsArray.length > 0);

      const fetchBlobsAndVideos = async () => {
        const blobs = await Promise.all(
          updatedAttachmentsArray.map(async (attachment: IEvidenceFile) => {
            const url = attachment.content_type.includes("video")
              ? getDownloadUrl(attachment)
              : getThumbnailUrl(attachment);
            const { blob, objectURL } = await fetchBlobAndCreateURL(url);

            const mediaType = attachment.content_type.includes("video")
              ? "video"
              : "image";
            try {
              const dimensions = await getMediaDimensions(mediaType, objectURL);
              return {
                blob,
                filename: attachment?.filename,
                dimensions,
                media_type: mediaType,
              } as MediaBlob;
            } catch (err) {
              console.log("Error getting media dimensions", err);
              return {
                blob,
                filename: attachment?.filename,
                dimensions: { width: 0, height: 0 },
                media_type: mediaType,
              } as MediaBlob;
            }
          }),
        );

        setMediaLoading(false);
        setAttachmentBlobArray(blobs);
      };

      fetchBlobsAndVideos();
    } else if (inView) {
      if (mediaLoading) {
        setMediaLoading(false);
      }
      setAttachmentBlobArray([]);
      setAttachmentsArray([]);
    }
  }, [evidences, inView]);

  const renderContactInformation = (contacts) => {
    return (
      <>
        {contacts
          .filter((contact) => contact.name)
          .map((item, index) => (
            <div
              style={{ whiteSpace: "pre-wrap" }}
              key={item?.email ? item.email : index.toString()}
            >
              {item?.name ? item.name : ""}
              {item?.name && item?.badge_number ? " - " : ""}
              {item?.badge_number ? item.badge_number : ""}
              {item?.name || item?.badge_number ? "     " : ""}
              {item.email !== "" && (
                <EmailButton
                  email={item.email}
                  subject={`Inquiry about ${type} alert - ${occurrenceNumbers.join()}`}
                />
              )}
              {item.phone_number !== "" && (
                <>
                  {" "}
                  <PhoneButton
                    phoneNumber={item.phone_number}
                    extension={item.extension}
                  />
                </>
              )}
              {index < contacts.length - 1 && <br />}
            </div>
          ))}
      </>
    );
  };

  const getDownloadUrl = (attachment: IEvidenceFile) => {
    const url = new URL(attachment?.download_url ?? "");
    if (mode === "view" && view_token)
      url.searchParams.set("access_token", view_token);
    return url.toString();
  };

  const getMediaIndexUrl = (index: number) => {
    if (index === -1) return "";
    if (!attachmentsArray) return "";
    const item = attachmentsArray[index];

    return getDownloadUrl(item);
  };

  const getThumbnailUrl = (attachment) => {
    // const url = new URL((attachment as any)?.download_url ?? "");
    // TODO Use Below once thumbnail with view_token is fixed
    const url = new URL(
      (attachment as any)?.thumbnail_url ?? (attachment as any)?.download_url,
    );
    if (mode === "view" && view_token)
      url.searchParams.set("access_token", view_token);
    return url.toString();
  };

  let policeLogo;
  if (policeServiceNameImage[issuing_police_service])
    policeLogo = policeServiceNameImage[issuing_police_service];
  else policeLogo = policeServiceNameImage["DRDC"];

  const RubiconLogo = () => (
    <div className={styles.logo}>
      <b style={{ fontSize: 12, color: "grey" }}>POWERED BY</b>
      <img
        src="/Rubicon Logo.png"
        alt="Rubicon logo"
        style={{ height: "28px", marginTop: "-8px" }}
        onClick={() => window.open("https://tryRubicon.com", "_blank")}
      />
    </div>
  );

  const [openTooltip, setOpenTooltip] = useState(false);
  const handleTooltipToggle = () => {
    setOpenTooltip(!openTooltip);
  };

  const iconStyle = {
    color: "darkblue",
    cursor: "pointer",
    marginLeft: 1,
    fontSize: isSmall ? 20 : 14,
  };

  const MediaTagDisplay = (
    fileName: string,
    tagDict: { [key: string]: ITag },
  ) => {
    const mediaTags = Object.values(tagDict).filter((tag) =>
      tag?.attachments?.find((name) => fileName === name),
    );

    return (
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          gap: "4px",
        }}
      >
        {mediaTags.map((mediaTag, index) => (
          <Chip
            label={mediaTag?.title}
            color="secondary"
            size="small"
            key={index}
          />
        ))}
      </div>
    );
  };

  const renderMedia = () => {
    const mediaItems = attachmentsArray.map((attachment, index) => (
      <>
        <MediaSlide
          key={`${attachment?.filename}-${index}`}
          mediaSrc={
            attachment.content_type.includes("video")
              ? getDownloadUrl(attachment)
              : getThumbnailUrl(attachment)
          }
          mediaType={
            attachment?.content_type.includes("video") ? "video" : "image"
          }
          description={
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "4px",
                padding: "4px",
                alignItems: "center",
              }}
            >
              {MediaTagDisplay((attachment as any).filename, tagDict)}
            </div>
          }
          onClick={() => setSelectedIndex(index)}
          descriptionStyle={{
            height: "auto",
            overflow: "hidden",
          }}
        />
      </>
    ));

    const renderSlider = () => {
      if (attachmentsArray.length === 0) {
        return null;
      }

      return (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            maxWidth: "var(--page-max-width)",
            margin: "auto",
          }}
        >
          <CustomSlider items={mediaItems} />
        </div>
      );
    };
    if (mediaLoading) {
      return (
        <div className={styles.progress_container}>
          <Typography> Loading Images/Videos...</Typography>
          <LinearProgress />
        </div>
      );
    } else
      return (
        <>
          {/* <div className={styles.imagesContainer}>
            {attachmentsArray.map((attachment, index) => (
              <AlertMediaDisplay
                key={getThumbnailUrl(attachment)}
                url={getThumbnailUrl(attachment)}
                index={index}
                setSelectedIndex={setSelectedIndex}
                fileName={(attachment as any).filename}
                mediaType={
                  attachment?.content_type.includes("video") ? "video" : "image"
                }
              />
            ))}
          </div> */}
          {renderSlider()}
        </>
      );
  };
  const slideShowMediaAsset = ({
    attachmentBlobArray,
    selectedIndex,
    getMediaIndexUrl,
  }: {
    attachmentBlobArray: MediaBlob[];
    selectedIndex: number;
    getMediaIndexUrl: (index: number) => string;
  }) => {
    if (attachmentBlobArray[selectedIndex]?.media_type === "image") {
      return (
        <img
          src={getMediaIndexUrl(selectedIndex)}
          className={styles.alert_full_image}
          alt="Suspect, Vehicle or Other"
        />
      );
    }
    return (
      <LazyLoadVideo
        src={getMediaIndexUrl(selectedIndex)}
        controls
        className={styles.alert_full_image}
      />
    );
  };

  const linkToMapView = (addressText: string) => {
    if (mode === "view") {
      window.location.href = `https://maps.google.com/maps/place/${encodeURIComponent(addressText)}`;
      return;
    }
    searchParams.set("view", "map");
    setHighlightAlert(alertId ?? null);
    navigate("/rubialert/alerts");
  };

  return (
    <>
      <div className={styles.alert_preview_container} ref={ref}>
        {mode === "view" && (
          <div className={styles.viewOnlyHeader}>
            <Button
              variant="contained"
              color="primary"
              size="medium"
              startIcon={<Search />}
              onClick={() => {
                navigate("/login");
                setCurrentUser(null);
              }}
              sx={{ margin: "0 8px 8px 8px" }}
            >
              See All Bulletins
            </Button>
            <RubiconLogo />
          </div>
        )}

        {alertProperties.isDraft && isDraftsRoute && !isEditRoute && (
          <div style={{ textAlign: "center" }}>
            <Button
              variant="contained"
              color="primary"
              size="medium"
              onClick={() =>
                navigate(`/rubialert/drafts/${alertId}/single/edit`)
              }
              sx={{ margin: "0 8px 8px 8px" }}
            >
              DRAFT - Click Edit to finish and publish
            </Button>
          </div>
        )}

        {publishedResolutions
          ?.sort(
            (a, b) =>
              new Date(b.time_updated).getTime() -
              new Date(a.time_updated).getTime(),
          )
          .map((resolution) => (
            <div key={resolution.id} className={styles.publishedResolutions}>
              <PublishedResolution resolution={resolution} />
            </div>
          ))}

        <div className={styles.header_container}>
          <Paper elevation={4} className={styles.alert_details_parent}>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Typography
                variant="caption"
                sx={{ color: "darkblue", textAlign: "center" }}
              >
                *** {alertProperties.classification} ***
              </Typography>
              <Tooltip
                title={
                  <Fragment>
                    {classificationDetails[alertProperties.classification]}{" "}
                    <Link
                      href="https://www.tbs-sct.canada.ca/pol/doc-eng.aspx?id=32614"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Read more here
                    </Link>
                  </Fragment>
                }
                placement={isSmall ? "bottom" : "right"}
                onClose={() => setOpenTooltip(false)}
                open={openTooltip}
                arrow
              >
                <Info sx={iconStyle} onClick={handleTooltipToggle} />
              </Tooltip>
            </div>

            <div className={styles.alert_heading_inner1}>
              <div>
                <img
                  src={policeLogo}
                  className={styles.police_logo}
                  alt="Police logo"
                />
                {operationalArea && (
                  <Typography variant="subtitle1" align="center">
                    {operationalArea}
                  </Typography>
                )}
              </div>

              <div className={styles.type_crimes_container}>
                <Typography
                  align="center"
                  variant={isSmall ? "h5" : "h4"}
                  style={{ textTransform: "uppercase" }}
                >
                  <b>{type || <Skeleton />}</b>
                </Typography>

                <div className={styles.crimes_container}>
                  {associatedCrimes.map((crime, index) => (
                    <Chip key={index} variant="outlined" label={crime} />
                  ))}
                </div>
              </div>
              <div
                style={{ maxWidth: "80px", minWidth: isSmall ? "0px" : "60px" }}
              ></div>
            </div>

            <Typography variant="subtitle1" align="center">
              <b>Incident Time:</b> {formatDateLong(incidentTime)}
            </Typography>
          </Paper>
        </div>
        {renderMedia()}

        <div className={styles.bulletin_container}>
          <Paper elevation={4} className={styles.alert_details_parent}>
            {Object.keys(tagDict).length > 0 && (
              <>
                <div className={styles.tags_wrap_container}>
                  {Object.values(tagDict).map(
                    (tag) =>
                      tag.description != "" && (
                        <div key={tag.id} className={styles.tag_container}>
                          <Chip
                            label={tag.title}
                            color="secondary"
                            style={{
                              height: "24px",
                            }}
                          />
                          <Typography
                            variant="body1"
                            style={{ whiteSpace: "pre-line" }}
                            paragraph
                          >
                            {tag.description}
                          </Typography>
                        </div>
                      ),
                  )}
                </div>
                <div className={styles.tags_nodesc_container}>
                  {Object.values(tagDict).map(
                    (tag: ITag) =>
                      tag.description === "" && (
                        <div key={tag.id.toString()}>
                          <Chip
                            label={tag.title}
                            color="secondary"
                            style={{
                              height: "24px",
                            }}
                          />
                          {tag.description}
                        </div>
                      ),
                  )}
                </div>
              </>
            )}
            {rawAddresses?.map((address: PlaceType, index) => {
              const classification =
                getAddressClassification(address) ||
                LOCATION_CLASSIFICATION.INCIDENT_ADDRESS;
              const isDuplicate =
                rawAddresses.filter(
                  (addr) => getAddressClassification(addr) === classification,
                ).length > 1;

              return (
                <Typography variant="body1" key={index}>
                  {classification}
                  {isDuplicate ? ` ${index + 1}` : ""}:{" "}
                  {!alertProperties.isDraft ? (
                    <a
                      className={styles.alertAddressHover}
                      onClick={() =>
                        linkToMapView(getAddressDescription(address))
                      }
                    >
                      <b>{getAddressDescription(address)}</b>
                      <LinkIcon
                        sx={{ marginLeft: "8px", verticalAlign: "middle" }}
                        fontSize="small"
                      />
                    </a>
                  ) : (
                    <b>{getAddressDescription(address)}</b>
                  )}
                </Typography>
              );
            })}

            <br></br>

            <Typography
              variant="body1"
              style={{ whiteSpace: "pre-line" }}
              paragraph
            >
              {description}
            </Typography>
            <Typography variant="h6">
              ANYONE WITH INFORMATION IS ASKED TO CONTACT:
            </Typography>

            <div style={{ whiteSpace: "pre-line" }}>
              {renderContactInformation(contacts)}
            </div>

            <Typography variant="subtitle1" align="center">
              <b>{issuing_police_service}</b>
              {" - "}
              {occurrenceNumbers.join(", ")}
            </Typography>
            {(approvedBy || creatorToUse || approvedByBadge) && (
              <Typography variant="subtitle1" align="center">
                {creatorToUse && creatorText(creatorToUse) && (
                  <>
                    <b>Prepared by:</b> {creatorText(creatorToUse)}
                  </>
                )}
                {(approvedBy || approvedByBadge) && (
                  <>
                    &nbsp;
                    <b>Approved by: &nbsp;</b>
                    {approvedByText(approvedBy, approvedByBadge)}
                  </>
                )}
              </Typography>
            )}
            {issuedTime ? (
              <Typography align="center" variant="caption">
                Issued on: {formatDateLong(issuedTime)}
              </Typography>
            ) : (
              <Typography align="center" variant="caption">
                Issued on <i>(Pending)</i>: {formatDateLong(new Date())}
              </Typography>
            )}
            <Typography variant="caption" align="center">
              {alertId !== 0 && `Alert ID - ${alertId}`}
            </Typography>
          </Paper>
        </div>
        {pdf && Object.keys(pdf).length > 0 && (
          <div className={styles.attached_pdf_container}>
            <DisplayAttachedPDF
              pdfFile={pdf}
              mode={mode}
              view_token={view_token}
            />
          </div>
        )}
        <div className={styles.generated_pdf_container}>
          <DownloadReportPDF
            policeLogo={policeLogo}
            mediaBlobs={attachmentBlobArray}
            issuedTime={
              issuedTime
                ? formatDateLong(issuedTime)
                : formatDateLong(new Date())
            }
            disabled={mediaLoading}
          />
        </div>
        {isSlideShowActive && (
          <ExpandedMediaDisplay
            isSlideShowActive={isSlideShowActive}
            setIsSlideShowActive={setIsSlideShowActive}
            mediaSrc={getMediaIndexUrl(selectedIndex)}
            mediaType={
              attachmentBlobArray[selectedIndex]?.media_type === "image"
                ? "image"
                : "video"
            }
            selectedIndex={selectedIndex}
            setSelectedIndex={setSelectedIndex}
            slideshowLength={attachmentsArray.length}
          />
        )}
      </div>
    </>
  );
};
