import React, { createContext, useContext, useState } from "react";

import { devEnvironment } from "./common/featureFlags";

const DevEnvironmentContext = createContext<{
  isDevEnv: boolean;
  toggleDevEnv: () => void;
}>(
  {} as {
    isDevEnv: boolean;
    toggleDevEnv: () => void;
  },
);

interface IDevEnvironmentProviderProps {
  children: React.ReactNode;
}

const DevEnvironmentProvider = ({ children }: IDevEnvironmentProviderProps) => {
  const [isDevEnv, setIsDevEnv] = useState(devEnvironment());

  const toggleDevEnv = () => {
    setIsDevEnv(!isDevEnv);
  };

  return (
    <DevEnvironmentContext.Provider value={{ isDevEnv, toggleDevEnv }}>
      {children}
    </DevEnvironmentContext.Provider>
  );
};

const useDevEnvironment = () => {
  return useContext(DevEnvironmentContext);
};

export { DevEnvironmentProvider, useDevEnvironment };
