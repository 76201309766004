import { Typography } from "@mui/material";
import {
  GoogleMap,
  LoadScriptProps,
  MarkerF,
  useJsApiLoader,
} from "@react-google-maps/api";
import React from "react";

import { ICameras, ICoordinates } from "../../../../types";

const GOOGLE_PLACES_KEY = import.meta.env.VITE_GOOGLE_PLACES_KEY;
const mapLibraries: LoadScriptProps["libraries"] = ["places"];

interface IPropertyCamerasMapProps {
  location: ICoordinates;
  values: ICameras;
  setFieldValue: any;
  errors: any;
  displayOnly?: boolean;
}

const PropertyCamerasMap: React.FC<IPropertyCamerasMapProps> = ({
  location,
  values,
  setFieldValue,
  errors,
  displayOnly = false,
}) => {
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: GOOGLE_PLACES_KEY,
    libraries: mapLibraries,
  });

  const mapOptions = {
    streetViewControl: false,
    mapTypeId: google.maps.MapTypeId.SATELLITE,
    zoomControl: true,
    draggable: false, // Disabling drag
    scrollwheel: true,
    maxZoom: 22,
    minZoom: 17,
    tilt: 0,
  };

  const handleMapClick = (event: any) => {
    if (!displayOnly && event?.latLng) {
      const newCameras = [
        ...values.cameras,
        {
          locationDescription: "",
          FOVDescription: "",
          Make: "",
          Model: "",
          geometry: { lat: event.latLng.lat(), lng: event.latLng.lng() },
        },
      ];
      console.log("newCameras", newCameras);
      setFieldValue("cameras", newCameras);
    }
  };

  return isLoaded ? (
    <>
      {errors.cameras && typeof errors.cameras === "string" && (
        <Typography sx={{ color: "red" }}>{errors.cameras}</Typography>
      )}
      <GoogleMap
        center={location}
        zoom={19}
        mapContainerStyle={{ height: "412px", width: "100%" }}
        onClick={handleMapClick}
        options={mapOptions}
      >
        <>
          {values.cameras.map((camera, index) => (
            <MarkerF
              key={index}
              position={camera.geometry}
              label={{ text: (index + 1).toString(), color: "white" }}
            />
          ))}
        </>
      </GoogleMap>
    </>
  ) : (
    <div>Loading...</div>
  );
};

export default PropertyCamerasMap;
