//External Imports
import { ArrowForward, ExpandMore } from "@mui/icons-material";
import {
  TextField,
  Button,
  MenuItem,
  Paper,
  CircularProgress,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from "@mui/material";
import { Formik, FormikHelpers } from "formik";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";

//Internal Imports
import styles from "./AddAddressStep.module.css";
import MapSearch from "./MapWithSearch";
import { API } from "../../../../common/api-client/camera-api/api";
import { IProperty } from "../../../../types";
import { CameraRegistryContext } from "../context";

// Define the validation schema
const validationSchema = Yup.object({
  googleMapsPlace: Yup.object().required("Address is required"),
  type: Yup.string().required("Property Type is required"),
  businessName: Yup.string().when("type", {
    is: "Business",
    then: (validationSchema) =>
      validationSchema.required("Business Name is required"),
  }),
});

const AddAddressStep = ({ nextStep }: { nextStep?: () => void }) => {
  const { property, setProperty, contacts, clearForm } = useContext(
    CameraRegistryContext,
  );
  if (!property || !setProperty) return <></>;
  const [expandedAccordion, setExpandedAccordion] = useState<boolean>(true);
  const navigate = useNavigate();

  console.log("PROPERTY: ", property);
  console.log("CONTACTS: ", contacts);

  const handleOnSubmit = async (
    values: IProperty,
    helpers: FormikHelpers<IProperty>,
  ) => {
    console.log("SUBMITTING ", values);
    helpers.setSubmitting(true);
    setProperty({ ...values, id: property.id });

    if (nextStep) {
      nextStep();
    } else {
      navigate("../add-cameras");
    }
    // try {
    //   await API.property.patchPortal(property.id ?? "", {
    //     place_details: values.googleMapsPlace,
    //     location_type: values.type,
    //     business_name: values.businessName,
    //     additional_description: values.additionalPropertyDescription,
    //   });
    //   console.log("SUCCESFULLY PASSED");

    //   if (nextStep) {
    //     nextStep();
    //   } else {
    //     navigate("../add-cameras");
    //   }
    // } catch (error) {
    //   console.log("ERROR: ", error);
    // }
  };

  useEffect(() => {
    clearForm && clearForm();
  }, []);

  //TODO API Property Put

  if (!property.id) {
    return (
      <div className={styles.loadingOverlay}>
        <CircularProgress size={80} />
      </div>
    );
  }

  return (
    <Formik
      initialValues={property}
      validationSchema={validationSchema}
      onSubmit={handleOnSubmit}
    >
      {(props) => {
        const {
          values,
          touched,
          errors,
          isSubmitting,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
        } = props;
        return (
          <form className={styles.formContainer} onSubmit={handleSubmit}>
            <Accordion
              disableGutters
              elevation={3}
              expanded={expandedAccordion}
              onChange={() => setExpandedAccordion((prevValue) => !prevValue)}
            >
              <AccordionSummary
                expandIcon={<ExpandMore />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <div className={styles.accordionTitleContainer}>
                  <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                    Property Information
                  </Typography>
                  <Typography sx={{ color: "text.secondary" }}>
                    Fields marked with * are required.
                  </Typography>
                </div>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  Provide details of the property for which you are registering
                  cameras.
                </Typography>
              </AccordionDetails>
            </Accordion>

            <Paper elevation={3} className={styles.propertyContainer}>
              <Typography variant="h5">Address</Typography>

              <MapSearch
                values={values}
                setFieldValue={setFieldValue}
                errors={errors}
                touched={touched}
                handleBlur={handleBlur}
              />

              <TextField
                fullWidth
                select
                name="type"
                label="Type of Property"
                value={values.type}
                onChange={handleChange}
                onBlur={handleBlur}
                required
                style={{
                  backgroundColor: "white",
                }}
                size="small"
                helperText={errors.type && touched.type}
                error={!!(errors.type && touched.type)}
                onKeyDown={(e) => {
                  e.key === "Enter" && e.preventDefault();
                }}
              >
                <MenuItem value="Residential">
                  Residential (e.g. your home)
                </MenuItem>
                <MenuItem value="Business">Business</MenuItem>
              </TextField>

              {values.type === "Business" && (
                <TextField
                  name="businessName"
                  label="Business Name"
                  required
                  style={{
                    backgroundColor: "white",
                  }}
                  size="small"
                  value={values.businessName}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  fullWidth
                  onKeyDown={(e) => {
                    e.key === "Enter" && e.preventDefault();
                  }}
                  helperText={errors.businessName && touched.businessName}
                  error={!!(errors.businessName && touched.businessName)}
                />
              )}
              <TextField
                name="additionalPropertyDescription"
                label="Additional details (e.g., building description, landmarks, etc.)"
                multiline
                fullWidth
                style={{
                  background: "white",
                }}
                value={values.additionalPropertyDescription}
                onChange={handleChange}
                onBlur={handleBlur}
                minRows={3}
                onKeyDown={(e) => {
                  e.key === "Enter" && e.preventDefault();
                }}
                helperText={
                  errors.additionalPropertyDescription &&
                  touched.additionalPropertyDescription
                }
              />
            </Paper>

            <div className={styles.buttonContainer}>
              <Button
                type="submit"
                variant="contained"
                size="large"
                endIcon={
                  isSubmitting ? (
                    <CircularProgress size={20} />
                  ) : (
                    <ArrowForward />
                  )
                }
                disabled={isSubmitting || !property.id}
              >
                Next
              </Button>
            </div>
          </form>
        );
      }}
    </Formik>
  );
};

export default AddAddressStep;
