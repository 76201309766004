import { useContext, useRef, useState } from "react";
import { toast } from "react-toastify";

import LoadingModal from "../../../common/LoadingModal";
import TriUploadFile from "../../../common/TriUploadFile";
import { apiAlertClient } from "../../../common/api-client";
import "../alerts.scss";
import { SingleAlertContext } from "../context";
import { updateToContextStructure } from "../utils";

interface UploadPdfProps {
  setIsUploadPdfModalOpen: (arg: boolean) => void;
  setEvidenceFilesLoading: (arg: boolean) => void;
}

const UploadPdf = ({
  setIsUploadPdfModalOpen,
  setEvidenceFilesLoading,
}: UploadPdfProps) => {
  const pdfInput = useRef<HTMLInputElement>(null);
  const [isPdfUploading, setIsPDFUploading] = useState(false);
  const { setAlertProperties, setAlertProperty } =
    useContext(SingleAlertContext);

  const handleFileUploadPdf = (_event, files: FileList | null) => {
    if (!files || files.length === 0) {
      toast.error("No file selected.");
      return;
    }

    const file = files[0];
    const ext = file.name.match(/\.([^.]+)$/)?.[1];
    if (ext !== "pdf") {
      toast.error("File type not allowed");
      return;
    }
    setIsPDFUploading(true);
    apiAlertClient
      .alertGetBulletinDetailsFromPDF("file", file)
      .then((res) => {
        console.info("UPLOADED PDF", res.pdf);
        setAlertProperty("pdf", res.pdf);
        const alertProperties = updateToContextStructure(res);
        setAlertProperties(alertProperties);

        setIsPDFUploading(false);
        setIsUploadPdfModalOpen(false);
        setEvidenceFilesLoading(true);
        apiAlertClient
          .alertGetBulletinImagesFromPDF("file", file, res.id)
          .then((bulletin) => {
            setAlertProperty("evidence_files", bulletin.evidence_files);
            setEvidenceFilesLoading(false);
          });
      })
      .catch((err) => {
        console.error(err);
        toast.error("Error uploading the PDF.");
        setIsPDFUploading(false);
      });
  };
  const loadingMessages = [
    "Extracting text from your PDF...",
    "Analyzing bulletin details...",
    "Creating a detailed description...",
    "Determining the precise location...",
    "Finding relevant contacts...",
    "Filling in bulletin details...",
    "Finishing up, almost there...",
  ];

  return (
    <div
      onClick={() => {
        if (pdfInput.current) {
          pdfInput.current.click();
        }
      }}
      style={{
        cursor: "pointer",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        width: "100%",
        height: "100%",
        borderRadius: "5px",
        padding: "10px",
      }}
    >
      {!isPdfUploading && (
        <TriUploadFile
          uploadMessage="UPLOAD A CRIME BULLETIN PDF TO GET STARTED."
          fileInput={pdfInput}
          handleFileUpload={handleFileUploadPdf}
          accept="application/pdf"
          multiple={false}
        />
      )}
      {isPdfUploading && (
        <LoadingModal
          stages={loadingMessages}
          timingMessage="This may take a few seconds . . ."
        />
      )}
    </div>
  );
};

export default UploadPdf;
