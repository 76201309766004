import { ArrowBack } from "@mui/icons-material";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";

export const BackButton = (props) => {
  const navigate = useNavigate();

  const handleClick = () => {
    if (props?.path) navigate(props.path);
    else navigate(-1);
  };

  return (
    <Button
      startIcon={<ArrowBack />}
      color="primary"
      onClick={handleClick}
      variant="contained"
    >
      Back
    </Button>
  );
};
