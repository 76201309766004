import { Delete } from "@mui/icons-material";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
} from "@mui/material";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { useContext, useState } from "react";

import { AlertViewContext } from "./context";
import { deleteBulletin } from "../../common/api-client/apiAlertClient";
import { isSmallScreen } from "../../common/utils";
import { BulletinResourceResponse } from "../../types/typesAlerts";

/**
 * Creates columns for the DataGrid and applies default settings
 * @param fields
 * @returns
 */
const createColumns = (fields: GridColDef[]) => {
  return fields.map((f: GridColDef) => ({
    width: 200,
    sortable: false,
    ...f,
  }));
};

const generateRowData = (bulletin: BulletinResourceResponse) => {
  const getAssociatedCrimes = () => {
    if (!bulletin.associated_crime) return "";
    return bulletin.associated_crime.join(", ");
  };
  const getOccurence = () => {
    if (!bulletin.occurrence_number) return "";
    return bulletin.occurrence_number.join(", ");
  };
  const getAddresses = () => {
    const addresses = bulletin.location.addresses;
    if (!addresses) return "";
    return addresses.map((address) => address.description).join(", ");
  };

  const incidentTime = new Date(bulletin.incident_time);
  const issuedTime = new Date(bulletin.issued_time);
  const issuingPoliceName = bulletin.issuing_police_service?.name || "";
  const bulletinType = bulletin.type || "";
  const updatedDate = new Date(bulletin.time_updated || bulletin.time_created);

  const getTags = () => {
    if (!bulletin.tags) return "";
    return Object.values(bulletin.tags)
      .map((tag) => tag.title)
      .join(", ");
  };

  const rowData = {
    id: bulletin.id,
    issued_time: issuedTime,
    incident_time: incidentTime,
    issuing_police_service: issuingPoliceName,
    type: bulletinType,
    location: getAddresses(),
    associated_crime: getAssociatedCrimes(),
    occurence: getOccurence(),
    approved_by: bulletin.approved_by,
    approved_by_badge: bulletin.approved_by_badge,
    classification: bulletin.classification,
    operational_area: bulletin.operational_area,
    tags: getTags(),
    time_updated: updatedDate,
    time_created: new Date(bulletin.time_created),
  };
  return rowData;
};

interface IAlertTableProps {
  alertClickHandler: (item: any, index: any) => void;
  loadMore: () => void;
  isDraftsList: boolean;
  refetch: () => void;
  selectedAlertId?: number | null;
}

const AlertTable = ({
  alertClickHandler,
  loadMore,
  isDraftsList,
  refetch,
  selectedAlertId,
}: IAlertTableProps) => {
  const { totalAlerts, currentItems, updatedItems, setUpdatedItems } =
    useContext(AlertViewContext);

  const [deleteOpen, setDeleteOpen] = useState(false);
  const [deleteID, setDeleteID] = useState("");

  const processDeletion = async () => {
    await deleteBulletin(deleteID);
    refetch();
    setDeleteID("");
    setDeleteOpen(false);
  };

  const rows = currentItems
    .filter(
      (bulletin) =>
        (isDraftsList && bulletin.is_draft) ||
        (!isDraftsList && !bulletin.is_draft),
    )
    .map((bulletin) => generateRowData(bulletin));

  const fields: GridColDef[] = [];

  if (isDraftsList) {
    fields.push({
      field: "delete",
      headerName: "Delete",
      width: 100,
      renderCell: (params) => {
        const id = params.id;
        return (
          <IconButton
            onClick={() => {
              setDeleteID(id.toString());
              setDeleteOpen(true);
            }}
          >
            <Delete />
          </IconButton>
        );
      },
      disableColumnMenu: true,
    });
  }

  fields.push(
    {
      field: "id",
      headerName: "ID",
      width: 100,
      renderCell: (params) => {
        const id = params.id;
        return (
          <div
            style={{ cursor: "pointer", color: "#1976d2" }}
            onClick={() =>
              alertClickHandler(
                currentItems.find((item) => item.id === id),
                currentItems.findIndex((item) => item.id === id),
              )
            }
          >
            {id}
          </div>
        );
      },
      disableColumnMenu: true,
    },
    {
      field: "occurence",
      headerName: "Occurrence",
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: "time_updated",
      headerName: "Last Updated",
      sortable: false,
      type: "dateTime",
      sortingOrder: ["desc", "asc"],
      disableColumnMenu: true,
    },
    {
      field: "time_created",
      headerName: "Time Created",
      sortable: false,
      type: "dateTime",
      sortingOrder: ["desc", "asc"],
      disableColumnMenu: true,
    },
    {
      field: "issued_time",
      headerName: "Issued Time",
      sortable: false,
      type: "dateTime",
      disableColumnMenu: true,
    },
    {
      field: "issuing_police_service",
      headerName: "Issuing Police Service",
      filterable: true,
      disableColumnMenu: true,
    },
    { field: "type", headerName: "Type", width: 200, disableColumnMenu: true },
    {
      field: "location",
      headerName: "Location",
      filterable: true,
      width: 300,
      disableColumnMenu: true,
    },
    {
      field: "associated_crime",
      headerName: "Associated Crime",
      disableColumnMenu: true,
    },
    {
      field: "incident_time",
      headerName: "Incident Time",
      sortable: false,
      type: "dateTime",
      disableColumnMenu: true,
    },
    {
      field: "approved_by",
      headerName: "Approved By",
      sortable: false,
      filterable: true,
      disableColumnMenu: true,
    },
    {
      field: "approved_by_badge",
      headerName: "Approved By Badge",
      sortable: false,
      filterable: true,
      disableColumnMenu: true,
    },
    {
      field: "classification",
      headerName: "Classification",
      sortable: false,
      filterable: true,
      disableColumnMenu: true,
    },
    {
      field: "operational_area",
      headerName: "Operational Area",
      sortable: false,
      filterable: true,
      disableColumnMenu: true,
    },
    {
      field: "tags",
      headerName: "Tags",
      sortable: false,
      filterable: true,
      disableColumnMenu: true,
    },
  );

  const columns = createColumns(fields);
  const mobileView = isSmallScreen();

  const mobileViewStyle = {
    maxHeight: "100vh",
    paddingTop: "16px",
    width: "95vw",
  };

  const desktopStyle = {
    paddingTop: "16px",
    width: "calc(100% - 32px)",
    marginLeft: "16px",
    marginRight: "16px",
    height: "calc(100vh)",
  };

  return (
    <div style={mobileView ? mobileViewStyle : desktopStyle}>
      <DataGrid
        rows={rows}
        columns={columns}
        disableVirtualization
        autoPageSize
        rowSelection={false}
        sx={{
          "& .MuiDataGrid-row:not(.MuiDataGrid-row--dynamicHeight)>.MuiDataGrid-cell":
            {
              whiteSpace: "normal",
              wordWrap: "break-word",
            },
        }}
        onPaginationModelChange={loadMore}
        rowCount={totalAlerts}
        getRowClassName={(params) => {
          if (selectedAlertId && selectedAlertId === params.id) {
            return "slideout-alert-selected-row";
          }
          return "slideout-alert-row";
        }}
        onRowClick={(params) => {
          alertClickHandler(
            currentItems.find((item) => item.id === params.id),
            currentItems.findIndex((item) => item.id === params.id),
          );
        }}
        onCellClick={(params, event) => {
          if (params.field === "delete") {
            event.stopPropagation();
            setDeleteID(params.id.toString());
            setDeleteOpen(true);
          }
        }}
      />
      <Dialog
        open={deleteOpen}
        onClose={() => setDeleteOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Delete Draft Alert?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete draft bulletin with ID {deleteID}?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteOpen(false)}>No</Button>
          <Button onClick={processDeletion} autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default AlertTable;
