//External imports
import { ChevronRight, Lock, LockOpen, Videocam } from "@mui/icons-material";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Drawer,
  IconButton,
  Paper,
  SxProps,
  Theme,
  Typography,
  useMediaQuery,
} from "@mui/material";
import {
  GoogleMap,
  InfoWindowF,
  Marker,
  MarkerF,
  OverlayView,
  useJsApiLoader,
} from "@react-google-maps/api";
import moment from "moment";
import { useEffect, useState } from "react";
import { useTheme } from "react-admin";
//TODO replace loading with Skeleton
import Skeleton from "react-loading-skeleton";
import { useNavigate } from "react-router";
import "react-loading-skeleton/dist/skeleton.css";
import { useSearchParams } from "react-router-dom";

import styles from "./MapRegistryPage.module.css";
import {
  CameraModel,
  ContactModel,
  PropertyModel,
} from "../../../common/api-client/camera-api";
import { API } from "../../../common/api-client/camera-api/api";
import {
  APP_GOOGLE_PLACES_KEY,
  GOOGLE_MAPS_LIBRARIES,
} from "../../../environment";
import { ICoordinates } from "../../../types";

const drawerWidth = "500px";
const drawerToTop = "300px";

const MapRegistryPage = () => {
  const [location, setLocation] = useState<ICoordinates>({
    lat: 43.9593,
    lng: -78.1677,
  });
  const [searchParams] = useSearchParams();
  const [zoom, setZoom] = useState(13);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [alertOpen, setAlertOpen] = useState(false);
  const [mapWidth, setMapWidth] = useState("100%");
  const [mapHeight, setMapHeight] = useState("100%");
  console.log("location", location);

  const mapOptions = {
    streetViewControl: false, // Disable Street View
    tilt: 0,
    fullscreenControl: false,
  };

  const [cameras, setCameras] = useState<CameraModel[]>([]);
  const [selectedPropertyID, setSelectedPropertyID] = useState<string | null>(
    null,
  );
  const [selectedProperty, setSelectedProperty] =
    useState<PropertyModel | null>(null);
  const [selectedContacts, setSelectedContacts] = useState<ContactModel[]>([]);
  const [selectedCameras, setSelectedCameras] = useState<CameraModel[]>([]);

  const navigate = useNavigate();
  const [theme] = useTheme();
  const isSmall = useMediaQuery((theme as Theme).breakpoints.down("sm"));

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: APP_GOOGLE_PLACES_KEY,
    libraries: GOOGLE_MAPS_LIBRARIES,
  });

  const emailTitle = "Camera Registry - Request for Information";
  const emailBody = `Hello,

We are looking to access camera footage from a camera registered on the following property:
${selectedProperty?.place_details?.formatted_address.replace(", Canada", "")}.
  
Please provide us with the best way to follow-up and scheudle a meeting to discuss further. If you are not the best person to contact, please forward this email to the appropriate person.

Thank you,
  `;

  useEffect(() => {
    API.portal.cameraList().then((res) => {
      console.log("res", res);
      setCameras(res.cameras);

      if (searchParams.get("propertyID")) {
        const propertyID = searchParams.get("propertyID");
        openDrawer(propertyID ?? "");
      }
    });
  }, []);

  const getPropertyDetails = async (propertyID: string) => {
    // Implement react query to cache the results
    // const {isLoading, error, data} = useQuery({
    //   queryKey: ['property', propertyID],
    //   queryFn: () => API.property.getPortal(propertyID),
    // })
    const result = await API.property.getPortal(propertyID);
    setSelectedProperty(result.property);
    setSelectedCameras(result.cameras.map((camera) => camera.camera));
    setSelectedContacts(result.contacts);
  };

  const clearData = () => {
    setSelectedProperty(null);
    setSelectedCameras([]);
    setSelectedContacts([]);
  };

  const closeDrawer = () => {
    setSelectedPropertyID(null);
    setDrawerOpen(false);
    clearData();
    setMapWidth("100%");
    setMapHeight("100%");
  };

  const openDrawer = async (propertyID: string) => {
    clearData();
    setSelectedPropertyID(propertyID);
    getPropertyDetails(propertyID);
    setDrawerOpen(true);
  };

  const handleClose = () => {
    setAlertOpen(false);
  };

  const handleRemoveProperty = async () => {
    try {
      await API.property.deletePortal(selectedProperty?.id ?? "");
    } catch (e) {
      console.log(e);
    }
    API.portal.cameraList().then((res) => {
      console.log("res", res);
      setCameras(res.cameras);
    });
    closeDrawer();
    setAlertOpen(false);
  };

  const handleModify = async (selectedPropertyID: string) => {
    navigate("/rubicanvas/add-camera?propertyID=" + selectedPropertyID);
  };

  let drawerProperties: SxProps<Theme> = {
    maxWidth: drawerWidth,
    width: "100%",
  };

  const selectedPropertyDate = selectedProperty
    ? new Date(Date.parse(selectedProperty.time_created))
    : new Date();

  if (isSmall) {
    drawerProperties = {
      top: drawerToTop,
      width: "100%",
    };
  }

  return !isLoaded ? (
    <></>
  ) : (
    <div className={styles.mapContainer}>
      <style>
        {`
          
        `}
      </style>

      <GoogleMap
        center={location}
        zoom={zoom}
        mapContainerStyle={{ height: mapHeight, width: mapWidth }}
        options={mapOptions}
      >
        {cameras.map((camera) => (
          <OverlayView
            key={camera.id}
            position={{ lat: camera.latitude, lng: camera.longitude }}
            mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
          >
            <div
              className={styles.pinMarker}
              style={
                selectedPropertyID === camera.property_id
                  ? { border: "1px solid var(--action-color)" }
                  : {}
              }
            >
              <IconButton
                aria-label="select camera"
                size="small"
                onClick={() => {
                  openDrawer(camera.property_id);
                  //TODO this is a proxy, the offset should be based on the zoom so that the camera is in the center
                  setLocation({
                    lat: camera.latitude,
                    lng: camera.longitude,
                  });
                  isSmall
                    ? setMapHeight(`calc(${drawerToTop} - 54px)`)
                    : setMapWidth(`calc(100vw - ${drawerWidth} - 54px)`);
                }}
              >
                {selectedProperty &&
                selectedPropertyID === camera.property_id ? (
                  <div className={styles.pinNumber}>
                    {selectedCameras
                      .map((camera) => camera.id)
                      .indexOf(camera.id) + 1}
                  </div>
                ) : (
                  <Videocam
                    color={
                      selectedPropertyID === camera.property_id
                        ? "primary"
                        : "secondary"
                    }
                    style={{ transform: "rotate(170deg)" }}
                  />
                )}
              </IconButton>

              <div
                className={styles.pinMarkerTriangle}
                style={
                  selectedPropertyID === camera.property_id
                    ? { borderTop: "18px solid var(--action-color)" }
                    : {}
                }
              ></div>
            </div>
          </OverlayView>
        ))}
      </GoogleMap>

      <Drawer
        anchor={isSmall ? "bottom" : "right"}
        open={drawerOpen}
        onClose={() => closeDrawer()}
        variant="persistent"
        PaperProps={{ sx: drawerProperties }}
      >
        {!isSmall && <div style={{ paddingTop: "48px" }}></div>}

        {!selectedProperty && (
          <div
            style={{
              minWidth: "400px",
              textAlign: "center",
              paddingTop: "24px",
            }}
          >
            Loading <br />
            <br />
            <CircularProgress size={60} />
          </div>
        )}
        <div className={styles.drawerHeader}>
          {selectedProperty && (
            <>
              <IconButton
                onClick={closeDrawer}
                size="large"
                sx={{ border: 1 }}
                color="secondary"
              >
                <ChevronRight
                  style={isSmall ? { transform: "rotate(90deg)" } : {}}
                />
              </IconButton>
              <Paper
                elevation={4}
                className={styles.drawerCard}
                sx={{ flex: 1 }}
              >
                <Typography>
                  Registration Source:{" "}
                  <b>
                    {selectedProperty?.submission_source == "public"
                      ? "Public"
                      : "Police"}
                  </b>
                </Typography>
                <Typography>
                  Added On:{" "}
                  <b>
                    {moment(selectedPropertyDate).format("YYYY-MM-DD hh:mm A")}
                  </b>
                </Typography>
              </Paper>
            </>
          )}
        </div>

        {selectedProperty && (
          <Paper elevation={4} className={styles.drawerCard} sx={{ flex: 1 }}>
            {selectedProperty?.submission_source === "public" ? (
              <div className={styles.accessContainer}>
                <div className={styles.lockSymbol}>
                  <LockOpen fontSize="large" color="secondary" />
                </div>
                <div>
                  <Typography>
                    This property was created by the public. Access and editing
                    privileges are granted to the following addresses:
                    <ul>
                      {selectedContacts.map((contact, index) => (
                        <li key={contact.id}>
                          <a
                            href={`mailto:${contact.email}?subject=${emailTitle}&body=${encodeURIComponent(emailBody)}`}
                          >
                            {contact.email}
                          </a>
                        </li>
                      ))}
                    </ul>
                  </Typography>
                </div>
              </div>
            ) : (
              <div className={styles.accessContainer}>
                <div className={styles.lockSymbol}>
                  <Lock fontSize="large" color="secondary" />
                </div>
                <div>
                  <Typography>
                    This property was created by the police service. Access and
                    modification are disabled for the public. Listed contacts
                    will not receive change notifications or requests for
                    updates.
                  </Typography>
                </div>
              </div>
            )}
          </Paper>
        )}

        {selectedContacts.map((contact, index) => (
          <Paper elevation={4} key={contact.id} className={styles.drawerCard}>
            <Typography variant="h5">Contact #{index + 1}</Typography>
            <Typography>
              <b>Name: </b> {contact.first_name} {contact.last_name}
            </Typography>
            <Typography>
              <b>Phone: </b>{" "}
              <a href={`tel:${contact.phone_number}`}>{contact.phone_number}</a>
            </Typography>
            <Typography>
              <b>Email: </b>{" "}
              <a
                href={`mailto:${contact.email}?subject=${emailTitle}&body=${encodeURIComponent(emailBody)}`}
              >
                {contact.email}
              </a>
            </Typography>
            <Typography>
              <b>Notes: </b> {contact.notes}
            </Typography>
          </Paper>
        ))}
        <Paper
          elevation={4}
          hidden={selectedProperty === null}
          className={styles.drawerCard}
        >
          <Typography variant="h5">
            {selectedProperty?.place_details?.formatted_address.replace(
              ", Canada",
              "",
            )}
          </Typography>
          <Typography>
            <b>Property Type: </b> {selectedProperty?.location_type}
          </Typography>
          {selectedProperty?.location_type === "Business" && (
            <Typography>
              <b>Business Name: </b> {selectedProperty?.business_name}
            </Typography>
          )}
          <Typography>
            <b>Additional Property Description: </b>{" "}
            {selectedProperty?.additional_description}
          </Typography>
          <div style={{ margin: "8px" }}></div>
          <Typography>
            <b>Number of cameras: </b> {selectedCameras.length}
          </Typography>
          <Typography>
            <b>Installed by: </b> {selectedProperty?.installed_by}
          </Typography>
          <Typography>
            <b>Additional camera details: </b>{" "}
            {selectedProperty?.additional_description_cameras}
          </Typography>
          {selectedCameras.map((camera, index) => (
            <div key={camera.id} className={styles.cameraEntry}>
              <Typography className={styles.cameraTitle}>
                Camera #{index + 1}
              </Typography>
              <Typography>
                <b>Location: </b> {camera.location_description}
              </Typography>
              {camera.make && (
                <Typography>
                  <b>Make: </b> {camera.make}
                </Typography>
              )}
              {camera.model && (
                <Typography>
                  <b>Model: </b> {camera.model}
                </Typography>
              )}
              <Typography>
                <b>Field of View: </b> {camera.field_of_view_description}
              </Typography>
            </div>
          ))}
          <Typography>
            Property ID: <b>{selectedProperty?.id}</b>
          </Typography>
          {selectedProperty?.added_by && (
            <>
              <Typography>
                Added by: <b>{selectedProperty?.added_by}</b>
              </Typography>
              <Typography>
                Added by email:{" "}
                <a
                  href={`mailto:${selectedProperty?.added_by_email}?subject=${emailTitle}&body=${encodeURIComponent(emailBody)}`}
                >
                  <b>{selectedProperty?.added_by_email}</b>
                </a>
              </Typography>
            </>
          )}
        </Paper>
        {selectedProperty && (
          <div className={styles.modifyButtonContainer}>
            <Button
              variant="outlined"
              color="secondary"
              onClick={() => handleModify(selectedProperty?.id ?? "")}
            >
              Modify
            </Button>
            <Button
              variant="outlined"
              color="secondary"
              onClick={() => setAlertOpen(true)}
            >
              Remove
            </Button>
          </div>
        )}
      </Drawer>
      <Dialog
        open={alertOpen}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Are you sure?</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            This will ERASE this property, associated cameras, and associated
            contact details from the camera registry system.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} autoFocus>
            CANCEL
          </Button>
          <Button onClick={handleRemoveProperty}>REMOVE PROPERTY</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default MapRegistryPage;
