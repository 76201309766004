import { Drawer, Box } from "@mui/material";
import { format } from "date-fns";
import React, { useState, useEffect } from "react";
import {
  List,
  Datagrid,
  TextField,
  ShowButton,
  Pagination,
  SelectInput,
  TextInput,
  useRefresh,
  useRecordContext,
} from "react-admin";

import { AttachmentField } from "./fields/AttachmentField";
import { AttachmentPopup } from "./fields/AttachmentPopup";
import { apiReportClient } from "../../common/api-client";

export const ReporterName = (props) => {
  const record = useRecordContext();
  const reportingPerson = record.report.reporter;
  return (
    <div>
      {reportingPerson["firstName"]} {reportingPerson["lastName"]}
    </div>
  );
};

export const UpdatedByName = (props) => {
  const record = useRecordContext();
  const user = record.lastUser;
  if (user) {
    return (
      <div>
        {user["firstName"]} {user["lastName"]}
      </div>
    );
  } else {
    return <div />;
  }
};

export const ReportDate = (props) => {
  const record = useRecordContext();
  const d = Date.parse(record.time_created);

  let fmtDate = record.time_created;
  if (!isNaN(d)) {
    fmtDate = format(d, "PP - p");
  }
  return <div>{fmtDate}</div>;
};

export const ReportStatus = (props) => {
  const record = useRecordContext();
  const status = record.status;
  if (status === "NEW" || status === "UPDATED") {
    return <div className="normal">{status}</div>;
  }
  if (status === "APPROVED") {
    return (
      <div style={{ color: "green", fontWeight: "bold" }} className="normal">
        {status}
      </div>
    );
  }
  if (status === "REJECTED") {
    return (
      <div style={{ color: "red", fontWeight: "bold" }} className="normal">
        {status}
      </div>
    );
  } else {
    return <div className="normal">{status}</div>;
  }
};

const PostPagination = (props) => (
  <Pagination
    color="primary"
    rowsPerPageOptions={[10, 25, 50, 100]}
    {...props}
  />
);

const reportRowStyle = (record, index) => ({
  backgroundColor:
    record.status === "NEW" || record.status === "UPDATED"
      ? "#d9dff6"
      : "white",
  border: "1.5px solid #ccc",
});

export const ReportList = () => {
  console.log("In REPORT LIST");

  const refresh = useRefresh();
  const [isViewImage, setIsViewImage] = useState(false);
  const [imageData, setImageData] = useState([]);
  const [id, setId] = useState([]);
  const [filtersData, setFiltersData] = useState<any | null>(null);
  const [filterComponents, setFilterComponents] = useState<
    React.ReactElement[]
  >([]);

  const onClose = () => {
    setIsViewImage(false);
  };
  const onImageClick = (data, id) => {
    setImageData(data);
    setId(id);
    setIsViewImage(true);
  };

  useEffect(() => {
    apiReportClient
      .getFilters()
      .then((res) => {
        setFiltersData(res);
      })
      .catch((err) => {
        console.log(err);
      });
    const mins = 1000 * 60 * 5;
    const timer = setInterval(() => {
      refresh();
    }, mins);
    return () => clearInterval(timer);
  }, [refresh]);

  useEffect(() => {
    setFilterComponents([
      <SelectInput
        emptyText="Clear Value"
        resettable={true}
        label="Incident Type"
        alwaysOn
        variant="outlined"
        source="incidentType"
        choices={
          filtersData?.incidentType?.data ? filtersData.incidentType.data : []
        }
        optionText="text"
        optionValue="value"
        sx={{
          minWidth: "150px",
          width: "200px",
        }}
      />,
      <TextInput
        resettable={true}
        variant="outlined"
        label="First Name"
        source="firstName"
        alwaysOn
        sx={{
          minWidth: "150px",
          width: "200px",
        }}
      />,
      <TextInput
        resettable={true}
        variant="outlined"
        label="Last Name"
        source="lastName"
        alwaysOn
        sx={{
          minWidth: "150px",
          width: "200px",
        }}
      />,
      <TextInput
        resettable={true}
        className="tracking-number-filter"
        variant="outlined"
        label="Tracking Number"
        source="trackingNumber"
        sx={{
          minWidth: "150px",
          width: "200px",
        }}
      />,
      <SelectInput
        emptyText="Clear Value"
        resettable
        label="Report Type"
        variant="outlined"
        source="reportType"
        choices={
          filtersData?.reportType?.data ? filtersData.reportType.data : []
        }
        optionText="text"
        optionValue="value"
        sx={{
          minWidth: "150px",
          width: "200px",
        }}
      />,
      <SelectInput
        emptyText="Clear Value"
        resettable
        label="Report Status"
        variant="outlined"
        source="status"
        choices={filtersData?.status?.data ? filtersData.status.data : []}
        optionText="text"
        optionValue="value"
        sx={{
          minWidth: "150px",
          width: "200px",
        }}
      />,
    ]);
  }, [filtersData]);

  return (
    <Box sx={{ margin: "4px 12px" }}>
      <List
        pagination={<PostPagination />}
        title="Reports Inbox"
        filters={filterComponents}
      >
        <Datagrid bulkActionButtons={false} rowStyle={reportRowStyle}>
          <ShowButton />
          <TextField
            source="report.meta.trackingNumber"
            label="Report #"
            sortable={false}
          />
          <TextField
            source="report.meta.reportType"
            label="Report Type"
            sortable={false}
          />
          <ReportDate label="Report Date" sortable={false} />
          <TextField
            source="report.meta.incidentType"
            label="Incident Type"
            sortable={false}
          />
          <ReporterName label="Reporting Person" sortable={false} />
          <AttachmentField
            source="Attachments"
            label="Attachments"
            onImageClick={onImageClick}
          />
          <ReportStatus label="Status" sortable={false} />
          <UpdatedByName label="Last Update By" sortable={false} />
        </Datagrid>
      </List>
      <Drawer
        anchor="right"
        onClose={onClose}
        PaperProps={{ sx: { maxWidth: 750, zIndex: 100 } }}
        open={isViewImage}
      >
        {isViewImage && (
          <AttachmentPopup id={id} onClose={onClose} attachments={imageData} />
        )}
      </Drawer>
    </Box>
  );
};
