import {
  Analytics,
  AssessmentOutlined,
  Assignment,
  PhoneInTalk,
  Settings,
} from "@mui/icons-material";
import { IconButton, Theme, Tooltip, useMediaQuery } from "@mui/material";
import { FC, useContext } from "react";
import { Menu, MenuItemLink, useSidebarState, useTheme } from "react-admin";

import { useDevEnvironment } from "../../DevContext";
import { AuthStateContext } from "../../context";

interface Props {
  dense: boolean;
  logout: () => void;
  onMenuClick: () => void;
}

const RubiReportV2Menu: FC<Props> = ({ onMenuClick, dense, logout }) => {
  const [theme] = useTheme();
  const isSmall = useMediaQuery((theme as Theme).breakpoints.down("sm"));
  const [open, setOpen] = useSidebarState();
  const { currentUser, getOrgIDFromURL } = useContext(AuthStateContext);
  const orgID = getOrgIDFromURL() ?? currentUser?.rubi_org_id ?? "";

  const { isDevEnv } = useDevEnvironment();

  const hideSideBar = () => {
    setOpen(false);
  };

  return (
    <div className="menu">
      {open ? (
        <div className="sidebar-top">
          <div className="app-name">
            Rubi<span>REPORT V2</span>
          </div>
        </div>
      ) : (
        <div className="application-item">
          <Tooltip title="Online Reporting System" placement="right" arrow>
            <IconButton
              color="secondary"
              aria-label="RubiREPORT App"
              size="large"
              disableRipple
              sx={{ backgroundColor: "lightgrey" }}
            >
              <Assignment />
            </IconButton>
          </Tooltip>
        </div>
      )}
      <div className="left-menu">
        <Menu.Item
          to={`/rubireportv2/${orgID}/reports`}
          primaryText="Reports"
          leftIcon={<AssessmentOutlined />}
          sidebarIsOpen={open}
          dense={dense}
          onClick={isSmall ? hideSideBar : () => {}}
        />
        <MenuItemLink
          to={`/rubireportv2/${orgID}/citizen-engage`}
          primaryText="CitizenENGAGE"
          leftIcon={<PhoneInTalk />}
          onClick={isSmall ? hideSideBar : () => {}}
        />
        <MenuItemLink
          to={`/rubireportv2/${orgID}/analytics`}
          primaryText="Dashboard"
          leftIcon={<Analytics />}
          onClick={isSmall ? hideSideBar : () => {}}
        />
        <MenuItemLink
          to={`/rubireportv2/${orgID}/settings`}
          primaryText="Settings"
          leftIcon={<Settings />}
          onClick={isSmall ? hideSideBar : () => {}}
        />

        {/* //TODO Enable once we have a dashboards */}
        {/* <DashboardMenuItem sidebarIsOpen={open} /> */}
      </div>
    </div>
  );
};

export default RubiReportV2Menu;
