/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BaseHttpRequest } from './core/BaseHttpRequest';
import type { OpenAPIConfig } from './core/OpenAPI';
import { AxiosHttpRequest } from './core/AxiosHttpRequest';

import { AuthService } from './services/AuthService';
import { CallLogService } from './services/CallLogService';
import { CameraService } from './services/CameraService';
import { ContactService } from './services/ContactService';
import { ModifyService } from './services/ModifyService';
import { OrgConfigService } from './services/OrgConfigService';
import { PoliceServiceService } from './services/PoliceServiceService';
import { PortalService } from './services/PortalService';
import { PropertyService } from './services/PropertyService';
import { RecordDownloadService } from './services/RecordDownloadService';
import { SubmissionService } from './services/SubmissionService';
import { SurveyService } from './services/SurveyService';

type HttpRequestConstructor = new (config: OpenAPIConfig) => BaseHttpRequest;

export class CameraRegistryAPI {

    public readonly auth: AuthService;
    public readonly callLog: CallLogService;
    public readonly camera: CameraService;
    public readonly contact: ContactService;
    public readonly modify: ModifyService;
    public readonly orgConfig: OrgConfigService;
    public readonly policeService: PoliceServiceService;
    public readonly portal: PortalService;
    public readonly property: PropertyService;
    public readonly recordDownload: RecordDownloadService;
    public readonly submission: SubmissionService;
    public readonly survey: SurveyService;

    public readonly request: BaseHttpRequest;

    constructor(config?: Partial<OpenAPIConfig>, HttpRequest: HttpRequestConstructor = AxiosHttpRequest) {
        this.request = new HttpRequest({
            BASE: config?.BASE ?? '',
            VERSION: config?.VERSION ?? '0.1.0',
            WITH_CREDENTIALS: config?.WITH_CREDENTIALS ?? false,
            CREDENTIALS: config?.CREDENTIALS ?? 'include',
            TOKEN: config?.TOKEN,
            USERNAME: config?.USERNAME,
            PASSWORD: config?.PASSWORD,
            HEADERS: config?.HEADERS,
            ENCODE_PATH: config?.ENCODE_PATH,
        });

        this.auth = new AuthService(this.request);
        this.callLog = new CallLogService(this.request);
        this.camera = new CameraService(this.request);
        this.contact = new ContactService(this.request);
        this.modify = new ModifyService(this.request);
        this.orgConfig = new OrgConfigService(this.request);
        this.policeService = new PoliceServiceService(this.request);
        this.portal = new PortalService(this.request);
        this.property = new PropertyService(this.request);
        this.recordDownload = new RecordDownloadService(this.request);
        this.submission = new SubmissionService(this.request);
        this.survey = new SurveyService(this.request);
    }
}
