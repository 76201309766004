import React from "react";

import { ReportField } from "./ReportField";
import styles from "../report.module.css";

export const TheftFromVehicleField = (props) => {
  const { data } = props;
  return (
    <>
      {data &&
        data.length > 0 &&
        data.map((row, index) => (
          <div key={index}>
            <div className={styles.subHeading}>Vehicle {index + 1}</div>
            <ReportField
              value={row.colours}
              source="row.colours"
              label="colours"
            />
            <ReportField
              value={row.damagedValue}
              source="row.damagedValue"
              label="damagedValue"
            />
            <ReportField
              value={row.involvementType}
              source="row.involvementType"
              label="Involvement Type"
            />
            <ReportField
              value={row.licenseLocation}
              source="row.licenseLocation"
              label="License Location"
            />
            <ReportField
              value={row.licensePlateNo}
              source="row.licensePlateNo"
              label="License Plate No"
            />
            <ReportField
              value={row.licenseType}
              source="row.licenseType"
              label="License Type"
            />
            <ReportField value={row.maker} source="row.maker" label="Maker" />
            <ReportField
              value={row.marketValue}
              source="row.marketValue"
              label="Market Value"
            />
            <ReportField value={row.model} source="row.model" label="Model" />
            <ReportField value={row.style} source="row.style" label="Style" />
            <ReportField value={row.vIN} source="row.vIN" label="VIN" />
            <ReportField value={row.year} source="row.year" label="Year" />
          </div>
        ))}
    </>
  );
};
