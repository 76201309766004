import { useEffect, useState } from "react";
import Lottie from "react-lottie";

import animationData from "./gears.json";
interface LoadingModalProps {
  stages: string[];
  timingMessage: string;
}

const LoadingModal = ({ stages, timingMessage }: LoadingModalProps) => {
  const [stageIndex, setStageIndex] = useState(0);

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const animationDuration = 4000; // Adjust as needed for slower animation
  useEffect(() => {
    const messageChangeTiming = animationDuration; // Change message when it's invisible

    const interval = setInterval(() => {
      setStageIndex((prevIndex) => (prevIndex + 1) % stages.length);
    }, messageChangeTiming);
    return () => clearInterval(interval);
  }, [stages.length]);

  return (
    <div
      style={{ display: "flex", flexDirection: "column", alignItems: "center" }}
    >
      <Lottie options={defaultOptions} height={150} width={150} />
      <p
        style={{
          margin: "10px 0 0",
          padding: "5px",
          lineHeight: "1.4",
          fontSize: "14px",
          fontStyle: "italic",
          color: "#666",
        }}
      >
        {timingMessage}
      </p>
      <p
        style={{
          margin: "10px 0 0",
          padding: "5px",
          lineHeight: "1.4",
          fontFamily: "Arial, sans-serif",
          animation: `fadeInUpFadeOutDown ${
            animationDuration / 1000
          }s infinite`, // Sync with the adjusted timing
          fontSize: "16px",
          fontWeight: "bold",
          color: "#333",
          backgroundColor: "#f0f0f0",
          borderRadius: "5px",
          boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
        }}
      >
        {stages[stageIndex]}
      </p>
      <style>
        {`
          @keyframes fadeInUpFadeOutDown {
            0%, to {
              opacity: 0;
              transform: translateY(20%);
            }
            20%, 80% {
              opacity: 1;
              transform: translateY(0);
            }
          }
        `}
      </style>
    </div>
  );
};

export default LoadingModal;
