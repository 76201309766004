import {
  Assignment,
  CameraEnhance,
  Campaign,
  FindInPage,
  PrivacyTip,
} from "@mui/icons-material";
import {
  Box,
  ButtonBase,
  Chip,
  Paper,
  Theme,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { useSidebarState, useTheme } from "react-admin";
import { useNavigate } from "react-router-dom";

import styles from "./AppSelector.module.scss";
import { useDevEnvironment } from "../../DevContext";
import { getServiceConfig } from "../../common/serviceConfigurations";
import { AuthStateContext } from "../../context";
import { Permission } from "../UserManagement/types";
import { userPermissionChecks } from "../UserManagement/utils";

const AppSelector = () => {
  const navigate = useNavigate();
  const [theme] = useTheme();
  const isSmall = useMediaQuery((theme as Theme).breakpoints.down("sm"));
  const [open, setOpen] = useSidebarState();
  const [isAppAccessDisabled, setIsAppAccessDisabled] = useState(false);

  const { currentUser } = useContext(AuthStateContext);
  const { isDevEnv } = useDevEnvironment();

  const currentUserPermissions =
    currentUser?.permissions?.map((permission) => permission.title) || [];

  const userPermissions = userPermissionChecks(currentUserPermissions);

  const organizationConfig = getServiceConfig(
    currentUser?.rubi_org_id ?? "NA",
    isDevEnv,
  );
  const organizationAppPermissions = organizationConfig?.apps ?? [];

  useEffect(() => {
    setOpen(false);
  }, []);
  const openSidebar = () => {
    if (!isSmall) {
      setOpen(true);
    }
  };

  // TODO: get a list of apps from BE
  const apps = [
    {
      title: "RubiREPORT",
      description: "Online Reporting System",
      navigateTo: "/rubireport",
      chip: null,
      disabled:
        !userPermissions.canAccessReportsApp ||
        !organizationAppPermissions.includes(Permission.REPORT),
      icon: <Assignment className={styles.icon} fontSize="large" />,
    },
    {
      title: "RubiREPORT V2",
      description: "Online Reporting System",
      navigateTo: "/rubireportv2/reports",
      chip: null,
      disabled:
        !userPermissions.canAccessReportsV2App ||
        !organizationAppPermissions.includes(Permission.REPORT_V2),
      icon: <Assignment className={styles.icon} fontSize="large" />,
    },
    {
      title: "RubiINTEL",
      description: "Intelligence Management System",
      navigateTo: "/rubiintel/reports",
      chip: null,
      disabled:
        !userPermissions.canAccessReportsV2App ||
        !organizationAppPermissions.includes(Permission.INTEL),
      icon: <FindInPage className={styles.icon} fontSize="large" />,
    },
    {
      title: "RubiALERT",
      description: "Bulletin Management System",
      navigateTo: "/rubialert/alerts",
      chip: null,
      disabled:
        !userPermissions.canAccessAlertsApp ||
        !organizationAppPermissions.includes(Permission.ALERT),
      icon: <PrivacyTip className={styles.icon} fontSize="large" />,
    },
    {
      title: "RubiPARADE",
      description: "eParade System",
      navigateTo: "/rubiparade/alerts",
      chip: "alpha",
      disabled: true,
      icon: <Campaign className={styles.icon} fontSize="large" />,
    },
    {
      title: "RubiCANVAS",
      description: "Camera Registry",
      navigateTo: "/rubicanvas/map",
      chip: "beta",
      disabled:
        !userPermissions.canAccessCameraRegistryApp ||
        !organizationAppPermissions.includes(Permission.CAMERA_REGISTRY),
      icon: <CameraEnhance className={styles.icon} fontSize="large" />,
    },
  ];

  apps.sort((a, b) => {
    if (a.disabled && !b.disabled) {
      return 1;
    } else if (!a.disabled && b.disabled) {
      return -1;
    } else {
      return 0;
    }
  });

  return (
    <div className={styles.root}>
      <div className={styles.topContainer}>
        <Typography variant="h4" component="h1" style={{ fontWeight: "bold" }}>
          Select an App to Open
        </Typography>
      </div>

      <div className={styles.flexContainer}>
        {apps.map((app, index) => (
          <div
            className={app.disabled ? styles.buttonBaseDisabled : ""}
            key={index}
          >
            <ButtonBase
              onClick={() => {
                if (!app.disabled) {
                  openSidebar();
                  navigate(app.navigateTo);
                }
              }}
              disabled={app.disabled}
            >
              <Paper className={styles.paper} elevation={4}>
                <Box display="flex" alignItems="center">
                  {app.icon}
                  <Typography
                    variant="h5"
                    component="h2"
                    className={styles.appTitle}
                  >
                    {app.title}
                  </Typography>
                  <Box display="flex" sx={{ marginLeft: "4px" }}>
                    {app.chip && (
                      <Chip
                        label={app.chip}
                        sx={{ bgcolor: "black", color: "white" }}
                      />
                    )}
                  </Box>
                </Box>
                <Box display="flex" sx={{ margin: "4px 0 0 52px" }}>
                  <Typography
                    variant="body2"
                    gutterBottom
                    className={styles.appDescription}
                  >
                    {app.description}
                  </Typography>
                </Box>
                {app.disabled && (
                  <div className={styles.tooltip}>
                    Please contact your admin to get access to this app
                  </div>
                )}
              </Paper>
            </ButtonBase>
          </div>
        ))}
      </div>
    </div>
  );
};

export default AppSelector;
