import { Typography } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router";

import { AuthStateContext } from "../../../context";

const useDynamicColumnWidths = (topPageRef) => {
  const calculateColumnWidths = useCallback((parentWidth) => {
    const baseWidth = parentWidth
      ? parentWidth * 0.85
      : window.innerWidth * 0.85;

    const ratios = {
      name: 10,
    };
    const totalRatios = Object.values(ratios).reduce(
      (acc, val) => acc + val,
      0,
    );

    const columnWidths = {};
    for (const [key, value] of Object.entries(ratios)) {
      columnWidths[key] = (baseWidth / totalRatios) * value;
    }

    return columnWidths;
  }, []);

  const [columnWidths, setColumnWidths] = useState<{
    [key: string]: number;
  }>(() =>
    calculateColumnWidths(topPageRef.current?.parentElement?.offsetWidth),
  );
  useEffect(() => {
    const handleResize = () => {
      const parentWidth = topPageRef.current?.parentElement?.offsetWidth;
      setColumnWidths(calculateColumnWidths(parentWidth));
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [topPageRef]);

  return columnWidths;
};

const AlertGroupManagement = () => {
  const { currentUserGroups } = useContext(AuthStateContext);
  const navigate = useNavigate();

  const serviceGroups = currentUserGroups.filter(
    (group) => group.is_self_group === false,
  );

  const topPageRef = useRef<HTMLInputElement | null>(null);

  const columnWidths = useDynamicColumnWidths(topPageRef);

  const columns = [
    {
      field: "name",
      headerName: "Group Name",
      width: 300,
    },
  ];

  const totalGroups = 10;

  return (
    <div className="users_list_container">
      <div id="top-page-div" ref={topPageRef}></div>
      <Typography variant="h4" gutterBottom>
        Your Groups
      </Typography>

      <DataGrid
        rows={serviceGroups}
        columns={columns}
        rowSelection={false}
        sx={{
          "& .MuiDataGrid-row:not(.MuiDataGrid-row--dynamicHeight)>.MuiDataGrid-cell":
            {
              whiteSpace: "normal",
              wordWrap: "break-word",
              cursor: "pointer",
            },
        }}
        rowCount={totalGroups}
        onRowClick={(row) => {
          navigate(`${row.id}/members`);
        }}
      />
    </div>
  );
};

export default AlertGroupManagement;
