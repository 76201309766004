//External imports
import {
  Paper,
  Typography,
  Autocomplete,
  TextField,
  LinearProgress,
} from "@mui/material";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";

// Internal imports
import styles from "./SettingsPage.module.css";
import { API } from "../../../common/api-client/camera-api/api";
import { validateEmail } from "../../../common/utils";

interface IAddEmailOption {
  inputValue: string;
  label: string;
}

const SettingsPage = () => {
  const queryClient = useQueryClient();

  const { data: policeService, isLoading } = useQuery({
    queryFn: () => API.policeService.get(),
    queryKey: ["rubiCanvas-settings-notifyEmails"],
  });

  const { mutate: notifyEmailsMutate } = useMutation({
    mutationFn: API.policeService.patch,
    onSuccess: (data) => {
      queryClient.setQueryData(["rubiCanvas-settings-notifyEmails"], data);
    },
  });

  const selectedEmails = policeService?.notify.emails ?? [];

  const handleEmailChange = async (
    _event: React.ChangeEvent<{}>,
    newValues: (string | IAddEmailOption)[],
  ) => {
    console.log("newValues", newValues);
    console.log("selectedEmails", policeService?.notify.emails);
    let emails: string[] = [];

    if (newValues.length > selectedEmails.length) {
      //A new email is added
      const lastValue = newValues[newValues.length - 1];
      const newEmail =
        typeof lastValue === "string" ? lastValue : lastValue.inputValue;

      if (!validateEmail(newEmail)) {
        toast.error("Invalid email format!");
        return;
      }

      if (selectedEmails.some((email) => email === newEmail)) {
        toast.warning(`${newEmail} already exists`);
        return;
      }
    }

    emails = [
      ...newValues.map((v) => (typeof v === "string" ? v : v.inputValue)),
    ];

    const newData = {
      notify: {
        emails: emails,
      },
    };

    queryClient.setQueryData(["rubiCanvas-settings-notifyEmails"], newData);
    API.policeService.patch(newData);
  };
  return (
    <div className={styles.formContainer}>
      <Paper elevation={3} className={styles.sectionContainer}>
        <Typography variant="h6">
          <b>Notification Setting</b>
        </Typography>

        <Typography variant="body2" gutterBottom>
          Who should receive an email notification when a new public camera
          registration is made?
        </Typography>

        {!selectedEmails ? (
          <LinearProgress sx={{ width: "100%" }} />
        ) : (
          <Autocomplete
            fullWidth
            multiple
            options={[]}
            id="email-autocomplete"
            value={selectedEmails}
            onChange={handleEmailChange}
            freeSolo
            loading={isLoading}
            filterOptions={(_options, params) => {
              const filtered: string[] = [];
              if (params.inputValue !== "") {
                filtered.push(params.inputValue);
              }

              return filtered;
            }}
            getOptionLabel={(option) => option}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                label="Emails"
                placeholder="Add email..."
              />
            )}
          />
        )}
      </Paper>
    </div>
  );
};

export default SettingsPage;
