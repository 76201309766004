import moment from "moment";

export function validateEmail(email) {
  return /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(email);
}

export function validatePhone(phone) {
  return /^(\d{10}|\d{11})$/.test(phone);
}

export const formatPhoneNumber = (phoneNumber) => {
  const digits = phoneNumber.replace(/\D/g, "");

  if (digits.length < 4) return digits;
  if (digits.startsWith("1") && digits.length < 5)
    return `+1 (${digits.slice(1)}`;

  if (digits.startsWith("1") && digits.length <= 11) {
    return `+1 (${digits.slice(1, 4)}) ${
      digits.length < 8
        ? digits.slice(4)
        : `${digits.slice(4, 7)}-${digits.slice(7)}`
    }`;
  } else if (digits.length <= 10) {
    return `(${digits.slice(0, 3)}) ${
      digits.length < 7
        ? digits.slice(3)
        : `${digits.slice(3, 6)}-${digits.slice(6)}`
    }`;
  }

  return digits;
};

export const stripPhoneNumberFormatting = (phoneNumber) =>
  phoneNumber.replace(/\D/g, "");

export const isSmallScreen = () => {
  return window.innerWidth <= 600;
};

export const formatDateLong = (dateTime) => {
  const m = moment(dateTime);
  return m.format("dddd - MMMM DD, YYYY, hh:mm A");
};
export const formatDateShort = (dateTime) => {
  const m = moment(dateTime);
  return m.format("MMM DD, YYYY - hh:mm A ");
};
export const formatWeekdayShort = (dateTime) => {
  const m = moment(dateTime);
  return m.format("ddd");
};

/**
 * Converts a Date String (YYYY-MM-DD) into
 * proper timezone ISO String (YYYY-MM-DDTHH:mm.ss.xxxZ)
 */
export const dateToISOString = function (date: string): string {
  date = date + "T00:00:00";
  return new Date(date).toISOString();
};

export const mailTo = ({ email, subject, body }) => {
  return `mailto:${email}?subject=${encodeURIComponent(
    subject,
  )}&body=${encodeURIComponent(body)}`;
};
