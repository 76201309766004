import {
  Cancel,
  Save,
  AssignmentTurnedIn,
  CheckBox,
} from "@mui/icons-material";
import { Button } from "@mui/material";

import styles from "../report.module.css";

export const ReportActions = (props) => {
  const { status } = props;
  return (
    <div className={styles.reportActions}>
      <div className={styles.otherActions}>
        {(status === "NEW" ||
          status === "NOT_APPROVED" ||
          status === "REJECTED") && (
          <Button
            className={styles.actionButton}
            variant="contained"
            color="primary"
            size="medium"
            startIcon={<CheckBox />}
            onClick={props.onApprove}
          >
            Approve
          </Button>
        )}

        {(status === "NEW" ||
          status === "NOT_APPROVED" ||
          status === "REJECTED") && (
          <Button
            className={styles.actionButton}
            variant="contained"
            color="primary"
            size="medium"
            startIcon={<AssignmentTurnedIn />}
            onClick={props.onApproveWithNote}
          >
            Approve with Note
          </Button>
        )}

        {(status === "NEW" ||
          status === "NOT_APPROVED" ||
          status === "APPROVED") && (
          <Button
            className={styles.actionButton}
            variant="contained"
            color="primary"
            size="medium"
            startIcon={<Cancel />}
            onClick={props.onReject}
          >
            Reject
          </Button>
        )}
      </div>
      <div>
        <Button
          className={styles.actionButton + styles.saveActionButton}
          variant="contained"
          color="primary"
          size="medium"
          startIcon={<Save />}
          onClick={props.onSave}
        >
          Save
        </Button>
      </div>
    </div>
  );
};
