import { Divider, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";

import styles from "./ReportCard.module.scss";
import { imageUrlToBase64 } from "./utils";
import { GetSubmissionResponsePortal } from "../../common/api-client/camera-api";
import { SubmissionSummary } from "../../common/api-client/or-api";
import AlertCardMedia from "../alerts/AlertCard/ImageGallery";

const drugColorMapping = {
  "Bath Salts": "#FFC0CB", // Light pink
  Cocaine: "#FFFFFF", // White
  "Crack Cocaine": "#FFD700", // Gold
  "Drug Press and Press Platers": "#D3D3D3", // Light gray
  Fentanyl: "#0000FF", // Blue
  GHB: "#8A2BE2", // Blue violet
  Hashish: "#DEB887", // Burlywood
  Heroin: "#FF0000", // Red
  Ketamine: "#7B68EE", // Medium slate blue
  Khat: "#32CD32", // Lime green
  "KroKodil (Desomophine)": "#DAA520", // Goldenrod
  LSD: "#FF4500", // Orange red
  Marijuana: "#228B22", // Forest green
  MDA: "#FF69B4", // Hot pink
  "MDMA / Molly / Ecstasy": "#BA55D3", // Medium orchid
  "Mescaline / Peyote": "#FFDAB9", // Peach puff
  Methamphetamine: "#7FFF00", // Chartreuse
  Other: "#B0C4DE", // Light steel blue
  PCP: "#FF00FF", // Magenta
  "Pills and Tablets": "#FFA500", // Orange
  "Prescription Drugs": "#1E90FF", // Dodger blue
  Psilocybin: "#9370DB", // Medium purple
  Steroids: "#FF6347", // Tomato
  "THC Eatables": "#FF1493", // Deep pink
  Unknown: "#D3D3D3", // Light gray
};

interface ReportCardProps {
  report: SubmissionSummary;
  onClick: () => void;
  index: number;
  reportType:
    | "Drug Identification Search Request"
    | "Significant Drug Seizure Report";
  className?: string;
}

const generateSummary = (report: SubmissionSummary) => {
  const {
    incident_date,
    incident_drugType,
    incident_location_address,
    incident_seizureAmount,
    incident_measurementType,
  } = report.latest_data;

  const location = incident_location_address?.googleMapsPlace;
  const locationDescription = `${location?.name}, ${location?.formatted_address}`;

  return (
    <>
      <Typography variant="body2">
        <strong>Date:</strong> {incident_date}
      </Typography>
      <Typography variant="body2">
        <strong>Drug Type:</strong> {incident_drugType}
      </Typography>
      <Typography variant="body2">
        <strong>Seizure Amount:</strong> {incident_seizureAmount}{" "}
        {incident_measurementType}
      </Typography>
      <Typography variant="body2">
        <strong>Location:</strong> {locationDescription}
      </Typography>
    </>
  );
};

const generateDetails = (report: GetSubmissionResponsePortal) => {
  const {
    incident_drugImpressions,
    incident_quantitativeAnalysis,
    incident_occurrenceNumber,
    incident_packagingMaterial,
    incident_packagingMarkings,
    incident_remarks,
    person_reportingParty_agency,
    person_reportingParty_email,
    person_reportingParty_firstName,
    person_reportingParty_lastName,
    person_reportingParty_phoneNumber,
    person_reportingParty_unit,
    report_shareDEA,
    report_shareOtherPolice,
  } = report.submission;

  return (
    <>
      <Typography variant="body2">
        <strong>Drug Impressions:</strong> {incident_drugImpressions}
      </Typography>
      <Typography variant="body2">
        <strong>Quantitative Analysis:</strong> {incident_quantitativeAnalysis}
      </Typography>
      <Typography variant="body2">
        <strong>Occurrence Number:</strong> {incident_occurrenceNumber}
      </Typography>
      <Typography variant="body2">
        <strong>Packaging Material:</strong> {incident_packagingMaterial}
      </Typography>
      <Typography variant="body2">
        <strong>Packaging Markings:</strong> {incident_packagingMarkings}
      </Typography>
      <Typography variant="body2">
        <strong>Remarks:</strong> {incident_remarks}
      </Typography>
      <Typography variant="body2">
        <strong>Reporting Party:</strong>
      </Typography>
      <Typography variant="body2">
        - <strong>Agency:</strong> {person_reportingParty_agency}
      </Typography>
      <Typography variant="body2">
        - <strong>Unit:</strong> {person_reportingParty_unit}
      </Typography>
      <Typography variant="body2">
        - <strong>Name:</strong> {person_reportingParty_firstName}{" "}
        {person_reportingParty_lastName}
      </Typography>
      <Typography variant="body2">
        - <strong>Email:</strong> {person_reportingParty_email}
      </Typography>
      <Typography variant="body2">
        - <strong>Phone:</strong> {person_reportingParty_phoneNumber}
      </Typography>
      <Typography variant="body2">
        <strong>Shared with:</strong>
      </Typography>
      <Typography variant="body2">
        - <strong>DEA:</strong> {report_shareDEA ? "Yes" : "No"}
      </Typography>
      <Typography variant="body2">
        - <strong>Other Police:</strong>{" "}
        {report_shareOtherPolice ? "Yes" : "No"}
      </Typography>
    </>
  );
};

const ReportCard: React.FC<ReportCardProps> = ({
  report,
  onClick,
  index,
  reportType,
  className,
}) => {
  // const [expanded, setExpanded] = useState(false);
  const summary = generateSummary(report);
  // const details = generateDetails(report);
  const [reportImages, setReportImages] = useState<any>([]);

  const {
    incident_date,
    incident_drugImpressions,
    incident_drugType,
    incident_evidences,
    incident_location_address,
    incident_measurementType,
    incident_occurrenceNumber,
    incident_packagingMarkings,
    incident_packagingMaterial,
    incident_quantitativeAnalysis,
    incident_remarks,
    incident_seizureAmount,
    incident_seizureType,
    "person-reportingParty_agency": person_reportingParty_agency,
    "person-reportingParty_email": person_reportingParty_email,
    "person-reportingParty_firstName": person_reportingParty_firstName,
    "person-reportingParty_lastName": person_reportingParty_lastName,
    "person-reportingParty_phoneNumber": person_reportingParty_phoneNumber,
    "person-reportingParty_unit": person_reportingParty_unit,
    report_shareDEA,
    report_shareOtherPolice,
  } = report.latest_data;

  const getReportMedia = async () => {
    const tempData: { [key: string]: string }[] = [];
    for (const attachment of report.attachments) {
      const base64 = await imageUrlToBase64(
        attachment.presigned_get.s3_presigned.uri,
      );
      tempData.push({ base64 });
    }
    setReportImages(tempData);
  };

  useEffect(() => {
    getReportMedia();
  }, []);

  // const handleExpandClick = () => {
  //   setExpanded(!expanded);
  // };

  return (
    <div className={`${styles.alert_item} ${className}`}>
      <div
        className={styles.content}
        onClick={() => {
          if (window.getSelection()?.toString().length === 0) {
            onClick();
          }
        }}
      >
        {incident_drugType && (
          <div
            className={styles.alert_title}
            style={{ background: drugColorMapping[incident_drugType] }}
          >
            {incident_drugType}
          </div>
        )}
        <div className={styles.alert_image_parent}>
          {reportImages.length > 0 ? (
            <AlertCardMedia
              mediaDetails={reportImages.map((image: any) => ({
                media_type: "image",
                download_url: image.base64,
              }))}
              resolved={false}
            />
          ) : (
            <div className={styles.alert_description}>
              <Typography variant="body2">{summary}</Typography>
            </div>
          )}
        </div>

        <div className={styles.alert_action}>
          <Divider />
          <div className={styles.alert_info}>
            {person_reportingParty_agency && (
              <div className={styles.truncate}>
                <span className={styles.alert_title}>
                  {person_reportingParty_agency}
                </span>{" "}
              </div>
            )}

            {incident_location_address && (
              <div className={styles.truncate}>
                <span className={styles.alert_title}>
                  {incident_location_address.googleMapsPlace?.name}
                </span>{" "}
              </div>
            )}
            {/* add date*/}
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
              }}
            >
              <div className={styles.alert_date_parent}>
                <Typography variant="caption">{incident_date}</Typography>
              </div>
              {incident_occurrenceNumber && (
                <div className={styles.truncate}>
                  <span
                    className={styles.alert_title}
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "flex-end",
                    }}
                  >
                    {incident_occurrenceNumber}
                  </span>{" "}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReportCard;

// <Card
// style={{
//   margin: 16,
//   padding: 16,
//   border: "1px solid #ccc",
//   borderRadius: 8,
//   transition: "transform 0.2s",
//   cursor: "pointer"
//   // boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)" : "none"
// }}
// >
// <CardContent>
//   {reportImages.length > 0 &&
//     reportImages.map((image: any, index: number) => (
//       <img
//         key={index}
//         src={image.base64}
//         alt="report"
//         style={{
//           width: "100%",
//           height: "auto",
//           marginBottom: 16,
//           borderRadius: 8
//         }}
//       />
//     ))}

//   <Typography
//     variant="h6"
//     style={{
//       backgroundColor:
//         reportType === "Drug Identification Search Request"
//           ? "#FFD700"
//           : "#FF4500",
//       padding: 8,
//       borderRadius: 4,
//       color: "#fff",
//       textAlign: "center"
//     }}
//   >
//     {reportType}
//   </Typography>
//   <Typography
//     variant="body2"
//     style={{
//       whiteSpace: "pre-wrap",
//       marginTop: 16
//     }}
//   >
//     {summary}
//   </Typography>
//   {/* <IconButton
//     onClick={handleExpandClick}
//     aria-expanded={expanded}
//     aria-label="show more"
//     style={{ marginLeft: "auto", display: "block" }}
//   >
//     {expanded ? <ExpandLess /> : <ExpandMore />}
//   </IconButton> */}
//   {/* <Collapse in={expanded} timeout="auto" unmountOnExit>
//     <Typography
//       variant="body2"
//       style={{
//         whiteSpace: "pre-wrap",
//         marginTop: 16
//       }}
//     >
//       {details}
//     </Typography>
//   </Collapse> */}
//   <Button
//     variant="contained"
//     color="primary"
//     style={{
//       marginTop: 16
//     }}
//     onClick={onClick}
//     aria-label={`View details of report ${index + 1}`}
//   >
//     View Details
//   </Button>
// </CardContent>
// </Card>
