//External Imports
import { ArrowBack, ExpandMore } from "@mui/icons-material";
import {
  Button,
  Paper,
  Divider,
  FormControlLabel,
  Checkbox,
  CircularProgress,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from "@mui/material";
import { Formik, FormikHelpers } from "formik";
import { useContext, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";

//Internal Imports
import PropertyCamerasMap from "./PropertyCamerasMap";
import styles from "./ReviewStep.module.css";
import { API } from "../../../../common/api-client/camera-api/api";
import { IReview } from "../../../../types";
import { CameraRegistryContext } from "../context";

const initialValues: IReview = {
  disclaimerAgreed: false,
};

// Define the validation schema
const reviewValidationSchema = Yup.object().shape({
  disclaimerAgreed: Yup.bool()
    .oneOf([true], "You must agree to the disclaimer")
    .required("You must agree to the disclaimer"),
});

const DisplayRow = ({ keyName, value }: { keyName: string; value: string }) => (
  <div className={styles.displayRowContainer}>
    <div className={styles.rowContainer}>
      <Typography className={styles.rowItemKey}>
        <b>{keyName}: </b>
      </Typography>
      <Typography className={styles.rowItemValue} whiteSpace="pre-line">
        {value}
      </Typography>
    </div>
    <Divider />
  </div>
);

const ReviewStep = ({
  nextStep,
  prevStep,
}: {
  nextStep?: () => void;
  prevStep?: () => void;
}) => {
  //   const [isSubmitionCompleted, setSubmitionCompleted] = useState(false);
  const [expandedAccordion, setExpandedAccordion] = useState<boolean>(true);
  const navigate = useNavigate();
  const { police, property, cameras, contacts, clearForm } = useContext(
    CameraRegistryContext,
  );
  console.log("Initial property: ", property);
  console.log("Initial cameras: ", cameras);
  console.log("Initial contacts: ", contacts);

  useEffect(() => {
    console.log("In UseEffect Add Cameras");
    if (
      !property ||
      !property?.googleMapsPlace?.geometry ||
      !cameras ||
      !contacts ||
      !clearForm
    )
      navigate("add-address");
  }, []);

  if (
    !property ||
    !property?.googleMapsPlace?.geometry ||
    !cameras ||
    !contacts ||
    !clearForm
  ) {
    console.log("didn't pass");
    return <>Error: Please notify support@tryrubicon.com</>;
  }

  useEffect(() => {
    API.property.patchPortal(property.id ?? "", {
      place_details: property.googleMapsPlace,
      location_type: property.type,
      business_name: property.businessName,
      additional_description: property.additionalPropertyDescription,
      installed_by: cameras.installedBy,
      additional_description_cameras: cameras.additionalCamerasDescription,
      added_by:
        contacts.contacts[0].firstName + " " + contacts.contacts[0].lastName,
      added_by_email: contacts.contacts[0].email,
    });
  }, []);

  const handleOnSubmit = async (
    values: IReview,
    helpers: FormikHelpers<IReview>,
  ) => {
    console.log("SUBMITTING ", values);
    helpers.setSubmitting(true);

    const result = await API.property.submitPortal(property.id ?? "", {});

    if (result.id) {
      console.log("SUCCESFULLY PASSED");
      clearForm();
      if (nextStep) {
        nextStep();
      } else {
        navigate("../final", { replace: true });
      }
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={reviewValidationSchema}
      onSubmit={handleOnSubmit}
    >
      {(props) => {
        const {
          values,
          touched,
          errors,
          isSubmitting,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
        } = props;

        return (
          <form className={styles.formContainer} onSubmit={handleSubmit}>
            <Accordion
              disableGutters
              elevation={3}
              expanded={expandedAccordion}
              onChange={() => setExpandedAccordion((prevValue) => !prevValue)}
            >
              <AccordionSummary
                expandIcon={<ExpandMore />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <div className={styles.accordionTitleContainer}>
                  <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                    Review Information
                  </Typography>
                </div>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  Please Carefully review all of the provided information.
                  Navigate back to appropriate sections to make a change
                </Typography>
              </AccordionDetails>
            </Accordion>

            <Paper elevation={3} className={styles.sectionContainer}>
              <Typography variant="h6">
                {property.googleMapsPlace?.formatted_address
                  ? property.googleMapsPlace.formatted_address?.includes(
                      property.googleMapsPlace.name
                        ? property.googleMapsPlace.name
                        : "",
                    )
                    ? property.googleMapsPlace.formatted_address
                    : property.googleMapsPlace.name +
                      " - " +
                      property.googleMapsPlace.formatted_address
                  : ""}
              </Typography>

              <DisplayRow keyName={"Type"} value={property.type} />
              {property.type === "Business" && (
                <DisplayRow
                  keyName={"Business Name"}
                  value={property.businessName}
                />
              )}
              <DisplayRow
                keyName={"Additional Property Details"}
                value={property.additionalPropertyDescription}
              />

              <PropertyCamerasMap
                location={
                  property.googleMapsPlace?.geometry
                    ? property.googleMapsPlace.geometry
                    : { lat: 43.6532, lng: -79.3832 }
                }
                values={cameras}
                setFieldValue={setFieldValue}
                errors={errors}
                displayOnly
              />

              <DisplayRow
                keyName={"Number of Cameras"}
                value={cameras.cameras.length + ""}
              />

              {cameras.cameras.map((camera, index) => {
                return (
                  <div key={index} style={{ width: "100%" }}>
                    <Typography variant="body1" style={{ marginBottom: "8px" }}>
                      <b>CAMERA #{index + 1}</b>
                    </Typography>
                    <div
                      style={{
                        marginLeft: "16px",
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <DisplayRow
                        keyName={"Location on Property"}
                        value={camera.locationDescription}
                      />
                      <DisplayRow
                        keyName={"Field of View"}
                        value={camera.FOVDescription}
                      />
                      <DisplayRow keyName={"Make"} value={camera.Make} />
                      <DisplayRow keyName={"Model"} value={camera.Model} />
                    </div>
                  </div>
                );
              })}

              <DisplayRow keyName={"Installer"} value={cameras.installedBy} />
              <DisplayRow
                keyName={"Additional Camera Details"}
                value={cameras.additionalCamerasDescription}
              />
            </Paper>

            <Paper elevation={3} className={styles.sectionContainer}>
              <Typography variant="h6">
                <b>Contacts</b>
              </Typography>

              {contacts.contacts.map((contact, index) => {
                return (
                  <div key={index} style={{ width: "100%" }}>
                    <Typography style={{ marginBottom: "8px" }} variant="h6">
                      <b>
                        {index === 0
                          ? "Primary Contact"
                          : `Contact #${index + 1}`}
                      </b>
                    </Typography>{" "}
                    <div
                      style={{
                        marginLeft: "16px",
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <DisplayRow
                        keyName={"Name"}
                        value={contact.firstName + " " + contact.lastName}
                      />
                      <DisplayRow keyName={"Email"} value={contact.email} />
                      <DisplayRow
                        keyName={"Primary Phone"}
                        value={contact.phoneNumber}
                      />
                      <DisplayRow
                        keyName={"Secondary Phone"}
                        value={contact.secondaryPhoneNumber}
                      />
                      <DisplayRow
                        keyName={"Contact Notes"}
                        value={contact.notes}
                      />
                    </div>
                  </div>
                );
              })}
            </Paper>

            <Paper elevation={3} className={styles.sectionContainer}>
              <FormControlLabel
                control={
                  <Checkbox
                    name="disclaimerAgreed"
                    color="primary"
                    checked={values.disclaimerAgreed}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                }
                label="Yes, I have reviewed the information"
              />
              {touched.disclaimerAgreed && errors.disclaimerAgreed ? (
                <Typography sx={{ color: "red" }}>
                  {errors.disclaimerAgreed}
                </Typography>
              ) : null}
            </Paper>

            <div className={styles.buttonContainer}>
              <Button
                variant="outlined"
                color="secondary"
                startIcon={<ArrowBack />}
                disabled={isSubmitting}
                onClick={() =>
                  prevStep ? prevStep() : navigate("../add-contacts")
                }
              >
                Back
              </Button>
              <Button
                type="submit"
                variant="contained"
                size="large"
                endIcon={isSubmitting ? <CircularProgress size={20} /> : <></>}
                disabled={isSubmitting}
              >
                Submit
              </Button>
            </div>
          </form>
        );
      }}
    </Formik>
  );
};

export default ReviewStep;
