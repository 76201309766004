import { PersonAdd } from "@mui/icons-material";
import { Button, Grid, Typography } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router";

import { useDevEnvironment } from "../../../DevContext";
import { LinkCell } from "../../../common/DataGridCells";
import { apiReportClient } from "../../../common/api-client";
import { AuthStateContext } from "../../../context";
import { IOrgUser } from "../../../types";
import "./UserManagement.scss";
import { userPermissionChecks } from "../utils";

const useDynamicColumnWidths = (topPageRef) => {
  const calculateColumnWidths = useCallback((parentWidth) => {
    const baseWidth = parentWidth
      ? parentWidth * 0.85
      : window.innerWidth * 0.85;

    const ratios = {
      first_name: 2,
      last_name: 2,
      email: 3,
      badge_number: 1,
      rank_short: 1,
    };
    const totalRatios = Object.values(ratios).reduce(
      (acc, val) => acc + val,
      0,
    );

    const columnWidths = {};
    for (const [key, value] of Object.entries(ratios)) {
      columnWidths[key] = (baseWidth / totalRatios) * value;
    }

    return columnWidths;
  }, []);

  const [columnWidths, setColumnWidths] = useState<{
    [key: string]: number;
  }>(() =>
    calculateColumnWidths(topPageRef.current?.parentElement?.offsetWidth),
  );
  useEffect(() => {
    const handleResize = () => {
      const parentWidth = topPageRef.current?.parentElement?.offsetWidth;
      setColumnWidths(calculateColumnWidths(parentWidth));
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [topPageRef]);

  return columnWidths;
};

const UsersList = () => {
  const navigate = useNavigate();
  const params = useParams();
  const { currentUser } = useContext(AuthStateContext);

  const orgId = params.orgId;

  const [users, setUsers] = useState<IOrgUser[]>([]);

  const currentUserPermissions =
    currentUser?.permissions?.map((permission) => permission.title) || [];

  const permissionsChecks = userPermissionChecks(currentUserPermissions);

  const { isDevEnv } = useDevEnvironment();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await apiReportClient.getUsersList(orgId);
        setUsers(response?.users);
      } catch (error) {
        navigate("/");
      }
    };

    console.info("Fetching users list");
    fetchData();
  }, [orgId, navigate]);

  const userClickHandler = (user) => {
    navigate(`/organizations/${orgId}/users/${user.id}`, {
      replace: true,
      state: { orgId },
    });
  };

  const renderLinkCell = (params) => {
    const id = params.id;
    return (
      <LinkCell
        value={params.value}
        onClick={() => userClickHandler(users.find((item) => item.id === id))}
      />
    );
  };

  const createNewUserHandler = () => {
    navigate(`/organizations/${orgId}/users/new`, {
      replace: true,
      state: { orgId },
    });
  };

  const topPageRef = useRef<HTMLInputElement | null>(null);

  const columnWidths = useDynamicColumnWidths(topPageRef);

  const columns = [
    {
      field: "first_name",
      headerName: "First Name",
      width: columnWidths.first_name,
      renderCell: renderLinkCell,
    },
    {
      field: "last_name",
      headerName: "Last Name",
      width: columnWidths.last_name,
      renderCell: renderLinkCell,
    },
    {
      field: "email",
      headerName: "Email",
      width: columnWidths.email,
    },

    {
      field: "badge_number",
      headerName: "Badge",
      width: columnWidths.badge_number,
      renderCell: renderLinkCell,
    },
    {
      field: "rank_short",
      headerName: "Rank",
      width: columnWidths.rank_short,
    },
  ];

  const loadMore = () => {
    console.info("Loading more users");
  };

  const totalAlerts = 10;

  const CreateUserButton = () => {
    return (
      <Button
        variant="contained"
        color="primary"
        onClick={() => createNewUserHandler()}
        startIcon={<PersonAdd />}
        className="create-user-button"
      >
        Create User
      </Button>
    );
  };

  return (
    <div className="users_list_container">
      <div id="top-page-div" ref={topPageRef}></div>
      <Grid container justifyContent="space-between" alignItems="center">
        <Grid item xs={12} sm={6}>
          <Typography variant="h4" gutterBottom>
            Users List
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6} style={{ textAlign: "right" }}>
          {permissionsChecks.canCreateUsers && <CreateUserButton />}
        </Grid>
      </Grid>
      <DataGrid
        rows={users?.length > 0 ? users : []}
        columns={columns}
        rowSelection={false}
        sx={{
          "& .MuiDataGrid-row:not(.MuiDataGrid-row--dynamicHeight)>.MuiDataGrid-cell":
            {
              whiteSpace: "normal",
              wordWrap: "break-word",
            },
        }}
        onPaginationModelChange={loadMore}
        rowCount={totalAlerts}
      />
    </div>
  );
};

export default UsersList;
