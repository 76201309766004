import { OndemandVideo } from "@mui/icons-material";
import { Button, Typography } from "@mui/material";
import { useRecordContext } from "react-admin";

import { APP_SERVER_URL } from "../../../environment";
import styles from "../report.module.css";

export const AttachmentField = (props) => {
  const record = useRecordContext();
  const { attachments } = record;

  if (attachments && attachments.length > 0) {
    const photos = attachments.filter(
      (item) => item.mime.split("/")[0] === "image",
    );
    const photoLim = 3;
    const videos = attachments.filter(
      (item) => item.mime.split("/")[0] === "video",
    );
    const videoLim = 2;
    return (
      <div className="attachments">
        {photos &&
          photos.slice(0, photoLim).map((item, index) => {
            const url = new URL(`${APP_SERVER_URL}${item.download_url}`);
            return (
              <div key={index}>
                <img
                  src={url.toString()}
                  className={styles.attachmentThumbnail}
                  alt="Report Attachment"
                  onClick={() => {
                    props.onImageClick(attachments, record.id);
                  }}
                />
              </div>
            );
          })}

        {videos &&
          videos.slice(0, videoLim).map((item, index) => {
            return (
              <div key={index} className={styles.videoAttachment}>
                <OndemandVideo
                  fontSize="large"
                  onClick={() => {
                    props.onImageClick(attachments, record.download_url);
                  }}
                />
              </div>
            );
          })}

        {photos.length > photoLim || videos.length > videoLim ? (
          <Button
            variant="outlined"
            style={{ maxHeight: "25px" }}
            size="small"
            onClick={() => {
              props.onImageClick(attachments, record.download_url);
            }}
          >
            <Typography variant="inherit"> Show more </Typography>
          </Button>
        ) : (
          <div></div>
        )}
      </div>
    );
  } else return <div></div>;
};
