import ReactPlayer from "react-player";

import { APP_SERVER_URL } from "../../../environment";
import download from "../../../images/download.png";
import styles from "../report.module.css";

export const DisplayAttachment = (props) => {
  const { attachments } = props;
  return (
    <div className={styles.attachmentContainer}>
      {attachments &&
        attachments.map((item, index) => {
          const url = new URL(`${APP_SERVER_URL}${item.download_url}`);
          const mime = item.mime;

          if (mime.split("/")[0] === "image") {
            return (
              <div key={index} className={styles.downloadBlock}>
                <img
                  src={url.toString()}
                  className={styles.attachmentImageView}
                  alt="Attachment Evidence"
                />
                <a
                  target="_blank"
                  download
                  type="application/octet-stream"
                  rel="noopener noreferrer"
                  href={url.toString()}
                  className={styles.downloadButton}
                >
                  <img
                    src={download}
                    className="download-button-image"
                    alt=""
                  />
                  <div className={styles.downloadCaption}>Download</div>
                  <div className={styles.attachmentName}>{item.filename}</div>
                </a>
              </div>
            );
          } else if (mime.split("/")[0] === "video") {
            return (
              <div key={index}>
                <ReactPlayer
                  url={url.toString()}
                  controls={true}
                  light={false}
                />
                <div className={styles.attachmentName}>{item.filename}</div>
                <a
                  target="_blank"
                  download
                  type="application/octet-stream"
                  rel="noopener noreferrer"
                  href={url.toString()}
                >
                  Download
                </a>
              </div>
            );
          } else {
            return <div></div>;
          }
        })}
    </div>
  );
};
