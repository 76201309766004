import.meta.env.VITE_SERVER_URL;
if (!("VITE_SERVER_URL" in import.meta.env)) {
  throw new Error("VITE_SERVER_URL is missing in .env file");
} else if (!("VITE_ALERT_SERVER_URL" in import.meta.env)) {
  throw new Error("VITE_ALERT_SERVER_URL is missing in .env file");
} else if (!("VITE_GOOGLE_PLACES_KEY" in import.meta.env)) {
  throw new Error("VITE_GOOGLE_PLACES_KEY is missing in .env file");
}
export const APP_SERVER_URL = import.meta.env.VITE_SERVER_URL;
export const APP_ALERT_SERVER_URL = import.meta.env.VITE_ALERT_SERVER_URL;
export const APP_CAMERA_SERVER_URL = import.meta.env.VITE_CAMERA_SERVER_URL;
export const APP_OR_SERVER_URL = import.meta.env.VITE_OR_SERVER_URL;
export const APP_GOOGLE_PLACES_KEY = import.meta.env.VITE_GOOGLE_PLACES_KEY;
export const APP_SURVEYJS_KEY = import.meta.env.VITE_SURVEYJS_KEY;
export const GOOGLE_MAPS_LIBRARIES: (
  | "places"
  | "drawing"
  | "geometry"
  | "localContext"
  | "visualization"
)[] = ["places"];
