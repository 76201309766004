/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CallLogModel } from '../models/CallLogModel';
import type { PatchCallLogRequest } from '../models/PatchCallLogRequest';
import type { PutCallLogRequest } from '../models/PutCallLogRequest';
import type { PutCallLogResponse } from '../models/PutCallLogResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class CallLogService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * @param orgId 
     * @param requestBody 
     * @returns PutCallLogResponse Call log created successfully
     * @throws ApiError
     */
    public putCallLog(
orgId: string,
requestBody: PutCallLogRequest,
): CancelablePromise<PutCallLogResponse> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/report/org/{org_id}/call_log',
            path: {
                'org_id': orgId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param orgId 
     * @param callLogId 
     * @param requestBody 
     * @returns CallLogModel Call log updated successfully
     * @throws ApiError
     */
    public patchCallLog(
orgId: string,
callLogId: string,
requestBody: PatchCallLogRequest,
): CancelablePromise<CallLogModel> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/report/org/{org_id}/call_log/{call_log_id}',
            path: {
                'org_id': orgId,
                'call_log_id': callLogId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}
