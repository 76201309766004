import { OnlineReportingAPI } from "./OnlineReportingAPI";
import { APP_OR_SERVER_URL } from "../../../environment";

export let API = new OnlineReportingAPI({
  BASE: APP_OR_SERVER_URL,
  WITH_CREDENTIALS: true,
});

export function updateToken(token: string) {
  API = new OnlineReportingAPI({
    BASE: APP_OR_SERVER_URL,
    WITH_CREDENTIALS: true,
    HEADERS: {
      "x-modification-token": token,
    },
  });
}
