import { LockOutlined } from "@mui/icons-material";
import {
  Avatar,
  Button,
  TextField,
  Link,
  Grid,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { toast } from "react-toastify";

import styles from "./Home.module.css";
import { apiUserClient } from "../common/api-client";
import { validateEmail } from "../common/utils";

export const HomeForgotPassword = (props) => {
  const [error, setError] = useState<string>("");
  const [email, setEmail] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);

  const handleSubmit = () => {
    setLoading(true);

    setError("");
    if (!email || !validateEmail(email)) {
      setError("Please enter a proper email");
      setLoading(false);
      return;
    }

    apiUserClient
      .resetUser({ email: email })
      .then(() => {
        setLoading(false);
        toast.success(
          "Your email was submitted! Please check your email for login instructions. It may take a few minutes to reach your inbox",
          { autoClose: 10000 },
        );
        props.onSignin();
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err);
      });
  };

  return (
    <div className={styles.paper}>
      <Avatar className={styles.avatar}>
        <LockOutlined />
      </Avatar>
      <Typography component="h1" variant="h5">
        Forgot Password
      </Typography>
      <form className={styles.form} noValidate>
        <TextField
          required
          autoFocus
          type="email"
          variant="outlined"
          id="email"
          label="Email"
          fullWidth
          disabled={loading}
          onChange={(event) => {
            if (event.target.value !== null) setEmail(event.target.value);
          }}
        />
        <Button
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          className={styles.submit}
          disabled={loading}
          onClick={() => {
            handleSubmit();
          }}
        >
          Reset Password
        </Button>
        {error && <h3 className={styles.errorMessage}>{error}</h3>}
        <Grid container>
          <Grid item xs>
            <Link
              className={styles.signAction}
              variant="body2"
              onClick={() => {
                props.onSignin();
              }}
            >
              {"Back to Sign In"}
            </Link>
          </Grid>
          <Grid item>
            <Link
              className={styles.signAction}
              variant="body2"
              onClick={() => {
                props.onSignup();
              }}
            >
              {"Don't have an account? Sign Up"}
            </Link>
          </Grid>
        </Grid>
      </form>
    </div>
  );
};
