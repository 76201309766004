import { Edit } from "@mui/icons-material";
import { Button, CircularProgress, Grid } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router";

import {
  BackToUsersListButton,
  DeactivateUserButton,
  emptyUser,
  generateInformationGrid,
} from "./utils";
import { useDevEnvironment } from "../../../../DevContext";
import { apiReportClient } from "../../../../common/api-client";
import { AuthStateContext } from "../../../../context";
import { IOrgUser } from "../../../../types";
import { Permission } from "../../types";
import { userPermissionChecks } from "../../utils";

const ViewUserPage: React.FC = () => {
  const navigate = useNavigate();
  const [user, setUser] = useState<IOrgUser>(emptyUser);
  const [orgId, setOrgId] = useState<string>("");
  const [userId, setUserId] = useState<string>("");
  const [modifyingOwnProfile, setModifyingOwnProfile] = useState(false);
  const [userPermissions, setUserPermissions] = useState([] as Permission[]);
  const [initUserPermissions, setInitUserPermissions] = useState(
    [] as Permission[],
  );

  const { isDevEnv } = useDevEnvironment();

  const { currentUser } = useContext(AuthStateContext);
  const currentUserPermissions =
    currentUser?.permissions?.map((permission) => permission.title) || [];

  const currentUserPermissionChecks = userPermissionChecks(
    currentUserPermissions,
  );
  const orgUserPermissionChecks = userPermissionChecks(userPermissions);

  useEffect(() => {
    const url = window.location.href;
    const parts = url.split("/").filter((part) => part.length > 0);
    const orgIdUrl = parts[parts.length - 3];
    let userIdUrl = parts[parts.length - 1];
    if (orgIdUrl.length > 0) {
      console.info("Setting org id: ", orgIdUrl);
      setOrgId(orgIdUrl);
    }
    if (userIdUrl.length > 0) {
      if (userIdUrl === "profile") {
        setModifyingOwnProfile(true);
        userIdUrl = currentUser?.id || "";
      }
      console.info("Setting user id: ", userIdUrl);
      setUserId(userIdUrl);
    }
  }, []);

  useEffect(() => {
    if (userId) {
      console.info("Getting user details for user id: ", userId);
      apiReportClient.getUser(userId).then((response) => {
        setUser(response);
      });
    }
  }, [userId]);

  const informationGrid = generateInformationGrid(
    user,
    setUser,
    "view",
    undefined,
    setUserPermissions,
    setInitUserPermissions,
    userPermissions,
    currentUserPermissions,
    orgId,
    modifyingOwnProfile,
  );

  const EditUserButton = ({ profile }: { profile: boolean }) => {
    return (
      <Button
        variant="contained"
        onClick={() => {
          if (profile) {
            navigate(`/users/${currentUser?.id}/profile/edit`);
          } else {
            navigate(`/organizations/${orgId}/users/${userId}/edit`);
          }
        }}
        startIcon={<Edit />}
      >
        Edit User
      </Button>
    );
  };

  const ViewModeButtons = ({ goToProfile }: { goToProfile: boolean }) => {
    console.log("ViewModeButtons: ", goToProfile);
    return (
      <Grid container justifyContent="space-between" alignItems="center">
        <Grid item xs={12} sm={6}></Grid>
        <Grid
          item
          xs={12}
          sm={6}
          style={{
            textAlign: "right",
            display: "flex",
            justifyContent: "flex-end",
            gap: "8px",
          }}
        >
          {userId !== currentUser?.id && (
            <DeactivateUserButton
              userData={user}
              adminHasEditPermission={
                currentUserPermissionChecks?.canCreateUsers
              }
              userHasLoginPermission={orgUserPermissionChecks?.canLogin}
            />
          )}
          {(currentUserPermissionChecks?.canModifyUsers ||
            modifyingOwnProfile) && <EditUserButton profile={goToProfile} />}
        </Grid>
      </Grid>
    );
  };

  if (user.id === "") {
    return <CircularProgress />;
  }

  return (
    <>
      {informationGrid}
      <ViewModeButtons goToProfile={modifyingOwnProfile} />
    </>
  );
};

export default ViewUserPage;
