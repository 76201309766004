import { Chip, TextField, Paper, Autocomplete } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";

import styles from "./ManageTags.module.css";
import { apiAlertClient } from "../../../../common/api-client";
import { ITag } from "../../../../types";
import { SingleAlertContext } from "../../context";

const predefinedTags = [
  "Suspect 1",
  "Suspect 2",
  "Suspect 3",
  "Suspect 4",
  "Suspect 5",
  "Vehicle 1",
  "Vehicle 2",
  "Vehicle 3",
];

export const ManageTags = ({ bulletinID }) => {
  const [tagInput, setTagInput] = useState<string | null>(null);
  const [descInput, setDescInput] = useState<{ [id: string]: string }>({});

  const { alertProperties, setAlertProperties, setAlertProperty } =
    useContext(SingleAlertContext);
  const { tagDict } = alertProperties;
  const updateTagDictInContext = (tagDict: any) => {
    setAlertProperty("tagDict", tagDict);
  };

  useEffect(() => {
    // If tags exist, prepare description object
    if (Object.keys(tagDict).length > 0) {
      const descriptionInput = {};

      for (const tagId of Object.keys(tagDict)) {
        descriptionInput[tagId] = tagDict[tagId].description;
      }
      setDescInput(descriptionInput);
    }
  }, []);

  const addTag = async (tagTitle: string) => {
    for (const tagId of Object.keys(tagDict)) {
      if (tagDict[tagId].title === tagTitle) {
        toast.warning("This tag already exists!");
        return;
      }
    }

    apiAlertClient.addNewTag(bulletinID, tagTitle).then((res) => {
      const newTag: ITag = {
        ...res,
      };
      updateTagDictInContext({ ...tagDict, [res.id]: newTag });
      setDescInput((prev) => ({ ...prev, [res.id]: "" }));
    });
    setTagInput(null);
  };

  const updateDescription = async (tagID: string) => {
    apiAlertClient
      .patchTag(bulletinID, tagID, {
        ...tagDict[tagID],
        description: descInput[tagID],
      })
      .then((res) => {
        updateTagDictInContext({ ...tagDict, [tagID]: res });
      });
  };

  const deleteTag = async (tagID: string) => {
    apiAlertClient.removeBulletinTag(bulletinID, tagID).then(() => {
      const updatedTagDict = { ...tagDict };
      delete updatedTagDict[tagID];
      updateTagDictInContext(updatedTagDict);

      const updatedDescInput = { ...descInput };
      delete updatedDescInput[tagID];
      setDescInput(updatedDescInput);
    });
  };

  return (
    <Paper elevation={4} style={{ padding: "24px 20px" }}>
      <div className={styles.tagsContainer}>
        {Object.keys(tagDict).map((tagID) => (
          <div key={tagID} className={styles.oneTagContainer}>
            <Chip
              label={tagDict[tagID].title}
              onDelete={() => deleteTag(tagID)}
            />
            <TextField
              label="Description"
              value={descInput[tagID] || ""}
              onChange={(e) =>
                setDescInput((prev) => ({ ...prev, [tagID]: e.target.value }))
              }
              fullWidth
              multiline
              margin="none"
              variant="outlined"
              minRows={3}
              onBlur={() => updateDescription(tagID)}
            />
          </div>
        ))}
      </div>

      <Autocomplete
        value={tagInput}
        freeSolo
        autoComplete
        // autoSelect
        clearOnBlur
        onChange={(_event, newValue) => {
          if (newValue) addTag(newValue);
          setTagInput(null);
        }}
        options={predefinedTags.filter(
          (tagTitle) =>
            !Object.values(tagDict).find(
              (tag) => (tag as ITag).title === tagTitle,
            ),
        )}
        className={styles.addTagContainer}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Add a Tag"
            variant="outlined"
            placeholder="Select or type a custom tag"
          />
        )}
      />
    </Paper>
  );
};
