import { FiberNew, Check, Close, Add, LooksOne } from "@mui/icons-material";
import {
  Timeline,
  TimelineItem,
  TimelineSeparator,
  TimelineContent,
  TimelineOppositeContent,
  timelineOppositeContentClasses,
  TimelineDot,
} from "@mui/lab";
import { Paper, Typography } from "@mui/material";
import { format } from "date-fns";

import displayActionsStyles from "./displayActions.module.css";
import styles from "../report.module.css";

export const DisplayActions = (props) => {
  const { actions } = props;
  return (
    <div className={styles.attachmentContainer}>
      {actions &&
        actions.map((item, index) => {
          let status = item.actionType;
          if (status === "REPORT_CREATE") {
            status = "REPORT WAS CREATED";
          } else if (status === "REPORT_STATUS_APPROVE") {
            status = "APPROVED";
          } else if (status === "REPORT_STATUS_REJECT") {
            status = "REJECTED";
          } else if (status === "REPORT_MODIFY") {
            status = "REPORT MODIFIED";
          } else if (status === "REPORT_OCCURENCE_NUMBER_SET") {
            status = "OCCURRENCE NUMBER CHANGED";
          }
          return (
            <Timeline
              key={index}
              className={displayActionsStyles.timeline}
              ref={actions.scrollToActionsBlock}
              sx={{
                [`& .${timelineOppositeContentClasses.root}`]: {
                  flex: 0.2,
                },
              }}
            >
              <TimelineItem>
                <TimelineOppositeContent
                  className={displayActionsStyles.timelineContent}
                >
                  <Typography variant="body2" color="textSecondary">
                    {format(Date.parse(item.time_created), "MMMM dd, p")}
                  </Typography>
                </TimelineOppositeContent>
                <TimelineSeparator>
                  {status === "REPORT WAS CREATED" && (
                    <TimelineDot className={styles.newIcon}>
                      <FiberNew fontSize="small" />
                    </TimelineDot>
                  )}
                  {status === "APPROVED" && (
                    <TimelineDot className={styles.approvedIcon}>
                      <Check fontSize="small" />
                    </TimelineDot>
                  )}
                  {status === "REJECTED" && (
                    <TimelineDot className={styles.rejectedIcon}>
                      <Close fontSize="small" />
                    </TimelineDot>
                  )}
                  {status === "REPORT MODIFIED" && (
                    <TimelineDot className={styles.modifiedIcon}>
                      <Add fontSize="small" />
                    </TimelineDot>
                  )}
                  {status === "OCCURRENCE NUMBER CHANGED" && (
                    <TimelineDot className={styles.occurrenceNumberIcon}>
                      <LooksOne fontSize="small" />
                    </TimelineDot>
                  )}
                </TimelineSeparator>
                <TimelineContent>
                  <Paper elevation={3} className={displayActionsStyles.paper}>
                    <Typography variant="subtitle2" component="h1">
                      {status}
                    </Typography>
                    <Typography display="inline">
                      {item.actionData.user ? "By: " : null}{" "}
                    </Typography>
                    <Typography>
                      {item.actionData?.user?.first_name}{" "}
                      {item.actionData?.user?.last_name}
                    </Typography>
                    <Typography display="inline">
                      {item.actionData.note ? "Note: " : null}
                    </Typography>
                    <Typography
                      variant="body1"
                      style={{ whiteSpace: "pre-line" }}
                      paragraph
                    >
                      {item.actionData?.note?.message}
                    </Typography>
                  </Paper>
                </TimelineContent>
              </TimelineItem>
            </Timeline>
          );
        })}
    </div>
  );
};
