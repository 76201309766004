import { AddressField } from "../fields/AddressField";
import { ReportDateField } from "../fields/ReportDateField";
import { ReportField } from "../fields/ReportField";
import { TheftFromVehiclePropertyField } from "../fields/TheftFromVehiclePropertyField";
import styles from "../report.module.css";

export const LostProperty = (props) => {
  const { data } = props;

  return (
    <>
      {data.info && data.info.lostProperty && (
        <div className="theftfromVehicle">
          <ReportDateField
            showtime={true}
            className="report-field-row"
            value={data.info.lostProperty.startTime}
            label="Start Date/Time of Incident"
          />
          <ReportDateField
            showtime={true}
            className="report-field-row"
            value={data.info.lostProperty.endTime}
            label="End Date/Time of Incident"
          />
          <ReportField
            className="report-field-row"
            value={data.info.lostProperty.locationType}
            label="Location Type"
          />
          <AddressField
            label="Incident Location"
            address={data.info.lostProperty.streetAddress}
            onEditAddress={() => {
              props.onEditAddress(
                data.info.lostProperty.streetAddress,
                "info.lostProperty.streetAddress",
              );
            }}
          />
          {data.info.lostProperty.narrative && (
            <div className={styles.subHeading}>Narrative</div>
          )}
          {data.info.lostProperty.narrative || props.clickState ? (
            <ReportField
              className={styles.narrativeField + " report-field-row"}
              value={data.info.lostProperty.narrative}
              label="Incident Description"
            />
          ) : null}

          {data.info &&
            data.info.lostProperty &&
            data.info.lostProperty.property && (
              <div className={styles.subHeading}>Property Information</div>
            )}
          {data.info &&
            data.info.lostProperty &&
            data.info.lostProperty.property && (
              <TheftFromVehiclePropertyField
                data={data.info.lostProperty.property}
              />
            )}
        </div>
      )}
    </>
  );
};
