import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Chip,
  FormControlLabel,
  Grid,
  Popover,
  TextField,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import match from "autosuggest-highlight/match";
import parse from "autosuggest-highlight/parse";
import dayjs from "dayjs";
import { useContext } from "react";
import { useSearchParams } from "react-router-dom";

import { BULLETIN_TYPE, crimeGroupedTypes } from "../constants";
import { AlertViewContext } from "../context";

const alertFilterConfig = (searchParams: URLSearchParams) => [
  {
    title: "My Alerts",
    urlParam: "myAlerts",
    value: searchParams.get("myAlerts"),
    displayFullLabel: false,
  },
  {
    title: "Incident Start Date",
    urlParam: "incidentStartDate",
    value: searchParams.get("incidentStartDate"),
    displayFullLabel: true,
  },
  {
    title: "Incident End Date",
    urlParam: "incidentEndDate",
    value: searchParams.get("incidentEndDate"),
    displayFullLabel: true,
  },
  {
    title: "Issued Start Date",
    urlParam: "issuedStartDate",
    value: searchParams.get("issuedStartDate"),
    displayFullLabel: true,
  },
  {
    title: "Issued End Date",
    urlParam: "issuedEndDate",
    value: searchParams.get("issuedEndDate"),
    displayFullLabel: true,
  },
  {
    title: "Bulletin Type",
    urlParam: "bulletinType",
    value: searchParams.get("bulletinType"),
    displayFullLabel: true,
  },
  {
    title: "Associated Crime",
    urlParam: "associatedCrime",
    value: searchParams.get("associatedCrime"),
    displayFullLabel: true,
  },
  {
    title: "Police Service",
    urlParam: "policeService",
    value: searchParams.get("policeService"),
    displayFullLabel: true,
  },
  {
    title: "Showing Similar",
    urlParam: "similarTo",
    value: searchParams.get("similarTo"),
    displayFullLabel: true,
  },
];

export const FilterChip = ({
  title,
  value,
  onRemove,
  displayFullLabel,
}: {
  title: string;
  value?: string;
  onRemove: () => void;
  displayFullLabel?: boolean;
}) => (
  <Chip
    label={displayFullLabel ? `${title}: ${value}` : title}
    onDelete={onRemove}
    color="secondary"
    variant="filled"
  />
);

export const RenderVisibleFilterChips = ({
  searchParams,
  setSearchParams,
}: {
  searchParams: URLSearchParams;
  setSearchParams: (searchParams: URLSearchParams) => void;
}) => {
  const filters = alertFilterConfig(searchParams);
  const hasActiveFilters = filters.some((filter) => filter.value);

  const handleClearAll = () => {
    const focusId = searchParams.get("focusId");
    const newParams = new URLSearchParams();
    if (focusId) newParams.set("focusId", focusId);
    setSearchParams(newParams);
  };

  return (
    <Box
      sx={{ display: "flex", flexWrap: "wrap", gap: 1, alignItems: "center" }}
    >
      {filters.map(
        (filter) =>
          filter.value && (
            <FilterChip
              key={filter.urlParam}
              title={filter.title}
              value={filter.value}
              onRemove={() => {
                searchParams.delete(filter.urlParam);
                setSearchParams(searchParams);
              }}
              displayFullLabel={filter.displayFullLabel}
            />
          ),
      )}
      {hasActiveFilters && (
        <Button variant="outlined" color="primary" onClick={handleClearAll}>
          Clear Filters
        </Button>
      )}
    </Box>
  );
};

const DateRangePicker = ({
  labelFrom,
  labelTo,
  startDate,
  endDate,
  onStartChange,
  onEndChange,
}: {
  labelFrom: string;
  labelTo: string;
  startDate: string;
  endDate: string;
  onStartChange: (newValue: string) => void;
  onEndChange: (newValue: string) => void;
}) => (
  <div className="date-range">
    <DatePicker
      label={labelFrom}
      value={startDate ? dayjs(startDate) : null}
      onChange={(newValue) =>
        onStartChange(newValue?.toISOString().split("T")[0] || "")
      }
      sx={{ flexGrow: 1 }}
      slotProps={{ textField: { variant: "outlined", fullWidth: true } }}
    />
    <DatePicker
      label={labelTo}
      value={endDate ? dayjs(endDate) : null}
      onChange={(newValue) =>
        onEndChange(newValue?.toISOString().split("T")[0] || "")
      }
      sx={{ flexGrow: 1 }}
      slotProps={{ textField: { variant: "outlined", fullWidth: true } }}
    />
  </div>
);

const AlertFilters = ({
  isOpen,
  anchorEl,
  onClose,
}: {
  isOpen: boolean;
  anchorEl: HTMLElement | null;
  onClose: () => void;
}) => {
  const { policeServices } = useContext(AlertViewContext);
  const [searchParams, setSearchParams] = useSearchParams();

  const updateSearchParams = (key, value) => {
    const newSearchParams = new URLSearchParams(searchParams);
    value ? newSearchParams.set(key, value) : newSearchParams.delete(key);
    setSearchParams(newSearchParams);
  };

  return (
    <Popover
      open={isOpen}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
      transformOrigin={{ vertical: "top", horizontal: "left" }}
      sx={{ marginTop: 1 }}
    >
      <Grid
        container
        spacing={2}
        justifyContent="flex-start"
        alignItems="center"
        sx={{
          padding: 2,
          width: { xs: "100%", sm: anchorEl?.clientWidth || "auto" },
          marginBottom: 1,
        }}
      >
        <Grid item xs={12} sm="auto">
          <FormControlLabel
            control={
              <Checkbox
                checked={searchParams.get("myAlerts") === "true"}
                onChange={(ev) =>
                  updateSearchParams("myAlerts", ev.target.checked)
                }
              />
            }
            label="My Alerts"
            labelPlacement="end"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <DateRangePicker
            labelFrom="Incident Date From"
            labelTo="Incident Date To"
            startDate={searchParams.get("incidentStartDate") || ""}
            endDate={searchParams.get("incidentEndDate") || ""}
            onStartChange={(value) =>
              updateSearchParams("incidentStartDate", value)
            }
            onEndChange={(value) =>
              updateSearchParams("incidentEndDate", value)
            }
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <DateRangePicker
            labelFrom="Issued Date From"
            labelTo="Issued Date To"
            startDate={searchParams.get("issuedStartDate") || ""}
            endDate={searchParams.get("issuedEndDate") || ""}
            onStartChange={(value) =>
              updateSearchParams("issuedStartDate", value)
            }
            onEndChange={(value) => updateSearchParams("issuedEndDate", value)}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Autocomplete
            id="select-bulltin-type"
            options={Object.values(BULLETIN_TYPE)}
            value={searchParams.get("bulletinType")}
            onChange={(event, newValue) =>
              updateSearchParams("bulletinType", newValue)
            }
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                label="Type of Bulletin"
                fullWidth
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Autocomplete
            id="select-crime"
            options={crimeGroupedTypes}
            groupBy={(option) => option.group}
            getOptionLabel={(option) => option.crime}
            value={
              crimeGroupedTypes.find(
                (c) => c.crime === searchParams.get("associatedCrime"),
              ) || null
            }
            onChange={(event, newValue) =>
              updateSearchParams("associatedCrime", newValue?.crime)
            }
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                label="Associated Crime"
                fullWidth
              />
            )}
            renderOption={(props, option, { inputValue }) => (
              <li {...props}>
                {parse(
                  option.crime,
                  match(option.crime, inputValue, { insideWords: true }),
                ).map((part, index) => (
                  <span
                    key={index}
                    style={{ fontWeight: part.highlight ? 700 : 400 }}
                  >
                    {part.text}
                  </span>
                ))}
              </li>
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Autocomplete
            id="police-service-select-autocomplete"
            options={policeServices}
            getOptionLabel={(option) => option.name}
            value={
              policeServices.find(
                (p) => p.name === searchParams.get("policeService"),
              ) || null
            }
            onChange={(event, newValue) =>
              updateSearchParams("policeService", newValue?.name)
            }
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                label="Issued Police Service"
                fullWidth
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <Button
            variant="outlined"
            color="primary"
            fullWidth
            onClick={() => setSearchParams(new URLSearchParams())}
          >
            Clear Filters
          </Button>
        </Grid>
      </Grid>
    </Popover>
  );
};

export default AlertFilters;
