import React, { useState } from "react";

import { ReportField } from "./ReportField";
import { ShowHideButton } from "./UI/ShowHideButton";
import styles from "../report.module.css";

export const BicycleInformationField = (props) => {
  const { data } = props;
  const [show, setShow] = useState(false);
  return (
    <>
      {data &&
        data.length > 0 &&
        data.map((row, index) => (
          <div key={index}>
            <div className={styles.subHeading}>
              Bicycle {index + 1}
              <ShowHideButton
                onClick={() => {
                  setShow(!show);
                }}
              >
                {" "}
                {show ? "Hide " : "Show "} empty fields{" "}
              </ShowHideButton>
            </div>

            <ReportField value={row.brand} source="row.brand" label="Brand" />
            <ReportField value={row.model} source="row.model" label="Model" />
            <ReportField
              value={row.bicycleType}
              source="row.bicycleType"
              label="Bicycle Type"
            />
            {row.style || show ? (
              <ReportField
                value={row.style}
                source="row.style"
                label="Bicycle Style"
              />
            ) : null}
            {row.handleType || show ? (
              <ReportField
                value={row.handleType}
                source="row.handleType"
                label="Handle Style"
              />
            ) : null}
            {row.seatStyle || show ? (
              <ReportField
                value={row.seatStyle}
                source="row.seatStyle"
                label="Seat Style"
              />
            ) : null}
            {row.speed || show ? (
              <ReportField
                value={row.speed}
                source="row.speed"
                label="Bicycle Speed"
              />
            ) : null}
            {row.wheelSize || show ? (
              <ReportField
                value={row.wheelSize}
                source="row.wheelSize"
                label="Wheel Size"
              />
            ) : null}
            <ReportField
              value={row.colour}
              source="row.colour"
              label="Colour"
            />
            <ReportField
              value={row.serialNumber}
              source="row.serialNumber"
              label="Serial Number"
            />
            <ReportField
              value={row.totalValue}
              source="row.totalValue"
              label="Total Value"
            />
            {row.additionalDescription || show ? (
              <ReportField
                value={row.additionalDescription}
                source="row.additionalDescription"
                label="Additional Description"
              />
            ) : null}
          </div>
        ))}
    </>
  );
};
