import { Info, Share as ShareIcon } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Autocomplete,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import match from "autosuggest-highlight/match";
import parse from "autosuggest-highlight/parse";
import React, { useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";

import styles from "./ShareAccess.module.scss";
import { apiAlertClient } from "../../../../common/api-client";
import { AuthStateContext } from "../../../../context";
import { IAlertGroup } from "../../../../types/typesAlerts";
import { AlertViewContext } from "../../context";
// import { getUserGroups } from "../../../../common/api-client/loadAlerts";

interface ShareAccessProps {
  bulletinID: number;
  setSharedFlag: (flag: boolean) => void;
  readOnly?: boolean;
}

interface RestrictionLevels {
  myself: boolean;
  myAgency: boolean;
  otherAgencies: boolean;
  cisoAutoTheft: boolean;
}

const ShareAccess = ({
  bulletinID,
  setSharedFlag,
  readOnly = false,
}: ShareAccessProps) => {
  const { policeServices } = useContext(AlertViewContext);
  const { currentUserAgency } = useContext(AuthStateContext);

  const initialRestrictionLevels: RestrictionLevels = {
    myself: true,
    myAgency: false,
    otherAgencies: false,
    cisoAutoTheft: false,
  };

  const [initialGroups, setInitialGroups] = useState<IAlertGroup[]>([]);

  const [initialAgencies, setInitialAgencies] = useState<IAlertGroup[]>([]);
  const [otherAgencyOptions, setOtherAgencyOptions] = useState<IAlertGroup[]>(
    [],
  );

  const [selectedAgencies, setSelectedAgencies] = useState<IAlertGroup[]>([]);
  const otherSelectedAgencies = selectedAgencies.filter(
    (agency) => agency.id !== currentUserAgency.id,
  );

  const [additionalGroups, setAdditionalGroups] = useState<IAlertGroup[]>([]);
  const cisoGroupID =
    window.location.href.includes("test.") ||
    window.location.href.includes("localhost")
      ? 63
      : 91;
  const autoTheftGroup = {
    id: cisoGroupID,
    name: "Auto Theft - CISO",
    is_police_service: false,
    is_self_group: false,
  };

  const [showAdvanced, setShowAdvanced] = useState(false);
  const [isShareLoading, setIsShareLoading] = useState(true);
  const [updatedRestrictionLevels, setUpdatedRestrictionLevels] = useState(
    initialRestrictionLevels,
  );

  const hasChanges = () => {
    const initialIds = new Set(initialAgencies.map((agency) => agency.id));
    const selectedIds = new Set(selectedAgencies.map((agency) => agency.id));
    if (initialIds.size !== selectedIds.size) {
      return true;
    }

    const previouslySelected =
      initialGroups.filter((group) => group.id === cisoGroupID).length > 0;
    const nowSelected = additionalGroups.length > 0;
    if (previouslySelected !== nowSelected) {
      return true;
    }

    for (const id of initialIds) {
      if (!selectedIds.has(id)) {
        return true;
      }
    }
    return false;
  };

  useEffect(() => {
    if (bulletinID && !readOnly) {
      const fetchBulletinAgencies = async () => {
        try {
          const res: { items: IAlertGroup[] } =
            await apiAlertClient.getBulletinGroups(bulletinID);
          if (res?.items) {
            setInitialGroups(res.items);
            const items = res.items.filter(
              (agency) => agency.is_police_service === true,
            );
            const additionalGroups = res.items.filter(
              (agency) => agency.id === cisoGroupID,
            );

            setInitialAgencies(items);
            setSelectedAgencies(items);
            setInitRestrictionLevels(items, additionalGroups);
            setOtherAgencyOptions(
              policeServices.filter(
                (agency) => agency.id !== currentUserAgency.id,
              ),
            );
            setAdditionalGroups(additionalGroups);
            setIsShareLoading(false);
          }
        } catch (error) {
          console.error("Error fetching bulletin agencies", error);
        }
      };
      fetchBulletinAgencies();
    }
    if (readOnly) {
      setIsShareLoading(false);
    }
  }, [bulletinID]);

  const setInitRestrictionLevels = (
    currentAgencies: IAlertGroup[],
    additionalGroups: IAlertGroup[],
  ) => {
    const agencyNames = currentAgencies
      .map((agency) => agency.name)
      .filter((name) => name);
    const newRestrictionLevels = { ...initialRestrictionLevels };

    if (agencyNames.includes(currentUserAgency.name)) {
      newRestrictionLevels.myAgency = true;
    }
    if (agencyNames.length > 1) {
      newRestrictionLevels.otherAgencies = true;
    }
    if (additionalGroups.length > 0) {
      newRestrictionLevels.cisoAutoTheft = true;
    }
    setUpdatedRestrictionLevels(newRestrictionLevels);
  };

  const handleMyAgencyChange = (checked: boolean) => {
    setUpdatedRestrictionLevels((prevState) => ({
      ...prevState,
      myAgency: checked,
      otherAgencies: checked ? prevState.otherAgencies : false,
    }));

    if (checked) {
      setSelectedAgencies((prevState) => [...prevState, currentUserAgency]);
    } else {
      setSelectedAgencies([]);
    }
  };

  const handleOtherAgenciesChange = (checked: boolean) => {
    setUpdatedRestrictionLevels((prevState) => ({
      ...prevState,
      myAgency: checked ? true : prevState.myAgency,
      otherAgencies: checked,
    }));

    if (checked) {
      const updatedSelectedAgencies = policeServices.filter(
        (agency) => agency.is_police_service,
      );
      setSelectedAgencies(updatedSelectedAgencies);
    } else {
      setSelectedAgencies([currentUserAgency]);
    }
  };

  const handleAutoTheftChange = (checked: boolean) => {
    setUpdatedRestrictionLevels((prevState) => ({
      ...prevState,
      cisoAutoTheft: checked,
    }));

    if (checked) {
      setAdditionalGroups([autoTheftGroup]);
    } else {
      setAdditionalGroups([]);
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = event.target;

    if (name === "myAgency") {
      handleMyAgencyChange(checked);
    } else if (name === "otherAgencies") {
      handleOtherAgenciesChange(checked);
    } else if (name === "cisoAutoTheft") {
      handleAutoTheftChange(checked);
    }
  };

  const [isSharedSuccessfully, setIsSharedSuccessfully] = useState({
    agenciesToSend: false,
    agenciesToRemove: false,
  });

  const handleSubmit = async () => {
    const agenciesToSend = selectedAgencies.filter(
      (agency) =>
        !initialGroups.find((initialGroup) => initialGroup.id === agency.id)
          ?.is_police_service,
    );

    const previouslySelected =
      initialGroups.filter((group) => group.id === cisoGroupID).length > 0;
    if (!previouslySelected && updatedRestrictionLevels.cisoAutoTheft) {
      agenciesToSend.push(autoTheftGroup);
    }

    const agenciesToRemove = initialAgencies.filter(
      (initialAgency) =>
        !selectedAgencies.find((agency) => agency.id === initialAgency.id)
          ?.is_police_service,
    );

    if (previouslySelected && !updatedRestrictionLevels.cisoAutoTheft) {
      agenciesToRemove.push(autoTheftGroup);
    }

    if (agenciesToSend.length) {
      try {
        await apiAlertClient.addBulletinGroups(bulletinID, agenciesToSend);
        toast.success("Bulletin successfully shared!");
        console.info(
          "Bulletin successfully shared with agencies",
          agenciesToSend,
        );
        setIsSharedSuccessfully((prevState) => ({
          ...prevState,
          agenciesToSend: true,
        }));
      } catch (error) {
        toast.error("An error occurred while sharing the bulletin.");
        console.error(error);
      }
    } else {
      setIsSharedSuccessfully((prevState) => ({
        ...prevState,
        agenciesToSend: true,
      }));
    }
    if (agenciesToRemove.length) {
      try {
        await apiAlertClient.removeBulletinGroups(bulletinID, agenciesToRemove);
        toast.success("Bulletin successfully shared!");
        console.info(
          "Bulletin successfully removed agencies",
          agenciesToRemove,
        );
        setIsSharedSuccessfully((prevState) => ({
          ...prevState,
          agenciesToRemove: true,
        }));
      } catch (error) {
        toast.error("An error occurred while sharing the bulletin.");
        console.error(error);
      }
    } else {
      setIsSharedSuccessfully((prevState) => ({
        ...prevState,
        agenciesToRemove: true,
      }));
    }
  };

  const refreshAgencies = async () => {
    try {
      const res: { items: IAlertGroup[] } =
        await apiAlertClient.getBulletinGroups(bulletinID);
      if (res?.items) {
        setInitialGroups(res.items);
        const items = res.items.filter(
          (agency) => agency.is_police_service === true,
        );
        const additionalGroups = res.items.filter(
          (agency) => agency.id === cisoGroupID,
        );
        setInitialAgencies(items);
        setSelectedAgencies(items);
        setInitRestrictionLevels(items, additionalGroups);
        setOtherAgencyOptions(
          policeServices.filter((agency) => agency.id !== currentUserAgency.id),
        );
        setIsShareLoading(false);
      }
    } catch (error) {
      console.error("Error fetching bulletin agencies", error);
    }
  };

  useEffect(() => {
    if (
      isSharedSuccessfully.agenciesToSend &&
      isSharedSuccessfully.agenciesToRemove
    ) {
      setTimeout(() => {
        setSharedFlag(true);
        refreshAgencies();
      }, 200);
    }
  }, [isSharedSuccessfully]);

  const handleAdvancedToggle = () => {
    setShowAdvanced(!showAdvanced);
  };

  const renderAgencyOption = (props, option, { inputValue }) => {
    const matches = match(option.name, inputValue);
    const parts = parse(option.name, matches);

    return (
      <li {...props}>
        {parts.map((part, index) => (
          <span key={index} style={{ fontWeight: part.highlight ? 700 : 400 }}>
            {part.text}
          </span>
        ))}
      </li>
    );
  };

  const disableShare = !hasChanges();

  const availableAgencyOptions = otherAgencyOptions.filter(
    (option) => !selectedAgencies.some((agency) => agency.id === option.id),
  );

  return (
    <Paper elevation={4} className={styles.shareAccessContainer}>
      <Accordion elevation={0}>
        <AccordionSummary
          sx={{ padding: "0px" }}
          expandIcon={<Info />}
          // aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <div>
            <Typography variant="h5" sx={{ width: "100%" }}>
              <b>Share Access</b>
            </Typography>
            <Typography>
              Select the Groups that can access this bulletin.
            </Typography>
          </div>
        </AccordionSummary>
        <AccordionDetails sx={{ padding: "0px" }}>
          <Typography>
            Members of these groups will be able to view, search, and analyze
            the bulletin if they also use RubiALERT bulletin management system.
            We recommend sharing with more agencies to increase the chances of a
            successful hit.
          </Typography>
        </AccordionDetails>
      </Accordion>

      <FormControl component="fieldset" fullWidth>
        {/* <FormLabel component="legend">Access Options</FormLabel> */}

        <div className={styles.restrictContainer}>
          <FormControlLabel
            control={
              <Checkbox
                checked={updatedRestrictionLevels.myself}
                disabled
                name="myself"
              />
            }
            label="Myself"
            sx={{ minWidth: "max-content" }}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={updatedRestrictionLevels.myAgency}
                onChange={handleChange}
                name="myAgency"
                disabled={isShareLoading || readOnly}
              />
            }
            label="My Agency"
            sx={{ minWidth: "max-content" }}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={updatedRestrictionLevels.cisoAutoTheft}
                onChange={handleChange}
                name="cisoAutoTheft"
                disabled={isShareLoading || readOnly}
              />
            }
            label="Auto Theft - CISO"
            sx={{ minWidth: "max-content" }}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={updatedRestrictionLevels.otherAgencies}
                onChange={handleChange}
                name="otherAgencies"
                disabled={isShareLoading || readOnly}
              />
            }
            label="Other Agencies"
            sx={{ minWidth: "max-content" }}
          />
        </div>

        {updatedRestrictionLevels.otherAgencies && (
          <div className={styles.buttonContainer}>
            <Button
              onClick={handleAdvancedToggle}
              size="small"
              variant="outlined"
              color="secondary"
            >
              Advanced
            </Button>
          </div>
        )}
      </FormControl>
      {showAdvanced && (
        <Autocomplete
          multiple
          filterSelectedOptions
          options={availableAgencyOptions}
          autoComplete
          autoHighlight
          clearOnBlur
          fullWidth
          openOnFocus
          getOptionLabel={(option) => option.name}
          renderInput={(params) => (
            <TextField {...params} label="Select Agencies" />
          )}
          value={otherSelectedAgencies}
          onChange={(_, newValue) => {
            // Filter out the user agency from the new values
            const filteredNewValue = newValue.filter(
              (agency) => agency.id !== currentUserAgency.id,
            );

            // If 'myAgency' is checked, ensure user agency is always included
            if (updatedRestrictionLevels.myAgency) {
              setSelectedAgencies([currentUserAgency, ...filteredNewValue]);
            } else {
              setSelectedAgencies(filteredNewValue);
            }

            if (filteredNewValue.length === 0) {
              setUpdatedRestrictionLevels((prevState) => ({
                ...prevState,
                otherAgencies: false,
              }));
            } else {
              setUpdatedRestrictionLevels((prevState) => ({
                ...prevState,
                otherAgencies: true,
              }));
            }
          }}
          renderOption={renderAgencyOption}
        />
      )}
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          width: "100%",
        }}
      >
        <Button
          variant="outlined"
          color="primary"
          onClick={handleSubmit}
          fullWidth
          sx={{ maxWidth: "620px" }}
          disabled={disableShare}
          startIcon={
            isShareLoading ? <CircularProgress size={20} /> : <ShareIcon />
          }
        >
          Share
        </Button>
      </Box>
    </Paper>
  );
};

export default ShareAccess;
