import LazyLoadVideo from "./LazyLoadVideo";

const MediaDisplay = ({
  mediaType,
  src,
  onClick,
  onLoad,
  style,
  customVideoErrorMessage,
  handleVideoErrorClick,
}: {
  mediaType: "video" | "image";
  src: string;
  onClick?: () => void;
  onLoad?: any;
  style?: React.CSSProperties;
  customVideoErrorMessage?: string;
  handleVideoErrorClick?: () => void;
}) => {
  if (mediaType === "video") {
    return (
      <LazyLoadVideo
        src={src}
        controls={true}
        onClick={onClick}
        style={{
          cursor: "pointer",
          ...style,
        }}
        handleLoad={onLoad}
        errorMessage={customVideoErrorMessage}
        handleVideoErrorClick={handleVideoErrorClick}
      />
    );
  }
  return (
    <img
      src={src}
      alt="Loaded Content"
      onLoad={onLoad}
      onClick={onClick}
      style={{
        cursor: onClick ? "pointer" : "default",
        ...style,
      }}
    />
  );
};

export default MediaDisplay;
