import { StyleSheet, Font } from "@react-pdf/renderer";

Font.register({
  family: "Helvetica-Bold",
  src: "",
});

export const applyCommonStyles = (styles: any) => {
  return StyleSheet.create({
    ...styles,
  });
};
export const dlPDFHeaderStyles = StyleSheet.create({
  header_container: {
    border: "5px solid #000080",
    padding: 10,
    margin: 16,
    maxHeight: "20%",
  },
  logo_container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    margin: 10,
    height: "80%",
    position: "absolute",
    top: 0,
    left: 0,
    right: "80%",
  },
  police_logo: {
    objectFit: "contain",
    maxHeight: "100%",
    width: "auto",
  },
  operational_area: {
    fontSize: 10,
    textAlign: "center",
  },
  confidentiality_text: {
    fontSize: 9,
    color: "#000080",
    textAlign: "center",
    marginBottom: 4,
  },
  alert_details_container: {
    flexDirection: "row",
    flexWrap: "wrap",
  },
  crime_types_container: {
    flexDirection: "column",
    alignItems: "center",
    marginLeft: "auto",
    marginRight: "auto",
    justifyContent: "center",
  },
  crime_type: {
    fontSize: 22,
    textTransform: "uppercase",
    fontFamily: "Helvetica-Bold",
  },
  associated_crimes_container: {
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "center",
    gap: 4,
  },
  associated_crimes: {
    fontSize: 9,
    borderRadius: 80,
    border: "0.5px solid #000",
    padding: 6,
    textAlign: "center",
  },
  incident_text: {
    fontSize: 9,
    textAlign: "center",
  },
});
export const dlPDFImageStyles = StyleSheet.create({
  page_one_image_container: {
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "center",
    maxHeight: "40%",
    padding: 10,
    gap: 8,
  },
  image_card: {
    flexDirection: "column",
    fontSize: 10,
    alignItems: "center",
  },
  tag: {
    borderRadius: 80,
    border: "0.5px solid #000",
    padding: "2 3",
    width: "auto",
    fontSize: 10,
  },
  tag_description_container: {
    display: "flex",
    flexDirection: "column",
    fontSize: 10,
    alignContent: "center",
  },
  page_one_image: {
    objectFit: "contain",
    padding: 4,
  },
  image_container_remaining: {
    margin: 24,
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "flex-start",
    gap: 4,
    maxHeight: "100%",
  },
  image_card_remaining: {
    height: 190,
  },
  more_images_text: {
    borderTop: "1px solid #000",
    width: "100%",
    fontSize: 10,
    textAlign: "center",
    marginBottom: 2,
  },
  video_link: {
    fontSize: 10,
    textDecoration: "underline",
    marginTop: 2,
  },
});

export const dlPDFDetailsStyles = StyleSheet.create({
  details_container: {
    flexDirection: "column",
    width: "100%",
    marginLeft: 16,
    marginRight: 16,
  },
  address: {
    fontSize: 12,
    fontWeight: "bold",
    textAlign: "left",
    fontFamily: "Helvetica-Bold",
  },
  description: {
    fontSize: 12,
    marginBottom: 4,
    marginTop: 4,
    marginRight: 28,
  },
  contact_label: {
    fontSize: 14,
    fontWeight: "bold",
    textAlign: "left",
    textTransform: "uppercase",
  },
  contact_text: {
    fontSize: 12,
    textAlign: "center",
    paddingBottom: 4,
  },
  contact_details: {
    flexDirection: "row",
    gap: 8,
    marginLeft: 8,
    fontSize: 12,
  },
  police_service_label: {
    fontSize: 10,
    textAlign: "center",
    fontFamily: "Helvetica-Bold",
    marginTop: 8,
  },
  approved_by_label: {
    fontSize: 10,
    textAlign: "center",
    fontFamily: "Helvetica-Bold",
  },
  issue_date: {
    fontSize: 9,
    textAlign: "center",
  },
});

export const dlPDFFooterStyles = StyleSheet.create({
  footer_container: {
    position: "absolute",
    bottom: 0,
    left: 0,
    right: 0,
    flexDirection: "row",
    height: "3%",
    borderTop: "1px solid #grey",
    fontSize: 8,
    marginLeft: 16,
    marginRight: 16,
    paddingTop: 4,
    color: "grey",
  },
});
