// All APIs related to User authentication, profile, etc.
import * as http from "./http";
import { APP_SERVER_URL } from "../../environment";
import { IUser } from "../../types";

export const APIUrl = APP_SERVER_URL;

export const loginUser = (data) => {
  const api = APIUrl + "auth/login";
  return http
    .post(api, { body: data, credentials: "include" })
    .then((response) => {
      return response;
    });
};

export const setPassword = (token: string, password: string) => {
  const api = APIUrl + "auth/set_password";
  return http
    .post(api, { body: { token, new: password }, credentials: "include" })
    .then((response) => {
      return response;
    });
};

export const addPhone = (data) => {
  const api = APIUrl + "auth/add_phone";
  return http
    .post(api, { body: data, credentials: "include" })
    .then((response) => {
      return response;
    });
};

export const verifyOTP = (data) => {
  const api = APIUrl + "auth/verify_otp";
  return http
    .post(api, { body: data, credentials: "include" })
    .then((response) => {
      return response;
    });
};

export const resendOTP = (data) => {
  const api = APIUrl + "auth/resend_code";
  return http
    .post(api, { body: data, credentials: "include" })
    .then((response) => {
      return response;
    });
};

export const verifyPasswordStrength = (password) => {
  const api = APIUrl + "auth/strength_check";
  return http
    .post(api, { body: { password }, credentials: "include" })
    .then((response) => {
      return response;
    });
};

export const logoutUser = () => {
  const api = APIUrl + "auth/logout";
  return http.post(api, { credentials: "include" }).then((response) => {
    window.location.href = "/login";
    window.location.reload();
    return response;
  });
};

export const resetUser = (data) => {
  const api = APIUrl + "auth/reset";
  return http.post(api, { body: data }).then((response) => {
    return response;
  });
};

export const getSSODetails = (organizationSlug: string) => {
  const api = APIUrl + "auth/org/" + organizationSlug;
  return http.get(api).then((response) => {
    return response;
  });
};

export const ssoStart = (connectionID: string) => {
  const api = APIUrl + "auth/sso_start/" + connectionID;
  return http.get(api).then((response) => {
    return response;
  });
};

export const userDetails = (): Promise<IUser> => {
  const api = APIUrl + "user/me";
  return http
    .get(api, { credentials: "include" })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
};

export const ChangePassword = (data) => {
  const api = APIUrl + "auth/password_reset";
  return http
    .post(api, { body: data, credentials: "include" })
    .then((response) => {
      return response;
    });
};

export const ResetPassword = (data) => {
  const api = APIUrl + "auth/email_reset_flow_reset";
  return http
    .post(api, { body: data, credentials: "include" })
    .then((response) => {
      return response;
    });
};

export const getMetabaseURL = (dashboard: number = 2, orgId: string) => {
  const api = `${APIUrl}user/metabase_view?dashboard=${dashboard}&orgId=${orgId}`;

  return http
    .get(api, {
      credentials: "include",
    })
    .then((response) => {
      return response;
    });
};
