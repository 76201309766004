import {
  AdvancedMarker,
  APIProvider,
  Map,
  Pin,
} from "@vis.gl/react-google-maps";
import React, { useEffect, useState } from "react";

import { APP_GOOGLE_PLACES_KEY } from "../../environment";
import { BulletinResourceResponse } from "../../types";

const containerStyle = {
  width: "calc(100% - 32px)",
  height: "calc(100vh - 300px)",
  marginLeft: "8px",
  marginRight: "8px",
};

function AlertsMap({
  alerts,
  alertClickHandler,
  selectedAlertId,
  setSelectedAlertId,
}: {
  alerts: BulletinResourceResponse[];
  alertClickHandler: (alert: BulletinResourceResponse, index: number) => void;
  selectedAlertId: number | null;
  setSelectedAlertId: (alertId: number) => void;
}) {
  const googleMapId = "4380cd85df35e961";
  const [defaultCenter, setDefaultCenter] =
    useState<google.maps.LatLngLiteral | null>(null);
  const [center, setCenter] = useState<google.maps.LatLngLiteral | null>(null);
  const [defaultZoom, setDefaultZoom] = useState(6);
  const [zoom, setZoom] = useState<number | null>(null);
  const [isMapLoaded, setIsMapLoaded] = useState(false);
  const selectedAlert = alerts.find((alert) => alert.id === selectedAlertId);

  useEffect(() => {
    if (alerts.length === 0) return;

    const selectedAlertLocation =
      selectedAlert?.location?.addresses[0]?.geometry;
    if (selectedAlertLocation) {
      setCenter(selectedAlertLocation);
      setDefaultCenter(selectedAlertLocation);
      setZoom(12);
      setDefaultZoom(12);
    } else {
      // Center the map based on the first valid alert if available
      const validCoordinates = alerts
        .map(
          (alert) =>
            alert.location?.addresses?.length &&
            alert.location.addresses[0]?.geometry,
        )
        .filter(
          (geometry) =>
            geometry &&
            typeof geometry.lat === "number" &&
            typeof geometry.lng === "number",
        ) as google.maps.LatLngLiteral[];

      if (validCoordinates.length > 0) {
        const latSum = validCoordinates.reduce(
          (sum, coord) => sum + coord.lat,
          0,
        );
        const lngSum = validCoordinates.reduce(
          (sum, coord) => sum + coord.lng,
          0,
        );
        const centerLat = latSum / validCoordinates.length;
        const centerLng = lngSum / validCoordinates.length;

        setDefaultCenter({
          lat: centerLat,
          lng: centerLng,
        });
      } else {
        setDefaultCenter({
          lat: 0,
          lng: 0,
        });
      }
    }
  }, [alerts]);

  const handleMarkerClick = (alert, index) => {
    setSelectedAlertId(alert.id.toString());
    alertClickHandler(alert, index);
  };

  return (
    <div style={containerStyle}>
      {defaultCenter && (
        <APIProvider apiKey={APP_GOOGLE_PLACES_KEY}>
          <Map
            center={selectedAlertId ? center : null}
            defaultCenter={
              selectedAlertId
                ? selectedAlert?.location.addresses[0]?.geometry
                : defaultCenter
            }
            onDragstart={() => {
              setCenter(null);
              setZoom(null);
            }}
            onZoomChanged={() => {
              setZoom(null);
            }}
            defaultZoom={defaultZoom}
            zoom={zoom}
            onTilesLoaded={() => setIsMapLoaded(true)}
            mapId={googleMapId}
          >
            {isMapLoaded &&
              alerts.map((alert, index) => {
                const geometry = alert.location?.addresses[0]?.geometry || {};
                const { lat, lng } = geometry || {};
                if (typeof lat === "number" && typeof lng === "number") {
                  return (
                    <AdvancedMarker
                      key={index}
                      position={{ lat, lng }}
                      onClick={() => {
                        handleMarkerClick(alert, index);
                        setCenter(geometry);
                        setZoom(12);
                      }}
                      clickable
                      zIndex={
                        selectedAlert && selectedAlert.id === alert.id
                          ? 1000
                          : 0
                      }
                    >
                      <Pin
                        scale={selectedAlert?.id === alert?.id ? 1.5 : 1}
                        glyphColor={
                          selectedAlert?.id === alert?.id ? "#ffffff" : ""
                        }
                      />
                    </AdvancedMarker>
                  );
                }
                return null;
              })}
          </Map>
        </APIProvider>
      )}
    </div>
  );
}

export default React.memo(AlertsMap);
