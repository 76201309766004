/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { GetSurveyResponse } from '../models/GetSurveyResponse';
import type { PutSurveyRequest } from '../models/PutSurveyRequest';
import type { PutSurveyResponse } from '../models/PutSurveyResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class SurveyService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * @param requestBody 
     * @returns PutSurveyResponse 
     * @throws ApiError
     */
    public put(
requestBody: PutSurveyRequest,
): CancelablePromise<PutSurveyResponse> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/report/survey',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param surveyId 
     * @returns GetSurveyResponse 
     * @throws ApiError
     */
    public get(
surveyId: string,
): CancelablePromise<GetSurveyResponse> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/report/survey/{survey_id}',
            path: {
                'survey_id': surveyId,
            },
        });
    }

}
