import { useContext, useEffect } from "react";

import { AuthStateContext } from "./context";
import { IUser } from "./types";

const ProtectedPage = ({ children }) => {
  const { currentUser, checkUserAuthStatus } = useContext(AuthStateContext);

  useEffect(() => {
    if (!currentUser) {
      checkUserAuthStatus(true);
    }
  }, []);

  if (!currentUser) {
    return <></>;
  }

  if (currentUser && (currentUser as IUser).has_temp_pw) {
    window.location.href = "/changepassword";
    return <></>;
  }
  return <>{children}</>;
};

export default ProtectedPage;
