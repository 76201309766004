import { LockOutlined } from "@mui/icons-material";
import {
  Avatar,
  Button,
  CircularProgress,
  TextField,
  Typography,
} from "@mui/material";
import { useContext, useState } from "react";
import { toast } from "react-toastify";

import { AuthStateContext } from "../../context";
import styles from "../../layout/Home.module.css";
import LoginWrapper from "../../layout/LoginWrapper";

const AddPhone = () => {
  const [error, setError] = useState("");
  const [phoneNumber, setPhoneNumber] = useState<string>("");
  const [loading, setLoading] = useState(false);

  const { addPhoneNumber } = useContext(AuthStateContext);

  const handleSubmit = () => {
    setLoading(true);

    setError("");
    if (!phoneNumber) {
      setError("Please enter a phone number");
      setLoading(false);
      return;
    }

    if (phoneNumber.length !== 10) {
      setError("Please enter a valid 10-digit phone number");
      setLoading(false);
      return;
    }

    addPhoneNumber("+1" + phoneNumber).catch(() => {
      toast.error(
        "An error occurred when adding the phone number. Please try again. If the problem persists, please contact support@tryrubicon.com.",
      );
      setLoading(false);
    });
  };

  function enterPressed(event: React.KeyboardEvent<HTMLInputElement>) {
    if (event.key === "Enter" && !loading) {
      handleSubmit();
    }
  }
  return (
    <LoginWrapper>
      <div className={styles.paper}>
        <Avatar className={styles.avatar}>
          <LockOutlined />
        </Avatar>
        <Typography component="h1" variant="h5">
          Add Phone Number
        </Typography>
        <Typography component="p">
          This number will be used to send SMS verification codes for
          multi-factor authentication.
        </Typography>
        <div className={styles.form}>
          <TextField
            required
            value={phoneNumber}
            autoFocus
            type="text"
            variant="outlined"
            id="phoneNumber"
            label="Phone Number"
            fullWidth
            disabled={loading}
            onChange={(event) => {
              if (event.target.value !== null)
                setPhoneNumber(event.target.value.replace(/\D/g, ""));
            }}
            onKeyDown={enterPressed}
          />

          <Button
            name="Submit"
            variant="contained"
            type="submit"
            color="primary"
            size="large"
            disabled={loading}
            fullWidth
            onClick={() => {
              handleSubmit();
            }}
          >
            {loading && <CircularProgress size={25} thickness={2} />}
            {"Submit"}
          </Button>

          {error && <h3 className={styles.errorMessage}>{error}</h3>}
        </div>
      </div>
    </LoginWrapper>
  );
};

export default AddPhone;
