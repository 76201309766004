import React, { useState } from "react";

import { AddressField } from "./AddressField";
import { ReportDateField } from "./ReportDateField";
import { ReportField } from "./ReportField";
import { ShowHideButton } from "../fields/UI/ShowHideButton";
import styles from "../report.module.css";

export const InvolvedPersonField = (props) => {
  const { data } = props;
  const [show, setShow] = useState(false);
  return (
    <>
      {data &&
        data.length > 0 &&
        data.map((row, index) => (
          <div key={index}>
            <div className={styles.subHeading}>
              Person {index + 1}
              <ShowHideButton
                onClick={() => {
                  setShow(!show);
                }}
              >
                {" "}
                {show ? "Hide " : "Show "} empty fields{" "}
              </ShowHideButton>
            </div>
            {row.involvementType || show ? (
              <ReportField
                className="report-field-row"
                value={row.involvementType}
                label="Involvement Type"
              />
            ) : null}
            {row.firstName || show ? (
              <ReportField
                label="First Name"
                value={row.firstName}
                source="reporter.firstName"
              />
            ) : null}
            {row.lastName || show ? (
              <ReportField
                label="Last Name"
                value={row.lastName}
                source="reporter.lastName"
              />
            ) : null}

            {row.homeAddress || show ? (
              <AddressField
                label="Incident Location"
                address={row.homeAddress}
                onEditAddress={() => {
                  props.onEditAddress(
                    row.homeAddress,
                    `data.info.bullying.involvedPersons[${index}].homeAddress`,
                  );
                }}
              />
            ) : null}

            {row.contactPhoneNumber || show ? (
              <ReportField
                value={row.contactPhoneNumber}
                source="row.contactPhoneNumber"
                label="Contact Phone Number"
              />
            ) : null}
            {row.race || show ? (
              <ReportField value={row.race} source="row.race" label="Race" />
            ) : null}
            {row.dateOfBirth || show ? (
              <ReportDateField
                showtime={false}
                className="report-field-row"
                value={row.dateOfBirth}
                label="Date of birth"
              />
            ) : null}
            {row.school || show ? (
              <ReportField
                value={row.school}
                source="row.school"
                label="What school?"
              />
            ) : null}
            {row.grade || show ? (
              <ReportField
                value={row.grade}
                source="row.grade"
                label="What grade?"
              />
            ) : null}
            {row.age || show ? (
              <ReportField value={row.age} source="row.age" label="Age" />
            ) : null}
            {row.height || show ? (
              <ReportField
                value={row.height}
                source="row.height"
                label="Height"
              />
            ) : null}
            {row.personSize || show ? (
              <ReportField
                value={row.personSize}
                source="row.personSize"
                label="How big is this person?"
              />
            ) : null}
            {row.eyeColor || show ? (
              <ReportField
                value={row.eyeColor}
                source="row.eyeColor"
                label="Eye Color"
              />
            ) : null}

            {row.height || show ? (
              <ReportField
                value={row.height}
                source="row.height"
                label="Height"
              />
            ) : null}
            {row.hairColor || show ? (
              <ReportField
                value={row.hairColor}
                source="row.hairColor"
                label="Hair Color"
              />
            ) : null}
            {row.hairStyle || show ? (
              <ReportField
                value={row.hairStyle}
                source="row.hairStyle"
                label="Hair Style"
              />
            ) : null}
            {row.facialHair || show ? (
              <ReportField
                value={row.facialHair}
                source="row.facialHair"
                label="Facial Hair"
              />
            ) : null}
            {row.clothingOrPhysicalDescription || show ? (
              <ReportField
                value={row.clothingOrPhysicalDescription}
                source="row.clothingOrPhysicalDescription"
                label="Clothing Or Physical Description"
              />
            ) : null}
            {row.tattoosOrMarks || show ? (
              <ReportField
                value={row.tattoosOrMarks}
                source="row.tattoosOrMarks"
                label="Tattoos Or Marks on body or face"
              />
            ) : null}
          </div>
        ))}
    </>
  );
};
