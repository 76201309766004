import { format } from "date-fns";

import styles from "../report.module.css";

export const ReportDateField = (props) => {
  const { value, label, showtime } = props;

  const d = Date.parse(value);

  let fmtDate = value;
  if (!isNaN(d)) {
    if (showtime) fmtDate = format(d, "PPP p z");
    else fmtDate = format(d, "PPP");
  }

  return (
    <div className="ra-field report-field-row">
      <div className="MuiFormControl-root MuiFormControl-marginDense">
        <label
          className="MuiFormLabel-root MuiInputLabel-root RaLabeled-label MuiInputLabel-formControl MuiInputLabel-animated MuiInputLabel-shrink MuiInputLabel-marginDense"
          data-shrink="true"
        >
          <span>{label}</span>
        </label>
        <div className={styles.fieldValue + " RaLabeled-value"}>
          {
            <span className="MuiTypography-root report-field-row MuiTypography-body2">
              {fmtDate}
            </span>
          }
        </div>
      </div>
    </div>
  );
};
