import { ArrowLeft, ArrowRight, CancelTwoTone } from "@mui/icons-material";
import { Box, IconButton, Modal, Typography } from "@mui/material";
import React, { useEffect } from "react";

import styles from "./ExpandedMediaDisplay.module.scss";
import LazyLoadVideo from "./LazyLoadVideo";

const ExpandedMediaDisplay = ({
  isSlideShowActive,
  setIsSlideShowActive,
  mediaSrc,
  mediaType,
  selectedIndex,
  setSelectedIndex,
  slideshowLength,
}: {
  isSlideShowActive: boolean;
  setIsSlideShowActive: React.Dispatch<React.SetStateAction<boolean>>;
  mediaSrc: string;
  mediaType: "image" | "video";
  selectedIndex: number;
  setSelectedIndex: React.Dispatch<React.SetStateAction<number>>;
  slideshowLength: number;
}) => {
  useEffect(() => {
    if (selectedIndex === -1) {
      setIsSlideShowActive(false);
      return;
    }
    setIsSlideShowActive(true);
  }, [selectedIndex]);
  const mediaAsset =
    mediaType === "image" ? (
      <img
        src={mediaSrc}
        alt="media"
        className={styles.alert_full_image}
        onClick={() => {
          if (selectedIndex < slideshowLength - 1)
            setSelectedIndex(selectedIndex + 1);
          else return;
        }}
      />
    ) : (
      <LazyLoadVideo
        src={mediaSrc}
        className={styles.alert_full_image}
        onClick={() => {
          if (selectedIndex < slideshowLength - 1)
            setSelectedIndex(selectedIndex + 1);
          else return;
        }}
      />
    );
  return (
    <>
      <Modal open={isSlideShowActive}>
        <Box>
          <div className="modal-content">
            <div className={styles.images_slide_container}>
              <div className={styles.image_slide}>{mediaAsset}</div>
              <div className={styles.close_arrow_icon}>
                <IconButton
                  color="default"
                  size="medium"
                  onClick={() => {
                    setSelectedIndex(-1);
                    setIsSlideShowActive(false);
                  }}
                >
                  <CancelTwoTone style={{ fontSize: 36 }} />
                </IconButton>
              </div>
              {/* ALLOW NAVIGATION IN CAROUSEL if there are multiple pictures */}
              {slideshowLength > 1 && (
                <>
                  <div
                    className={styles.back_arrow_icon}
                    onClick={() => {
                      if (selectedIndex > 0)
                        setSelectedIndex(selectedIndex - 1);
                    }}
                  >
                    <IconButton
                      color="default"
                      size="medium"
                      onClick={() => {
                        if (selectedIndex > 0)
                          setSelectedIndex(selectedIndex - 1);
                        else setSelectedIndex(slideshowLength - 1);
                      }}
                    >
                      <ArrowLeft style={{ fontSize: 36 }} />
                    </IconButton>
                  </div>
                  <div
                    className={styles.right_arrow_icon}
                    onClick={() => {
                      if (selectedIndex < slideshowLength - 1)
                        setSelectedIndex(selectedIndex + 1);
                    }}
                  >
                    <IconButton
                      color="default"
                      size="medium"
                      onClick={() => {
                        if (selectedIndex < slideshowLength - 1)
                          setSelectedIndex(selectedIndex + 1);
                        else setSelectedIndex(0);
                      }}
                    >
                      <ArrowRight style={{ fontSize: 36 }} />
                    </IconButton>
                  </div>
                </>
              )}
              <Typography className={styles.bottom_counter_text} variant="h6">
                {selectedIndex + 1}/{slideshowLength}
              </Typography>
            </div>
          </div>
        </Box>
      </Modal>
    </>
  );
};

export default ExpandedMediaDisplay;
