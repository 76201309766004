import { ArrowBack } from "@mui/icons-material";
import { Button, Container, Grid, Paper, Typography } from "@mui/material";
import React, { useContext } from "react";

import "../UserManagement.scss";
import UpdateUserPage from "./UpdateUserPage";
import ViewUserPage from "./ViewUserPage";
import { AuthStateContext } from "../../../../context";

const UserPage: React.FC<{
  mode: "add" | "edit" | "view";
  profile: boolean;
}> = ({ mode, profile }) => {
  const { currentUser } = useContext(AuthStateContext);
  const pageTitle = {
    add: "Add New User",
    edit: "Modify User",
    view: "User Details",
  };

  const profileTitle = {
    edit: "Profile",
    view: "Profile",
  };
  return (
    <div style={{ position: "relative" }}>
      <Button
        style={{ position: "absolute", top: "16px" }}
        startIcon={<ArrowBack />}
        className="cancel_button"
        disabled={currentUser?.profile_update_required && mode === "edit"}
        variant="outlined"
        color="primary"
        size="large"
        onClick={() => window.history.back()}
      >
        Back
      </Button>
      <Container maxWidth="md" className="user-page-container">
        <Paper className="user-details-paper">
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item>
              <Typography variant="h4" gutterBottom>
                {profile ? profileTitle[mode] : pageTitle[mode]}
              </Typography>
              {profile && mode === "edit" && (
                <Typography variant="body2" gutterBottom>
                  All required fields must be completed and saved before using
                  the system
                </Typography>
              )}
            </Grid>
          </Grid>
          {mode === "add" && <UpdateUserPage />}
          {mode === "edit" && <UpdateUserPage />}
          {mode === "view" && <ViewUserPage />}
        </Paper>
      </Container>
    </div>
  );
};

export default UserPage;
