import { Button, CircularProgress, Link, Typography } from "@mui/material";
import { createRef, useContext, useEffect, useRef, useState } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";

import AlertCard from "./AlertCard";
import { AlertSingle } from "./AlertSingle";
import AlertTable from "./AlertTable";
import styles from "./AlertsList.module.css";
import AlertMap from "./AlertsMap";
import AlertFilters, { RenderVisibleFilterChips } from "./Search/AlertFilters";
import SearchBar from "./Search/SearchBar";
import { ALERT_STATUS, crimeGroupedTypes } from "./constants";
import { AlertViewContext } from "./context";
import LoadingModal from "../../common/LoadingModal";
import { useDrawer } from "../../common/SlideoutDrawer/DrawerContext";
import SlideOutDrawer from "../../common/SlideoutDrawer/SlideoutDrawer";
import { useAlertsInfiniteQuery } from "../../common/api-client/loadAlerts";
import { isSmallScreen, mailTo } from "../../common/utils";
import { AuthStateContext } from "../../context";
import { ICreator, IUser, IUserPermission } from "../../types";
import { DisabledComponentDialog } from "../DisabledComponent";
import { parseUserPermissions } from "../UserManagement/utils";

import "./alerts.scss";

interface IAlertListProps {
  isDraftsList?: boolean;
}

export const AlertList = ({ isDraftsList = false }: IAlertListProps) => {
  const { currentUser: user } = useContext(AuthStateContext);
  const {
    totalAlerts,
    setTotalAlerts,
    currentItems,
    setCurrentItems,
    updatedItems,
    setUpdatedItems,
    policeServices,
    filtersVisible,
    setFiltersVisible,
    bulletinId,
  } = useContext(AlertViewContext);

  const [searchParams, setSearchParams] = useSearchParams();

  const view = searchParams.get("view") || "card";
  const focusId = searchParams.get("focusId");
  const myAlerts = searchParams.get("myAlerts") === "true";
  const incidentStartDate = searchParams.get("incidentStartDate");
  const incidentEndDate = searchParams.get("incidentEndDate");
  const issuedStartDate = searchParams.get("issuedStartDate");
  const issuedEndDate = searchParams.get("issuedEndDate");
  const bulletinType = searchParams.get("bulletinType");
  const associatedCrime = searchParams.get("associatedCrime");
  const policeService = searchParams.get("policeService");
  const searchText = searchParams.get("searchText");
  const similarTo = searchParams.get("similarTo");

  const setSearchText = (value) => {
    if (value === "") {
      setSearchParams((searchParams) => {
        searchParams.delete("searchText");
        return searchParams;
      });
      return;
    }
    setSearchParams((searchParams) => {
      searchParams.set("searchText", value);
      return searchParams;
    });
  };

  const { currentUser, setCurrentUser } = useContext(AuthStateContext);

  const { openDrawer, closeDrawer } = useDrawer();
  const { PUBLISHED } = ALERT_STATUS;

  const { pathname } = useLocation();

  const topPageRef = useRef<HTMLInputElement | null>(null);
  const bottomPageRef = useRef<HTMLDivElement>(null);
  const searchBarRef = useRef<HTMLInputElement | null>(null);

  const [alertPermissions] = useState(
    currentUser &&
      parseUserPermissions(currentUser?.permissions as IUserPermission[]),
  );
  const readOnly = alertPermissions
    ? !alertPermissions.canCreateBulletin
    : false;

  const navigate = useNavigate();
  const bulletinRefs = {};
  const {
    data,
    error: alertsError,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    refetch,
    isLoading,
  } = useAlertsInfiniteQuery(
    25,
    crimeGroupedTypes.find((crime) => crime.crime === associatedCrime) || null,
    bulletinType,
    policeServices.find((service) => service.name === policeService) || null,
    incidentStartDate,
    incidentEndDate,
    issuedStartDate,
    issuedEndDate,
    isDraftsList ? null : searchText,
    isDraftsList ? isDraftsList && !readOnly : null,
    myAlerts ? (currentUser?.id as string) : null,
    similarTo ? parseInt(similarTo) : null,
  );

  const [isLoadingAlerts, setIsLoadingAlerts] = useState(isLoading);
  const [isReadOnlyDialogOpen, setIsReadOnlyDialogOpen] = useState(false);
  const [isLoadingModalOpen, setIsLoadingModalOpen] = useState(false);

  if (currentItems.length) {
    currentItems.forEach((bulletin) => {
      bulletinRefs[bulletin.id] = createRef();
    });
  }

  const loadMore = () => {
    if (hasNextPage && currentItems.length < totalAlerts) {
      fetchNextPage();
    }
  };
  const alertClickHandler = (item, index) => {
    if (!isDraftsList && !isSmallScreen()) {
      setSearchParams((searchParams) => {
        const newSearchParams = new URLSearchParams(searchParams);
        newSearchParams.set("focusId", item?.id?.toString());
        return newSearchParams;
      });
      openDrawer();
    } else {
      navigate(
        `/rubialert/${isDraftsList ? "drafts" : "alerts"}/${item.id}/single`,
        {
          state: {
            incidentStartDate,
            incidentEndDate,
            issuedStartDate,
            issuedEndDate,
            bulletinType,
            associatedCrime,
            policeServices,
            policeService,
            view,
            filtersVisible,
            index,
            searchText,
          },
        },
      );
    }
  };
  const scrollToTop = () => {
    topPageRef?.current?.scrollIntoView({
      behavior: "smooth",
      block: "center",
    });
  };

  useEffect(() => {
    if (similarTo !== null) {
      const startTime = Date.now();
      setIsLoadingModalOpen(true);
      const result = refetch();
      const endTime = Date.now();
      const timeElapsed = endTime - startTime;
      const remainingTime = Math.max(0, 8000 - timeElapsed);
      result.then(() => {
        setTimeout(() => {
          setIsLoadingModalOpen(false);
        }, remainingTime);
      });
    }
  }, [similarTo]);

  useEffect(() => {
    if (readOnly) {
      setIsReadOnlyDialogOpen(true);
    }
  }, [readOnly]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            fetchNextPage();
          }
        });
      },
      {
        root: null,
        rootMargin: "0px",
        threshold: 0.1,
      },
    );

    const currentAnchor = bottomPageRef.current;
    if (currentAnchor) {
      observer.observe(currentAnchor);
    }

    return () => {
      if (currentAnchor) {
        observer.unobserve(currentAnchor);
      }
    };
  }, [bottomPageRef.current]);

  useEffect(() => {
    if (updatedItems.length > 0) {
      refetch();
      setUpdatedItems([]);
    }
  }, [updatedItems]);

  useEffect(() => {
    if (myAlerts && currentUser?.id !== undefined) {
      refetch();
    }
    if (!currentUser?.id && user) {
      const setUser: ICreator = {
        email: user.email,
        first_name: user.first_name,
        last_name: user.last_name,
        badge: user.badge,
        id: user.id,
      };
      setCurrentUser(setUser as IUser);
    }
    if (!myAlerts) {
      refetch();
    }
  }, [myAlerts, currentUser?.id]);

  useEffect(() => {
    if (isDraftsList && readOnly) {
      return;
    }
    if (data) {
      const items = data.pages.flatMap((page) => page.items);
      setCurrentItems(items);
      setTotalAlerts(data.pages[0].total_count);
    }
  }, [data]);

  useEffect(() => {
    if (isLoadingAlerts && hasNextPage) {
      loadMore();
    } else if (isLoadingAlerts && !hasNextPage) {
      setIsLoadingAlerts(false);
    }
  }, [isLoadingAlerts, hasNextPage]);

  useEffect(() => {
    if (focusId) {
      const alert = currentItems.find((item) => item.id === parseInt(focusId));
      if (alert) {
        setSearchParams((searchParams) => {
          searchParams.set("focusId", focusId);
          return searchParams;
        });
        openDrawer();
      }
      openDrawer();
    } else {
      closeDrawer();
    }
  }, [focusId]);

  if (alertsError) {
    toast.error("Error fetching alerts");
  }

  const renderDraftsView = () => {
    return (
      <AlertTable
        alertClickHandler={alertClickHandler}
        loadMore={loadMore}
        isDraftsList={isDraftsList}
        refetch={refetch}
      />
    );
  };

  const renderDisabledComponentDialog = () => {
    return (
      <DisabledComponentDialog
        isDialogOpen={isReadOnlyDialogOpen}
        handleClose={() => {
          navigate(`/rubialert/alerts`);
        }}
        title="You do not have access to create bulletins."
        message={
          <Typography>
            Please contact{" "}
            <Link
              rel="noopener noreferrer"
              target="_blank"
              href={mailTo({
                email: "support@tryrubicon.com",
                subject: "Requesting Access to Create Bulletins",
                body: `Hello Rubicon Support Team, 

                  I would like to request access to create bulletins.

                  Thank you. 

                  Sincerely,

                  ${currentUser?.first_name} ${currentUser?.last_name} 
                  ${currentUser?.email}`,
              })}
              color="secondary"
            >
              support@tryrubicon.com
            </Link>{" "}
            to request access.
          </Typography>
        }
      />
    );
  };

  const renderAlertsList = () => {
    return (
      <div className="alert-list">
        <div id="top-page-div" ref={topPageRef}></div>
        <div className="search-bar">
          {!isDraftsList && (
            <>
              <SearchBar
                getMoreItems={loadMore}
                onSearch={scrollToTop}
                onClearSearch={scrollToTop}
                searchView={view}
                setSearchView={(value) => {
                  setSearchParams((searchParams) => {
                    searchParams.set("view", value);
                    return searchParams;
                  });
                }}
                defaultView="card"
                searchText={searchText || ""}
                setSearchText={setSearchText}
                currentItems={currentItems}
                setCurrentItems={setCurrentItems}
                filtersVisible={filtersVisible}
                setFiltersVisible={setFiltersVisible}
                totalAlerts={totalAlerts}
                loading={isLoadingAlerts}
                setLoading={setIsLoadingAlerts}
                searchType="alert"
                filterChips={
                  <RenderVisibleFilterChips
                    searchParams={searchParams}
                    setSearchParams={setSearchParams}
                  />
                }
                filtersRef={searchBarRef}
              />
              {filtersVisible && (
                <AlertFilters
                  isOpen={filtersVisible}
                  anchorEl={searchBarRef.current}
                  onClose={() => setFiltersVisible(false)}
                />
              )}
            </>
          )}
        </div>

        {view === "card" && (
          <>
            <div className="alert-items">
              {currentItems?.length > 0 &&
                currentItems.map((bulletin, index) => (
                  <AlertCard
                    key={index}
                    ref={bulletinRefs[bulletin.id]}
                    alertItem={bulletin}
                    index={index}
                    alertClickHandler={() => {
                      alertClickHandler(bulletin, index);
                    }}
                    className={
                      focusId
                        ? bulletin.id === parseInt(focusId)
                          ? "slideout-alert-selected-card"
                          : ""
                        : ""
                    }
                  />
                ))}
            </div>
            <div ref={bottomPageRef} />
            {(isLoading || currentItems.length < totalAlerts) && (
              <div
                className="loading-more"
                style={{
                  padding: "16px 0",
                }}
              >
                <CircularProgress />
              </div>
            )}
          </>
        )}
        {view === "map" && (
          <>
            <AlertMap
              alerts={currentItems}
              alertClickHandler={alertClickHandler}
              selectedAlertId={focusId ? parseInt(focusId) : null}
              setSelectedAlertId={(id) => {
                setSearchParams((searchParams) => {
                  searchParams.set("focusId", id.toString());
                  return searchParams;
                });
              }}
            />
            <div className={styles.loadMoreContainer}>
              <div className="alert-count">
                Loaded {currentItems.length} of {totalAlerts}
              </div>
              <Button
                variant="contained"
                color="primary"
                disabled={isFetchingNextPage || !hasNextPage}
                onClick={() => {
                  loadMore();
                }}
                endIcon={
                  isFetchingNextPage ? <CircularProgress size={20} /> : null
                }
              >
                {isFetchingNextPage ? "Loading..." : "Load More Bulletins"}
              </Button>
            </div>
          </>
        )}
        {view === "list" && (
          <AlertTable
            alertClickHandler={alertClickHandler}
            loadMore={loadMore}
            isDraftsList={isDraftsList}
            refetch={refetch}
            selectedAlertId={focusId ? parseInt(focusId) : null}
          />
        )}
      </div>
    );
  };

  const renderLoadingModal = () => {
    const loadingMessages = [
      "Initializing the AI Engine...",
      "Using AI to Analyze all the bulletins...",
      "Computing the similarity matrix...",
      "Ranking the bulletins...",
    ];

    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
        }}
      >
        <LoadingModal
          stages={loadingMessages}
          timingMessage="This may take a few seconds . . ."
        />
      </div>
    );
  };

  const renderAlertsView = () => {
    return (
      <SlideOutDrawer
        width={240}
        slideoutLocation="right"
        slideoutContent={
          focusId ? (
            <AlertSingle
              mode={PUBLISHED}
              slideoutIdOverride={parseInt(focusId)}
            />
          ) : (
            <div></div>
          )
        }
        mainContent={
          isLoadingModalOpen ? renderLoadingModal() : renderAlertsList()
        }
        onDrawerClose={() => {
          setSearchParams((searchParams) => {
            searchParams.delete("focusId");
            return searchParams;
          });
          closeDrawer();
        }}
      />
    );
  };

  if (isDraftsList && readOnly) {
    return renderDisabledComponentDialog();
  } else if (isDraftsList) {
    return renderDraftsView();
  } else {
    return renderAlertsView();
  }
};
