import React, { useState } from "react";

import { ReportField } from "./ReportField";
import { ShowHideButton } from "./UI/ShowHideButton";
import styles from "../report.module.css";

export const TheftFromVehiclePropertyField = (props) => {
  const { data } = props;
  const [show, setShow] = useState(false);
  return (
    <>
      {data &&
        data.length > 0 &&
        data.map((row, index) => (
          <div key={index}>
            <div className={styles.subHeading}>
              Property {index + 1}
              <ShowHideButton
                onClick={() => {
                  setShow(!show);
                }}
              >
                {" "}
                {show ? "Hide " : "Show "} empty fields{" "}
              </ShowHideButton>
            </div>
            <ReportField
              value={row.propertyType}
              source="row.propertyType"
              label="Type"
            />
            {row.subType || show ? (
              <ReportField
                value={row.subType}
                source="row.subType"
                label="Subtype"
              />
            ) : null}
            {row.brand || show ? (
              <ReportField value={row.brand} source="row.brand" label="Brand" />
            ) : null}
            {row.model || show ? (
              <ReportField value={row.model} source="row.model" label="Model" />
            ) : null}
            {row.colour || show ? (
              <ReportField
                value={row.colour}
                source="row.colour"
                label="Colour"
              />
            ) : null}
            {row.serialNo || show ? (
              <ReportField
                value={row.serialNo}
                source="row.serialNo"
                label="Serial No"
              />
            ) : null}
            <ReportField
              value={row.quantity}
              source="row.quantity"
              label="Quantity"
            />
            <ReportField
              value={row.damagedValue}
              source="row.damagedValue"
              label="Damaged Value ($)"
            />
            {row.description || show ? (
              <ReportField
                value={row.description}
                source="row.description"
                label="Property Description"
              />
            ) : null}
          </div>
        ))}
    </>
  );
};
