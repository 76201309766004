import { CameraRegistryAPI } from "./CameraRegistryAPI";
import { APP_CAMERA_SERVER_URL } from "../../../environment";

export var API = new CameraRegistryAPI({
  BASE: APP_CAMERA_SERVER_URL,
  WITH_CREDENTIALS: true,
});

export function updateToken(token: string) {
  API = new CameraRegistryAPI({
    BASE: APP_CAMERA_SERVER_URL,
    WITH_CREDENTIALS: true,
    HEADERS: {
      "x-modification-token": token,
    },
  });
}
