import React, { useState, useEffect, useRef } from "react";

import styles from "./ImageGallery.module.scss";
import LazyLoadVideo from "../../../common/LazyLoadVideo";

interface ILoadedImage {
  width: number;
  height: number;
  ratio: number;
  url: string;
}
interface ILoadedVideo {
  url: string;
  type: "video";
}

interface AlertCardMediaProps {
  mediaDetails: { media_type: "video" | "image"; download_url: string }[];
  resolved: boolean;
}

const AlertCardMedia: React.FC<AlertCardMediaProps> = ({
  mediaDetails,
  resolved = false,
}) => {
  const resolvedMediaClass = resolved ? styles.resolvedMedia : "";

  const [loadedMedia, setLoadedMedia] = useState<
    (ILoadedImage | ILoadedVideo)[]
  >([]);
  const [isInView, setIsInView] = useState(false);
  const mediaContainerRef = useRef<HTMLDivElement>(null);

  const hasVideo = mediaDetails.some((media) => media.media_type === "video");

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setIsInView(true);
          }
        });
      },
      { threshold: 0.1 },
    );

    if (mediaContainerRef.current) {
      observer.observe(mediaContainerRef.current);
    }

    return () => {
      if (mediaContainerRef.current) {
        observer.unobserve(mediaContainerRef.current);
      }
    };
  }, []);

  useEffect(() => {
    if (isInView) {
      const tmpLoadedMedia: (ILoadedImage | ILoadedVideo)[] = [];

      if (!hasVideo) {
        mediaDetails
          .filter((media) => media.media_type === "image")
          .forEach(({ download_url: url }) => {
            const img = new Image();
            img.onload = () => {
              tmpLoadedMedia.push({
                width: img.naturalWidth,
                height: img.naturalHeight,
                ratio: img.naturalWidth / img.naturalHeight,
                url,
              });
              setLoadedMedia([...tmpLoadedMedia]);
            };
            img.src = url;
          });
      } else {
        const firstVideoUrl = mediaDetails.find(
          (media) => media.media_type === "video",
        )?.download_url;
        if (firstVideoUrl) {
          tmpLoadedMedia.push({ url: firstVideoUrl, type: "video" });
          setLoadedMedia([...tmpLoadedMedia]);
        }
      }
    }
  }, [isInView, mediaDetails, hasVideo]);

  const getLayoutClass = (sizes: ILoadedImage[]): string => {
    switch (sizes.length) {
      case 1:
        return styles.singleImage;
      case 2:
        if (sizes[0].ratio > 1 && sizes[1].ratio > 1)
          return styles.twoImagesVertical;
        else if (sizes[0].ratio < 1 && sizes[1].ratio < 1)
          return styles.twoImagesHorizontal;
        else return styles.twoImagesVertical;
      case 3:
        if (sizes[0].ratio > 1 && sizes[1].ratio > 1 && sizes[2].ratio > 1)
          return styles.threeImagesVertical;
        else if (sizes[0].ratio < 1 && sizes[1].ratio < 1 && sizes[2].ratio < 1)
          return styles.threeImagesHorizontal;
        else return styles.threeImagesVertical;
      case 4:
        return styles.fourImages;
      default:
        return styles.fourImages;
    }
  };

  const layoutClass = getLayoutClass(
    loadedMedia.filter((media) => "ratio" in media) as ILoadedImage[],
  );

  return (
    <div
      ref={mediaContainerRef}
      className={hasVideo ? styles.videoContainer : layoutClass}
    >
      {loadedMedia.map((media, index) => {
        if ("type" in media && media.type === "video") {
          return (
            <LazyLoadVideo
              key={index}
              src={media.url}
              muted
              controls={false}
              className={`${resolvedMediaClass}`}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            />
          );
        } else if (!hasVideo) {
          return (
            <img
              key={index}
              src={media.url}
              alt={`Gallery Image ${index + 1}`}
              className={`${resolvedMediaClass}`}
            />
          );
        }
        return null;
      })}
    </div>
  );
};

export default AlertCardMedia;
