import React from "react";
import { TextInput, SimpleForm } from "react-admin";

import { FormToolbar } from "../../../common/FormToolbar";
import styles from "../report.module.css";

const EditAddress = (props) => {
  console.log(props);
  const defaultValues = {
    streetNumber: props.address.streetNumber,
    streetName: props.address.streetName,
    streetType: props.address.streetType,
    postDirection: props.address.postDirection,
    apartment: props.address.apartment,
    city: props.address.city,
    province: props.address.province,
    country: props.address.country,
    zipCode: props.address.zipCode,
  };
  const handleSubmit = async (formdata) => {
    const finalAddress = { ...props.address, ...formdata };
    if (props.onAddressUpdate) {
      props.onAddressUpdate(finalAddress);
    }
    props.onClose();
  };

  return (
    <React.Fragment>
      <h3 className={styles.formHeader}>Edit Address</h3>
      <SimpleForm
        toolbar={
          <FormToolbar
            {...props}
            showDelete={false}
            showCancel={true}
            saveLabel="Update Address"
            onCancel={props.onClose}
            onSave={handleSubmit}
          />
        }
        onSubmit={handleSubmit}
        defaultValues={defaultValues}
      >
        <TextInput
          source="streetNumber"
          label="Street Number"
          variant="outlined"
          fullWidth={true}
          parse={(value) => value}
        />
        <TextInput
          source="streetName"
          label="Street Name"
          variant="outlined"
          fullWidth={true}
          parse={(value) => value}
        />
        <TextInput
          source="streetType"
          label="Street Type"
          variant="outlined"
          fullWidth={true}
          parse={(value) => value}
        />
        <TextInput
          source="postDirection"
          label="Post Direction"
          variant="outlined"
          fullWidth={true}
          parse={(value) => value}
        />
        <TextInput
          source="apartment"
          label="Apartment"
          variant="outlined"
          fullWidth={true}
          parse={(value) => value}
        />
        <TextInput
          source="city"
          label="City"
          variant="outlined"
          fullWidth={true}
          parse={(value) => value}
        />
        <TextInput
          source="province"
          label="Province"
          variant="outlined"
          fullWidth={true}
          parse={(value) => value}
        />
        <TextInput
          source="country"
          label="Country"
          variant="outlined"
          fullWidth={true}
          parse={(value) => value}
        />
        <TextInput
          source="zipCode"
          label="Postal Code"
          variant="outlined"
          fullWidth={true}
          parse={(value) => value}
        />
      </SimpleForm>
    </React.Fragment>
  );
};
export default EditAddress;
