import { useState } from "react";

import { AddressField } from "../fields/AddressField";
import { InvolvedPersonsField } from "../fields/InvolvedPersonsField";
import { ReportDateField } from "../fields/ReportDateField";
import { ReportField } from "../fields/ReportField";
import { ShowHideButton } from "../fields/UI/ShowHideButton";
import styles from "../report.module.css";

export const TheftOfGas = (props) => {
  const { data } = props;
  const [show, setShow] = useState(false);
  return (
    <>
      {data.info && data.info.theftOfGas && (
        <div className="theftfromVehicle">
          <ReportDateField
            showtime={true}
            className="report-field-row"
            value={data.info.theftOfGas.startTime}
            label="Start Date/Time of Incident"
          />
          <ReportDateField
            showtime={true}
            className="report-field-row"
            value={data.info.theftOfGas.endTime}
            label="End Date/Time of Incident"
          />
          <ReportField
            className="report-field-row"
            value={data.info.theftOfGas.locationType}
            label="Location Type"
          />
          {data.info.theftOfGas.theftType || props.clickState ? (
            <ReportField
              className="report-field-row"
              value={data.info.theftOfGas.theftType}
              label="Theft Type"
            />
          ) : null}
          <AddressField
            label="Incident Location"
            address={data.info.theftOfGas.streetAddress}
            onEditAddress={() => {
              props.onEditAddress(
                data.info.theftOfGas.streetAddress,
                "info.theftOfGas.streetAddress",
              );
            }}
          />
          {data.info.theftOfGas.narrative && (
            <div className={styles.subHeading}>Narrative</div>
          )}
          {data.info.theftOfGas.narrative || props.clickState ? (
            <ReportField
              className={styles.narrativeField + " report-field-row"}
              value={data.info.theftOfGas.narrative}
              label="Incident Description"
            />
          ) : null}

          {data.info.theftOfGas.vehicle && (
            <div className={styles.subHeading}>
              Vehicle Information
              <ShowHideButton
                onClick={() => {
                  setShow(!show);
                }}
              >
                {" "}
                {show ? "Hide " : "Show "} empty fields{" "}
              </ShowHideButton>
            </div>
          )}
          {data.info.theftOfGas && data.info.theftOfGas.vehicle && (
            <>
              <ReportField
                className="report-field-row"
                value={data.info.theftOfGas.vehicle.involvementType}
                label="Type"
              />
              {data.info.theftOfGas.vehicle.maker || show ? (
                <ReportField
                  className="report-field-row"
                  value={data.info.theftOfGas.vehicle.maker}
                  label="Make"
                />
              ) : null}
              {data.info.theftOfGas.vehicle.model || show ? (
                <ReportField
                  className="report-field-row"
                  value={data.info.theftOfGas.vehicle.model}
                  label="Model"
                />
              ) : null}
              {data.info.theftOfGas.vehicle.vIN || show ? (
                <ReportField
                  className="report-field-row"
                  value={data.info.theftOfGas.vehicle.vIN}
                  label="VIN"
                />
              ) : null}
              {data.info.theftOfGas.vehicle.style || show ? (
                <ReportField
                  className="report-field-row"
                  value={data.info.theftOfGas.vehicle.style}
                  label="Style"
                />
              ) : null}
              {data.info.theftOfGas.vehicle.year || show ? (
                <ReportField
                  className="report-field-row"
                  value={data.info.theftOfGas.vehicle.year}
                  label="Year"
                />
              ) : null}
              {data.info.theftOfGas.vehicle.colours || show ? (
                <ReportField
                  className="report-field-row"
                  value={data.info.theftOfGas.vehicle.colours}
                  label="Colour"
                />
              ) : null}
              {data.info.theftOfGas.vehicle.marketValue || show ? (
                <ReportField
                  className="report-field-row"
                  value={data.info.theftOfGas.vehicle.marketValue}
                  label="Market Value"
                />
              ) : null}
              {data.info.theftOfGas.vehicle.damagedValue || show ? (
                <ReportField
                  className="report-field-row"
                  value={data.info.theftOfGas.vehicle.damagedValue}
                  label="Damaged Value"
                />
              ) : null}
              {data.info.theftOfGas.vehicle.licenseType || show ? (
                <ReportField
                  className="report-field-row"
                  value={data.info.theftOfGas.vehicle.licenseType}
                  label="License Type"
                />
              ) : null}
              {data.info.theftOfGas.vehicle.licensePlateNo || show ? (
                <ReportField
                  className="report-field-row"
                  value={data.info.theftOfGas.vehicle.licensePlateNo}
                  label="License Plate No"
                />
              ) : null}
              {data.info.theftOfGas.vehicle.licenseLocation || show ? (
                <ReportField
                  className="report-field-row"
                  value={data.info.theftOfGas.vehicle.licenseLocation}
                  label="License Province"
                />
              ) : null}
            </>
          )}

          {data.info &&
            data.info.theftOfGas &&
            data.info.theftOfGas.involvedPersons && (
              <div className={styles.subHeading}>Involved Persons</div>
            )}
          {data.info &&
            data.info.theftOfGas &&
            data.info.theftOfGas.involvedPersons && (
              <InvolvedPersonsField
                data={data.info.theftOfGas.involvedPersons}
              />
            )}
        </div>
      )}
    </>
  );
};
