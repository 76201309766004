import { fetchUtils, DataProvider, HttpError } from "ra-core";

/**
 * Maps react-admin queries to a json-server powered REST API
 *
 * @see https://github.com/typicode/json-server
 *
 * @example
 *
 * getList          => GET http://my.api.url/posts?_sort=title&_order=ASC&_start=0&_end=24
 * getOne           => GET http://my.api.url/posts/123
 * getManyReference => GET http://my.api.url/posts?author_id=345
 * getMany          => GET http://my.api.url/posts/123, GET http://my.api.url/posts/456, GET http://my.api.url/posts/789
 * create           => POST http://my.api.url/posts/123
 * update           => PUT http://my.api.url/posts/123
 * updateMany       => PUT http://my.api.url/posts/123, PUT http://my.api.url/posts/456, PUT http://my.api.url/posts/789
 * delete           => DELETE http://my.api.url/posts/123
 *
 * @example
 *
 * import React from 'react';
 * import { Admin, Resource } from 'react-admin';
 * import jsonServerProvider from 'ra-data-json-server';
 *
 * import { PostList } from './posts';
 *
 * const App = () => (
 *     <Admin dataProvider={jsonServerProvider('http://jsonplaceholder.typicode.com')}>
 *         <Resource name="posts" list={PostList} />
 *     </Admin>
 * );
 *
 * export default App;
 */

export function transformString(input, replaceName) {
  const parts = input.split("-");
  parts[0] = replaceName;
  const output = parts.join("-");
  return output;
}

//TODO rewrite this and reuse across Data Providers
export const VSfetchJson = async (
  url: any,
  options: fetchUtils.Options = {},
) => {
  const headers = new Headers({
    Accept: "application/json",
    "Content-Type": "application/json",
  });

  // if the request host is *tryrubicon.com then force enable sending credentials
  if (new URL("" + url).hostname.match(/tryrubicon.com$/)) {
    options.credentials = "include";
  }

  return fetch(url, { ...options, headers: headers })
    .then((response) =>
      response.text().then((text) => ({
        status: response.status,
        statusText: response.statusText,
        headers: response.headers,
        body: text,
      })),
    )
    .then(({ status, statusText, headers, body }) => {
      console.log("Inside VSFeatchJson");
      let json;
      try {
        json = JSON.parse(body);
      } catch (e) {
        // not json, no big deal
      }
      if (status === 401) {
        return Promise.reject(
          new HttpError(
            "Unauthorized or Credentials expired, Please re-login",
            status,
            json,
          ),
        );
      }
      if (status === 409) {
        return Promise.reject(
          new HttpError(
            (json && json.error && json.error.message) || statusText,
            status,
            json,
          ),
        );
      }

      if (status === 422) {
        let message = "**VALIDATION FAILED** ";
        if (json.error && json.error.details && json.error.details.length > 0) {
          json.error.details.forEach((element: any) => {
            const f = element;
            const mf = f.path + " " + f.message;
            message = message + " , " + mf;
          });
        }
        return Promise.reject(new HttpError(message, status, json));
      }

      if (status < 200 || status >= 300) {
        return Promise.reject(
          new HttpError((json && json.message) || statusText, status, json),
        );
      }
      return Promise.resolve({ status, headers, body, json });
    });
};

export default (apiUrl: string, httpClient = VSfetchJson): DataProvider => ({
  getList: (resource, params) => {
    console.log("getList", resource, params);
    const { page, perPage } = params.pagination;
    const filters = params.filter;
    const moreOptions: any = { method: "GET" };

    const url = new URL(apiUrl);
    url.pathname = "report";
    url.searchParams.append("start", ((page - 1) * perPage).toString());
    url.searchParams.append("amount", perPage.toString());

    for (const filter in filters) {
      url.searchParams.append(filter, "" + filters[filter]);
    }

    return httpClient(url.toString(), moreOptions).then(({ headers, json }) => {
      let count: string = "100";
      let data = json;
      if (json.total_count) {
        count = json.total_count;
      } else {
        // throw new Error(
        //   "The Total-Count Value is missing in the HTTP Response. The Data Provider expects responses for lists of resources to contain this header with the total number of results to build the pagination."
        // );
      }

      data = json["items"];

      // data = data.map((item) => ({
      //   ...item,
      //   trackingNumber: transformString(item.trackingNumber, "NorthBay"),
      //   report: {
      //     ...item.report,
      //     meta: {
      //       ...item.report.meta,
      //       trackingNumber: transformString(
      //         item.report.meta.trackingNumber,
      //         "NorthBay"
      //       ),
      //     },
      //   },
      // }));

      const returnData = {
        data: data,
        total: parseInt(count, 10),
      };

      console.log(returnData);
      return {
        data: data,
        total: parseInt(count, 10),
      };
    });
  },

  getOne: (resource, params) =>
    httpClient(`${apiUrl}${resource}/${params.id}`).then(({ json }) => {
      console.log("getOne");

      const data = json["items"];
      return {
        data: data,
      };
    }),

  getMany: (resource, params) => {
    console.log("getMany");
    /* const query = {
            id: params.ids,
        };
        const url = `${apiUrl}${resource}?${stringify(query)}`; */

    const ids = params.ids;
    let where = "";
    if (ids.length === 1) {
      where = `filter[where][id]=` + ids[0];
    } else {
      ids.forEach(function (id, index) {
        where += `&filter[where][or][${index}][id]=${id}`;
      });
    }
    const url = `${apiUrl}${resource}?${where}`;

    return httpClient(url).then(({ json }) => ({ data: json }));
  },

  getManyReference: (resource, params) => {
    console.log("getManyReference");
    const { page, perPage } = params.pagination;
    const { field, order } = params.sort;
    const filter = params.filter;
    const keys = Object.keys(filter);
    let where = "";
    keys.forEach(function (key) {
      where += `&filter[where][${key}]=` + filter[key];
    });
    if (params.target !== undefined) {
      const col = params.target;
      const val = params.id;
      where += `&filter[where][${col}]=${val}`;
    }

    const loopbackquery = `filter[offset]=${
      (page - 1) * perPage
    }&filter[limit]=${perPage}&filter[order]=${field} ${order}${where}`;
    const url = `${apiUrl}${resource}?${loopbackquery}`;

    //const url = `${apiUrl}${resource}?${stringify(query)}`;

    return httpClient(url).then(({ headers, json }) => {
      if (!headers.has("x-total-count")) {
        throw new Error(
          "The X-Total-Count header is missing in the HTTP Response. The jsonServer Data Provider expects responses for lists of resources to contain this header with the total number of results to build the pagination. If you are using CORS, did you declare X-Total-Count in the Access-Control-Expose-Headers header?",
        );
      }
      let count: string = "10";
      count = headers.get("x-total-count")!.split("/").pop()!;
      return {
        data: json,
        total: parseInt(count, 10),
      };
    });
  },

  update: async (resource, params) => {
    console.log("update");

    return httpClient(`${apiUrl}${resource}/${params.id}`, {
      method: "PUT",
      body: JSON.stringify(params.data),
    }).then(({ json }) => ({ data: json }));
  },

  // json-server doesn't handle filters on UPDATE route, so we fallback to calling UPDATE n times instead
  updateMany: (resource, params) => {
    console.log("updateMany");

    return Promise.all(
      params.ids.map((id) =>
        httpClient(`${apiUrl}${resource}/${id}`, {
          method: "PUT",
          body: JSON.stringify(params.data),
        }),
      ),
    ).then((responses) => ({ data: responses.map(({ json }) => json.id) }));
  },

  create: async (resource, params) => {
    console.log("create");

    let finalUrl = `${apiUrl}${resource}`;
    if (resource === "user") {
      finalUrl = `${apiUrl}${resource}`;
    }

    return httpClient(finalUrl, {
      method: "PUT",
      body: JSON.stringify(params.data),
    }).then(({ json }) => {
      console.log(json);
      return {
        data: { ...params.data, id: json.id } as any,
      };
    });
  },
  delete: (resource, params) => {
    console.log("delete");

    return httpClient(`${apiUrl}${resource}/${params.id}`, {
      method: "DELETE",
    }).then(({ json }) => ({ data: json }));
  },

  // json-server doesn't handle filters on DELETE route, so we fallback to calling DELETE n times instead
  deleteMany: (resource, params) => {
    console.log("deleteMany");
    return Promise.all(
      params.ids.map((id) =>
        httpClient(`${apiUrl}${resource}/${id}`, {
          method: "DELETE",
        }),
      ),
    ).then((responses) => ({ data: responses.map(({ json }) => json.id) }));
  },
});
