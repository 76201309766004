import { fetchUtils, DataProvider, HttpError } from "ra-core";

/**
 * Maps react-admin queries to a json-server powered REST API
 *
 * @see https://github.com/typicode/json-server
 *
 * @example
 *
 * getList          => GET http://my.api.url/posts?_sort=title&_order=ASC&_start=0&_end=24
 * getOne           => GET http://my.api.url/posts/123
 * getManyReference => GET http://my.api.url/posts?author_id=345
 * getMany          => GET http://my.api.url/posts/123, GET http://my.api.url/posts/456, GET http://my.api.url/posts/789
 * create           => POST http://my.api.url/posts/123
 * update           => PUT http://my.api.url/posts/123
 * updateMany       => PUT http://my.api.url/posts/123, PUT http://my.api.url/posts/456, PUT http://my.api.url/posts/789
 * delete           => DELETE http://my.api.url/posts/123
 *
 * @example
 *
 * import React from 'react';
 * import { Admin, Resource } from 'react-admin';
 * import jsonServerProvider from 'ra-data-json-server';
 *
 * import { PostList } from './posts';
 *
 * const App = () => (
 *     <Admin dataProvider={jsonServerProvider('http://jsonplaceholder.typicode.com')}>
 *         <Resource name="posts" list={PostList} />
 *     </Admin>
 * );
 *
 * export default App;
 */

// const tmpRecord = {
//   email: "rubidevone@gmail.com",
//   firstName: "",
//   lastName: "",
//   badge: "901",
//   role: "Admin",
// };

const dataProvName = "users-admin";

//TODO rewrite this and reuse across Data Providers
export const fetchJson = async (
  url: string,
  options: fetchUtils.Options = {},
) => {
  const headers = new Headers({
    Accept: "application/json",
    "Content-Type": "application/json",
  });

  // if the request host is *tryrubicon.com then force enable sending credentials
  if (new URL(url).hostname.match(/tryrubicon.com$/)) {
    options.credentials = "include";
  }

  console.log("FetchJSON", url, headers, options);

  return fetch(url, { ...options, headers: headers })
    .then((response) =>
      response.text().then((text) => ({
        status: response.status,
        statusText: response.statusText,
        headers: response.headers,
        body: text,
      })),
    )
    .then(({ status, statusText, headers, body }) => {
      console.log("Inside users FeatchJson");
      let json;
      try {
        json = JSON.parse(body);
      } catch (e) {
        // not json, no big deal
      }
      if (status === 401) {
        return Promise.reject(
          new HttpError(
            "Unauthorized or Credentials expired, Please re-login",
            status,
            json,
          ),
        );
      }
      if (status === 409) {
        return Promise.reject(
          new HttpError(
            (json && json.error && json.error.message) || statusText,
            status,
            json,
          ),
        );
      }

      if (status === 422) {
        let message = "**VALIDATION FAILED** ";
        if (json.error && json.error.details && json.error.details.length > 0) {
          json.error.details.forEach((element: any) => {
            const f = element;
            const mf = f.path + " " + f.message;
            message = message + " , " + mf;
          });
        }
        return Promise.reject(new HttpError(message, status, json));
      }

      if (status < 200 || status >= 300) {
        return Promise.reject(
          new HttpError((json && json.message) || statusText, status, json),
        );
      }
      return Promise.resolve({ status, headers, body, json });
    });
};

export default (apiUrl: string, httpClient = fetchJson): DataProvider => ({
  getList: (resource, params) => {
    console.log("getList", dataProvName, params);
    const { page, perPage } = params.pagination;
    const url = new URL(apiUrl);
    url.pathname = "user";
    url.searchParams.set("start", String((page - 1) * perPage));
    url.searchParams.set("amount", String(perPage));

    const moreOptions: any = { method: "GET" };

    return httpClient(url.toString(), moreOptions).then(({ headers, json }) => {
      const count: number = 50;
      let data = json;

      const items = json["items"];
      data = items.map((res: any) => ({ ...res, id: res.email }));

      const returnData = {
        data: data,
        total: count,
      };

      console.log(returnData);
      return returnData;
    });
  },

  getOne: (resource, params) =>
    //TODO not used - Rewrite
    httpClient(`${apiUrl}user/${params.id}`).then(({ json }) => {
      console.log("getOne", dataProvName);
      return {
        data: json["items"],
      };
    }),

  getMany: (resource, params) => {
    //TODO not used - Rewrite
    console.log("getMany", dataProvName);
    // /* const query = {
    //         id: params.ids,
    //     };
    //     const url = `${apiUrl}${resource}?${stringify(query)}`; */

    const ids = params.ids;
    let where = "";
    if (ids.length === 1) {
      where = `filter[where][id]=` + ids[0];
    } else {
      ids.forEach(function (id, index) {
        where += `&filter[where][or][${index}][id]=${id}`;
      });
    }
    const url = `${apiUrl}${resource}?${where}`;

    return httpClient(url).then(({ json }) => ({ data: json }));
    // return { data: [tmpRecord, tmpRecord] };
  },

  getManyReference: (resource, params) => {
    //TODO not used - Rewrite

    console.log("getManyReference", dataProvName);
    const { page, perPage } = params.pagination;
    const { field, order } = params.sort;
    const filter = params.filter;
    const keys = Object.keys(filter);
    let where = "";
    keys.forEach(function (key) {
      where += `&filter[where][${key}]=` + filter[key];
    });
    if (params.target !== undefined) {
      const col = params.target;
      const val = params.id;
      where += `&filter[where][${col}]=${val}`;
    }

    const loopbackquery = `filter[offset]=${
      (page - 1) * perPage
    }&filter[limit]=${perPage}&filter[order]=${field} ${order}${where}`;
    const url = `${apiUrl}${resource}?${loopbackquery}`;

    //const url = `${apiUrl}${resource}?${stringify(query)}`;

    return httpClient(url).then(({ headers, json }) => {
      if (!headers.has("x-total-count")) {
        throw new Error(
          "The X-Total-Count header is missing in the HTTP Response. The jsonServer Data Provider expects responses for lists of resources to contain this header with the total number of results to build the pagination. If you are using CORS, did you declare X-Total-Count in the Access-Control-Expose-Headers header?",
        );
      }
      let count: string = "10";
      count = headers.get("x-total-count")!.split("/").pop()!;
      return {
        data: json,
        total: parseInt(count, 10),
      };
    });
  },

  update: async (resource, params) => {
    //TODO not used - Rewrite

    console.log("update", dataProvName);

    return httpClient(`${apiUrl}${resource}/${params.id}`, {
      method: "PUT",
      body: JSON.stringify(params.data),
    }).then(({ json }) => ({ data: json }));
  },

  // json-server doesn't handle filters on UPDATE route, so we fallback to calling UPDATE n times instead
  updateMany: (resource, params) => {
    //TODO not used - Rewrite

    console.log("updateMany", dataProvName);

    return Promise.all(
      params.ids.map((id) =>
        httpClient(`${apiUrl}${resource}/${id}`, {
          method: "PUT",
          body: JSON.stringify(params.data),
        }),
      ),
    ).then((responses) => ({ data: responses.map(({ json }) => json.id) }));
  },

  create: async (resource, params) => {
    console.log("create", dataProvName);
    const url = new URL(apiUrl);
    url.pathname = "user";

    return httpClient(url.toString(), {
      method: "PUT",
      body: JSON.stringify(params.data),
    }).then(({ json }) => {
      console.log(json);
      return {
        data: { ...params.data, id: json.id } as any,
      };
    });
  },

  delete: (resource, params) => {
    //TODO not used - Rewrite

    console.log("delete", dataProvName);

    return httpClient(`${apiUrl}${resource}/${params.id}`, {
      method: "DELETE",
    }).then(({ json }) => ({ data: json }));
  },

  // json-server doesn't handle filters on DELETE route, so we fallback to calling DELETE n times instead
  deleteMany: (resource, params) => {
    //TODO not used - Rewrite

    console.log("deleteMany", dataProvName);
    return Promise.all(
      params.ids.map((id) =>
        httpClient(`${apiUrl}${resource}/${id}`, {
          method: "DELETE",
        }),
      ),
    ).then((responses) => ({ data: responses.map(({ json }) => json.id) }));
  },
});
