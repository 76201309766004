import styles from "./BottomFixedActionContainer.module.scss";

interface IBottomFixedActionContainerProps {
  buttonGroups: JSX.Element[][];
}

const BottomFixedActionContainer = ({
  buttonGroups,
}: IBottomFixedActionContainerProps) => {
  return (
    <div className={styles.bottomFixedActionContainer}>
      <div className={styles.buttonGroupContainer}>
        {buttonGroups.map((buttonGroup, index) => (
          <div key={`button-group-${index}`} className={styles.buttonGroup}>
            {buttonGroup.map((button, index) => (
              <div key={`button-${index}`} className={styles.subItem}>
                {button}
              </div>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
};

export default BottomFixedActionContainer;
