import {
  Clear,
  Crop169,
  CropSquare,
  Rotate90DegreesCw,
  Save,
} from "@mui/icons-material";
import { Button, IconButton, Slider } from "@mui/material";
import React, { useCallback, useState } from "react";
import Cropper, { Area, Point } from "react-easy-crop";

import styles from "./ImageEditor.module.css";
import getCroppedImg from "./getCroppedImg";

interface CropProps {
  imageObject: any;
  handleImageUpload: (filename: string, bulletinID: number, file: File) => void;
  onCancel: () => void;
  bulletinID: number;
}

const ImageEditor: React.FC<CropProps> = ({
  imageObject,
  handleImageUpload,
  onCancel,
  bulletinID,
}) => {
  const [crop, setCrop] = useState<Point>({ x: 0, y: 0 });
  const [zoom, setZoom] = useState<number>(1.2);
  const [aspect, setAspect] = useState<number>(9 / 16);
  const [rotation, setRotation] = useState<number>(0);
  const [cropAreaPixels, setCropAreaPixels] = useState<Area | null>(null);
  const url = new URL(imageObject.download_url);
  const imageUrl = url.toString();

  const onCropComplete = useCallback((_, croppedAreaPixels) => {
    setCropAreaPixels(croppedAreaPixels);
  }, []);

  const saveCroppedImage = useCallback(async () => {
    if (!cropAreaPixels) return;

    const croppedImageBlob = await getCroppedImg(
      imageUrl,
      cropAreaPixels,
      rotation,
    );

    console.log("croppedImageBlob", croppedImageBlob);

    // Convert Blob into File
    const croppedImageFile = new File(
      [croppedImageBlob],
      imageObject.filename,
      {
        type: "image/jpeg",
      },
    );

    console.log(croppedImageFile);

    // Now you can send this file to your API
    // const formData = new FormData();
    // formData.append("image", croppedImageFile);

    handleImageUpload(imageObject.filename, bulletinID, croppedImageFile);
  }, [cropAreaPixels, handleImageUpload, imageUrl, rotation]);

  const handleRotate = () => {
    let newRotatation = (rotation + 90) % 360;
    newRotatation = Math.floor(newRotatation / 90) * 90;
    setRotation(newRotatation);
  };

  return (
    <div className={styles.imageEditorContainer}>
      <div className={styles.cropContainer}>
        <Cropper
          image={imageUrl}
          crop={crop}
          zoom={zoom}
          rotation={rotation}
          aspect={aspect}
          onCropChange={setCrop}
          onCropComplete={onCropComplete}
          onZoomChange={setZoom}
          zoomSpeed={0.5}
          maxZoom={10}
          style={{
            containerStyle: {
              maxHeight: "calc(90% - 48px)",
              top: "48px",
            },
          }}
        />
      </div>

      <div className={styles.bottomContainer}>
        <div className={styles.controlsContainer}>
          <IconButton
            onClick={() => setAspect(9 / 16)}
            color="primary"
            size="large"
            sx={{ transform: "rotate(90deg)" }}
          >
            <Crop169 />
          </IconButton>
          <IconButton onClick={() => setAspect(1)} color="primary" size="large">
            <CropSquare />
          </IconButton>
          <IconButton
            onClick={() => setAspect(16 / 9)}
            color="primary"
            size="large"
          >
            <Crop169 />
          </IconButton>

          <div className={styles.zoomContainer}>
            <Slider
              value={rotation}
              min={0}
              max={360}
              step={1}
              aria-labelledby="Rotation"
              onChange={(e, rotation) => {
                if (Array.isArray(rotation)) {
                  setRotation(rotation[0]);
                } else setRotation(rotation);
              }}
            />
            <IconButton onClick={handleRotate} color="primary" size="large">
              <Rotate90DegreesCw />
            </IconButton>
          </div>
        </div>

        <div className={styles.controlContainer}></div>

        <div className={styles.buttonsContainer}>
          <Button
            color="primary"
            variant="outlined"
            onClick={onCancel}
            endIcon={<Clear />}
          >
            CANCEL
          </Button>
          <Button
            color="primary"
            variant="contained"
            onClick={saveCroppedImage}
            startIcon={<Save />}
          >
            SAVE
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ImageEditor;
