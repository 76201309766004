import { Link, Typography } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router";

import { apiAlertClient } from "../../../common/api-client";
import { mailTo } from "../../../common/utils";
import { AuthStateContext } from "../../../context";
import { IUserPermission } from "../../../types";
import { DisabledComponentDialog } from "../../DisabledComponent";
import { parseUserPermissions } from "../../UserManagement/utils";

function AlertAnalytics() {
  const [iframeUrl, setIframeUrl] = useState("");
  const [isReadOnlyDialogOpen, setIsReadOnlyDialogOpen] = useState(false);
  const navigate = useNavigate();
  const { currentUser } = useContext(AuthStateContext);

  const [alertPermissions] = useState(
    currentUser &&
      parseUserPermissions(currentUser?.permissions as IUserPermission[]),
  );
  const readOnly = alertPermissions
    ? !alertPermissions.canAccessDashboard
    : false;

  const getMetabaseURL = () => {
    apiAlertClient.getMetabaseURL().then((res) => {
      setIframeUrl(res.metabase_url);
    });
  };

  useEffect(() => {
    if (readOnly) {
      setIsReadOnlyDialogOpen(true);
    }
  }, [readOnly]);

  useEffect(() => {
    if (!readOnly) getMetabaseURL();
  }, []);

  if (readOnly) {
    return (
      <DisabledComponentDialog
        isDialogOpen={isReadOnlyDialogOpen}
        handleClose={() => {
          navigate(`/rubialert/alerts`);
        }}
        title="You do not have access to view alert analytics."
        message={
          <Typography>
            Please contact{" "}
            <Link
              rel="noopener noreferrer"
              target="_blank"
              href={mailTo({
                email: "support@tryrubicon.com",
                subject: "Requesting Access to View Alert Analytics",
                body: `Hello Rubicon Support Team, 

                I would like to request access to view alert analytics.

                Thank you. 

                Sincerely,

                ${currentUser?.first_name} ${currentUser?.last_name} 
                ${currentUser?.email}`,
              })}
              color="secondary"
            >
              support@tryrubicon.com
            </Link>{" "}
            to request access.
          </Typography>
        }
      />
    );
  }

  return (
    <div style={{ display: "flex", width: "100%", height: "100%" }}>
      <iframe
        src={iframeUrl}
        style={{
          flexGrow: 1,
        }}
        allowTransparency
      ></iframe>
    </div>
  );
}

export default AlertAnalytics;
