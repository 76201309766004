import { useState } from "react";

import { AddressField } from "../fields/AddressField";
import { ReportDateField } from "../fields/ReportDateField";
import { ReportField } from "../fields/ReportField";
import { TheftFromVehiclePropertyField } from "../fields/TheftFromVehiclePropertyField";
import { ShowHideButton } from "../fields/UI/ShowHideButton";
import styles from "../report.module.css";

export const TheftFromVehicle = (props) => {
  const { data } = props;
  const [show, setShow] = useState(false);
  return (
    <>
      {data.info && data.info.theftFromVehicle && (
        <div className="theftfromVehicle">
          <ReportDateField
            showtime={true}
            className="report-field-row"
            value={data.info.theftFromVehicle.startTime}
            label="Start Date/Time of Incident"
          />
          <ReportDateField
            showtime={true}
            className="report-field-row"
            value={data.info.theftFromVehicle.endTime}
            label="End Date/Time of Incident"
          />
          <ReportField
            className="report-field-row"
            value={data.info.theftFromVehicle.locationType}
            label="Location Type"
          />
          <AddressField
            label="Incident Location"
            address={data.info.theftFromVehicle.streetAddress}
            onEditAddress={() => {
              props.onEditAddress(
                data.info.theftFromVehicle.streetAddress,
                "info.theftFromVehicle.streetAddress",
              );
            }}
          />
          {data.info.theftFromVehicle.narrative && (
            <div className={styles.subHeading}>Narrative</div>
          )}
          {data.info.theftFromVehicle.narrative || props.clickState ? (
            <ReportField
              className={styles.narrativeField + " report-field-row"}
              value={data.info.theftFromVehicle.narrative}
              label="Incident Description"
            />
          ) : null}

          {data.info.theftFromVehicle && data.info.theftFromVehicle.vehicle && (
            <div className={styles.subHeading}>
              Vehicle Information
              <ShowHideButton
                onClick={() => {
                  setShow(!show);
                }}
              >
                {" "}
                {show ? "Hide " : "Show "} empty fields{" "}
              </ShowHideButton>
            </div>
          )}

          {data.info.theftFromVehicle && data.info.theftFromVehicle.vehicle && (
            <>
              <ReportField
                className="report-field-row"
                value={data.info.theftFromVehicle.vehicle.involvementType}
                label="Type"
              />
              <ReportField
                className="report-field-row"
                value={data.info.theftFromVehicle.vehicle.maker}
                label="Make"
              />
              <ReportField
                className="report-field-row"
                value={data.info.theftFromVehicle.vehicle.model}
                label="Model"
              />
              {data.info.theftFromVehicle.vehicle.vIN || show ? (
                <ReportField
                  className="report-field-row"
                  value={data.info.theftFromVehicle.vehicle.vIN}
                  label="VIN"
                />
              ) : null}
              <ReportField
                className="report-field-row"
                value={data.info.theftFromVehicle.vehicle.style}
                label="Style"
              />
              <ReportField
                className="report-field-row"
                value={data.info.theftFromVehicle.vehicle.year}
                label="Year"
              />
              <ReportField
                className="report-field-row"
                value={data.info.theftFromVehicle.vehicle.colours}
                label="Colour"
              />
              {data.info.theftFromVehicle.vehicle.marketValue || show ? (
                <ReportField
                  className="report-field-row"
                  value={data.info.theftFromVehicle.vehicle.marketValue}
                  label="Market Value"
                />
              ) : null}
              {data.info.theftFromVehicle.vehicle.damagedValue || show ? (
                <ReportField
                  className="report-field-row"
                  value={data.info.theftFromVehicle.vehicle.damagedValue}
                  label="Damaged Value"
                />
              ) : null}
              {data.info.theftFromVehicle.vehicle.licenseType || show ? (
                <ReportField
                  className="report-field-row"
                  value={data.info.theftFromVehicle.vehicle.licenseType}
                  label="License Type"
                />
              ) : null}
              <ReportField
                className="report-field-row"
                value={data.info.theftFromVehicle.vehicle.licensePlateNo}
                label="License Plate No"
              />
              <ReportField
                className="report-field-row"
                value={data.info.theftFromVehicle.vehicle.licenseLocation}
                label="License Province"
              />
              {data.info.theftFromVehicle.vehicle.vehicleSituation || show ? (
                <ReportField
                  className="report-field-row"
                  value={data.info.theftFromVehicle.vehicle.vehicleSituation}
                  label="Vehicle locked and Windows closed"
                />
              ) : null}
            </>
          )}

          {data.info &&
            data.info.theftFromVehicle &&
            data.info.theftFromVehicle.property && (
              <div className={styles.subHeading}>Property Information</div>
            )}
          {data.info &&
            data.info.theftFromVehicle &&
            data.info.theftFromVehicle.property && (
              <TheftFromVehiclePropertyField
                data={data.info.theftFromVehicle.property}
              />
            )}
        </div>
      )}
    </>
  );
};
