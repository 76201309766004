import { Add, Delete, Send, Info } from "@mui/icons-material";
import {
  Box,
  Typography,
  TextField,
  Autocomplete,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Button,
  CircularProgress,
  Tooltip,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Paper,
} from "@mui/material";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";

import styles from "./NotifyEmails.module.css";
import { apiAlertClient } from "../../../../common/api-client";

const NotifyEmails = ({
  bulletinID,
  setSentFlag,
  readOnly,
}: {
  bulletinID: any;
  setSentFlag: (arg: boolean) => void;
  readOnly: boolean;
}) => {
  const [emailGroups, setEmailGroups] = useState<any[]>([]);
  const [selectedGroups, setSelectedGroups] = useState<any[]>([]);
  const [additionalEmails, setAdditionalEmails] = useState<any[]>([]);
  const [newGroupName, setNewGroupName] = useState("");
  const [selectedManageGroup, setSelectedManageGroup] = useState<any>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  const handleSend = () => {
    setIsLoading(true);
    const groupEmails = selectedGroups.flatMap((group) => group.items);
    const formattedEmails = additionalEmails.map((email) => {
      return {
        email: email,
      };
    });
    let allEmails = [...groupEmails, ...formattedEmails];
    allEmails = allEmails.filter(
      (value, index, self) =>
        index === self.findIndex((email) => email.email === value.email),
    );
    // Call the backend with the list of all emails associated with the selected groups and the additional emails

    if (allEmails.length) {
      apiAlertClient
        .patchBulletin({ recipients: allEmails }, bulletinID)
        .then((res) => {
          apiAlertClient.sendBulletin(bulletinID).then((res) => {
            toast.success("Emails have been sent to recipients");
          });
          setIsLoading(false);
          setSentFlag(true);
        })
        .catch((err) => {
          toast.error("Problem sending emails");
          setIsLoading(false);
        });
    } else {
      toast.warning(
        "No emails to send to. Check that the email groups contain emails.",
      );
      setIsLoading(false);
    }
  };

  const handleAddGroup = () => {
    if (newGroupName.trim()) {
      //Check if the group already exists
      if (emailGroups.find((item) => item.name === newGroupName)) {
        toast.error("Email group already exist with same name.");
      } else {
        apiAlertClient
          .addMailingList(newGroupName)
          .then((res) => {
            if (res.id) {
              const emailGroupsArr = [...emailGroups];
              emailGroupsArr.push(res);
              setEmailGroups(emailGroupsArr);
              setSelectedManageGroup(res);
              setNewGroupName("");
            }
            toast.success("New Email Group Added, please add emails to it");
          })
          .catch((err) => console.log(err));
      }
    }
  };

  const handleDeleteGroup = () => {
    if (selectedManageGroup) {
      apiAlertClient
        .DeleteMailingList(selectedManageGroup.id)
        .then(() => {
          toast.success("Email Group Deleted");
          setEmailGroups(
            emailGroups.filter((group) => group.id !== selectedManageGroup.id),
          );
          setSelectedManageGroup(null);
          setDeleteDialogOpen(false);
        })
        .catch((err) => console.log(err));
    }
  };

  const addEmailAddress = (groupId, email) => {
    apiAlertClient
      .addEmailToMailingList(groupId, email)
      .catch((err) => console.log(err));
  };

  const removeEmailAddress = (groupId, email) => {
    apiAlertClient
      .removeEmailFromMailingList(groupId, email)
      .catch((err) => console.log(err));
  };

  const handleGroupEmailsChange = (_, newValue) => {
    let validEmails: { email: string }[] = [];

    if (newValue.length > 0) {
      // Convert last string email into object {"email": newValue[-1]}
      if (typeof newValue[newValue.length - 1] === "string") {
        newValue[newValue.length - 1] = {
          email: newValue[newValue.length - 1],
        };
      }
      validEmails = newValue.filter((item) => emailRegex.test(item.email));
      if (validEmails.length < newValue.length) {
        toast.error("Sorry, Invalid Email Address.");
        return;
      }

      // Check for duplicates
      if (validEmails.length > 2) {
        const newEmail = validEmails[validEmails.length - 1].email;
        const tmpValidEmails = validEmails.slice(0, -1);
        if (tmpValidEmails.find((item) => item.email === newEmail)) {
          toast.error("This email address already exists");
          return;
        }
      }
    }

    if (selectedManageGroup) {
      // Check if an email was added or removed and call API
      const prevEmailGroup = emailGroups.find(
        (group) => group.id === selectedManageGroup.id,
      );

      const addedEmail: { email: string }[] = validEmails.filter(
        (emailItem) => !prevEmailGroup.items.includes(emailItem),
      );
      const removedEmail: { email: string }[] = prevEmailGroup.items.filter(
        (emailItem) => !validEmails.includes(emailItem),
      );

      if (addedEmail.length > 0) {
        addEmailAddress(selectedManageGroup.id, addedEmail[0].email);
      }
      if (removedEmail.length > 0) {
        removeEmailAddress(selectedManageGroup.id, removedEmail[0].email);
      }

      selectedManageGroup.items = validEmails;
      const updatedGroups = emailGroups.map((group) =>
        group.name === selectedManageGroup.name
          ? { ...group, items: validEmails }
          : group,
      );
      setEmailGroups(updatedGroups);
    }
  };

  const loadMailingLists = () => {
    apiAlertClient
      .getMailingLists()
      .then((res) => {
        if (res.items && res.items.length > 0) {
          setEmailGroups(res.items);
        }
      })
      .catch((err) => {
        console.log(err, "Error getting Mailing List");
      });
  };

  useEffect(() => {
    if (!readOnly) loadMailingLists();
  }, []);

  return (
    <Paper elevation={4} className={styles.notifyEmailsContainer}>
      <Accordion elevation={0}>
        <AccordionSummary
          sx={{ padding: "0px" }}
          expandIcon={<Info />}
          // aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <div>
            <Typography variant="h5" sx={{ width: "100%" }}>
              <b>Send</b>
            </Typography>
          </div>
        </AccordionSummary>
        <AccordionDetails sx={{ padding: "0px" }}>
          <Typography>
            Recipients will receive an email containing a link to view the
            bulletin, even if they don't have access to the RubiALERT platform
            they can still view it on their phone or computers.
          </Typography>
        </AccordionDetails>
      </Accordion>
      {/* <Typography variant="h6">Select Email Groups</Typography> */}
      <Autocomplete
        multiple
        freeSolo
        disableClearable
        options={emailGroups}
        getOptionLabel={(option) => option.name}
        value={[...selectedGroups, ...additionalEmails]}
        onChange={(_, newValue) => {
          const groups = newValue.filter((item) => typeof item === "object");
          const emails = newValue.filter((item) => typeof item === "string");
          setSelectedGroups(groups);
          setAdditionalEmails(emails);
        }}
        renderTags={(value, getTagProps) =>
          value.map((option, index) => {
            if (typeof option === "object") {
              return (
                <Chip
                  label={option.name}
                  {...getTagProps({ index })}
                  color="secondary"
                />
              );
            } else {
              return <Chip label={option} {...getTagProps({ index })} />;
            }
          })
        }
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            label="Email Groups and Addresses"
            placeholder="Select Groups or Type Emails"
          />
        )}
        disabled={readOnly}
      />

      <Box mt={2}>
        <Typography variant="h6">Manage Email Group</Typography>
        <div className={styles.manageGroupsContainer}>
          <Box
            display="flex"
            alignItems="center"
            sx={{ minWidth: "200px", flexGrow: 1 }}
          >
            <TextField
              variant="outlined"
              label="Create New Email Group"
              value={newGroupName}
              fullWidth
              onChange={(e) => setNewGroupName(e.target.value)}
              disabled={readOnly}
            />
            <IconButton
              onClick={handleAddGroup}
              color="primary"
              disabled={readOnly || !newGroupName.trim()}
            >
              <Add />
            </IconButton>
          </Box>

          <Box
            display="flex"
            alignItems="center"
            sx={{ minWidth: "200px", flexGrow: 3 }}
          >
            <Autocomplete
              options={emailGroups}
              getOptionLabel={(option) => option.name}
              value={selectedManageGroup}
              isOptionEqualToValue={(option, value) =>
                option.name === value.name
              }
              sx={{ minWidth: "200px", flexGrow: 2 }}
              onChange={(_, newValue) => setSelectedManageGroup(newValue)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  label="Select Group to Manage"
                  placeholder="Select Group"
                />
              )}
              disabled={readOnly}
            />
            {selectedManageGroup && (
              <Tooltip title="Delete This Email Group" placement="top">
                <IconButton
                  onClick={() => setDeleteDialogOpen(true)}
                  color="primary"
                >
                  <Delete />
                </IconButton>
              </Tooltip>
            )}
          </Box>
        </div>

        {selectedManageGroup && (
          <Box>
            {/* <Typography variant="h6">
              Manage Emails in {selectedManageGroup.name}
            </Typography> */}
            <Autocomplete
              multiple
              freeSolo
              options={[]}
              value={selectedManageGroup.items}
              onChange={handleGroupEmailsChange}
              disableClearable
              renderTags={(value, getTagProps) =>
                value.map((emailItem: { email: string }, index) => (
                  <Chip label={emailItem?.email} {...getTagProps({ index })} />
                ))
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  label="Add or Remove Email Addresses"
                  placeholder="Type Email Addresses"
                />
              )}
            />
          </Box>
        )}
      </Box>

      <Box
        mt={"16px"}
        sx={{
          display: "flex",
          justifyContent: "center",
          width: "100%",
        }}
      >
        <Button
          variant="outlined"
          color="primary"
          onClick={handleSend}
          fullWidth
          disabled={
            (selectedGroups.length === 0 && additionalEmails.length === 0) ||
            isLoading
          }
          startIcon={isLoading ? <CircularProgress size={20} /> : <Send />}
        >
          Send
        </Button>

        <Dialog
          open={deleteDialogOpen}
          onClose={() => setDeleteDialogOpen(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">Delete Email Group</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Are you sure you want to delete the email group "
              {selectedManageGroup?.name}"? This action cannot be undone.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setDeleteDialogOpen(false)} color="primary">
              Cancel
            </Button>
            <Button onClick={handleDeleteGroup} color="primary" autoFocus>
              Delete
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
    </Paper>
  );
};

export default NotifyEmails;
