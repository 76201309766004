/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { GetOrgRecordDownloadResponse } from '../models/GetOrgRecordDownloadResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class RecordDownloadService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * @param orgId 
     * @returns GetOrgRecordDownloadResponse 
     * @throws ApiError
     */
    public get(
orgId: string,
): CancelablePromise<GetOrgRecordDownloadResponse> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/report/org/{org_id}/record_download',
            path: {
                'org_id': orgId,
            },
        });
    }

}
