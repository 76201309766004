import { defaultTheme } from "react-admin";
const actionColor = "#fd6262";
const backgroundLightColor = "#F4F7F8";
const backgroundDarkColor = "#1A1B2B";
const selectBlueColor = "#6d7db9";

export const darkTheme = {
  ...defaultTheme,
  palette: {
    primary: {
      main: "#90caf9",
    },
    type: "dark", // Switching the dark mode on is a single property value change.
  },
};

export const lightTheme = {
  ...defaultTheme,
  palette: {
    primary: {
      main: actionColor,
      contrastText: "#fff",
    },
    secondary: {
      main: selectBlueColor,
    },
    // Used by `getContrastText()` to maximize the contrast between
    // the background and the text.
    contrastThreshold: 3,
    // Used by the functions below to shift a color's luminance by approximately
    // two indexes within its tonal palette.
    // E.g., shift from Red 500 to Red 300 or Red 700.
    tonalOffset: 0.2,
    type: "light",
  },

  sidebar: {
    width: 224, // The default value is 240
    closedWidth: 54, // The default value is 55
  },

  components: {
    ...defaultTheme.components,
    RaLayout: {
      styleOverrides: {
        root: {
          "& .RaLayout-content": {
            padding: "0px 0px",
          },
        },
      },
    },
    RaSidebar: {
      styleOverrides: {
        root: {
          backgroundColor: backgroundDarkColor,
          "& .RaSidebar-fixed": {
            width: "inherit",
          },
          "& .MuiPaper-root": {
            backgroundColor: backgroundDarkColor,
          },
        },
      },
    },
    RaAppBar: {
      styleOverrides: {
        root: {
          backgroundColor: backgroundDarkColor,
        },
      },
    },
    // MuiButton : {
    //   styleOverrides: {
    //     root: {
    //       backgroundColor: backgroundDarkColor,
    //     }
    //   }
    // }

    // RaSelectInput : {
    //   styleOverrides: {
    //     root: {
    //       backgroundColor: "white",
    //     }
    //   }
    // },

    // MuiFilledInput: {
    //   styleOverrides: {
    //     root: {
    //       backgroundColor: "white",
    //       "&$disabled": {
    //         backgroundColor: "rgba(0, 0, 0, 0.04)",
    //       },
    //     },
    //   },
    // },
  },
};

// export const darkTheme = {
//     ...defaultTheme,
//     palette: {
//         primary: {
//             main: '#90caf9',
//         },
//         secondary: {
//             main: '#FBBA72',
//         },
//         mode: 'dark' as 'dark', // Switching the dark mode on is a single property value change.
//     },
//     sidebar: {
//         width: 200,
//     },
//     components: {
//         ...defaultTheme.components,
//         RaMenuItemLink: {
//             styleOverrides: {
//                 root: {
//                     borderLeft: '3px solid #000',
//                     '&.RaMenuItemLink-active': {
//                         borderLeft: '3px solid #90caf9',
//                     },
//                 },
//             },
//         },
//         MuiAppBar: {
//             styleOverrides: {
//                 backgroundColor: {backgroundDarkColor},
//                 colorSecondary: {
//                     color: '#ffffffb3',
//                     backgroundColor: '#616161e6',
//                 },
//             },
//         },
//     },
// };

// export const lightTheme = {
//     ...defaultTheme,
//     palette: {
//         primary: {
//             main: '#4f3cc9',
//         },
//         secondary: {
//             light: '#5f5fc4',
//             main: '#283593',
//             dark: '#001064',
//             contrastText: '#fff',
//         },
//         background: {
//             default: '#fcfcfe',
//         },
//         mode: 'light' as 'light',
//     },
//     shape: {
//         borderRadius: 10,
//     },
//     sidebar: {
//         width: 200,
//     },
//     components: {
//         ...defaultTheme.components,
//         RaMenuItemLink: {
//             styleOverrides: {
//                 root: {
//                     borderLeft: '3px solid #fff',
//                     '&.RaMenuItemLink-active': {
//                         borderLeft: '3px solid #4f3cc9',
//                     },
//                 },
//             },
//         },
//         MuiPaper: {
//             styleOverrides: {
//                 elevation1: {
//                     boxShadow: 'none',
//                 },
//                 root: {
//                     border: '1px solid #e0e0e3',
//                     backgroundClip: 'padding-box',
//                 },
//             },
//         },
//         MuiAppBar: {
//             styleOverrides: {
//                 root:{
//                     backgroundColor: {backgroundDarkColor},
//                     '&	.MuiAppBar-root' : {
//                         backgroundColor: {backgroundDarkColor},
//                     }
//                     // colorSecondary: {
//                     //     color: '#808080',
//                     //     backgroundColor: '#fff',
//                     // },
//                 }
//             },
//         },
//         MuiLinearProgress: {
//             styleOverrides: {
//                 colorPrimary: {
//                     backgroundColor: '#f5f5f5',
//                 },
//                 barColorPrimary: {
//                     backgroundColor: '#d7d7d7',
//                 },
//             },
//         },
//     },
// };
