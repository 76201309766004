// All APIs related to Reports (RubiREPORT)
import * as http from "./http";
import { APP_SERVER_URL } from "../../environment";

export const APIUrl = APP_SERVER_URL;

export const formatDate = (date) => {
  let d = new Date(date),
    month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;
  return [year, month, day].join("-");
};

export const getReport = (id) => {
  const api = `${APIUrl}report/${id}`;
  return http.get(api, { credentials: "include" }).then((response) => {
    return response;
  });
};
export const saveReport = (id, data) => {
  const api = `${APIUrl}report/${id}`;
  return http
    .put(api, { body: data, credentials: "include" })
    .then((response) => {
      return response;
    });
};

export const getFilters = () => {
  const api = `${APIUrl}filters`;
  return http.get(api, { credentials: "include" }).then((response) => {
    return response;
  });
};

export const getReportList = () => {
  const api = `${APIUrl}report`;
  return http.get(api, { credentials: "include" }).then((response) => {
    return response;
  });
};

export const resetReportstatus = (id) => {
  const api = `${APIUrl}report/${id}/status/reset`;
  return http.post(api, { credentials: "include" }).then((response) => {
    return response;
  });
};

export const approveReport = (id) => {
  const api = `${APIUrl}report/${id}/status/approve`;
  return http.post(api, { credentials: "include" }).then((response) => {
    return response;
  });
};

export const approveReportWithNote = (id, note) => {
  const api = `${APIUrl}report/${id}/status/approve`;

  const data: any = {
    message: note,
  };

  return http
    .post(api, { body: data, credentials: "include" })
    .then((response) => {
      return response;
    });
};

export const rejectReport = (id, note) => {
  const api = `${APIUrl}report/${id}/status/reject`;

  const data: any = {
    message: note,
  };

  return http
    .post(api, { body: data, credentials: "include" })
    .then((response) => {
      return response;
    });
};

export const deleteRecord = async (path, id) => {
  const api = APIUrl + path + "/" + id;
  return await http.del(api).then((response) => {
    return response;
  });
};

// Organization endpoints

export const getUsersList = (org_id) => {
  const api = `${APIUrl}org/${org_id}/user`;
  return http.get(api, { credentials: "include" }).then((response) => {
    return response;
  });
};

export const getUser = (user_id) => {
  const api = `${APIUrl}user/${user_id}`;
  return http.get(api, { credentials: "include" }).then((response) => {
    return response;
  });
};

export const getUserPermissions = (user_id) => {
  const api = `${APIUrl}user/${user_id}/permissions`;
  return http.get(api, { credentials: "include" }).then((response) => {
    return response;
  });
};

/**
 * Add a user to the app
 * @param org_id
 * @param org_user
 * @returns
 */
export const putUser = (org_id, org_user) => {
  const api = `${APIUrl}org/${org_id}/user`;
  return http
    .put(api, { body: org_user, credentials: "include" })
    .then((response) => {
      return response;
    });
};

export const deleteOrgUser = (org_user_id) => {
  const api = `${APIUrl}user/${org_user_id}`;
  return http.del(api, { credentials: "include" }).then((response) => {
    return response;
  });
};

export const updateOrgUser = (user_id, org_user) => {
  const api = `${APIUrl}user/${user_id}`;
  return http
    .patch(api, { body: org_user, credentials: "include" })
    .then((response) => {
      return response;
    });
};

export const putReportUser = (org_user_id) => {
  const api = `${APIUrl}user`;
  const data = {
    user_id: org_user_id,
  };
  return http
    .put(api, {
      body: data,
      credentials: "include",
    })
    .then((response) => {
      return response;
    });
};

export const revokeReportAccess = (user_id) => {
  const api = `${APIUrl}user/${user_id}/revoke_access`;
  return http.post(api, { credentials: "include" }).then((response) => {
    return response;
  });
};

export const addOrgUserPermission = (org_user_id, permission) => {
  const api = `${APIUrl}user/${org_user_id}/add_permission`;
  const body = {
    permission_title: permission,
  };
  return http.post(api, { body, credentials: "include" }).then((response) => {
    return response;
  });
};

export const removeOrgUserPermission = (org_user_id, permission) => {
  const api = `${APIUrl}user/${org_user_id}/remove_permission`;
  const body = {
    permission_title: permission,
  };
  return http.post(api, { body, credentials: "include" }).then((response) => {
    return response;
  });
};

export const deactivateOrgUser = (org_user_id) => {
  const api = `${APIUrl}user/${org_user_id}/disable`;
  return http.post(api, { credentials: "include" }).then((response) => {
    return response;
  });
};

export const promoteToAdmin = (org_user_id) => {
  const api = `${APIUrl}user/${org_user_id}/promote_to_org_admin`;
  return http.post(api, { credentials: "include" }).then((response) => {
    return response;
  });
};

export const updateAppAccess = (
  org_user_id: string,
  app_name: "alert" | "report" | "report_v2" | "camera_registry",
  access: boolean,
) => {
  const api = `${APIUrl}user/${org_user_id}/access`;
  const body = {
    application: app_name,
    access: access,
  };
  return http.post(api, { body, credentials: "include" }).then((response) => {
    return response;
  });
};
