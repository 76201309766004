import { LocationOn } from "@mui/icons-material";
import {
  TextField,
  Autocomplete,
  Grid,
  Typography,
  Box,
  debounce,
} from "@mui/material";
import { useJsApiLoader } from "@react-google-maps/api";
import parse from "autosuggest-highlight/parse";
import { useEffect, useMemo, useRef, useState } from "react";

import { LOCATION_CLASSIFICATION } from "../../components/alerts/constants";
import {
  APP_GOOGLE_PLACES_KEY,
  GOOGLE_MAPS_LIBRARIES,
} from "../../environment";
import { PlaceType } from "../../types";

function loadScript(src: string, position: HTMLElement | null, id: string) {
  if (!position) {
    return;
  }

  const script = document.createElement("script");
  script.setAttribute("async", "");
  script.setAttribute("id", id);
  script.src = src;
  position.appendChild(script);
}

const autocompleteService = { current: null };

interface Props {
  setSelectedAddress: any;
  selectedAddress: PlaceType | null;
  required?: boolean;
  error?: boolean;
  language?: string;
  componentRestrictions?: { country: string[] };
  minLengthAutocomplete?: number;
  index?: number | null;
  label?: string;
}

const GooglePlacesAutocomplete: React.FC<Props> = ({
  language = "en",
  componentRestrictions = { country: ["us", "ca"] },
  minLengthAutocomplete = 3,
  required = true,
  error = false,
  label = "Incident Address",
  selectedAddress = null,
  index = null,
  setSelectedAddress = null,
}) => {
  const [value, setValue] = useState<PlaceType | null>(selectedAddress);
  const [inputValue, setInputValue] = useState("");
  const [options, setOptions] = useState<readonly PlaceType[]>([]);
  const loaded = useRef(false);
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: APP_GOOGLE_PLACES_KEY,
    libraries: GOOGLE_MAPS_LIBRARIES,
  });

  if (typeof window !== "undefined" && !loaded.current) {
    loaded.current = true;
  }

  const fetch = useMemo(
    () =>
      debounce(
        (
          request: {
            input: string;
            language: string;
            componentRestrictions: { country: string[] };
          },
          callback: (results?: readonly PlaceType[]) => void,
        ) => {
          (autocompleteService.current as any).getPlacePredictions(
            request,
            callback,
          );
        },
        400,
      ),
    [],
  );

  useEffect(() => {
    let active = true;

    if (!isLoaded) return undefined;

    if (
      !autocompleteService.current &&
      (window as any).google &&
      window.google.maps.places
    ) {
      autocompleteService.current = new (
        window as any
      ).google.maps.places.AutocompleteService();
    }

    if (!autocompleteService.current) {
      return undefined;
    }

    if (inputValue === "") {
      setOptions(value ? [value] : []);
      return undefined;
    }

    if (inputValue.length < minLengthAutocomplete) {
      setOptions(value ? [value] : []);
      return undefined;
    }

    fetch(
      {
        input: inputValue,
        language: language,
        componentRestrictions: componentRestrictions,
      },
      (results?: readonly PlaceType[]) => {
        if (active) {
          let newOptions: readonly PlaceType[] = [];

          if (value) {
            newOptions = [value];
          }

          if (results) {
            newOptions = [...newOptions, ...results];
          }

          setOptions(newOptions);
        }
      },
    );

    return () => {
      active = false;
    };
  }, [value, inputValue, fetch, language, minLengthAutocomplete, isLoaded]);

  return (
    <Autocomplete
      id="google-map-autocomplete"
      getOptionLabel={(option) =>
        typeof option === "string" ? option : option.description
      }
      filterOptions={(x) => x}
      options={options}
      autoComplete
      includeInputInList
      filterSelectedOptions
      fullWidth
      value={value}
      noOptionsText="No locations"
      onChange={(_event: any, newValue: PlaceType | null) => {
        setOptions(newValue ? [newValue, ...options] : options);
        setValue(newValue);
        if (index !== null) {
          setSelectedAddress((prevAddresses) => {
            const newAddressFields = [...prevAddresses];
            const existingAddress = prevAddresses[index];
            newAddressFields[index] = {
              ...existingAddress,
              ...newValue,
              location_classification:
                existingAddress?.location_classification ||
                LOCATION_CLASSIFICATION.INCIDENT_ADDRESS,
            };
            return newAddressFields;
          });
        } else {
          setSelectedAddress(newValue);
        }
      }}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          variant="outlined"
          fullWidth
          placeholder="Start typing"
          required={required}
          error={error}
        />
      )}
      renderOption={(props, option) => {
        const matches =
          option.structured_formatting.main_text_matched_substrings || [];
        const parts = parse(
          option.structured_formatting.main_text,
          matches.map((match: any) => [
            match.offset,
            match.offset + match.length,
          ]),
        );

        return (
          <li {...props}>
            <Grid container alignItems="center">
              <Grid item sx={{ display: "flex", width: 44 }}>
                <LocationOn sx={{ color: "text.secondary" }} />
              </Grid>
              <Grid
                item
                sx={{ width: "calc(100% - 44px)", wordWrap: "break-word" }}
              >
                {parts.map((part, index) => (
                  <Box
                    key={index}
                    component="span"
                    sx={{ fontWeight: part.highlight ? "bold" : "regular" }}
                  >
                    {part.text}
                  </Box>
                ))}
                <Typography variant="body2" color="text.secondary">
                  {option.structured_formatting.secondary_text}
                </Typography>
              </Grid>
            </Grid>
          </li>
        );
      }}
    />
  );
};

export default GooglePlacesAutocomplete;
