import { ITheme } from "survey-core";

export const defaultTheme: ITheme = {
  backgroundImage: "",
  backgroundImageFit: "cover",
  backgroundImageAttachment: "scroll",
  backgroundOpacity: 1,
  header: {
    height: 100,
    inheritWidthFrom: "survey",
    textAreaWidth: 500,
    overlapEnabled: false,
    backgroundImageOpacity: 1,
    backgroundImageFit: "contain",
    logoPositionX: "right",
    logoPositionY: "bottom",
    titlePositionX: "left",
    titlePositionY: "bottom",
    descriptionPositionX: "left",
    descriptionPositionY: "bottom",
    backgroundImage: "",
  },
  themeName: "plain",
  colorPalette: "light",
  isPanelless: true,
  cssVariables: {
    "--sjs-general-backcolor": "rgba(255, 255, 255, 1)",
    "--sjs-general-backcolor-dark": "rgba(248, 248, 248, 1)",
    "--sjs-general-backcolor-dim": "rgba(255, 255, 255, 1)",
    "--sjs-general-backcolor-dim-light": "rgba(255, 255, 255, 1)",
    "--sjs-general-backcolor-dim-dark": "rgba(243, 243, 243, 1)",
    "--sjs-general-forecolor": "rgba(0, 0, 0, 0.91)",
    "--sjs-general-forecolor-light": "rgba(0, 0, 0, 0.45)",
    "--sjs-general-dim-forecolor": "rgba(0, 0, 0, 0.91)",
    "--sjs-general-dim-forecolor-light": "rgba(0, 0, 0, 0.45)",
    "--sjs-primary-backcolor": "#0057a8",
    "--sjs-primary-backcolor-light": "rgba(0, 87, 168, 0.1)",
    "--sjs-primary-backcolor-dark": "rgba(0, 77, 148, 1)",
    "--sjs-primary-forecolor": "rgba(255, 255, 255, 1)",
    "--sjs-primary-forecolor-light": "rgba(255, 255, 255, 0.25)",
    "--sjs-base-unit": "8px",
    "--sjs-corner-radius": "4px",
    "--sjs-secondary-backcolor": "rgba(255, 152, 20, 1)",
    "--sjs-secondary-backcolor-light": "rgba(255, 152, 20, 0.1)",
    "--sjs-secondary-backcolor-semi-light": "rgba(255, 152, 20, 0.25)",
    "--sjs-secondary-forecolor": "rgba(255, 255, 255, 1)",
    "--sjs-secondary-forecolor-light": "rgba(255, 255, 255, 0.25)",
    "--sjs-shadow-small": "0px 0px 0px 1px rgba(0, 0, 0, 0.15)",
    "--sjs-shadow-small-reset": "0px 0px 0px 0px rgba(0, 0, 0, 0.15)",
    "--sjs-shadow-medium": "0px 0px 0px 1px rgba(0, 0, 0, 0.1)",
    "--sjs-shadow-large": "0px 8px 16px 0px rgba(0, 0, 0, 0.05)",
    "--sjs-shadow-inner": "0px 0px 0px 1px rgba(0, 0, 0, 0.15)",
    "--sjs-shadow-inner-reset": "0px 0px 0px 0px rgba(0, 0, 0, 0.15)",
    "--sjs-border-light": "rgba(0, 0, 0, 0.15)",
    "--sjs-border-default": "rgba(0, 0, 0, 0.15)",
    "--sjs-border-inside": "rgba(0, 0, 0, 0.16)",
    "--sjs-special-red": "rgba(229, 10, 62, 1)",
    "--sjs-special-red-light": "rgba(229, 10, 62, 0.1)",
    "--sjs-special-red-forecolor": "rgba(255, 255, 255, 1)",
    "--sjs-special-green": "rgba(25, 179, 148, 1)",
    "--sjs-special-green-light": "rgba(25, 179, 148, 0.1)",
    "--sjs-special-green-forecolor": "rgba(255, 255, 255, 1)",
    "--sjs-special-blue": "rgba(67, 127, 217, 1)",
    "--sjs-special-blue-light": "rgba(67, 127, 217, 0.1)",
    "--sjs-special-blue-forecolor": "rgba(255, 255, 255, 1)",
    "--sjs-special-yellow": "rgba(255, 152, 20, 1)",
    "--sjs-special-yellow-light": "rgba(255, 152, 20, 0.1)",
    "--sjs-special-yellow-forecolor": "rgba(255, 255, 255, 1)",
    "--sjs-article-font-xx-large-textDecoration": "none",
    "--sjs-article-font-xx-large-fontWeight": "700",
    "--sjs-article-font-xx-large-fontStyle": "normal",
    "--sjs-article-font-xx-large-fontStretch": "normal",
    "--sjs-article-font-xx-large-letterSpacing": "0",
    "--sjs-article-font-xx-large-lineHeight": "64px",
    "--sjs-article-font-xx-large-paragraphIndent": "0px",
    "--sjs-article-font-xx-large-textCase": "none",
    "--sjs-article-font-x-large-textDecoration": "none",
    "--sjs-article-font-x-large-fontWeight": "700",
    "--sjs-article-font-x-large-fontStyle": "normal",
    "--sjs-article-font-x-large-fontStretch": "normal",
    "--sjs-article-font-x-large-letterSpacing": "0",
    "--sjs-article-font-x-large-lineHeight": "56px",
    "--sjs-article-font-x-large-paragraphIndent": "0px",
    "--sjs-article-font-x-large-textCase": "none",
    "--sjs-article-font-large-textDecoration": "none",
    "--sjs-article-font-large-fontWeight": "700",
    "--sjs-article-font-large-fontStyle": "normal",
    "--sjs-article-font-large-fontStretch": "normal",
    "--sjs-article-font-large-letterSpacing": "0",
    "--sjs-article-font-large-lineHeight": "40px",
    "--sjs-article-font-large-paragraphIndent": "0px",
    "--sjs-article-font-large-textCase": "none",
    "--sjs-article-font-medium-textDecoration": "none",
    "--sjs-article-font-medium-fontWeight": "700",
    "--sjs-article-font-medium-fontStyle": "normal",
    "--sjs-article-font-medium-fontStretch": "normal",
    "--sjs-article-font-medium-letterSpacing": "0",
    "--sjs-article-font-medium-lineHeight": "32px",
    "--sjs-article-font-medium-paragraphIndent": "0px",
    "--sjs-article-font-medium-textCase": "none",
    "--sjs-article-font-default-textDecoration": "none",
    "--sjs-article-font-default-fontWeight": "400",
    "--sjs-article-font-default-fontStyle": "normal",
    "--sjs-article-font-default-fontStretch": "normal",
    "--sjs-article-font-default-letterSpacing": "0",
    "--sjs-article-font-default-lineHeight": "28px",
    "--sjs-article-font-default-paragraphIndent": "0px",
    "--sjs-article-font-default-textCase": "none",
    "--sjs-header-backcolor": "transparent",
    "--sjs-font-pagedescription-weight": "600",
    "--sjs-font-pagedescription-color": "rgba(0, 0, 0, 0.8)",
    "--sjs-font-questiondescription-color": "rgba(0, 0, 0, 0.8)",
  },
};
interface IModalDetails {
  title: string;
  description: string | JSX.Element;
  detailsRequired: boolean;
  detailsText: string;
  rteOptionalText?: string;
}
export const modalDetails: { [key: string]: IModalDetails } = {
  edit: {
    title: "Edit Report",
    description: "Edit the report to fix any mistakes and add information.",
    detailsRequired: false,
    detailsText: "",
  },
  modification_requested: {
    title: "Request Changes",
    description: (
      <>
        <b>Keep the report open</b> and ask for follow-up information from the
        reporting party. Mark as “Modification Requested” and provide a link for
        the reporting party to add their changes. Once they respond, it will be
        marked as “Pending.”
      </>
    ),
    detailsRequired: true,
    detailsText: "Details to provide to the individual.",
    rteOptionalText:
      "The report link to add changes will be automatically generated and attached to the email.",
  },
  approved: {
    title: "Approve Report",
    description: (
      <>
        <b>Close this report</b> with no immediate follow-up required. Mark as
        “Approved” and notify the reporting party.
      </>
    ),
    detailsRequired: false,
    detailsText:
      "Explain any actions taken and any next steps (if any) for the reporting party.",
  },
  rejected: {
    title: "Reject Report",
    description: (
      <>
        <b>Close the report</b> if it is not a police matter or cannot be
        processed. Mark as “Rejected” and notify the reporting party{" "}
      </>
    ),
    detailsRequired: true,
    detailsText: "Details to provide to the individual.",
  },
  escalated: {
    title: "Escalate Report",
    description: (
      <>
        <b>Close the report</b> with police timely follow-up required. Mark as
        “Escalated” and notify the reporting individual.
      </>
    ),
    detailsRequired: true,
    detailsText:
      "Explain what kind of follow-up the reporting party should expect and when.",
  },
};
