import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  Paper,
  Typography,
} from "@mui/material";
import React from "react";

// some random comment
interface DisabledComponentBannerProps {
  handleClose?: () => void;
  title?: string;
  message?: JSX.Element | string;
}

const DisabledComponentBanner: React.FC<DisabledComponentBannerProps> = ({
  handleClose,
  title = "This component is disabled",
  message,
}) => {
  const bannerStyle: React.CSSProperties = {
    backgroundColor: "#2196f3",
    color: "white",
    ...(handleClose
      ? {
          position: "relative",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          padding: "16px",
        }
      : {
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          width: "100%",
          borderRadius: "0px",
          padding: "16px 0px",
        }),
  };

  const iconButtonStyle: React.CSSProperties = {
    color: "white",
    position: "absolute",
    right: "8px",
    top: "8px",
  };

  return (
    <>
      <Paper style={bannerStyle} elevation={2}>
        <Typography variant="h6">{title}</Typography>
        {handleClose && (
          <IconButton onClick={handleClose} style={iconButtonStyle}>
            <CloseIcon />
          </IconButton>
        )}
      </Paper>
      {message && (
        <Typography
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
            padding: "16px",
          }}
        >
          {message}
        </Typography>
      )}
    </>
  );
};

interface DisabledComponentWithVideoProps {
  title?: string;
  youtubeUrl?: string;
}

const DisabledComponentWithVideo: React.FC<DisabledComponentWithVideoProps> = ({
  title = "This component is disabled",
  youtubeUrl,
}) => {
  const videoId = youtubeUrl?.split("v=")[1];
  const embedUrl = `https://www.youtube.com/embed/${videoId}`;

  return (
    <Box
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        gap: "16px",
        width: "100%",
      }}
    >
      <DisabledComponentBanner title={title} />
      <iframe
        width="100%"
        height="100%"
        src={embedUrl}
        title="YouTube video player"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        onError={(e: React.SyntheticEvent<HTMLIFrameElement, Event>) =>
          console.error("Error loading video", e)
        }
      ></iframe>
    </Box>
  );
};

interface DisabledComponentDialogProps {
  isDialogOpen: boolean;
  handleClose: () => void;
  title?: string;
  message?: JSX.Element | string;
  youtubeUrl?: string;
}
const DisabledComponentDialog = ({
  isDialogOpen,
  handleClose,
  title,
  message,
  youtubeUrl,
}: DisabledComponentDialogProps) => {
  const renderContent = () => {
    if (youtubeUrl) {
      return (
        <DisabledComponentWithVideo title={title} youtubeUrl={youtubeUrl} />
      );
    } else {
      return (
        <>
          <DisabledComponentBanner title={title} />
          <Typography
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              textAlign: "center",
              padding: "16px",
            }}
          >
            {message}
          </Typography>
        </>
      );
    }
  };

  const defaultStyles = {
    "& .MuiDialogContent-root": {
      padding: 0,
      width: "100%",
    },
    "& .MuiPaper-root": {
      width: "100%",
    },
  };

  const youtubeStyles = {
    "& .MuiDialog-paper": {
      margin: 0,
      minWidth: "80%",
      height: "80%",
      maxHeight: "100%",
      padding: "8px",
    },
    "& .MuiBox-root": {
      height: "100%",
      width: "100%",
      overflowX: "hidden",
    },
    "& .MuiPaper-root": {
      padding: "0",
    },
    "& .MuiDialogContent-root": {
      padding: "0",
      height: "100%",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
    },
  };

  return (
    <Dialog
      open={isDialogOpen}
      onClose={handleClose}
      sx={youtubeUrl ? youtubeStyles : defaultStyles}
    >
      <DialogContent>{renderContent()}</DialogContent>
      <DialogActions>
        <Button onClick={() => handleClose()} color="secondary">
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export { DisabledComponentBanner, DisabledComponentDialog };
