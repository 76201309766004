import { TextField, Typography } from "@mui/material";

interface IViewFieldProps {
  mode: "view";
  value: string;
  label: string;
}

interface IEditFieldProps {
  mode: "edit" | "add";
  value: string;
  label: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  error?: boolean;
  errorMessage?: string;
  required?: boolean;
  disabled?: boolean;
}
type ViewOrEditFieldProps = IViewFieldProps | IEditFieldProps;

const ViewOrEditTextField = (props: ViewOrEditFieldProps) => {
  if (props.mode === "view") {
    return (
      <>
        <Typography variant="h6">{props.label}</Typography>
        <Typography variant="body1">{props.value}</Typography>
      </>
    );
  }

  return (
    <TextField
      label={props.label}
      type="text"
      error={props.error}
      value={props.value}
      onChange={props.onChange}
      fullWidth
      margin="normal"
      style={{ backgroundColor: "white" }}
      variant="outlined"
      required={props.required}
      disabled={props.disabled}
    />
  );
};

export default ViewOrEditTextField;
