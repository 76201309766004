import { LockOutlined } from "@mui/icons-material";
import { Avatar, Link, Typography, Box } from "@mui/material";

import styles from "./Home.module.css";

export const HomeSignUp = (props) => {
  return (
    <div className={styles.paper}>
      <Avatar className={styles.avatar}>
        <LockOutlined />
      </Avatar>
      <Typography component="h1" variant="h5">
        Sign up
      </Typography>

      <div className={styles.instructions}>
        <Typography component="h1" variant="h5">
          Existing Service adding a New Member?
        </Typography>
        <Typography component="h1">
          Ask your organization administrator to Sign you up. If you don't know
          who your administrator is please email{" "}
          <Link
            rel="noopener noreferrer"
            target="_blank"
            href="mailto:support@tryrubicon.com"
          >
            support@tryrubicon.com
          </Link>
        </Typography>
        <Typography component="h1" variant="h5">
          New Service?
        </Typography>
        <Typography component="h4">
          Please reachout to Rubicon by email{" "}
          <Link
            rel="noopener noreferrer"
            target="_blank"
            href="mailto:support@tryrubicon.com"
          >
            support@tryrubicon.com
          </Link>{" "}
          or through{" "}
          <Link
            rel="noopener noreferrer"
            target="_blank"
            href="https://www.tryrubicon.com/"
          >
            TryRubicon.com
          </Link>{" "}
          to setup a new service account.
        </Typography>
      </div>
      {/* <form className={classes.form} noValidate>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <TextField
              autoComplete="fname"
              name="firstName"
              variant="outlined"
              required
              fullWidth
              id="firstName"
              label="First Name"
              autoFocus
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              variant="outlined"
              required
              fullWidth
              id="lastName"
              label="Last Name"
              name="lastName"
              autoComplete="lname"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              variant="outlined"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
            />
          </Grid>
        </Grid>
        <Button
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          className={classes.submit}
        >
          Sign Up
        </Button>
        <Grid container justify="flex-end">
          <Grid item>
            <Link
              className="sign-action"
              variant="body2"
              onClick={() => {
                props.onSignin();
              }}
            >
              Already have an account? Sign in
            </Link>
          </Grid>
        </Grid>
      </form> */}
      <Box>
        <Link
          className={styles.signAction}
          variant="body2"
          onClick={() => {
            props.onSignin();
          }}
        >
          Already have an account? Sign in
        </Link>
      </Box>
    </div>
  );
};
