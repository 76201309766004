import { useEffect, useState } from "react";
import { useParams } from "react-router";

import { apiUserClient } from "../../common/api-client";

function ReportV2Analytics({ cisoDisplay }: { cisoDisplay: boolean }) {
  const [iframeUrl, setIframeUrl] = useState("");
  const { orgId } = useParams();

  const getMetabaseURL = () => {
    apiUserClient
      .getMetabaseURL(cisoDisplay ? 3 : 2, orgId ?? "")
      .then((res) => {
        setIframeUrl(res.metabase_url);
      });
  };

  useEffect(() => {
    getMetabaseURL();
  }, []);

  return (
    <div style={{ display: "flex", width: "100%", height: "100%" }}>
      <iframe
        src={iframeUrl}
        style={{
          flexGrow: 1,
        }}
        allowTransparency
      ></iframe>
    </div>
  );
}

export default ReportV2Analytics;
