import {
  Analytics,
  AssessmentOutlined,
  Assignment,
  FindInPage,
  Settings,
} from "@mui/icons-material";
import { IconButton, Theme, Tooltip, useMediaQuery } from "@mui/material";
import { FC, useContext } from "react";
import { Menu, MenuItemLink, useSidebarState, useTheme } from "react-admin";

import { AuthStateContext } from "../../context";

interface Props {
  dense: boolean;
  logout: () => void;
  onMenuClick: () => void;
}

const RubiIntelMenu: FC<Props> = ({ onMenuClick, dense, logout }) => {
  const [theme] = useTheme();
  const isSmall = useMediaQuery((theme as Theme).breakpoints.down("sm"));
  const [open, setOpen] = useSidebarState();
  const { currentUser, getOrgIDFromURL } = useContext(AuthStateContext);
  const orgID = getOrgIDFromURL() ?? currentUser?.rubi_org_id ?? "";

  const hideSideBar = () => {
    setOpen(false);
  };

  return (
    <div className="menu">
      {open ? (
        <div className="sidebar-top">
          <div className="app-name">
            Rubi<span>INTEL</span>
          </div>
        </div>
      ) : (
        <div className="application-item">
          <Tooltip
            title="Intelligence Management System"
            placement="right"
            arrow
          >
            <IconButton
              color="secondary"
              aria-label="RubiINTEL"
              size="large"
              disableRipple
              sx={{ backgroundColor: "lightgrey" }}
            >
              <FindInPage />
            </IconButton>
          </Tooltip>
        </div>
      )}
      <div className="left-menu">
        <Menu.Item
          to={`/rubiintel/${orgID}/reports`}
          primaryText="Reports"
          leftIcon={<AssessmentOutlined />}
          sidebarIsOpen={open}
          dense={dense}
          onClick={isSmall ? hideSideBar : () => {}}
        />
        <MenuItemLink
          to={`/rubiintel/${orgID}/analytics`}
          primaryText="Dashboard"
          leftIcon={<Analytics />}
          onClick={isSmall ? hideSideBar : () => {}}
        />
        <MenuItemLink
          to={`/rubiintel/${orgID}/settings`}
          primaryText="Settings"
          leftIcon={<Settings />}
          onClick={isSmall ? hideSideBar : () => {}}
        />
        {/* //TODO Enable once we have a dashboards */}
        {/* <DashboardMenuItem sidebarIsOpen={open} /> */}
      </div>
    </div>
  );
};

export default RubiIntelMenu;
